.p-section {
  background-color: var(--white);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
  width: 100%;
  padding: 16px;
}
.p-chatbotInternal {
    /*  */
}
.p-chatbotInternal .item {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}