.page-productsRegist__subttl {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__subttl p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: bold;
}
.page-productsRegist__tableBox + .page-productsRegist__tableBox {
  margin-top: 16px;
}
.c-table-list {
}
.c-table-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-list table th,
.c-table-list table td {
  border: 1px solid #cfdae1;
  padding: 8px;
}
.c-table-list table th {
  width: 240px;
  font-weight: normal;
  text-align: left;
  background: #ebf1f3;
}
.c-table-list__note {
  font-size: 14px;
  margin-top: 8px;
}
.c-table-list__note.bold {
  font-weight: bold;
}
.c-table-list__note.red {
  color: #dc3e15;
}
.c-table-list__note.yellow {
  color: #ffcc00;
}
.c-table-list__note.green {
  color: #00bf98;
}
.c-table-list__note.blue {
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .c-table-list table,
  .c-table-list table tbody,
  .c-table-list table tr,
  .c-table-list table th,
  .c-table-list table td {
    width: 100%;
    display: block;
  }
  .c-table-list table tr + tr {
    margin-top: 8px;
  }
  .c-table-list table th {
    width: 100%;
    margin-bottom: -1px;
  }
  .c-table-list table td .base-t-800 {
    width: 100%;
  }
}
