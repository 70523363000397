.edit-container {
  width: 100%;
}
.edit-container > * + * {
  margin-top: 8px;
}
.edit-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.edit-row > * + * {
  margin-left: 8px;
}
.input-cell {
  width: 100%;
}
.button-cell {
  flex-grow: 0;
  flex-shrink: 0;
  width: 250px;
}
.info-text {
  font-weight: bold;
  min-height: 40px;
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .edit-row {
    flex-direction: column;
  }
  .edit-row > * + * {
    margin-left: 0px;
    margin-top: 8px;
  }
}
