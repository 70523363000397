.c-form__box__header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
.c-inputtext,
.c-textarea {
  width: 100%;
  font-size: 14px;
  color: #001d36;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #f9f9f9;
  padding: 12px 16px;
}

.p-regist__url {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .p-regist__url {
    flex-wrap: wrap;
  }
}

.p-regist__url__main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-regist__url__main input {
  width: 160px;
  margin: 0 4px;
  flex-shrink: 0;
}
@media screen and (min-width: 769px) {
  .p-regist__url__check {
    margin-left: auto;
  }
}
.p-regist__url__check p {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .p-regist__url__check p {
    margin-top: 16px;
  }
}
.p-regist__url__check p span {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 4px;
  flex-shrink: 0;
  position: relative;
}
.p-regist__url__check p.ok {
  color: #008ec9;
}
.p-regist__url__check p.ok span {
  background: #008ec9;
}
.p-regist__url__check p.ok span::after {
  content: '';
  width: 4px;
  height: 10px;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 8px;
  box-sizing: initial;
}
.p-regist__url__check p.ng {
  color: #ff0000;
}
.p-regist__url__check p.ng span {
  background: #ff0000;
}
.p-regist__url__check p.ng span::before,
.p-regist__url__check p.ng span::after {
  content: '';
  width: 14px;
  height: 4px;
  background: #fff;
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 7px);
}
.p-regist__url__check p.ng span::before {
  transform: rotate(45deg);
}
.p-regist__url__check p.ng span::after {
  transform: rotate(-45deg);
}
