.step-button-row {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
    .step-button-row {
        margin-left: 8px;
        margin-right: 8px;
    }
    .action-buttons-row {
        flex-wrap: wrap;
    }
    .clear-button-container {
        margin-top: 8px;
    }
}
