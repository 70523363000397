.c-loading-lg, .c-loading-md .c-loading-sm {
  width: fit-content;
  padding: 24px;
  margin-left: auto;
  margin-right: auto;
}

.c-loading-lg div, .c-loading-md div, .c-loading-sm div {
  margin: 0 auto;
}
.c-loading-sm div {
  width: 24px;
  height: 24px;
}
.c-loading-md div {
  width: 48px;
  height: 48px;
}
.c-loading-lg div {
  width: 64px;
  height: 64px;
}

.c-loading-lg svg, .c-loading-md svg, .c-loading-sm svg {
  color: #008EC9;
  display: block;
  animation: animation-spin 1.4s linear infinite;
}
.c-loading-lg circle, .c-loading-md circle, .c-loading-sm circle {
  stroke: currentColor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  animation: animation-circle 1.4s ease-in-out infinite;
}

.c-loading-lg p, .c-loading-md p, .c-loading-sm p {
  color: var(--gray700);
  margin-top: 16px;
}
.c-loading-sm p {
  font-size: 10px;
}
.c-loading-md p {
  font-size: 14px;
}
.c-loading-lg p {
  font-size: 18px;
}

@keyframes animation-spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes animation-circle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
