:root {
  --numDays: 7;
  --numHours: calc(24 * 6 * 2); /* 5 minute intervals */
  --timeHeight: calc(60px / (6 * 2));
  --calBgColor: #fff;
  --eventBorderColor: #f2d3d8;
  --eventColor1: #ffd6d1;
  --eventColor2: #fafaa3;
  --eventColor3: #e2f8ff;
  --eventColor4: #d1ffe6;
}
/* new */
.schedule-table {
  position: relative;
  width: 100%;
}

.header {
  display: grid;
  grid-template-columns: 40px 1fr;
}

.header__space {
  display: grid;
  width: 50px;
}

.header__main {
  margin: 4px;
  overflow: hidden;
}

.header__days {
  display: grid;
  grid-column: 2;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  min-width: 1400px;
}

.header__day {
  gap: 1em;
}

.header__date {
  margin-bottom: 4px !important; /* need important to override semantic ui */
  margin-top: auto !important; /* need important to override semantic ui */
  margin-left: auto !important; /* need important to override semantic ui */
  margin-right: auto !important; /* need important to override semantic ui */

  text-align: center;
  font-weight: bold;
}

.header__date-event {
  margin-bottom: 4px !important; /* need important to override semantic ui */
  margin-top: auto !important; /* need important to override semantic ui */
  margin-left: auto !important; /* need important to override semantic ui */
  margin-right: auto !important; /* need important to override semantic ui */
  text-align: center;
  font-weight: bold;
}

.body {
  display: grid;
  grid-template-columns: 40px 1fr;
}

.body__timeline {
  display: grid;
  grid-template-rows: repeat(var(--numHours), var(--timeHeight));
  height: calc(60px * 10);
  width: 50px;
  overflow: hidden;
}

.body__main {
  margin: 4px;
  overflow: auto;
}

.body__days {
  display: grid;
  grid-column: 2;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  min-width: 1400px;
  overflow-x: auto;
  height: calc(60px * 10);
}

.body__day {
  height: 60px;
  gap: 1em;
}

/* old */

.events {
  display: grid;
  grid-template-rows: repeat(var(--numHours), var(--timeHeight));
  border-radius: 5px;
  background: var(--calBgColor);
}

.title {
  font-weight: 600;
  margin-bottom: 0.25rem;
  -webkit-line-clamp: 1; 
  text-overflow: ellipsis; 
  overflow: hidden; 
  display: -webkit-box; 
  -webkit-box-orient: vertical;
}

.event {
  border: 1px solid var(--eventBorderColor);
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0 0.5rem;
  background: white;
  min-width: 1px;
}

.event:hover {
  color: #008ec9;
}

body {
  font-family: system-ui, sans-serif;
}

.corp-fi {
  background: var(--eventColor1);
}

.ent-law {
  background: var(--eventColor2);
}

.writing {
  background: var(--eventColor3);
}

.securities {
  background: var(--eventColor4);
}

.time-marker {
}

.mon {
}

.tue {
}

.wed {
}

.thu {
}

.fri {
}

.sat {
}

.sun {
}

.time {
  text-align: center;
  margin-bottom: 0 !important; /* need important to override semantic ui */
}

.link {
  color: #008ec9;
  font-weight: bold;
}
.link:hover {
  color: #008ec9;
  font-weight: bold;
}
