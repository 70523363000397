.body-editor {
  border: 1px solid #cfdae1;
}
.base-info {
  margin-left: 8px;
  display: inline-block;
  position: relative;
}
.base-info__body {
  width: 320px;
  font-size: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 12px;
  position: absolute;
  top: 35px;
  left: -80px;
  z-index: 10;
  display: none;
}
.base-info:hover .base-info__body {
  display: block;
}
