.p-siteControlWidget {
}
.p-siteControlWidget__actions {
  display: flex;
  flex-direction: row;
}
.p-siteControlWidget__actions > *:not(:first-child) {
  margin-left: 8px;
}
.p-section {
  background-color: var(--white);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
  width: 100%;
}
.p-section__header {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-section__body {
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
}
.p-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.p-summary__item {
  display: flex;
  padding: 8px;
}
.p-summary__item__header {
  width: 240px;
}
