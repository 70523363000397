.container {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 16px;
}
.title {
  color: var(--Colors-Gray-700, #3f3f46);
  text-align: center;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 600 14px/143% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.button {
  cursor: pointer;
}
