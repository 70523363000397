.label {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
