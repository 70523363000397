.c-accordion {
}
.c-accordion__item {
}
.c-accordion__item + .c-accordion__item {
  margin-top: 16px;
}
.c-accordion__item__header {
  cursor: pointer;
  width: 100%;
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-accordion__item__header > p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.c-accordion__item__header .ic {
  margin-left: auto;
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}
.c-accordion__item__header .ic p {
}
.c-accordion__item__header .ic span {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #ebf1f3;
  display: block;
  position: relative;
  margin-left: 4px;
}
.c-accordion__item__header .ic span::before,
.c-accordion__item__header .ic span::after {
  content: '';
  display: block;
  width: 12px;
  height: 2px;
  background: #008ec9;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 6px);
}
.c-accordion__item__header .ic span::after {
  transform: rotate(90deg);
}
.c-accordion__item__body {
  width: 100%;
  border: 1px solid #cfdae1;
  border-top: none;
  background: #f9f9f9;
  padding: 16px;
  display: none;
}
.c-accordion__item__body__inner {
  width: 100%;
  background: #fff;
  padding: 16px;
}
.c-accordion__item__body__btns {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.c-accordion__item__body__btns * + * {
  margin-left: 16px;
}
.c-accordion__item__body__btns__txt {
  color: #4a6c7a;
  text-decoration: underline;
}
.c-accordion__item.is-active .c-accordion__item__header .ic {
  color: #4a6c7a;
}
.c-accordion__item.is-active .c-accordion__item__header .ic span::after {
  transform: rotate(0);
}
.c-accordion__item.is-active .c-accordion__item__body {
  display: block;
}

.c-accordion__item.bg .c-accordion__item__header {
  background: #f9f9f9;
}
.c-accordion__item.bg .c-accordion__item__body {
  background: #fff;
}
.acHeader {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.acHeader__ttl {
  font-size: 14px;
  font-weight: bold;
}
.acHeader a {
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .c-accordion__item__header {
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 16px 8px;
  }
  .c-accordion__item__header > p {
    font-size: 14px;
  }
  .c-accordion__item__header .ic p {
    display: none;
  }
  .c-accordion__item__body {
    border: none;
    padding: 8px;
  }
  .c-accordion__item__body__inner {
    background: transparent;
    padding: 0;
  }
  .c-accordion__item.bg .c-accordion__item__header {
    border-top: 1px solid #cfdae1;
    border-left: 1px solid #cfdae1;
    border-right: 1px solid #cfdae1;
  }
  .c-accordion__item.bg .c-accordion__item__body {
    border-bottom: 1px solid #cfdae1;
    border-left: 1px solid #cfdae1;
    border-right: 1px solid #cfdae1;
  }
}
