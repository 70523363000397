.condition-group-divider {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  padding: 16px;
  &:before {
    content: '';
    background: #000;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}
.add-new {
  cursor: pointer;
  color: #008ec9;
  display: flex;
  gap: 4px;
  align-items: center;
}
.add-new i {
  opacity: 1 !important;
}
.add-new p {
  margin-left: 4px;
}
