.info-trigger {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.info-trigger:hover {
  text-decoration: none;
  color: #14aaf5;
}
