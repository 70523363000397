/* Override Semantic UI default scrollbar styles for timepicker */

.rc-time-picker {
  vertical-align: middle;
  width: 85px;
}

.rc-time-picker-input {
  width: 100%;
  min-height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  padding: 4px 8px;
  color: #000000;
}

.rc-time-picker-clear {
  top: 8px;
}

.rc-time-picker-overlay {
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.4); */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
