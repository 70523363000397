.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.actionButton:hover {
  background-color: var(--color-gray-100);
}

.actionButton.red:hover {
  background-color: var(--color-red-100);
}

.actionButton i {
  font-size: 16px;
}

.actionButton p {
  margin: 0;
  font-size: 14px;
}

.actionButton.red i,
.actionButton.red p {
  color: #dc2626;
}
