.container {
  display: flex;
  gap: 16px;
  margin-top: 8px;
  width: 100%;
  justify-content: center;
  font-size: 12px;
  color: var(--Colors-Gray-500, #71717a);
  font-weight: 400;
  white-space: nowrap;
  line-height: 133%;
}
.item {
  display: flex;
  gap: 4px;
}
.session-marker {
  background-color: var(--Colors-Primary-100, #e0f3fe);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: auto 0;
}
.text {
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
}
.conversion-marker {
  background-color: var(--Warning-500, #f59e0b);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: auto 0;
}
