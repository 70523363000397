.c-subnav li {
  height: 44px;
}
.c-subnav li:not(:first-child) {
  margin-top: 4px;
}
.c-subnav li a {
  width: 100%;
  height: 100%;
  color: var(--gray500);
  font-size: var(--text-md-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-md-height);
  border-radius: var(--radius-sm);
  padding: 0 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-subnav li a:hover {
  color: var(--gray600);
  background-color: var(--gray100);
}
.c-subnav li a:active {
  color: var(--gray600);
  background-color: var(--gray100);
  box-shadow: var(--ring-gray-shadow-xs);
}
.c-subnav li.is-active a {
  color: var(--primary700);
  background-color: var(--white);
  box-shadow: var(--shadow-xs);
}
