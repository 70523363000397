.section {
}
.container {
  background-color: var(--white);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
  width: 100%;
  padding: 32px;
}
.header {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
}
.header h2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
