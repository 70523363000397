.container {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
}

.formGroup {
  display: flex;
  gap: 16px;
}

.formGroup + .formGroup {
  margin-top: 16px;
}

.inputWrapper {
  flex: 1;
}

.profilePictures {
  padding: 24px 0;
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Black, #1e202f);
  text-transform: capitalize;
  margin-bottom: 24px;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.imageUploadButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--White, #fff);
  border: 1px dashed rgba(62, 140, 196, 1);
  border-radius: 8px;
  padding: 80px;
  cursor: pointer;
}

.imageUploadButton img {
  width: 46px;
  height: 46px;
  margin-bottom: 16px;
}

.imageUploadButton span {
  font-size: 18px;
  color: var(--color-17, #3e8cc4);
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .imageGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .imageUploadButton {
    padding: 40px;
  }
}

.add-new {
  color: #008ec9;
  display: flex;
  gap: 4px;
  align-items: center;
}
.add-new i {
  opacity: 1 !important;
}
.add-new p {
  margin-left: 4px;
}
