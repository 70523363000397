.container {
  align-self: stretch;
  border-radius: var(--Dimension-Radius-xl, 12px);
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 100%;
}
.header {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 20px 24px;
}
.header-inner {
  display: flex;
  padding-right: 80px;
  gap: 20px;
}
.header-text {
  color: var(--Color-Gray-700, #3f3f46);
  margin: auto 0;
  font: 600 18px/156% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.header-select {
  display: flex;
  gap: 8px;
  font-weight: 400;
  min-width: 200px;
}
.graph-container {
  height: 500px;
  justify-content: center;
  overflow-x: auto;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 12px;
  color: var(--Color-Gray-500, #71717a);
  font-weight: 400;
  line-height: 133%;
  padding: 17px 24px;
}
