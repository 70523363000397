.acHeader {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.acHeader__ttl {
  font-size: 14px;
  font-weight: bold;
}
.acHeader a {
  margin-left: auto;
}
.acBody {
}
.acBody__btns {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.acBody__btns > * + * {
  margin-left: 8px;
}
.detailInfo {
}
.detailInfo + .detailInfo {
  border-top: 1px solid #cfdae1;
  padding-top: 8px;
  margin-top: 8px;
}
.detailInfo__ttl {
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
}
.detailInfo__ttl > p {
  font-weight: bold;
  display: inline-block;
}
.detailInfo__list {
  padding-left: 24px;
}
.detailInfo__list li {
  list-style: disc;
}
.detailInfoEdit {
}
.detailInfoEdit + .detailInfoEdit {
  margin-top: 8px;
}
.detailInfoEditTtl,
.detailInfoEditBody {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.detailInfoEditTtl {
  margin-bottom: 4px;
}
.detailInfoEditTtl > * {
  display: table-cell;
  vertical-align: middle;
}
.detailInfoEditBody > * {
  display: table-cell;
  vertical-align: top;
}
.detailInfoEditTtl > *:nth-child(1),
.detailInfoEditBody > *:nth-child(1) {
  padding-right: 8px;
}
.detailInfoEditBody + .detailInfoEditBody {
  margin-top: 8px;
}
.detailInfoEditBody li {
}
.scheds {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.scheds > li {
  width: 100%;
}
.scheds > li + li {
  margin-top: 8px;
}
.scheds > li.half {
  width: 50%;
}
.scheds > li.borderTop {
  border-top: 1px solid #cfdae1;
  padding-top: 8px;
  margin-top: 8px;
}
.scheds > li.borderBottom {
  border-bottom: 1px solid #cfdae1;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.scheds__ttl {
  font-weight: bold;
  margin-bottom: 4px;
}
.scheds__ttl.blue {
  color: #008ec9;
}
.scheds__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 10px;
}
.scheds__body + .scheds__body {
  margin-top: 8px;
}
.scheds__body__content {
  width: 100%;
}
.scheds__body__content__body {
  display: flex;
  justify-content: flex-start;
}
.scheds__body__form {
  width: 100%;
}
.scheds__body__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 24px;
}
.scheds__body__item p {
  margin-right: 4px;
  flex-shrink: 0;
}
.scheds__body__item span {
}
.scheds__body__item span.blue {
  color: #008ec9;
  font-weight: bold;
}
.scheds__body__item label {
  width: 138px;
}
.scheds__cell {
  display: table;
  table-layout: fixed;
}
.scheds__cell > *:first-child {
  display: table-cell;
  padding-right: 8px;
}
.scheds__body__placelist {
  width: 100%;
  height: 200px;
  background: #fff;
  border: 1px solid #cfdae1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 1;
  display: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scheds__body__placelist.is-active {
  display: block;
}
.scheds__body__placelist li {
  font-size: 14px;
  padding: 10px 12px;
  cursor: pointer;
}
.scheds__body__placelist li:nth-child(odd) {
  background: #fff;
}
.scheds__body__placelist li:nth-child(even) {
  background: #f9f9f9;
}
.select {
  margin-bottom: 8px;
}
.select__ttl {
  margin-bottom: 4px;
}
.select__box {
  display: flex;
}
.select__box a {
  height: 40px !important;
  margin-left: 16px;
  flex-shrink: 0;
}
.selectFrame {
  width: 100%;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  overflow: hidden;
}
.selectFrame + .selectFrame {
  margin-top: 8px;
}
.selectFrame2 {
}
.selectFrame__header {
  width: 100%;
  height: 40px;
  background: #ebf1f3;
  border-bottom: 1px solid #cfdae1;
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectFrame2__header {
  padding: 8px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectFrame__header__ttl {
  font-size: 14px;
  font-weight: bold;
}
.selectFrame__header__ttl.blue {
  color: #008ec9;
}
.selectFrame__header__delete {
  font-size: 12px;
  color: #dc3e15;
  margin-left: auto;
}
.selectFrame__body {
  padding: 8px;
}
.selectFrame2__body {
}
.selectFrame__body > div + div {
  margin-top: 8px;
}
.c-table-list {
}
.c-table-list > table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-list > table th,
.c-table-list > table td {
  border: 1px solid #cfdae1;
  padding: 8px;
}
.c-table-list > table th {
  width: 240px;
  font-weight: normal;
  text-align: left;
  background: #ebf1f3;
}
.c-table-list__note {
  font-size: 14px;
  margin-top: 8px;
}
.c-table-list__note.bold {
  font-weight: bold;
}
.c-table-list__note.red {
  color: #dc3e15;
}
.c-table-list__note.yellow {
  color: #ffcc00;
}
.c-table-list__note.green {
  color: #00bf98;
}
.c-table-list__note.blue {
  color: #008ec9;
}
.c-table-list__btns {
  width: 88px;
}
.c-table-list__btns.hidden {
  visibility: collapse;
}
@media screen and (max-width: 768px) {
  .duration-input-box {
    max-width: 220px;
  }
  .start-time-pickup-container {
    flex-wrap: wrap;
  }
  .start-time-pickup-container:first-child {
    flex-basis: 100%;
  }
  .acHeader {
    display: block;
  }
  .acBody__btns {
    justify-content: center;
  }
  .acBody__btns > * + * {
    margin: 0 8px;
  }
  .detailInfoEditTtl > *,
  .detailInfoEditBody > * {
    display: block;
  }
  .detailInfoEditTtl > *:last-child {
    /* display: none !important; */
  }
  .detailInfoEditBody li + li {
    margin-top: 4px;
  }
  .scheds > li + li {
    border-top: 1px solid #cfdae1;
    padding-top: 8px;
  }
  .scheds > li.half {
    width: 100%;
  }
  .scheds > li.borderBottom {
    border: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .scheds__body__item {
    width: 100% !important;
    margin-right: 0;
    justify-content: space-between;
  }
  .scheds__body__item + .scheds__body__item {
    margin-top: 8px;
  }
  .scheds__body__item.base-t-160 {
    width: 100% !important;
  }
  .scheds__cell {
    display: block;
  }
  .scheds__cell > *:first-child {
    padding-right: 0;
    display: block;
  }
  .scheds__cell > * + * {
    margin-top: 8px;
  }
  .c-table-list > table,
  .c-table-list > table tbody,
  .c-table-list > table tr,
  .c-table-list > table th,
  .c-table-list > table td {
    width: 100%;
    display: block;
  }
  .c-table-list > table tr + tr {
    margin-top: 8px;
  }
  .c-table-list > table th {
    width: 100%;
  }
  .c-table-list > table td {
    margin-top: -1px;
  }
  .c-table-list > table td .base-t-800 {
    width: 100%;
  }
  .c-table-list__btns {
    width: 100%;
  }
}

.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul {
    border-bottom: 1px solid #cfdae1;
    margin-bottom: 8px;
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
}
