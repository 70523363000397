.p-dashboardData__body__box {
  border-radius: 12px;
  background-color: var(--gray50);
  padding: 16px 16px 0 16px;
}
.p-dashboardData__body__box:not(:first-child) {
  margin-top: 24px;
}
.p-dashboardData__body__box__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.p-dashboardData__body__box__ttl__actions {
  margin-left: auto;
}
.p-dashboardDataList {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.p-dashboardDataList__item {
  width: calc(50% - 8px);
  border-radius: var(--radius-xl);
  /* border: 1px solid var(--gray200);
  background-color: var(--white); */
  margin-bottom: 16px;
}
.p-dashboardDataList__item:nth-child(even) {
  margin-left: 16px;
}
.p-dashboardDataList__item.full {
  width: 100%;
}
.p-dashboardDataList__item.full:nth-child(even) {
  margin-left: 0;
}
.p-dashboardDataList__item__header {
  width: 100%;
  border-bottom: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-dashboardDataList__item__header__actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.p-dashboardDataList__item__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-dashboardDataList__item__header__actions a {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-dashboardDataList__item__header__actions .on {
  font-size: 32px;
  color: var(--primary500);
}
.p-dashboardDataList__item__header__actions .off {
  font-size: 32px;
  color: var(--gray300);
}
.p-dashboardDataList__item__body {
  padding: 24px;
}
.p-dashboardDataList__item__body__ttl {
}
.p-dashboardDataList__item__body__ttl p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray500);
}
.p-dashboardDataList__item__body__ttl p span {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  color: var(--gray700);
}
.p-dashboardDataList__item__body__main {
  height: 100%;
  margin-top: 16px;
  position: relative;
}
.p-dashboardDataList__item__body.summary table th,
.p-dashboardDataList__item__body.summary table tr {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .p-dashboardDataList__item {
    width: 100%;
  }
  .p-dashboardDataList__item:nth-child(even) {
    margin-left: 0;
  }
}
