.back-arrow-circle {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #008ec9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-arrow-circle img {
  width: 24px;
  height: 24px;
}
