@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPMincho:wght@400;700&family=Klee+One&family=Kosugi+Maru&family=M+PLUS+1p:wght@400;700;900&family=Noto+Sans+JP:wght@400;700;900&family=Noto+Serif+JP:wght@400;700;900&family=Shippori+Mincho:wght@400;700&family=Zen+Kaku+Gothic+New:wght@400;700;900&display=swap');

.base-wrap {
  width: 100%;
  position: relative;
}

.opacity-hover {
  opacity: 0.25;
}
.opacity-hover:hover {
  opacity: 1;
}
