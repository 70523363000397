/**
 * This code was generated by Builder.io.
 */
.promotionList {
  background-color: #fff;
  border: 1px solid #f3f6f8;
  border-radius: 12px;
  overflow: hidden;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #f3f6f8;
}

.listTitle {
  font-size: 18px;
  font-weight: 600;
  color: #393d3f;
  margin: 0;
}

.periodSelector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.periodLabel {
  font-size: 12px;
  color: #b5b8bc;
}

.periodSelect {
  padding: 8px 12px;
  border: 1px solid #dadde2;
  border-radius: 8px;
  font-size: 14px;
  color: #393d3f;
}

.chart {
  padding: 24px;
  border-bottom: 1px solid #f3f6f8;
}

.chartImage {
  width: 100%;
  height: auto;
}

.chartLegend {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 8px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legendColor {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.blue {
  background-color: #3e8cc4;
}

.orange {
  background-color: #ff8360;
}

.legendText {
  font-size: 12px;
  color: #b5b8bc;
}

.listTabs {
  display: flex;
  border-bottom: 1px solid #f3f6f8;
}

.tabButton {
  padding: 8px 16px;
  background: none;
  border: none;
  font-size: 14px;
  color: #393d3f;
  cursor: pointer;
}

.tabButton.active {
  font-weight: 600;
  color: #3e8cc4;
  border-bottom: 2px solid #3e8cc4;
}

.promotionTable {
  width: 100%;
  border-collapse: collapse;
}

.promotionTable th,
.promotionTable td {
  padding: 16px 24px;
  text-align: left;
  border-bottom: 1px solid #f3f6f8;
}

.promotionTable th {
  font-size: 14px;
  font-weight: 400;
  color: #b5b8bc;
  text-transform: capitalize;
}

.promotionTable td {
  font-size: 16px;
  color: #393d3f;
}

.promoCode {
  font-weight: 700;
  color: #3e8cc4;
}

.timePeriod {
  background-color: #f3f6f8;
  border-radius: 100px;
  padding: 4px 8px;
  font-size: 14px;
  color: #707282;
}

.detailsButton {
  padding: 10px 16px;
  background-color: #f3f6f8;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: #393d3f;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b5b8bc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3e8cc4;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

@media (max-width: 991px) {
  .listHeader,
  .promotionTable th,
  .promotionTable td {
    padding: 16px;
  }

  .promotionTable {
    font-size: 14px;
  }
}
