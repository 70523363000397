.c-tab-box {
}
.c-tab-box__tab {
  width: 100%;
  margin-bottom: -1px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.c-tab-box__tab li {
  width: 160px;
  min-height: 40px;
  font-size: 13px;
  font-weight: bold;
  color: #9bacb5;
  border-radius: 8px 8px 0 0;
  border: 1px solid #cfdae1;
  background: #cfdae1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-tab-box__tab li.is-active {
  color: #008ec9;
  background: #fff;
  border-bottom: 1px solid #fff;
}
.c-tab-box__tab li + li {
  margin-left: 8px;
}
.c-tab-box__tab li a {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0px 4px;
}
.c-tab-box__box {
  display: none;
}
.c-tab-box__box.is-active {
  display: block;
}
.page-productsEdit__select {
  margin-bottom: 8px;
  width: 100%;
}
.page-productsEdit__select__ttl {
  margin-bottom: 4px;
}
.page-productsEdit__select__box {
  display: flex;
}
.page-productsEdit__select__box a {
  height: 40px !important;
  margin-left: 16px;
  flex-shrink: 0;
}
.page-productsRegist__date {
}
.page-productsRegist__date:not(:last-child) {
  /* border-bottom: 1px solid #cfdae1; */
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.page-productsRegist__date__ttl {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 4px !important;
}
.page-productsRegist__date__range {
  width: 100%;
  max-width: 320px;
}
.page-productsRegist__per__group__unit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}
.page-productsRegist__per__group__unit__select {
  max-width: 200;
}
.page-productsRegist__priceMain__ttl {
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 8px !important;
}
.page-productsRegist__priceMain__ttl.borderTop {
  border-top: 1px solid #cfdae1;
  padding-top: 8px;
  margin-top: 8px;
}
.back-arrow-circle {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #008ec9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-arrow-circle img {
  width: 24px;
  height: 24px;
}
.package-start-time-section + .package-start-time-section {
  border-top: 1px solid #cfdae1;
  padding-top: 8px;
}
.package-start-time-label {
  padding-top: 8px;
  padding-bottom: 8px;
}
.package-start-time-component-section + .package-start-time-component-section {
  padding-top: 16px;
}
@media screen and (max-width: 768px) {
  .c-tab-box__tab {
    padding: 0 8px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-tab-box__tab li {
    width: 112px;
    font-size: 12px;
    flex-shrink: 0;
  }
  .c-tab-box__box {
    margin-top: 0;
  }
  .header-product-title {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.acBody {
}
.acBody__btns {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.acBody__btns > * + * {
  margin-left: 8px;
}
.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul {
    border-bottom: 1px solid #cfdae1;
    margin-bottom: 8px;
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
}

.addon__item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.addon__item__selector {
  width: calc((100% - 80px) / 2);
  margin-right: 8px;
}


/*------------------------------------------------------------------------------
table-main
------------------------------------------------------------------------------*/
.c-table-main {
  position: relative;
  scroll-margin-top: 96px;
  width: 100%;
}
.c-table-main__thead {
  background: #fff;
  border-bottom: 2px solid #cfdae1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: sticky;
  top: -1px;
  z-index: 2;
}
.c-table-main__thead .desc,
.c-table-main__thead .asc {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
}
.c-table-main__thead th:hover {
  background: #f9f9f9;
}
.c-table-main__thead .desc {
  border-width: 6px 4px 0 4px;
  border-color: #008ec9 transparent transparent transparent;
  margin-top: -2px;
}
.c-table-main__thead .asc {
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #008ec9 transparent;
  margin-top: -4px;
}
.c-table-main__tbody {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.c-table-main__tbody__header {
}
.c-table-main table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table-main tr:nth-child(odd) {
  background: #fff;
}
.c-table-main tr:nth-child(even) {
  background: #f9f9f9;
}
.c-table-main th,
.c-table-main td {
  padding: 8px;
  border: 1px solid #cfdae1;
}
.c-table-main th {
  height: 32px;
  text-align: center;
}
.c-table-main th:first-child {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
}
.c-table-main.mobile-header th:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
  pointer-events: none;
}
.c-table-main td {
}
.c-table-main td:first-child {
  background: #fff;
  left: 0;
}
.c-table-main.mobile-header td:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
  pointer-events: none;
}
.c-table-main td a,
.c-table-main th a {
  font-weight: bold;
  color: #008ec9;
}
.c-table-main td a:hover {
  opacity: 0.8;
}
.c-table-main__btn {
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-table-main__btn.prev {
  left: -16px;
}
.c-table-main__btn.next {
  right: -16px;
}
.c-table-main__btn p {
  width: 24px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  position: sticky;
  /* top: calc(50% - 40px); NOTE(goro) probably something wring */
  cursor: pointer;
}
.c-table-main__btn p::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(-45deg);
  top: calc(50% - 8px);
}
.c-table-main__btn.prev p {
  border-radius: 0 8px 8px 0;
}
.c-table-main__btn.next p {
  border-radius: 8px 0 0 8px;
}
.c-table-main__btn.prev p::after {
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  left: 6px;
}
.c-table-main__btn.next p::after {
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  right: 8px;
}
.c-table-main__btn p:hover {
  background: rgba(0, 0, 0, 0.6);
}
.c-table-main__btn.next::before {
  content: '';
  width: 48px;
  height: calc(100% - 2px);
  border-right: 1px solid #cfdae1;
  position: absolute;
  top: 1px;
  right: 16px;
}
@media screen and (max-width: 768px) {
  .c-table-main__thead {
    display: none;
  }
  .c-table-main__tbody__header {
    display: none !important;
  }
  .c-table-main__tbody table {
  }
  .c-table-main__tbody tr {
    background: #fff !important;
    /* border-bottom: 1px solid #CFDAE1; */
    padding: 8px;
    display: block;
  }
  .c-table-main tr + * {
    margin-top: 16px;
  }
  .c-table-main tr > * + * {
    margin-top: 8px;
  }
  .c-table-main th,
  .c-table-main td {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .c-table-main th[class^='base-t-'],
  .c-table-main td[class^='base-t-'] {
    width: 100% !important;
  }
  .c-table-main th {
    height: 48px !important;
  }
  .c-table-main th::before {
    display: none;
  }
  .c-table-main th a {
    width: 100% !important;
    height: 48px !important;
    border-radius: 4px !important;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 8px;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .c-table-main th a img {
    width: 32px !important;
    height: 32px !important;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .c-table-main th a::after {
    content: attr(data-text) '';
  }
  .c-table-main th a::before {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 2px solid #9bacb5;
    border-right: 2px solid #9bacb5;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 12px;
  }
  .c-table-main td::before {
    content: attr(data-text) '';
    width: 96px;
    min-height: 24px;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
  }
  .c-table-main__tbody__spMore {
    width: calc(100% + 16px);
    height: 32px;
    font-weight: bold;
    color: #008ec9;
    border-top: 1px solid #cfdae1;
    margin-left: -8px;
    margin-bottom: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-table-main__tbody__spMore::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #008ec9 transparent transparent transparent;
    display: block;
    margin-left: 8px;
  }
  .c-table-main__tbody__spMore.arrowUp::after {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #008ec9 transparent;
  }
  .c-table-main__btn {
    display: none;
  }
}
