/**
 * This code was generated by Builder.io.
 */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white, #fff);
  box-shadow: 0 -4px 16.7px 0 #e0eefb;
  padding: 24px;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.resetButton {
  background: none;
  border: none;
  color: var(--Dark, #262b2d);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.actions {
  margin-left: auto;
  align-items: center;
  display: flex;
  gap: 24px;
}

.saveButton {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--colour-9, #b5b8bc);
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  color: var(--White, #fff);
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}

.saveButton img {
  width: 13px;
  height: 13px;
}

@media (max-width: 991px) {
  .footer {
    padding: 20px;
  }
}
