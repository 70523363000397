.page-productsRegist__priceMain {
  margin-top: 8px;
}
.page-productsRegist__priceMain__ttl {
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 8px;
}
.page-productsRegist__priceMain__age {
  display: flex;
  justify-content: flex-start;
}
.page-productsRegist__priceMain__age p {
  line-height: 40px;
  padding: 0 8px;
}
.page-productsRegist__priceMain__price {
  display: flex;
  justify-content: flex-start;
}
.page-productsRegist__priceMain__price p {
  font-weight: bold;
  line-height: 40px;
  padding: 0 8px;
  margin: 0px;
}
.page-productsRegist__priceMain__unitRow {
  padding-bottom: 8px;
}
.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
td .per-group-add-delete {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
  td.per-group-add-delete {
    width: 100%;
  }
}
