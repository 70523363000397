.g-section {
  margin: 0;
}

.p-search__bottom {
  width: 100%;
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.center {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center > *:not(:last-child) {
  margin-right: 12px;
}


/* search */
.p-search {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-search__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-search__header__title {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
  margin: 0;
}
.p-search__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-search__header__actions > *:not(:last-child) {
  margin-right: 12px;
}

.p-search__body {
  width: 100%;
  padding: 16px 24px;
}
.p-search__body__item {
  display: flex;
}
.p-search__body__item > * {
  flex-shrink: 1;
}
.p-search__body__item:not(:first-child) > * {
  margin-top: 16px;
}
.p-search__body__item > *:not(:last-child) {
  margin-right: 16px;
}
.p-search__body__item:last-child {
  padding-bottom: 16px;
}
.p-search__body__item__flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.p-search__body__item__flex > *:not(:last-child) {
  margin-right: 16px;
}
.p-search__body__item__radiotitle {
  font-size: var(--text-sm-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
}
[class^='p-search__bottom'] {
  width: 100%;
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
[class^='p-search__bottom'][class*='center'] {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
[class^='p-search__bottom'][class*='center'] > *:not(:last-child) {
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  .p-search__body__item {
    display: block;
  }
  .p-search__body__item > * {
    margin-right: 0 !important;
  }
  .p-search__body__item > *:not(:first-child) {
    margin-top: 16px;
  }
  .p-search__body__item__radiotitle {
    width: 100%;
    margin-bottom: 8px;
  }
}

/* dropdown */
.c-dropdown {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-dropdown__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-dropdown__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.c-dropdown__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-dropdown__body > i::before {
  line-height: 20px;
}
.c-dropdown__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-dropdown__body label {
  width: 100%;
  position: relative;
}
.c-dropdown__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}

.c-dropdown__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-dropdown__body__selected ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -4px;
}
.c-dropdown__body__selected ul li {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  background-color: var(--gray100);
  border-radius: var(--radius-sm);
  padding: 3px 4px 3px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-dropdown__body__selected ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.c-dropdown__body__selected ul li i::before {
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  color: var(--gray400);
  content: "\e9c8";
}

.c-dropdown.is-hover {
  border-color: var(--primary200);
}
.c-dropdown.is-active {
  border-color: var(--primary700);
}
.c-dropdown.is-input .c-dropdown__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-dropdown.is-active .c-dropdown__body__title {
  color: var(--primary700);
}

.c-dropdown__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.c-dropdown__menu {
  width: 100%;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.c-dropdown__menu.is-active {
  display: block;
}
.c-dropdown__menu__item {
  padding: 8px 10px;
  position: relative;
}
.c-dropdown__menu__item:hover {
  background-color: var(--gray50);
}
.c-dropdown__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.c-dropdown__menu__item i {
  opacity: 0;
}
.c-dropdown__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.c-dropdown__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.c-dropdown__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}
.c-dropdown__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}

/* calendar */
.c-calendar {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-calendar__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-calendar__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-time-outline' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\f006";
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
}
.c-calendar__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-calendar__body > i::before {
  line-height: 20px;
}
.c-calendar__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-calendar__body label {
  width: 100%;
  position: relative;
}
.c-calendar__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}

.c-calendar__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-calendar__body__selected p {
  width: 100%;
  height: 24px;
  padding: 0 28px 0 0px;
  font-size: var(--text-sm-size);
  display: block;
}
.c-calendar.is-hover {
  border-color: var(--primary200);
}
.c-calendar.is-active {
  border-color: var(--primary700);
}
.c-calendar.is-input .c-calendar__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-calendar.is-active .c-calendar__body__title {
  color: var(--primary700);
}

.c-calendar__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.c-calendar__menu {
  /* width: 320px; */
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 4px;
  position: absolute;
  /* left: calc(50% - 160px); */
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.c-calendar__menu.is-active {
  display: block;
}
.c-calendar__menu__header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-calendar__menu__header a {
  width: 40px;
  height: 40px;
  position: relative;
}
.c-calendar__menu__header a::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid var(--primary600);
  border-left: 2px solid var(--primary600);
  transform: rotate(-45deg);
  position: absolute;
  top: 16px;
  left: 16px;
}
.c-calendar__menu__header a:last-child {
  transform: scale(-1, 1);
}
.c-calendar__menu__header a:hover {
  background-color: var(--primary50);  
}
.c-calendar__menu__header p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.c-calendar__menu__body__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.c-calendar__menu__body__header li {
  width: calc(100% / 7);
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-calendar__menu__body__day {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-calendar__menu__body__day li {
  width: calc(100% / 7);
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-calendar__menu__body__day li.disabled {
  color: var(--gray300);
  pointer-events: none;
}
.c-calendar__menu__body__day li.start {
  color: var(--white);
  background-color: var(--primary600);
  border-radius: 4px 0 0 4px;
}
.c-calendar__menu__body__day li.end {
  color: var(--white);
  background-color: var(--primary600);
  border-radius: 0 4px 4px 0;
}
.c-calendar__menu__body__day li.between {
  background-color: var(--primary50);
}
.c-calendar__menu__body__day li:not(.start):not(.end):not(.between):hover {
  background-color: var(--gray50);
}

.c-inputcontent-sm > span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-left: 24px;
  display: block;
}
.c-inputcontent-md > span {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 28px;
  display: block;
}

/* checkbox */
[class^="c-checkbox"] {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
[class^="c-checkbox"] > i {
  font-family: 'icon-general-solid' !important;
  background-color: var(--white);
  border: 1px solid var(--gray300);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
[class^="c-checkbox"] > i::before {
  content: "\e914";
  line-height: 1;
  color: var(--white);
  margin-left: -2px;
}
[class^="c-checkbox"] > input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
[class^="c-checkbox"] > p {
  margin-left: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* checkbox checked */
[class^="c-checkbox"] > input:checked + i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
[class^="c-checkbox"] > input:checked + i::before {
  color: var(--primary600);
}
/* checkbox indeterminate */
[class^="c-checkbox"] > input:indeterminate + i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
[class^="c-checkbox"] > input:indeterminate + i::before {
  content: "\e982";
  color: var(--primary600);
}
/* checkbox hover */
[class^="c-checkbox"]:hover > i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
[class^="c-checkbox"]:hover > input:checked + i::before {
  color: var(--primary600);
}
/* checkbox active */
[class^="c-checkbox"]:active > i {
  background-color: var(--white);
  border: 1px solid var(--primary300);
  box-shadow: var(--ring-primary-shadow-xs);
}
/* checkbox disabled */
[class^="c-checkbox"] input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
}
[class^="c-checkbox"]:active input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
  box-shadow: var(--ring-primary-shadow-none);
}
[class^="c-checkbox"] input:disabled + i::before {
  content: "";
}

/* checkbox sm */
[class^="c-checkbox"][class*="sm"] > i {
  width: 16px;
  height: 16px;
  border-radius: var(--radius-xs);
}
[class^="c-checkbox"][class*="sm"] > i::before {
  font-size: 12px;
}
[class^="c-checkbox"][class*="sm"] > p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

/* checkbox md */
[class^="c-checkbox"][class*="md"] > i {
  width: 20px;
  height: 20px;
  border-radius: var(--radius-xs);
}
[class^="c-checkbox"][class*="md"] > i::before {
  font-size: 14px;
}
[class^="c-checkbox"][class*="md"] > p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}

/* field */

.c-fieldcontent > p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  margin-bottom: 6px;
}
.c-fieldcontent-narrow {
  position: relative;
}
.c-fieldcontent-narrow > p {
  font-size: 10px;
  line-height: 10px;
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -5px;
  left: 12px;
  z-index: 1;
}
.c-fieldcontent-narrow.is-focus > p {
  color: var(--primary700);
}
.c-fieldcontent-narrow .c-field.is-focus {
  border-color: var(--primary700);
}
[class^="c-fieldcontent"] span {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.c-field {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-field__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-field__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-field__body > i::before {
  line-height: 20px;
}
.c-field__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-field__body label {
  width: 100%;
  height: 100%;
  position: relative;
}
.c-field__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.c-field__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-field__body__selected input {
  width: 100%;
}
.c-field.is-hover {
  border-color: var(--primary200);
}
.c-field.is-active {
  border-color: var(--primary700);
}
.c-field.is-input .c-field__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  color: var(--gray700);
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-field.is-active .c-field__body__title {
  color: var(--primary700);
}
.c-field.is-disabled {
  border-color: var(--gray200);
  background-color: var(--gray50);
  pointer-events: none;
}
.c-field.is-disabled .c-field__body__selected input {
  color: var(--gray500);
}
.c-field.is-input .c-field__body__title.has-icon {
  left: -28px;
}

.c-inputcontent-sm > span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-left: 24px;
  display: block;
}
.c-inputcontent-md > span {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 28px;
  display: block;
}

/* radio */
[class^="c-radio"] {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
[class^="c-radio"] > i {
  background-color: var(--white);
  border: 1px solid var(--gray300);
  border-radius: var(--radius-4xl);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
[class^="c-radio"] > i::before {
  content: "";
  border-radius: var(--radius-4xl);
}
[class^="c-radio"] > input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
[class^="c-radio"] > p {
  margin-left: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* radio checked */
[class^="c-radio"] > input:checked + i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
[class^="c-radio"] > input:checked + i::before {
  background-color: var(--primary600);

}
/* radio hover */
[class^="c-radio"]:hover > i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
[class^="c-radio"]:hover > input:checked + i::before {
  color: var(--primary600);
}
/* radio active */
[class^="c-radio"]:active > i {
  background-color: var(--white);
  border: 1px solid var(--primary300);
  box-shadow: var(--ring-primary-shadow-xs);
}
/* radio disabled */
[class^="c-radio"] input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
}
[class^="c-radio"]:active input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
  box-shadow: var(--ring-primary-shadow-none);
}
[class^="c-radio"] input:disabled + i::before {
  content: "";
}

/* radio sm */
[class^="c-radio"][class*="sm"] > i {
  width: 16px;
  height: 16px;
}
[class^="c-radio"][class*="sm"] > i::before {
  width: 6px;
  height: 6px;
}
[class^="c-radio"][class*="sm"] > p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

/* radio md */
[class^="c-radio"][class*="md"] > i {
  width: 20px;
  height: 20px;
}
[class^="c-radio"][class*="md"] > i::before {
  width: 8px;
  height: 8px;
}
[class^="c-radio"][class*="md"] > p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}

[class^="c-button"] {
  width: fit-content;
  font-weight: var(--text-normal);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
[class^="c-button"]:hover {
  transition: all 200ms linear;
}

[class^="c-button"] > *:not(:first-child) {
  margin-left: 8px;
}

/* type */
[class^="c-button"][class*="bg"] {
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-xs);
}
[class^="c-button"][class*="link"] > *:not(i) {
  text-decoration: underline;
}
[class^="c-button"][class*="link"][disabled] > * {
  text-decoration: none;
}
[class^="c-button"][class*="close"] {
  border-radius: var(--radius-md);
}
[class^="c-button"][class*="close"][class*="sm"] > i::before {
  font-size: 20px;
}
[class^="c-button"][class*="close"][class*="md"] > i::before {
  font-size: 20px;
}
[class^="c-button"][class*="close"][class*="lg"] > i::before {
  font-size: 24px;
}

/* size */
[class^="c-button"][class*="xs"] {
  height: 28px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  padding: 0 8px;
}
[class^="c-button"][class*="xs"] > i::before {
  font-size: 16px;
}
[class^="c-button"][class*="sm"] {
  height: 36px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 0 8px;
}
[class^="c-button"][class*="sm"] > i::before {
  font-size: 20px;
}
[class^="c-button"][class*="md"] {
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 0 10px;
}
[class^="c-button"][class*="md"] > i::before {
  font-size: 20px;
}
[class^="c-button"][class*="lg"] {
  height: 44px;
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  padding: 0 12px;
}
[class^="c-button"][class*="lg"] > i::before {
  font-size: 20px;
}

/* bg primary */
[class^="c-button"][class*="primary"] {
  color: var(--white);
  background-color: var(--primary600);
}
[class^="c-button"][class*="primary"][class*="bg"]:hover {
  background-color: var(--primary700);
}
[class^="c-button"][class*="primary"][class*="bg"]:active {
  background-color: var(--primary600);
  box-shadow: var(--ring-primary-shadow-xs);
}
[class^="c-button"][class*="primary"][disabled] {
  color: var(--white);
  background-color: var(--gray200);
  pointer-events: none;
}

/* bg red */
[class^="c-button"][class*="red"] {
  color: var(--white);
  background-color: var(--error600);
}
[class^="c-button"][class*="red"][class*="bg"]:hover {
  background-color: var(--error700);
}
[class^="c-button"][class*="red"][class*="bg"]:active {
  background-color: var(--error600);
  box-shadow: var(--ring-error-shadow-xs);
}
[class^="c-button"][class*="red"][disabled] {
  color: var(--white);
  background-color: var(--gray200);
  pointer-events: none;
}

/* bg white */
[class^="c-button"][class*="white"] {
  color: var(--gray700);
  background-color: var(--white);
  border: 1px solid var(--gray200);
}
[class^="c-button"][class*="white"][class*="bg"]:hover {
  background-color: var(--gray50);
}
[class^="c-button"][class*="white"][class*="bg"]:active {
  background-color: var(--white);
  box-shadow: var(--ring-gray-shadow-xs);
}
[class^="c-button"][class*="white"][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg secondary */
[class^="c-button"][class*="secondary"] {
  color: var(--primary800);
  background-color: var(--primary50);
}
[class^="c-button"][class*="secondary"][class*="bg"]:hover {
  background-color: var(--primary100);
}
[class^="c-button"][class*="secondary"][class*="bg"]:active {
  background-color: var(--primary50);
  box-shadow: var(--ring-primary-shadow-xs);
}
[class^="c-button"][class*="secondary"][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg tertiaryred */
[class^="c-button"][class*="tertiaryred"] {
  color: var(--error500);
  background-color: var(--error50);
}
[class^="c-button"][class*="tertiaryred"][class*="bg"]:hover {
  background-color: var(--error100);
}
[class^="c-button"][class*="tertiaryred"][class*="bg"]:active {
  background-color: var(--error50);
  box-shadow: var(--ring-error-shadow-xs);
}
[class^="c-button"][class*="tertiaryred"][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg gray */
[class^="c-button"][class*="-gray"][class*="bg"] {
  color: var(--gray800);
  background-color: var(--gray100);
}
[class^="c-button"][class*="gray"][class*="bg"]:hover {
  background-color: var(--gray200);
}
[class^="c-button"][class*="gray"][class*="bg"]:active {
  background-color: var(--gray100);
  box-shadow: var(--ring-gray-shadow-xs);
}
[class^="c-button"][class*="gray"][class*="bg"][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg tertiarygray */
[class^="c-button"][class*="tertiarygray"] {
  color: var(--gray700);
  background-color: transparent;
  box-shadow: var(--shadow-none);
}
[class^="c-button"][class*="tertiarygray"][class*="bg"]:hover {
  background-color: var(--gray100);
}
[class^="c-button"][class*="tertiarygray"][class*="bg"]:active {
  color: var(--gray700);
  background-color: transparent;
}
[class^="c-button"][class*="tertiarygray"][disabled] {
  color: var(--gray300);
  pointer-events: none;
}

/* bg tertiarycolor */
[class^="c-button"][class*="tertiarycolor"] {
  color: var(--primary800);
  background-color: transparent;
  box-shadow: var(--shadow-none);
}
[class^="c-button"][class*="tertiarycolor"][class*="bg"]:hover {
  background-color: var(--primary100);
}
[class^="c-button"][class*="tertiarycolor"][class*="bg"]:active {
  color: var(--primary800);
  background-color: transparent;
}
[class^="c-button"][class*="tertiarycolor"][disabled] {
  color: var(--gray300);
  pointer-events: none;
}

/* link gray */
[class^="c-button"][class*="link"][class*="gray"] {
  color: var(--gray800);
}
[class^="c-button"][class*="link"][class*="gray"][disabled] {
  color: var(--gray300);
  pointer-events: none;
}
[class^="c-button"][class*="link"][class*="color"] {
  color: var(--primary700);
}
[class^="c-button"][class*="link"][class*="color"][disabled] {
  color: var(--gray300);
  text-decoration: none;  
  pointer-events: none;
}

/* close secondary */
[class^="c-button"][class*="close"][class*="secondary"] {
  color: var(--primary700);
  background-color: transparent;
}
[class^="c-button"][class*="close"][class*="secondary"]:hover {
  background-color: var(--primary50);
}
[class^="c-button"][class*="close"][class*="secondary"]:active {
  background-color: var(--primary50);
  box-shadow: var(--ring-primary-shadow-xs);
}

/* close gray */
[class^="c-button"][class*="close"][class*="gray"] {
  color: var(--gray600);
  background-color: transparent;
}
[class^="c-button"][class*="close"][class*="gray"]:hover {
  background-color: var(--gray50);
}
[class^="c-button"][class*="close"][class*="gray"]:active {
  background-color: var(--gray50);
}