/*------------------------------------------------------------------------------
  pagination
  ------------------------------------------------------------------------------*/
.c-pagination {
  width: 100%;
  margin: 16px 0 8px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.c-pagination > *:not(:last-child) {
  margin-right: 32px;
}
.c-pagination__size {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__size p {
  margin-right: 8px;
}
.c-pagination__size label {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #fff;
  display: block;
  position: relative;
}
.c-pagination__size label select {
  width: 100%;
  height: 100%;
  padding: 0 24px 0 8px;
  cursor: pointer;
}
.c-pagination__size label::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #001d36 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 4px);
  right: 8px;
}
.c-pagination__jump {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__jump p:first-child {
  margin-right: 8px;
}
.c-pagination__jump p:last-child {
  margin-left: 8px;
}
.c-pagination__jump input {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 0 8px;
}
.c-pagination__btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__btns > *:not(:last-child) {
  margin-right: 16px;
}
.c-pagination__btn {
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
  background: #4a6c7a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-pagination__btn.disable {
  background: #cfdae1;
  cursor: default;
  pointer-events: none;
}
.c-pagination__btn:hover {
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .c-pagination {
    margin: 0;
    padding: 0 16px 32px 16px;
    justify-content: center;
  }
  .c-pagination > *:not(:last-child) {
    margin-right: 0;
  }
  .c-pagination__size {
    order: 1;
    margin-right: 16px !important;
  }
  .c-pagination__jump {
    order: 1;
  }
  .c-pagination__btns {
    width: 100%;
    margin: 32px 16px;
    order: 0;
  }
  .c-pagination__btn {
    flex: 1;
  }
}
.c-table-main__btn {
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-table-main__btn.prev {
  left: -16px;
}
.c-table-main__btn.next {
  right: -16px;
}
.c-table-main__btn p {
  width: 24px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  position: sticky;
  /* top: calc(50% - 40px); NOTE(goro) probably something wring */
  cursor: pointer;
}
.c-table-main__btn p::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(-45deg);
  top: calc(50% - 8px);
}
.c-table-main__btn.prev p {
  border-radius: 0 8px 8px 0;
}
.c-table-main__btn.next p {
  border-radius: 8px 0 0 8px;
}
.c-table-main__btn.prev p::after {
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  left: 6px;
}
.c-table-main__btn.next p::after {
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  right: 8px;
}
.c-table-main__btn p:hover {
  background: rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 768px) {
  .c-table-main__btn {
    display: none;
  }
}
/*------------------------------------------------------------------------------
  table-availability
  ------------------------------------------------------------------------------*/
.c-table-availability {
  position: relative;
}
.c-table-availability__thead {
  background: #fff;
  border-bottom: 2px solid #cfdae1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: -1px;
  z-index: 2;
}
.c-table-availability__thead__left {
  /* width: calc(100% - 80px - (88px * 7));
    min-width: 320px;
    */
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 2;
}
.c-table-availability__thead__left > div {
  border-left: 1px solid #cfdae1;
  border-top: 1px solid #cfdae1;
  border-bottom: 1px solid #cfdae1;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/*
  .c-table-availability__thead__left > div:nth-child(2) {
    width: calc(100% - 48px);
  }
  */
.c-table-availability__thead__right {
}
.c-table-availability__thead__right table {
  width: 100%;
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.c-table-availability__thead__right th {
  height: 32px;
  padding: 8px;
  border: 1px solid #cfdae1;
}
.c-table-availability__thead__right th.pcTh {
  display: table-cell;
}
.c-table-availability__thead__right th.spTh {
  display: none;
}
.c-table-availability__thead__right th:first-child {
  background: #fff;
}
.c-table-availability__thead__right th.left {
  text-align: left;
}
.c-table-availability__thead__right th .total {
  display: flex;
  justify-content: center;
}
.c-table-availability__thead__right th .total div {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #9bacb5;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-table-availability__thead__right th .total img {
  width: 12px;
  height: 12px;
}

.c-table-availability__tbody {
  overflow-x: auto;
}
.c-table-availability__tbody__box {
  background: #fff;
  margin-top: -1px;
  display: flex;
  justify-content: flex-start;
}
.c-table-availability__tbody__box__left {
  /*
    width: calc(100% - 80px - (88px * 7));
    min-width: 320px;
    */
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
}
.c-table-availability__tbody__box__left > div {
  border-left: 1px solid #cfdae1;
  border-top: 1px solid #cfdae1;
  border-bottom: 1px solid #cfdae1;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/*
  .c-table-availability__tbody__box__left > div:nth-child(2) {
    width: calc(100% - 48px);
  }
  */
.c-table-availability__tbody__box__left > div a {
  font-weight: bold;
  color: #008ec9;
}
.c-table-availability__tbody__box__right {
}
.c-table-availability__tbody__box__right table.availability__table {
  width: 100%;
  height: calc(100% - 1px);
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.c-table-availability__tbody__box__right tr.availability__table:first-child {
  display: none;
}
.c-table-availability__tbody__box__right tr.availability__table:nth-child(odd),
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(odd)
  th,
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(odd)
  td {
  background: #f9f9f9;
}
.c-table-availability__tbody__box__right tr.availability__table:nth-child(even),
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(even)
  th.availability__table,
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(even)
  td.availability__table {
  background: #fff;
}
.c-table-availability__thead__right th.pcTh {
  display: none;
}
.c-table-availability__thead__right th.spTh {
  display: table-cell;
}

.c-table-availability__tbody__box__right td.availability__table,
.c-table-availability__tbody__box__right th.availability__table {
  height: 32px;
  border: 1px solid #cfdae1;
}
.c-table-availability__tbody__box__right td.availability__table.left,
.c-table-availability__tbody__box__right th.availability__table.left {
  text-align: left;
}
.c-table-availability__tbody__box__right td.availability__table > *,
.c-table-availability__tbody__box__right th.availability__table > * {
  padding: 16px 8px;
}
.c-table-availability__tbody__box__right td.availability__table > a,
.c-table-availability__tbody__box__right th.availability__table > a,
.c-table-availability__tbody__box__right
  td.availability__table
  > p.no-allotment,
.c-table-availability__tbody__box__right
  th.availability__table
  > p.no-allotment {
  width: 100%;
  height: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-table-availability__tbody__box__right td.availability__table > a:hover,
.c-table-availability__tbody__box__right th.availability__table > a:hover {
  color: #008ec9;
}
.c-table-availability__tbody__box__right .red100 {
  background: #ff7878;
}
.c-table-availability__tbody__box__right .red75 {
  background: #ffaaaa;
}
.c-table-availability__tbody__box__right .red50 {
  background: #ffd4d4;
}
.c-table-availability__tbody__box__right .red25 {
  background: #ffebeb;
}
.c-table-availability__tbody__box__right .yellow {
  background: #fff4c7;
}
.c-table-availability__tbody__box__right .gray {
  background: #ebf1f3;
  color: #9bacb5;
}
.c-table-availability__tbody__box__right
  td.availability__table
  img.availability__table {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
@media screen and (max-width: 768px) {
  .c-table-availability__thead {
    flex-direction: column;
    position: relative;
    overflow-x: auto;
    border-bottom: none;
  }
  .c-table-availability__thead__right {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-availability__thead__right .spFixed {
    background: #fff;
    position: sticky;
    left: 0;
  }
  .c-table-availability__thead__right .spFixed::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -1px;
    border-left: 1px solid #cfdae1;
    border-right: 3px solid #cfdae1;
  }
  .c-table-availability__thead__left {
    display: none;
  }
  .c-table-availability__tbody__box {
    flex-direction: column;
    position: relative;
    border-bottom: none;
    margin-top: 16px;
  }
  .c-table-availability__tbody__box__left {
    width: 100%;
  }
  .c-table-availability__tbody__box__left > div:first-child {
    display: none;
  }
  .c-table-availability__tbody__box__left > div:nth-child(2) {
    width: 100%;
  }
  .c-table-availability__tbody__box__left > div {
    border-bottom: none;
  }
  .c-table-availability__tbody__box__right {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-availability__tbody__box__right tr.availability__table:first-child {
    display: table-row;
  }
  .c-table-availability__tbody__box__right th.availability__table {
    padding: 0 4px;
  }
  .c-table-availability__tbody__box__right .spFixed,
  .c-table-availability__tbody__box__right .spFixed {
    position: sticky;
    left: 0;
  }
  .c-table-availability__tbody__box__right .spFixed::before,
  .c-table-availability__tbody__box__right .spFixed::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -1px;
    border-left: 1px solid #cfdae1;
    border-right: 3px solid #cfdae1;
  }
}
