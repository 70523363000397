.page-help {
  width: 100%;
  max-width: 800px;
}

.page-help img,
.page-help video,
.page-help iframe {
  width: 100%;
}

.page-help__title {
  font-size: 24px;
  font-weight: normal;
  color: #008ec9;
}

.page-help__content__headline {
  font-size: 18px;
  font-weight: bold;
  color: #008ec9;
}

.page-help__content__body {
  width: 100%;
  max-width: 800px;
  font-size: 14px;
  margin: 24px auto 24px auto;
}

.page-help__content__body ul,
.page-help__content__body ol {
  display: block;
  margin: 1em 0;
  padding-left: 40px;
  list-style-position: outside;
}

.page-help__content__body li {
  display: list-item;
}

.page-help__content__body ul > li {
  list-style-type: disc;
}

.page-help__content__body ol > li {
  list-style-type: decimal;
}

.page-help__content__body a {
  background-color: transparent;
  text-decoration: underline;
  color: #4183c4;
}

.page-help__content__body > * + * {
  margin-top: 16px;
}

.page-help__tag {
  display: inline-block;
  margin: 0 10px 0 0;
  background: lightgray;
  font-size: 12px;
  padding: 0.5em 1em;
  border-radius: 0.5em;
}

@media screen and (max-width: 768px) {
  .page-help {
    padding: 16px;
  }
}
