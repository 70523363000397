.tab-container {
  display: inline-flex;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f5f8fa; /* Light background for the tabs */

  margin-bottom: 20px;
}

/* Style for individual tabs */
.tab {
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  flex: 1; /* Evenly distribute the space */
  color: #c0c0c0; /* Gray color for non-selected tab */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effect */

  width: 120px;
}

/* Style for the active/selected tab */
.tab.active {
  color: #0078c2; /* Blue color for the selected tab */
  border-bottom: 2px solid #0078c2; /* Highlighted bottom border for the selected tab */
}
