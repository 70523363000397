/*------------------------------------------------------------------------------
modalCalendar
------------------------------------------------------------------------------*/
.c-modalCalendar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.c-modalCalendar p {
  margin-top: auto;
  margin-bottom: auto;
}

.c-modalCalendar__frame {
  width: 100%;
  max-width: 1124px;
  height: calc(100vh - 32px);
  max-height: 732px;
  border-radius: 8px;
  background: #fff;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}
.c-modalCalendar__frame.product_selector {
  max-height: 818px;
}
.c-modalCalendar__frame__header {
  color: #fff;
  background: #008ec9;
  border-radius: 8px 8px 0 0;
  padding-left: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-modalCalendar__frame__header__ttl {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-modalCalendar__frame__header__close {
  width: 48px;
  height: 48px;
  margin-left: auto;
  flex-shrink: 0;
  position: relative;
}
.c-modalCalendar__frame__header__close::before,
.c-modalCalendar__frame__header__close::after {
  content: '';
  display: block;
  width: 24px;
  height: 4px;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 22px;
  left: 12px;
}
.c-modalCalendar__frame__header__close::before {
  transform: rotate(45deg);
}
.c-modalCalendar__frame__header__close::after {
  transform: rotate(-45deg);
}
.c-modalCalendar__frame__body {
  padding: 16px;
}
.c-modalCalendar__frame__body__top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}
.c-modalCalendar__frame__body__main {
}
.c-modalCalendar__datepick {
  width: 240px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}
.c-modalCalendar__datepick p {
  margin-top: auto;
  margin-bottom: auto;
}
.c-modalCalendar__datepick__btn {
  width: 40px;
  height: 100%;
  flex-shrink: 0;
  position: relative;
}
.c-modalCalendar__datepick__btn.prev::after,
.c-modalCalendar__datepick__btn.next::after {
  content: '';
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 5px);
}
.c-modalCalendar__datepick__btn.prev::after {
  border-bottom: 2px solid #008ec9;
  border-left: 2px solid #008ec9;
  left: 18px;
}
.c-modalCalendar__datepick__btn.next::after {
  border-top: 2px solid #008ec9;
  border-right: 2px solid #008ec9;
  right: 18px;
}
.c-modalCalendar__datepick__main {
  width: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 1px solid #cfdae1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.c-modalCalendar__info {
  width: 100%;
  font-size: 14px;
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-modalCalendar__info__today {
  color: #008ec9;
  font-weight: bold;
  text-decoration: underline;
}
.c-modalCalendar__info__timezone {
}
.c-modalCalendar__frame__body__main {
}
.c-modalCalendar__overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}

.c-calendar {
  border: 1px solid #cfdae1;
  border-radius: 8px;
}
.c-calendar table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.c-calendar table th {
  height: 24px;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: #9bacb5;
}
.c-calendar table th:first-child {
  color: #dc3e15;
}
.c-calendar table th:last-child {
  color: #008ec9;
}
.c-calendar table td {
  border-top: 1px solid #cfdae1;
  height: 96px;
  vertical-align: top;
  text-align: left;
  padding: 2px;
  position: relative;
}
.c-calendar table td.disabled > * {
  opacity: 0.3;
}

.c-calendar table td.out-of-office {
  background-color: #cdcdcd;
  outline: 1px solid white;
  outline-offset: -1px;
}

.c-calendar__day {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #9bacb5;
  border-radius: 100%;
  margin: 0 auto;
}
.c-calendar__day.today {
  color: #fff;
  background: #008ec9;
}
.c-calendar__scheds {
  margin-top: 2px;
}
.c-calendar__scheds li {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.5px;
  border-radius: 4px;
  padding: 0 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.c-calendar__scheds > li:first-child {
  justify-content: center;
}

.c-calendar__scheds > .out-of-office.single-day {
  background-color: #ff7878;
}

.c-calendar__scheds > .in-of-office.single-day {
  background-color: #fff4c7;
}

.c-calendar__scheds > .normal-day {
  background-color: #f9f9f9;
}

.c-calendar__scheds > li:nth-child(n + 2) {
  background: #eeffff;
}
.c-calendar__scheds > li:hover:nth-child(n + 2),
.c-calendar__scheds > li.more:hover:nth-child(n + 2) {
  background: #ebf1f3;
}
.c-calendar__scheds li + li {
  margin-top: 4px;
}
.c-calendar__scheds li p {
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; /* NOTE(goro) : to override the definition of semantic ui */
}
.c-calendar__scheds li img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
  flex-shrink: 0;
}
.c-calendar__scheds li b {
  flex-shrink: 0;
}
.c-calendar__scheds li.yellow {
  background: #fff4c7;
}
.c-calendar__scheds li.gray {
  background: #ebf1f3;
}
.c-calendar__scheds li.red100 {
  background: #ff7878;
}
.c-calendar__scheds li.red75 {
  background: #ffaaaa;
}
.c-calendar__scheds li.red50 {
  background: #ffd4d4;
}
.c-calendar__scheds li.red25 {
  background: #ffebeb;
}
.c-calendar__scheds li.more {
  font-weight: bold;
  background: #fff;
}
.c-calendar__scheds li.more::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #008ec9;
  display: inline-block;
  margin-right: 4px;
}
.c-calendar__scheds li.selected {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.c-calendar__allScheds {
  width: 240px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: -32px;
  z-index: 1;
  opacity: 1 !important;
  display: none;
}
.c-calendar__allScheds.is-open {
  display: block;
}
.c-calendar table tr:nth-child(2) .c-calendar__allScheds {
  bottom: -100%;
}
.c-calendar table td:nth-child(1) .c-calendar__allScheds,
.c-calendar table td:nth-child(2) .c-calendar__allScheds,
.c-calendar table td:nth-child(3) .c-calendar__allScheds,
.c-calendar table td:nth-child(4) .c-calendar__allScheds {
  left: calc(100% + 4px);
}
.c-calendar table td:nth-child(5) .c-calendar__allScheds,
.c-calendar table td:nth-child(6) .c-calendar__allScheds,
.c-calendar table td:nth-child(7) .c-calendar__allScheds {
  right: calc(100% + 4px);
}
.c-calendar__allScheds__header {
  width: 100%;
  border-bottom: 1px solid #cfdae1;
  padding-left: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-calendar__allScheds__header__ttl {
  font-size: 16px;
  font-weight: bold;
}
.c-calendar__allScheds__header__close {
  width: 48px;
  height: 48px;
  margin-left: auto;
  position: relative;
}
.c-calendar__allScheds__header__close::before,
.c-calendar__allScheds__header__close::after {
  content: '';
  display: block;
  width: 24px;
  height: 4px;
  border-radius: 4px;
  background: #9bacb5;
  position: absolute;
  top: 22px;
  left: 12px;
}
.c-calendar__allScheds__header__close::before {
  transform: rotate(45deg);
}
.c-calendar__allScheds__header__close::after {
  transform: rotate(-45deg);
}
.c-calendar__allScheds__body {
  min-height: 200px;
  max-height: 320px;
  padding: 16px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.c-calendar__allScheds__body ul {
}
.c-calendar__allScheds__body li {
  font-size: 12px;
  font-weight: bold;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 0 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.c-calendar__allScheds__body li:hover {
  opacity: 0.8;
}
.c-calendar__allScheds__body li + li {
  margin-top: 8px;
}
.c-calendar__allScheds__body li p {
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-calendar__allScheds__body li img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-left: auto;
  flex-shrink: 0;
}
.c-calendar__allScheds__body li b {
  flex-shrink: 0;
}
.c-calendar__allScheds__body li.yellow {
  background: #fff4c7;
}
.c-calendar__allScheds__body li.gray {
  background: #ebf1f3;
}
.c-calendar__allScheds__body li.red100 {
  background: #ff7878;
}
.c-calendar__allScheds__body li.red75 {
  background: #ffaaaa;
}
.c-calendar__allScheds__body li.red50 {
  background: #ffd4d4;
}
.c-calendar__allScheds__body li.red25 {
  background: #ffebeb;
}

.c-calendar__allScheds__body li.front__red100 {
  color: #ffebeb;
}

@media screen and (max-width: 768px) {
  .c-modalCalendar__frame {
    height: 100vh;
    max-height: none;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .c-modalCalendar__frame__header {
    border-radius: 0;
  }
  .c-modalCalendar__frame__body {
    padding: 0;
  }
  .c-modalCalendar__frame__body__top {
    flex-wrap: wrap-reverse;
    margin-bottom: 0;
  }
  .c-modalCalendar__datepick {
    width: 100%;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
  .c-modalCalendar__info {
    font-size: 12px;
    height: 32px;
    margin-left: 0;
    padding: 0 8px;
  }
  .c-calendar {
    border-radius: 0;
    border: 0;
  }
  .c-calendar table th {
    font-size: 10px;
    height: 16px;
  }
  .c-calendar table td {
    height: calc((100vh - 48px - 32px - 40px - 16px - 1px) / 6);
  }
  .c-calendar table td.selected {
    background: #ebf1f3;
  }
  .c-calendar__day {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 11px;
  }
  .c-calendar__scheds li {
    font-size: 10px;
    line-height: 16px;
    border-radius: 2px;
  }
  .c-calendar__scheds li:hover,
  .c-calendar__scheds li.more:hover {
  }
  .c-calendar__scheds li img {
    width: 10px;
    height: 10px;
    margin-right: 0px;
  }
  .c-calendar__scheds li b {
    display: none;
  }
  .c-calendar__scheds li.selected {
    box-shadow: none;
  }
  .c-calendar__allScheds__body {
    min-height: 160px;
    max-height: 240px;
  }
  .c-calendar__scheds li p {
    line-height: 16px; /* NOTE(goro) : to override the definition of semantic ui */
  }
  .c-calendar table tr:nth-child(2) .c-calendar__allScheds {
    top: 100%;
    bottom: auto;
  }
  .c-calendar table tr:nth-child(3) .c-calendar__allScheds {
    top: 75%;
    bottom: auto;
  }
  .c-calendar table tr:nth-child(4) .c-calendar__allScheds {
    top: 25%;
    bottom: auto;
  }
  .c-calendar table tr:nth-child(5) .c-calendar__allScheds {
    bottom: -50%;
  }
  .c-calendar table tr:nth-child(6) .c-calendar__allScheds {
    bottom: 100%;
  }
  .c-calendar table tr:nth-child(7) .c-calendar__allScheds {
    bottom: 100%;
  }

  .c-calendar table td:nth-child(1) .c-calendar__allScheds {
    left: 8px;
  }
  .c-calendar table td:nth-child(2) .c-calendar__allScheds {
    left: calc(50% - 60px);
  }
  .c-calendar table td:nth-child(3) .c-calendar__allScheds {
    left: calc(50% - 90px);
  }
  .c-calendar table td:nth-child(4) .c-calendar__allScheds {
    left: calc(50% - 120px);
  }
  .c-calendar table td:nth-child(5) .c-calendar__allScheds {
    left: calc(50% - 150px);
  }
  .c-calendar table td:nth-child(6) .c-calendar__allScheds {
    left: calc(50% - 180px);
  }
  .c-calendar table td:nth-child(7) .c-calendar__allScheds {
    right: 8px;
  }
}

/**/
