.base-single__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 0;
}
.base-single__header > *:last-child {
  margin-top: 12px;
  align-self: flex-end;
}
.base-single__title {
  position: relative;
}
.base-single__title__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.base-single__title__wrapper > * {
  margin: 0;
  margin-left: 12px;
}
@media screen and (max-width: 768px) {
  .base-single__title__wrapper {
    flex-wrap: nowrap;
  }
}
.base-single__title__h1 {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  color: #333;
  /* padding-top: 16px; */
  /* padding-bottom: 16px; */
  position: relative;
  z-index: 1;
}
.base-single__buttons {
  display: flex;
  align-items: center;
}
.base-single__buttons > * {
  margin-left: 12px;
}
.base-single__buttons > div {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .base-single__buttons {
    flex-wrap: wrap;
  }

  .base-single__buttons > *:not(:first-child) {
    margin-top: 12px;
}
}
.base-single__internal__title {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #333;
}
.base-single__headerinfo {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 8px 0 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.base-single__headerinfo__labels {
  font-size: 14px;
  font-weight: bold;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.base-single__headerinfo__labels__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-single__headerinfo__labels__item + div {
  margin-left: 16px;
}
.base-single__headerinfo__labels__item.ranking {
  color: #ffb300;
}
.base-single__headerinfo__labels__item.recommend {
  color: #2cb582;
}
.base-single__headerinfo__labels__item.promotion {
  color: #ff0045;
}
.base-single__headerinfo__labels__item img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.base-single__headerinfo__other {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.base-single__headerinfo__other__review {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-single__headerinfo__other__review img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.base-single__headerinfo__other__review span:first-of-type {
  font-size: 16px;
  margin-right: 4px;
}
.base-single__headerinfo__other__review span:last-of-type {
  font-size: 16px;
  color: #888;
}
.base-single__headerinfo__other__favo {
}
.base-single__headerinfo__other__favo label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.base-single__headerinfo__other__favo input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.base-single__headerinfo__other__favo img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.base-single__headerinfo__other__favo img.off {
  display: block;
  opacity: 0.6;
}
.base-single__headerinfo__other__favo img.on {
  display: none;
}
.base-single__headerinfo__other__favo p {
  color: #aaa;
}
.base-single__headerinfo__other__favo input:checked ~ img.off {
  display: none;
}
.base-single__headerinfo__other__favo input:checked ~ img.on {
  display: block;
}
.base-single__headerinfo__other__favo input:checked ~ p {
  color: #ff0045;
}
.tag-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .base-single__headerinfo {
    order: 1;
    padding: 16px 0;
  }
  .base-single__headerinfo__labels {
    width: calc(100% - 32px);
    font-size: 12px;
    padding: 8px;
    background: #fff;
    margin: 0 auto 16px auto;
  }
  .base-single__headerinfo__other {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
  }
  .base-single__headerinfo__other__review {
    margin-right: auto;
  }
  .base-single__title {
    margin-top: 16px;
    margin-right: 16px;
  }
  .base-single__title__h1 {
    width: 100%;
    font-size: 18px;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .tag-bar {
    flex-direction: column;
    margin-left: 8px;
    margin-bottom: 16px;
    align-items: flex-start;
  }
}
.buttons-row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.button-container + .button-container {
  margin-left: 8px;
}
.language-select {}
.base-single__headerinfo__other__review {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-single__headerinfo__other__review img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.base-single__headerinfo__other__review span:first-of-type {
  font-size: 16px;
  margin-right: 4px;
}
.base-single__headerinfo__other__review span:last-of-type {
  font-size: 16px;
  color: #888;
}
.base-single__sales__status {
  width: 150px;
  height: 25px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-right: 8px;
  display: block;
  flex-shrink: 0;
}
.base-single__sales__status.on-sale {
  background: rgb(0, 148, 204);
}
.base-single__sales__status.not-on-sale {
  background: rgb(255, 204, 0);
}
.base-single__sales__status.not-listed {
  background: rgb(220, 62, 21);
}

@media screen and (max-width: 768px) {
  .buttons-row {
    margin-left: 8px;
    flex-direction: column;
  }
  .button-container + .button-container {
    margin-left: 0px;
    margin-top: 8px;
  }
  .language-select {
    margin-left: 16px;
  }
  .base-single__sales__status {
    margin-left: 16px;
    margin-top: 16px;
  }
}
