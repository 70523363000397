.tutorialFixed {
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.4);
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
}
.tutorialFixed__btn {
  width: 100%;
  max-width: 320px;
  height: 56px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  color: #fff;
  background: #ffcc00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tutorialFixed__balloon {
  width: 80px;
  height: 32px;
  color: #ff0045;
  font-weight: bold;
  border-radius: 2px;
  background: #fff;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  animation: balloon 0.8s linear infinite;
}
.tutorialFixed__balloon::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  top: calc(50% - 8px);
  left: -8px;
}
@keyframes balloon {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-8px);
  }
  100% {
    transform: translateX(0);
  }
}
