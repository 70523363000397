.p-areaGroupSettingsModal__provinceCity__list__item {
}

.p-areaGroupSettingsModal__provinceCity__list__item__ttl {
  display: flex;
  flex-direction: row;
}
.p-areaGroupSettingsModal__provinceCity__list__item__ttl p:first-child {
  margin-right: 6px;
}

.p-areaGroupSettingsModal__box {
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  padding: 16px;
}
.p-areaGroupSettingsModal__provinceCity__list__item__or {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 16px;
  color: var(--gray500);
}
.p-areaGroupSettingsModal__provinceCity__list__item__or::before,
.p-areaGroupSettingsModal__provinceCity__list__item__or::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: var(--gray200);
}
.p-areaGroupSettingsModal__provinceCity__list__item__or::before {
  margin-right: 10px;
}
.p-areaGroupSettingsModal__provinceCity__list__item__or::after {
  margin-left: 10px;
}
