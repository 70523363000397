.container {
  margin: 24px 0 16px;
}
.header {
  display: flex;
  gap: 16px;
}
.title{
}
.add-new {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.add-new i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}