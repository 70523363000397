.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.g-section {
  scroll-margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .g-section {
    scroll-margin-top: 136px;
  }
}

.p-reservationsDetail {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservationsDetail__header {
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsDetail__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-reservationsDetail__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservationsDetail__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservationsDetail__actions__pin {
  position: relative;
}
.p-reservationsDetail__actions__pin > div {
  bottom: 48px;
  display: none;
}
.p-reservationsDetail__actions__pin:hover > div {
  display: block;
}
.p-reservationsDetail__body {
  padding: 24px;
}
@media screen and (max-width: 768px) {
  .p-reservationsDetail__header {
    align-items: flex-start;
  }
  .p-reservationsDetail__body {
    padding: 16px;
  }
  .p-reservationsDetail__header.contact {
    display: block;
  }
  .p-reservationsDetail__actions.contact {
    margin-top: 16px;
    justify-content: flex-start;
  }
}
