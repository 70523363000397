.c-pageTab {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  margin-bottom: 64px;
}
.c-pageTab__header {
  border-bottom: 1px solid var(--gray200);
  padding: 8px 24px 0 24px;
}
.c-pageTab__header__tab {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-medium);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-pageTab__header__tab li {
  color: var(--gray500);
  padding: 12px 4px 14px 4px;
  margin-right: 20px;
  cursor: pointer;
}
.c-pageTab__header__tab li.is-active {
  color: var(--primary700);
  padding: 12px 4px;
  border-bottom: 2px solid var(--primary700);
}
.c-pageTab__body {
  padding: 24px;
}