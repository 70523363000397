.c-enumRadioButtonGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-enumRadioButtonGroup > li {
  display: flex;
}
.c-enumRadioButtonGroup > li > div {
  margin-left: 8px;
}
.c-enumRadioButtonGroup > * {
  padding: 4px 0;
}
.c-enumRadioButtonGroup > *:not(:last-child) {
  margin-right: 12px;
}
.disabled {
  color: lightgray;
}
