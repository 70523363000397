/**
 * This code was generated by Builder.io.
 */
.menu {
  padding: 24px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Black, #1e202f);
  text-transform: capitalize;
  margin-bottom: 24px;
}

.editMenuButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-17, #3e8cc4);
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  color: var(--white, #fff);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
}

.editMenuButton img {
  width: 20px;
  height: 20px;
}
