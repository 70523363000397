/**
 * This code was generated by Builder.io.
 */
.popularCategories {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  color: #393d3f;
  margin: 0 0 8px;
}

.sectionDescription {
  font-size: 14px;
  color: #393d3f;
  margin: 0 0 16px;
}

.categoryList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.categoryItem {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.categoryName {
  width: 80px;
  font-size: 12px;
  font-weight: 700;
  color: #393d3f;
  text-transform: capitalize;
}

.categoryBar {
  flex: 1;
  height: 12px;
  background-color: #dadde2;
  border-radius: 6px;
  overflow: hidden;
}

.categoryProgress {
  height: 100%;
  background-color: #3e8cc4;
  border-radius: 6px;
}

@media (max-width: 991px) {
  .categoryItem {
    flex-direction: column;
    align-items: flex-start;
  }

  .categoryName {
    margin-bottom: 4px;
  }

  .categoryBar {
    width: 100%;
  }
}
