.edit-link {
  color: black !important;
  text-decoration: none !important;
}
.p-availability__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.p-availability__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.red {
  color: #dc2626;
}
.actions {
  display: flex;
  gap: 8px;
}
