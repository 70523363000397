.timelineContainer {
  border-radius: 20px;
  border: 1px solid var(--color-15, #3aa0ff);
  background: var(--white, #fff);
  box-shadow: 0 4px 10.3px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 100%;
  padding: 16px 24px;
  flex-direction: column;
  gap: 16px;
}

.timelineHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.timelineHeaderIcon {
  aspect-ratio: 2;
  object-fit: contain;
  width: 10px;
  stroke-width: 2px;
  stroke: var(--colour-10, #393d3f);
}

.timelineContent {
  display: flex;
  width: 100%;
  max-width: 454px;
}

.timelineLine {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 8px;
  padding: 3px 4px;
}

.timelineTrack {
  background-color: #393d3f;
  width: 0;
  border: 1px dashed rgba(57, 61, 63, 1);
  margin-top: 10px;
}

.timelineMarkers {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 28px;
  right: 0;
  top: 0;
  width: 8px;
}

.timelineMarker {
  background-color: var(--white, #fff);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  border: 2px solid rgba(57, 61, 63, 1);
}

.timelineItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  z-index: 10;
  margin-left: -7px;
}

.timelineItem {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--colour-10, #393d3f);
  text-transform: capitalize;
  font: 16px Inter, sans-serif;
  padding-bottom: 16px;
}

.timelineTime {
  font-weight: 400;
}

.timelineIcon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 16px;
}

.timelineAction {
  font-weight: 600;
}

.chip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 33px;
  height: 33px;
  padding: 0 8px;
}
.chip.ticket {
  background: #44bc7f;
}
.chip.mobileOrder {
  background: #ff8360;
}
.chipIcon {
  width: 15px;
}

@media (max-width: 991px) {
  .timelineContainer {
    max-width: 100%;
    padding: 16px 20px;
  }

  .timelineContent,
  .timelineHeader,
  .timelineItem {
    max-width: 100%;
  }
}
