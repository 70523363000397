/* Top margin for menu scroll, only target sections and divs with ids */
section[id],
div[id] {
  scroll-margin-top: 16px;
}
@media screen and (max-width: 768px) {
  section[id],
  div[id] {
    scroll-margin-top: 136px;
  }
}

/* Account for fixed footer height */
.p-productEditor {
  margin-bottom: 64px;
}
@media screen and (max-width: 768px) {
  .p-productEditor {
    margin-bottom: 32px;
  }
}

.p-products__info {
  width: 100%;
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-products__info__pic {
  width: 68px;
  height: 68px;
  margin-right: 16px;
  flex-shrink: 0;
}
.p-products__info__pic img {
  width: 100%;
  height: 100%;
  border-radius: var(--radius-md);
  object-fit: cover;
}
.p-products__info_body {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-products__info_body__label {
  width: fit-content;
  font-size: var(--text-xs-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xs-height);
  color: var(--white);
  border-radius: var(--radius-full);
  padding: 4px 12px;
  flex-shrink: 0;
}
.p-products__info_body__label.status1 {
  background-color: var(--primary600);
}
.p-products__info_body__label.status2 {
  background-color: var(--success500);
}
.p-products__info_body__label.status3 {
  background-color: var(--warning500);
}
.p-products__info_body__label.status4 {
  background-color: var(--gray500);
}
.p-products__info_body__ttl {
  margin-top: 8px;
}

.p-products__ttl {
  font-size: var(--text-xl-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-xl-height);
  margin-bottom: 16px;
}
.p-products__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-products__section:not(:first-child) {
  margin-top: 16px;
}
.p-products__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-products__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-products__section__ttl > span {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 4px;
  display: block;
}
.p-products__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-products__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-products__section__body.is-active {
  display: block;
}
.p-products__section__body__textarea textarea {
  min-height: 44px;
}
.p-products__section__body__textarea2 {
  width: 100%;
}
.p-products__section__body__textarea2 textarea {
  min-height: 120px;
}
.p-products__section__body__item {
}
.p-products__section__body__item:not(:first-child) {
  margin-top: 16px;
}
.p-products__section__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-products__section__body__item.flex > *:not(:last-child) {
  margin-right: 16px;
}
.p-products__section__body__youtube i {
  color: #eb361c;
}
.p-products__section__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 16px; */
}
.p-products__section__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .p-products__section__header {
    padding: 16px;
  }
  .p-products__section__body {
    padding: 16px;
  }
}

.p-list {
}
.p-list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-list__item:not(:first-child) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--gray200);
}
.p-list__item__ttl {
  width: 240px;
  color: var(--gray500);
  flex-shrink: 0;
  margin-right: 32px;
}
.p-list__item__ttl.full {
  width: 100%;
  margin-bottom: 4px;
}
.p-list__item__ttl__txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__ttl__txt > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 4px;
}
/* TODO: we can remove this span later */
.p-list__item__ttl__txt span {
  content: attr(data-required);
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--white);
  background-color: var(--warning500);
  border-radius: var(--radius-full);
  padding: 2px 6px;
  margin-left: auto;
}
/* TODO: this is to position badge */
.p-list__item__ttl__txt p {
  margin-left: auto;
}

.p-list__item__ttl > span {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 4px;
}
/* TODO: translation do this later, or delete if not needed */
.p-list__item__ttl__translationHeader {
  min-width: 240px;
  margin-right: 32px;
}
.p-list__item__translationHeader {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 240px - 32px);
  font-weight: var(--text-semibold);
}
@media screen and (max-width: 768px) {
  .p-list__item__translationHeader {
    width: 100%;
  }
}
.p-list__item.p-list__item__agentReference {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.p-list__item__body {
  width: calc(100% - 240px - 32px);
}
.p-list__item__body__detail > ul {
  width: 100%;
}
.p-list__item__body__detail > ul > li:not(:first-child) {
  margin-top: 0;
  border-top: none;
}
.p-list__item__body.row {
  flex-direction: column;
}
.p-list__item__body.row > *:not(:first-child) {
  margin-top: 8px;
}
.p-list__item__body__calendar {
  display: flex;
  align-items: center;
}
.p-list__item__body__calendar > p {
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0;
}
.p-list__item__body.fullWidth {
  width: 100%;
}
.p-list__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -12px;
}
.p-list__item__body.flex > * {
  margin-top: 12px;
}
.p-list__item__body.flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-list__item__body.flex > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.p-list__item__body.flex > div:not(:last-child) {
  margin-right: 12px;
}
.p-list__item__body.flex > div > div:last-child {
  margin-left: 4px;
}
.p-list__item__body.flex > p {
  width: 100%;
}
.p-list__item__body__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__body__flex__main {
  width: 100%;
}
.p-list__excludedDates {
  display: flex;
  align-items: center;
}
.p-list__excludedDates > * {
  flex: 1;
}
.p-list__excludedDates > *:not(:first-child) {
  margin-left: 8px;
}
.p-list__highlight {
  display: flex;
}
.p-list__highlight > *:not(:first-child) {
  margin-left: 4px;
}
.p-list__item__body__flex__actions {
  width: 36px;
  margin-left: 8px;
  flex-shrink: 0;
}
/* TODO: translation do this later, or delete if not needed */
.p-list__item__body__flex__translation {
  min-width: 50%;
}
.p-list__item__body__flex > *:not(.p-list__item__body__flex__main),
.p-list__item__body__flex > *:not(.p-list__item__body__flex__actions) {
  margin-right: 12px;
}
.p-list__item__body__flex > *:last-child {
  margin-right: 0;
}
.p-list__item__body__flex.full {
  width: 100%;
}
.p-list__item__body__flex.full > * {
  width: 100%;
  flex-shrink: 1;
}
.p-list__item__body__photo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: -16px;
}
.p-list__item__body__photo li {
  width: 128px;
  height: 128px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
}
.p-list__item__body__photo li img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.p-list__item__body__photo li a {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
}
.p-list__item__body__photo li a i {
  font-size: 16px;
  color: var(--white);
}
.p-list__item__body__photo li.add {
  border: 1px dashed var(--primary700);
  color: var(--primary700);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.p-list__item__body__photo li.add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.p-list__item__body__photo li.add p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 10px;
}
.p-list__item__body__list > div:not(:first-child) {
  margin-top: 8px;
}
.p-list__item__body__list:not(:first-child) {
  margin-top: 16px;
}
.p-list__item__body__list__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-bottom: 4px;
}

.p-list__item.row {
  flex-direction: column;
}
.p-list__item.row > .p-list__item__ttl {
  width: 100%;
  margin-bottom: 16px;
  margin-right: 0;
}
.p-list__item.row > .p-list__item__body {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-list__item {
    flex-direction: column;
  }
  .p-list__item__ttl {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .p-list__item__ttl > p {
    width: 100%;
  }
  .p-list__item__ttl > p span {
    margin-left: 8px;
  }
  .p-list__item__body {
    width: 100%;
  }
  .p-list__item__body__photo li {
    width: 100px;
    height: 100px;
  }
  .p-list__item__body__flex.full {
    flex-direction: column;
    margin-top: 16px;
  }
  .p-list__item__body__flex.full > * {
    margin-right: 0;
  }
  .p-list__item__body__flex.full > *:not(:first-child) {
    margin-top: 16px;
  }
}

.p-frame {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
}
.p-frame:not(:first-child) {
  margin-top: 16px;
}
.p-frame__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-frame__header__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
}
.p-frame__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-frame__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-frame__body {
  border-top: 1px solid var(--gray200);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  padding: 20px;
  /* TODO: for now set as block
  display: none; */
  display: block;
}
.p-frame__body.gray {
  background-color: var(--gray50);
}
.p-frame__body.is-active {
  display: block;
}
.p-frame__body__note {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
}

.p-box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--gray50);
  padding: 16px;
}
.p-box.padding-0 {
  padding: 0;
}
.p-box:not(:first-child) {
  margin-top: 16px;
}
.p-box__header {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-box__table {
  width: 100%;
  display: table;
  margin-top: 16px;
}
.p-box__table__header {
  display: table-row;
}
.p-box__table__body {
  display: table-row;
}
.p-box__table__item {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 8px;
}
.p-box__table__item:not(:last-child) {
  padding-right: 12px;
}
.p-box__table__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
}
.p-box__table__item__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-box__table__item__flex > * {
  flex-shrink: 1;
}
.p-box__table__item__flex > span {
  padding: 0 8px;
}
.p-box__table__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
.p-box__table__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.p-box__table2 {
}
.p-box__table2__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray200);
}
.p-box__table2__header > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  padding-right: 16px;
}
.p-box__table2__body {
  border-bottom: 1px solid var(--gray200);
  padding: 16px;
}
.p-box__table2__body__item {
  width: 100%;
}
.p-box__table2__body__item:not(:first-child) {
  margin-top: 16px;
}
.p-box__table2__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-box__table2__body__item.flex > div {
  padding-right: 16px;
  flex-shrink: 0;
}
.p-box__table2__body__item.flex > div.right {
  width: 100%;
  flex-shrink: 1;
  padding-right: 0;
}
.p-box__table2__body__item.flex > div.right > * {
  margin-left: auto;
}
.p-box__table2__body__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-bottom: 4px !important;
}
.p-box__table2__body__item__body {
}
.p-box__table2__body__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-box__table2__body__item__body.flex > *:not(:last-child) {
  margin-right: 8px;
}
.p-box__table2__body__item__body.flex > div {
  flex-shrink: 0;
}
.p-box__table2__body__item__box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px;
}
.p-box__table2__body__item__box__table {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.p-box__table2__body__item__box__table__header,
.p-box__table2__body__item__box__table__body {
  display: table-row;
}
.p-box__table2__body__item__box__table__item {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 8px;
}
.p-box__table2__body__item__box__table__item:not(:last-child) {
  padding-right: 12px;
}
.p-box__table2__body__item__box__table__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-bottom: 4px;
}

.p-box__table2__actions {
  padding: 16px;
}
.p-box__table2__actions__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-box__table2__actions__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

.p-box__table3 {
  margin-top: 16px;
}
.p-box__table3 > *:not(:first-child) {
  margin-top: 16px;
}
.p-box__table3__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-box__table3__item__card {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px;
}
.p-box__table3__item__card > *:not(:first-child) {
  margin-top: 16px;
}
.p-box__table3__item__body {
  width: 100%;
  display: flex;
}
.p-box__table3__item__body__item {
  width: 100%;
  flex-shrink: 1;
}
.p-box__table3__item__body__item:not(:last-child) {
  margin-right: 12px;
}
.p-box__table3__item__body__item__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-box__table3__item__body__item__flex > * {
  flex-shrink: 1;
}
.p-box__table3__item__body__item__flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-box__table3__item__body__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-bottom: 8px !important;
  display: flex;
  align-items: center;
}
.p-box__table3__item__body__item__ttl > div {
  margin-left: 8px;
}
.p-box__table3__item__list__item .p-box__table3__item__body__item__ttl {
  margin-bottom: 8px;
}
.p-box__table3__item__body__item__actions {
  width: 36px;
  flex-shrink: 0;
}
.p-box__table3__item__actions {
  width: 36px;
  margin-left: 12px;
  flex-shrink: 0;
}
.p-box__table3__item__list {
}
.p-box__table3__item__list__item {
  width: 100%;
  flex-shrink: 1;
}
.p-box__table3__item__list__item:not(:first-child) {
  margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .p-box__table {
    display: block;
  }
  .p-box__table__body {
    width: 100%;
    border-radius: var(--radius-md);
    border: 1px solid var(--gray200);
    background-color: var(--white);
    padding: 16px;
    margin-bottom: 16px;
    display: block;
  }
  .p-box__table__item {
    display: block;
    padding-bottom: 0;
  }
  .p-box__table__item:not(:first-child) {
    margin-top: 16px;
  }
  .p-box__table__item:not(:last-child) {
    padding-right: 0;
  }
  .p-box__table__item__ttl {
    margin-bottom: 6px;
  }
  .p-box__table2__body__item.flex {
    flex-direction: column;
  }
  .p-box__table2__body__item.flex > div {
    width: 100%;
    padding-right: 0;
  }
  .p-box__table2__body__item.flex > div:not(:first-child) {
    margin-top: 16px;
  }
  .p-box__table2__body__item__body.spStock {
    flex-direction: column;
  }
  .p-box__table2__body__item__body.spStock fieldset {
    width: 100% !important;
  }
  .p-box__table2__body__item__body.spStock > div {
    width: 100%;
    margin-top: 8px;
    margin-right: 0 !important;
  }
  .p-box__table2__body__item__box__table {
    display: block;
  }
  .p-box__table2__body__item__box__table__header,
  .p-box__table2__body__item__box__table__body {
    display: block;
  }
  .p-box__table2__body__item__box__table__item {
    display: block;
    padding-right: 0 !important;
  }
  .p-box__table3__item {
    flex-direction: column;
    align-items: flex-end;
  }
  .p-box__table3__item__body {
    flex-direction: column;
  }
  .p-box__table3__item__body__item:not(:first-child) {
    margin-top: 16px;
  }
  .p-box__table3__item__body__item__flex.spColumn {
    flex-direction: column;
    align-items: flex-end;
  }
  .p-box__table3__item__body__item__flex.spColumn > *:not(:first-child) {
    margin-top: 16px;
  }
  .p-box__table3__item__body__item__flex.spColumn > *:not(:last-child) {
    margin-right: 0;
  }
}

.p-box__list {
  margin-top: 16px;
}
.p-box__list__item {
}
.p-box__list__item:not(:first-child) {
  margin-top: 12px;
}
.p-box__list__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-bottom: 6px;
}
.p-box__list__item__body {
}
.p-box__list__item__body__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-box__list__item__body__flex.wrap {
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.p-box__list__item__body__flex.wrap > * {
  margin-bottom: 12px;
}
.p-box__list__item__body__flex.full {
  width: 100%;
}
.p-box__list__item__body__flex.full > * {
  width: 100%;
  flex-shrink: 1;
}
.p-box__list__item__body__flex:not(:first-child) {
  margin-top: 12px;
}
.p-box__list__item__body__flex__main {
  width: 100%;
  margin-right: 12px;
}
.p-box__list__item__body__flex__actions {
  flex-shrink: 0;
}
.p-box__list__item__body__flex > *:not(.p-box__list__item__body__flex__main),
.p-box__list__item__body__flex
  > *:not(.p-box__list__item__body__flex__actions) {
  margin-right: 12px;
}
.p-box__list__item__body__flex > *:last-child {
  margin-right: 0;
}

.p-box__list__item__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-box__list__item__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
@media screen and (max-width: 768px) {
  .p-box__list__item__body__flex.full {
    flex-direction: column;
    margin-top: 16px;
  }
  .p-box__list__item__body__flex.full > * {
    margin-right: 0;
  }
  .p-box__list__item__body__flex.full > *:not(:first-child) {
    margin-top: 16px;
  }
}

.p-box__nomalFlex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-box__nomalFlex > div {
  flex-shrink: 0;
}
.p-box__nomalFlex > div:not(:last-child) {
  margin-right: 32px;
}
@media screen and (max-width: 768px) {
  .p-box__nomalFlex {
    flex-direction: column;
  }
  .p-box__nomalFlex > div {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0 !important;
  }
}

.p-box__table__perBooking {
  display: flex;
  align-items: center;
}
.p-box__table__perBooking > div:last-child {
  margin-left: 8px;
}
.p-timesAndInventory {
}
.p-timesAndInventory > *:not(:first-child) {
  margin-top: 8px;
}

.p-previewBtn {
  height: 48px;
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-previewBtn.large {
  width: 240px;
}
.p-previewBtn.narrow {
  width: 128px;
}
.p-previewBtn.guide {
  width: 160px;
  height: 32px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-previewBtn.guide img {
  height: 20px;
  margin-right: 8px;
}

.p-flexSmall {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.p-unit {
}
.p-unit__header,
.p-unit__body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-unit__body {
  margin-top: 8px;
}
.p-unit__header > p {
  margin: 0;
}
.p-unit__header > * {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
}
.p-unit__header > *,
.p-unit__body > * {
  width: 172px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
}
.p-unit__header > *:not(:last-child),
.p-unit__body > *:not(:last-child) {
  margin-right: 12px;
}
.p-unit__body .toggle {
  display: flex;
  justify-content: center;
}
.p-unit__note {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray900);
  margin-top: 16px;
}

.p-rule {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
}
.p-rule__ttl {
  font-weight: var(--text-semibold);
  color: var(--warning600);
  margin-bottom: 4px;
}
.p-rule__body {
}

.p-reservationStart {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
}
.p-reservationStart__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-reservationStart__flex > p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-reservationStart__flex > * {
  margin-right: 12px;
}
.p-reservationStart__flex > p:first-child {
  margin-right: 12px;
}
.p-reservationStart__toggle {
  display: flex;
  align-items: center;
}
.p-reservationStart__toggle > *:not(:first-child) {
  margin-left: 8px;
}
@media screen and (max-width: 768px) {
  .p-reservationStart__flex {
    flex-direction: column;
  }
  .p-reservationStart__flex > * {
    width: 100%;
    margin-right: 0;
  }
  .p-reservationStart__flex > *:not(:first-child) {
    margin-top: 12px;
  }
}

.p-limit {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -8px;
}
.p-limit__item {
  width: 216px;
  min-height: 48px;
  border-right: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
}
.p-limit__item__flex {
  width: 100%;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-limit__item__flex.reverse {
  flex-direction: row-reverse;
}
.p-limit__item__flex div {
  font-size: var(--text-xs-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xs-height);
}
.p-limit__item__flex div.blue {
  color: var(--primary600);
}
.p-limit__item__flex div.blue2 {
  color: var(--primary800);
}
.p-limit__item__flex div.orange {
  color: var(--warning600);
}
.p-limit__item__flex div.gray {
  color: var(--gray400);
}
.p-limit__item__flex p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray700);
}
.p-limit__item__arrow {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.p-limit__item__arrow::before {
  content: '';
  width: 100%;
  height: 3px;
  background-color: var(--gray200);
}
.p-limit__item__arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 16px solid var(--gray200);
  border-right: 0;
}
.p-limit__item__txt {
  color: var(--primary600);
}

.p-cancelpolicy {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--warning600);
  margin-top: 8px;
}
@media screen and (max-width: 768px) {
  .p-limit {
    flex-direction: column;
    margin-bottom: 0;
  }
  .p-limit__item {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--gray200);
    margin-bottom: 0;
    flex-direction: row-reverse;
  }
  .p-limit__item__arrow {
    width: 16px;
    min-height: 48px;
    flex-direction: column;
  }
  .p-limit__item__arrow::before {
    width: 3px;
    height: 100%;
  }
  .p-limit__item__arrow::after {
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 12px solid var(--gray200);
    border-bottom: 0;
  }
  .p-limit__item__flex.reverse {
    flex-direction: row;
  }
}

.p-acBox {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  /* TODO: remove overflow hidden for now because this will hide children elements for example dropdown options from showing */
  /* overflow: hidden; */
}
.p-acBox:not(:first-child) {
  margin-top: 16px;
}
.p-acBox__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-acBox__header__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-acBox__header__ttl__ic {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-acBox__header__ttl__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-acBox__header__ttl__ic i::before,
.p-acBox__header__ttl__ic i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-acBox__header__ttl__txt {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  color: var(--gray900);
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}
.p-acBox__header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.p-acBox__header__actions > * {
  margin-left: 12px;
}
.p-acBox__body {
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  border-top: 1px solid var(--gray200);
  padding: 20px;
  display: none;
}
.p-acBox__body.is-active {
  display: block;
}
@media screen and (max-width: 768px) {
  .p-acBox__header__actions a > p {
    display: none;
  }
  .p-schedSpWidth {
    width: 100%;
    margin-right: 0 !important;
  }
  .p-schedSpWidth > * {
    flex-shrink: 1;
    width: 100%;
  }
  .p-schedSpWidth > *:not(:last-child) {
  }
}

.p-plane {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
}
.p-plane:not(:first-child) {
  margin-top: 16px;
}
.p-plane__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  margin-bottom: 12px;
}
.p-plane__sort {
  width: 100%;
}
.p-plane__sort__item {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--gray50);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-plane__sort__item.small {
  border: none;
  padding: 0;
}
.p-plane__sort__item.white {
  background-color: var(--white);
}
.p-plane__sort__item:not(:first-child) {
  margin-top: 16px;
}
.p-plane__sort__item > *:not(:last-child) {
  margin-right: 12px;
}
.p-plane__sort__item__ic {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-plane__sort__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-plane__sort__item__ic i::before,
.p-plane__sort__item__ic i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-plane__sort__item__txt {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-plane__sort__item__body {
  width: 100%;
}
.p-plane__sort__item__actions {
  flex-shrink: 0;
}
.p-plane__sort__children {
  padding-left: 32px;
}
.p-participationRules {
  /* display: flex;
  align-items: center; */
}
.p-participationRules__item {
  display: flex;
  flex-direction: column;
}
.p-participationRules__item:not(:first-child) {
  margin-top: 16px;
}
.p-participationRules__item__date {
  display: flex;
  align-items: center;
}
.p-participationRules__item__date > *:not(:first-child) {
  margin-left: 8px;
}
.p-participationRules__calendar {
  /* min-width: 300px; */
}
.p-limitReservations {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-limitReservations__item {
  width: 100%;
  flex-shrink: 1;
}
.p-limitReservations__item:not(:last-child) {
  margin-right: 12px;
}
.p-limitReservations__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
}
.p-limitReservations__item__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
.p-limitReservations__item__flex > * {
  width: 100%;
  flex-shrink: 1;
}
.p-limitReservations__item__flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-limitReservations__item__flex__actions {
  width: 36px;
}
.p-deadline__cancelFee {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-deadline__cancelFee > *:not(:first-child) {
  margin-left: 8px;
}
.p-deadline {
  display: flex;
  width: 100%;
}
.p-deadline > *:not(:first-child) {
  margin-left: 8px;
}
.p-deadline__first {
  flex-grow: 1;
}
.p-deadline__second {
  flex-grow: 2;
}
.p-deadline__third {
  flex-grow: 2;
}
.p-limitReservations__deadlineInput__flex {
  display: flex;
}
.p-limitReservations__deadlineInput__flex > *:not(:first-child) {
  margin-left: 8px;
}
@media screen and (max-width: 768px) {
  .p-limitReservations {
    flex-direction: column;
  }
  .p-limitReservations__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.p-products__msg {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--error600);
  border: 1px solid var(--error200);
  border-radius: var(--radius-xl);
  background-color: var(--error50);
  padding: 16px;
  margin-bottom: 24px;
}
.p-products__msg__ttl {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-products__msg__ttl i {
  font-size: 20px;
  margin-right: 16px;
}
.p-products__msg__ttl p {
  font-weight: var(--text-semibold);
}
.p-products__msg__list {
  padding-left: 24px;
  margin-left: 36px;
}
.p-products__msg__list li {
  list-style: disc;
  margin-top: 8px;
}
.p-products__msg__list li a {
  text-decoration: underline;
}

.p-products__fixed {
  width: 100%;
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
    0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 25;
  transition: bottom 0.2s linear;
}
.p-products__fixed.is-active {
  bottom: 0;
  opacity: 1;
}
.p-products__fixed__main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
  margin-left: 220px;
}
.p-products__fixed__main__ttl {
  margin-left: 8px;
}
.p-products__fixed__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-products__fixed__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-products__fixed__actions__select {
  position: relative;
}
.p-products__fixed__actions__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  bottom: 40px;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-products__fixed__actions__select__menu.is-active {
  display: block;
}
.p-products__fixed__actions__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-products__fixed__actions__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-products__fixed__actions__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
@media screen and (max-width: 768px) {
  .p-products__fixed {
    width: 100% !important;
    padding: 0 16px;
  }
  .p-products__fixed__main {
    display: none;
    margin-left: 0;
  }
  .p-products__fixed__actions {
    width: 100%;
  }
  .p-products__fixed__actions > *:first-child {
    /* TODO: do not use this yet since we don't have other menu to display  */
    /* margin-right: auto; */
  }
}

/* TODO: NEW STYLE end */

.c-tab-box {
}
.c-tab-box__tab {
  width: 100%;
  margin-bottom: -1px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.c-tab-box__tab li {
  width: 160px;
  min-height: 40px;
  font-size: 13px;
  font-weight: bold;
  color: #9bacb5;
  border-radius: 8px 8px 0 0;
  border: 1px solid #cfdae1;
  background: #cfdae1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-tab-box__tab li.is-active {
  color: #008ec9;
  background: #fff;
  border-bottom: 1px solid #fff;
}
.c-tab-box__tab li + li {
  margin-left: 8px;
}
.c-tab-box__tab li a {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0px 4px;
}
.c-tab-box__box {
  display: none;
}
.c-tab-box__box.is-active {
  display: block;
}
.page-productsEdit__select {
  margin-bottom: 16px;
}
.page-productsEdit__select__ttl {
  margin-bottom: 4px;
}
.page-productsEdit__select__box {
  display: flex;
}
.page-productsEdit__select__box a {
  height: 40px !important;
  margin-left: 16px;
  flex-shrink: 0;
}
.page-productsRegist__date {
}
.page-productsRegist__date:not(:last-child) {
  /* border-bottom: 1px solid #cfdae1; */
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.page-productsRegist__date__ttl {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.page-productsRegist__date__range {
  width: 100%;
  max-width: 320px;
}
.page-productsRegist__per__group__unit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}
.page-productsRegist__per__group__unit__select {
  max-width: 200;
}
.page-productsRegist__priceMain__ttl {
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 16px !important;
}
.page-productsRegist__priceMain__ttl.borderTop {
  border-top: 1px solid #cfdae1;
  padding-top: 16px;
  margin-top: 16px;
}
.page-productsRegist__priceMain__price {
  display: flex;
  justify-content: flex-start;
}
.page-productsRegist__priceMain__price p {
  font-weight: bold;
  line-height: 40px;
  padding: 0 8px;
  margin: 0px;
}
.back-arrow-circle {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #008ec9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-arrow-circle img {
  width: 24px;
  height: 24px;
}
.package-start-time-section + .package-start-time-section {
  border-top: 1px solid #cfdae1;
  padding-top: 8px;
}
.package-start-time-label {
  padding-top: 8px;
  padding-bottom: 8px;
}
.package-start-time-component-section + .package-start-time-component-section {
  padding-top: 16px;
}
@media screen and (max-width: 768px) {
  .c-tab-box__tab {
    padding: 0 8px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-tab-box__tab li {
    width: 112px;
    font-size: 12px;
    flex-shrink: 0;
  }
  .c-tab-box__box {
    margin-top: 0;
  }
  .header-product-title {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.acBody {
}
.acBody__btns {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.acBody__btns > * + * {
  margin-left: 8px;
}
.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul {
    border-bottom: 1px solid #cfdae1;
    margin-bottom: 8px;
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
}

.request-booking-toggle-container {
  min-width: 150px;
}

.p-transportRoute__routeTitle {
  font-weight: var(--text-semibold);
  margin-bottom: 16px !important;
}

.p-checkinPickup__displayTime {
  color: inherit;
  font-weight: var(--text-medium);
}

.p-reservationForm__item__list {
  display: flex;
  flex-direction: column;
}
.p-reservationForm__item__list > div:not(:first-child) {
  margin-top: 16px;
}
.p-reservationForm__item__list > div > p {
  margin-bottom: 8px;
}

.p-bookingWidget__language {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  border-collapse: collapse;
  width: 100%;
  align-items: center;
  row-gap: 12px;
  column-gap: 8px;
}
.p-bookingWidget__paymentType {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-collapse: collapse;
  width: 100%;
  align-items: center;
  row-gap: 12px;
  column-gap: 8px;
}

/*------------------------------------------------------------------------------
  tableChild
  ------------------------------------------------------------------------------*/
.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul {
    border-bottom: 1px solid #cfdae1;
    margin-bottom: 8px;
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
}

.p-selectableParticipantRule {
  margin-top: 16px;
}
.p-selectableParticipantRule__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.p-selectableParticipantRule__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.p-selectableParticipantRule__requiredUnit {
  display: flex;
  align-items: center;
}
.p-selectableParticipantRule__requiredUnit:not(:first-child) {
  margin-top: 8px;
}
.p-selectableParticipantRule__requiredUnit > *:not(:first-child) {
  margin-left: 8px;
}
.p-selectableParticipantRule__restrictedUnit {
  display: flex;
  align-items: center;
}
.p-err {
  color: var(--error600);
}
