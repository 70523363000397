.g-section {
  margin: 0;
}

.p-search__bottom {
  width: 100%;
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.center {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center > *:not(:last-child) {
  margin-right: 12px;
}

/* search */
.p-search {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-search__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-search__header__title {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
  margin: 0;
}
.p-search__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-search__header__actions > *:not(:last-child) {
  margin-right: 12px;
}

.p-search__body {
  width: 100%;
  padding: 16px 24px;
}
.p-search__body__item {
  display: flex;
}
.p-search__body__item > * {
  flex-shrink: 1;
}
.p-search__body__item:not(:first-child) > * {
  margin-top: 16px;
}
.p-search__body__item > *:not(:last-child) {
  margin-right: 16px;
}
.p-search__body__item:last-child {
  padding-bottom: 16px;
}
.p-search__body__item__flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.p-search__body__item__flex > *:not(:last-child) {
  margin-right: 16px;
}
.p-search__body__item__radiotitle {
  font-size: var(--text-sm-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
}
[class^='p-search__bottom'] {
  width: 100%;
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
[class^='p-search__bottom'][class*='center'] {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
[class^='p-search__bottom'][class*='center'] > *:not(:last-child) {
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  .p-search__body {
    padding: 16px;
  }
  .p-search__body__item {
    display: block;
  }
  .p-search__body__item > * {
    margin-right: 0 !important;
  }
  .p-search__body__item > *:not(:first-child) {
    margin-top: 16px;
  }
  .p-search__body__item__radiotitle {
    width: 100%;
    margin-bottom: 8px;
  }
}
.add-new {
  color: #008ec9;
  display: flex;
  gap: 4px;
  align-items: center;
}
.add-new i {
  opacity: 1 !important;
}
.add-new p {
  margin-left: 4px;
}
