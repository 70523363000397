.c-tooltip {
  display: flex;
  align-items: center;
}
.c-tooltip__body {
  font-size: 12px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 12px;
  position: fixed;
  z-index: 10;
  white-space: pre-line;
}
