p.table__section__header {
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 16px;
}
.c-table-list__btns {
  width: 88px;
}

.c-table-list__btns.hidden {
  visibility: collapse;
}
@media screen and (max-width: 768px) {
  .back-arrow {
    margin-top: 16px;
    margin-left: 8px;
  }
  .c-table-list__btns {
    width: 100%;
  }
}
