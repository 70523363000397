.draggable-box {
  width: 100%;
  max-width: 600px;
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: move;
}

.icon-box {
  padding-left: 2px;
  padding-right: 2px;
}
