.c-toggleableList {
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--gray50);
  padding: 16px;
  position: relative;
}
.c-toggleableList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-toggleableList__item:not(:first-child) {
  margin-top: 12px;
}
.c-toggleableList__item.is-disabled {
  color: var(--gray400);
  background-color: var(--gray50);
  pointer-events: none;
}
.c-toggleableList__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-toggleableList__item__left p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.c-toggleableList__item__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.c-toggleableList__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-toggleableList__item__ic i::before,
.c-toggleableList__item__ic i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.c-toggleableList__item__right {
  margin-left: auto;
}
.c-toggleableList__item__right > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}
.noOuterBox {
  border: none;
  border-radius: unset;
  background-color: unset;
  padding: 0;
}