/* Note: originally these properties were defined in 'ReservationDetailsSection.module.css' */
.p-primary__select {
  position: relative;
}
.p-primary__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  right: 0;
  margin-top: 4px;
  z-index: 10;
  display: none;
}
.is-active {
  display: block;
}
.p-primary__select__menu__item {
  position: relative;
  cursor: pointer;
}
.p-primary__select__menu__item > * {
  padding: 8px 10px;
}
.p-primary__select__menu__item *:not(:first-child) {
  margin-left: 4px;
}
.p-primary__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-primary__select__menu__item div,
.p-primary__select__menu__item a {
  width: 100%;
  text-decoration: none !important;
  color: inherit !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.p-primary__select__menu__item i {
  font-size: var(--text-base-size);
}
.p-primary__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.open-above {
  bottom: 44px;
}
.red {
  color: var(--error500);
}
