/**
 * This code was generated by Builder.io.
 */
.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fdfdff;
}

.mainContent {
  display: flex;
  flex: 1;
}

@media (max-width: 991px) {
  .mainContent {
    flex-direction: column;
  }
}
.mainContentSection {
  flex: 1;
  padding: 24px;
  background-color: #fdfdff;
}

.contentHeader {
  margin-bottom: 24px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: #393d3f;
  margin: 0;
}

.subtitle {
  font-size: 16px;
  color: #393d3f;
  margin: 8px 0 0;
}

.contentNav {
  margin-bottom: 24px;
}

.navList {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-radius: 5px;
}

.navItem {
  flex: 1;
}

.navLink {
  display: block;
  padding: 8px 16px;
  text-align: center;
  color: #b5b8bc;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.navLink:hover,
.navLink:focus {
  background-color: #f3f6f8;
}

.searchForm {
  margin-bottom: 24px;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.searchInput {
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #f3f6f8;
  border-radius: 8px;
  font-size: 16px;
}

.contentGrid {
  display: flex;
  gap: 16px;
}
.leftColumn {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (max-width: 991px) {
  .mainContentSection {
    padding: 16px;
  }

  .contentGrid {
    grid-template-columns: 1fr;
  }
}
