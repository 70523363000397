.p-topPage-products__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-topPage-products__section:last-of-type {
  margin-bottom: 50px;
}
.p-topPage-products__section:not(:first-child) {
  margin-top: 30px;
}
.p-topPage-products__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-topPage-products__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-topPage-products__section__ttl > span {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 4px;
  display: block;
}
.p-topPage-products__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-topPage-products__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-topPage-products__section__body.is-active {
  display: block;
}
.p-topPage-products__section__body__textarea textarea {
  min-height: 44px;
}
.p-topPage-products__section__body__textarea2 textarea {
  min-height: 120px;
}

.p-topPage-products__section__body__item:not(:first-child) {
  margin-top: 14px;
}

.p-topPage-products__section__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.p-topPage-products__section__body__item.flex > *:not(:last-child) {
  margin-right: 16px;
}

.p-topPage-products__section__body__youtube i {
  color: #EB361C;
}

.p-topPage-products__section__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-topPage-products__section__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .p-topPage-products__section__header {
    padding: 16px;
  }
  .p-topPage-products__section__body {
    padding: 16px;
  }
}

.p-topPage-acBox {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  overflow: hidden;
}
.p-topPage-acBox:not(:first-child) {
  margin-top: 30px;
}
.p-topPage-acBox__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.p-topPage-acBox__header__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.p-topPage-acBox__header__ttl__ic {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-topPage-acBox__header__ttl__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-topPage-acBox__header__ttl__ic i::before,
.p-topPage-acBox__header__ttl__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-topPage-acBox__header__ttl__txt {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  color: var(--gray900);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.p-topPage-acBox__header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.p-topPage-acBox__header__actions > * {
  margin-left: 12px;
}
.p-topPage-acBox__body {
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  border-top: 1px solid var(--gray200);
  padding: 21px 23px;
  display: none;
}
.p-topPage-acBox__body.is-active {
  display: block;
}

.p-topPage-acBox__body .p-topPage-acBox {
  margin-top: 14px;
}
@media screen and (max-width: 768px) {
  .p-topPage-acBox__header__actions a > p {
    display: none;
  }
  .p-schedSpWidth {
    width: 100%;
    margin-right: 0 !important;
  }
  .p-schedSpWidth > * {
    flex-shrink: 1;
    width: 100%;
  }
  .p-schedSpWidth > *:not(:last-child) {

  }
}
