.actions {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.manageLink {
  margin-left: 8px;
  text-decoration: none;
  color: #008ec9;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 240px;
}

.graphButton {
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #3e8cc4;
  background-color: #fff;
  color: #3e8cc4;
  font: 700 18px Inter, sans-serif;
  text-transform: capitalize;
  overflow: hidden;
}

.graphButtonIcon {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.actionButton {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.actionButton p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.red {
  color: #dc2626;
}