.c-weekdayInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -12px;
}
.c-weekdayInput > * {
  margin-top: 12px;
}
.c-weekdayInput > *:not(:last-child) {
  margin-right: 12px;
}
