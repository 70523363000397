/* TODO: New style */
/* This is only temporary until we have the official mockup HTML & CSS from designer */
.p-productsDetail {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-productsDetail__header {
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid var(--gray200);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.p-productsDetail__ttl {
  font-size: var(--text-2xl-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-2xl-height);
}
.p-productsDetail__ttl__noBg {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
  background: var(--bg);
}
.p-productsDetail__actions {
  display: flex;
  margin-top: 12px;
}
.p-productsDetail__section {
  margin-top: 32px;
}
.p-productsDetail__section__ttl {
  font-size: var(--text-2xl-size);
  font-weight: var(--text-bold);
  line-height: 1.5;
  border-top: 1px solid var(--gray200);
  padding-top: 24px;
  display: flex;
}
.p-productsDetail__section__body {
}

/* Note: these productsDetail__actions properties are used to override style in module.css of respective components */
.p-productsDetail__actions > ul {
  margin-top: 0;
}
.p-productsDetail__actions > div {
  flex-wrap: nowrap;
}
@media screen and (max-width: 768px) {
  .p-productsDetail__actions > ul {
    padding: 0;
  }
  .p-productsDetail__actions > div {
    flex-wrap: wrap;
    padding: 0;
  }
}
.p-productsDetail__body {
  padding: 24px;
  box-sizing: border-box;
}

/* TODO: OLD style from here */
.c-list {
}
.c-list__item {
  width: 100%;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-list__item + .c-list__item {
  padding-top: 16px;
}
.c-list__item__title {
  width: 160px;
  font-size: 14px;
  font-weight: bold;
  color: #0094cc;
}
.c-list__item__body {
  width: calc(100% - 160px);
  padding-left: 24px;
}
.c-list__item__body li {
  list-style: disc;
}
.base-single__card {
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 6px;
  margin-top: 16px;
  overflow: hidden;
}
.base-single-flex {
  display: flex;
  justify-content: flex-start;
}
@media screen and (max-width: 768px) {
  .c-list__item__title {
    width: 100%;
    margin-bottom: 16px;
  }
  .c-list__item__body {
    width: 100%;
  }
  .base-single__card {
    border-radius: 0;
  }
  .base-single-flex {
    flex-direction: column;
  }
}

.p-productsDetail__bookingwidgetpreview__wrapper {
  border-radius: var(--radius-xl);
}
.p-productsDetail__bookingwidgetpreview {
  width: 100%;
}
