.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media (max-width: 1023px) {
  .container {
    max-width: 100%;
  }
}
.container-row {
  gap: 20px;
  display: flex;
}
@media (max-width: 1023px) {
  .container-row {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
