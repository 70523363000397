.table-cell-minimal {
  width: 50px !important;
}
.table-cell-short {
  width: 120px !important;
}

@media screen and (max-width: 768px) {
  .table-cell-minimal {
    width: 100% !important;
  }
  .table-cell-short {
    width: 100% !important;
  }
}