.p-areaGroupSettings {
}
.p-areaGroupSettings__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}
.p-areaGroupSettings__header__ttl {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-semibold);
}
.p-areaGroupSettings__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-areaGroupSettings__body {
}
.p-areaGroupSettings__body .noData {
  padding: 16px;
  display: table-cell;
  text-align: center;
}

/* Modal properties */
.p-areaGroupSettingsModal {
}
.p-areaGroupSettingsModal__item {
}
.p-areaGroupSettingsModal__item:not(:first-child) {
  margin-top: 16px;
}
.p-areaGroupSettingsModal__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 6px !important;
  display: flex;
  align-items: center;
}
.p-areaGroupSettingsModal__item__ttl span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
  display: block;
}
.p-areaGroupSettingsModal__item__ttl p:first-child {
  margin-right: 6px;
}
.p-areaGroupSettingsModal__item__body {
}
.p-areaGroupSettingsModal__item__body__flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.p-areaGroupSettingsModal__item__body__flex > *:not(:first-child) {
  margin-left: 8px;
}
.p-tagSettingsModal__condition__list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.p-areaGroupSettingsModal__tagColor__item {
  display: flex;
  align-items: center;
}
.p-areaGroupSettingsModal__tagColor__item i {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: var(--radius-sm);
  border: 1px solid;
  opacity: 1;
  margin-right: 8px;
}
.p-areaGroupSettings__footer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.p-tagSettingsModal__condition__add {
    width: max-content;
    color: var(--primary700);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
}
.p-tagSettingsModal__condition__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
