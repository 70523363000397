.total-amount {
  margin-left: auto;
  background: #fff;
  padding: 16px;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #cfdae1;
  border-radius: 4px;
}
.header-buttons-row {
  display: flex;
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .header-buttons-row {
    flex-wrap: wrap;
  }
}
