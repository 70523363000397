.c-durationInput {
  display: flex;
  width: 100%;
}
.c-durationInput > *:not(:first-child) {
  margin-left: 8px;
}
.c-durationInput__sign {
  flex: 2;
}
.c-durationInput__duration {
  flex: 3;
}
