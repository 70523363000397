.nutmeg-admin-note {
  text-decoration: italic;
  margin-top: 8px;
  margin-bottom: 8px;
}
.id-editor {
  margin-top: 16px;
}
.id-editor-row {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}
.action-buttons {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
