/**
 * This code was generated by Builder.io.
 */
.details {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionTitle {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
  color: var(--Black, #1e202f);
  text-transform: capitalize;
}

.formGroup {
  display: flex;
  gap: 16px;
}

.inputWrapper {
  flex: 1;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  color: var(--colour-6, #ff8360);
  font-weight: 400;
  text-transform: capitalize;
}

.required {
  color: var(--colour-6, #ff8360);
}

.input,
.selectInput {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid rgba(218, 221, 226, 1);
  padding: 0 10px;
}

.select {
  position: relative;
}

.select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--colour-10, #393d3f);
}

@media (max-width: 991px) {
  .formGroup {
    flex-direction: column;
  }
}
