.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 16px;
}

.header {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  color: var(--colour-10, #393d3f);
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.timeChart {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  flex-direction: column;
  padding: 8px;
}

.dayList {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 15px;
  color: var(--colour-9, #b5b8bc);
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: -0.41px;
  justify-content: center;
  font: 600 14px/1 Inter, sans-serif;
}

.day {
  align-self: stretch;
  margin: auto 0;
}

.activeDay {
  border-bottom: 2px solid var(--color-15, #3aa0ff);
  align-self: stretch;
  width: 26px;
  margin: auto 0;
}

.chartContainer {
  display: flex;
  margin-top: 15px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.chart {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.chartBackground {
  z-index: 10;
  display: flex;
  width: 100%;
}

.backgroundLines {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
}

.backgroundLine {
  background-color: #f3f6f8;
  min-height: 1px;
  width: 266px;
  max-width: 100%;
  border: 1px solid rgba(243, 246, 248, 1);
}

.barContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.bar {
  background: var(--colour-8, #e6ebff);
  width: 20px;
  display: flex;
  justify-content: center;
}

.bar.active {
  background: var(--color-15, #3aa0ff);
}

.barLabel {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  margin-top: -24px;
}
.barLabel.active {
  color: var(--color-15, #3aa0ff);
  font-size: 14px;
  font-weight: 700;
}

.timeLabels {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--colour-10, #393d3f);
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: -0.41px;
  justify-content: space-between;
  font: 400 12px/2 Inter, sans-serif;
}

.timeLabel {
  align-self: stretch;
  margin: auto 0;
}

.liveInfo {
  align-self: center;
  display: flex;
  margin-top: 8px;
  align-items: center;
  gap: 4px;
  color: var(--colour-10, #393d3f);
  justify-content: flex-start;
  font: 400 14px Inter, sans-serif;
}

.liveIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 13px;
  align-self: stretch;
  margin: auto 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.bold {
  font-weight: 700;
}

.highlight {
  color: rgba(58, 160, 255, 1);
}
.toggle {
  padding: 0 8px;
}
