/* Modal properties */
.p-installModal {
}
.p-installModal__item {
}
.p-installModal__item:not(:first-child) {
  margin-top: 16px;
}
.p-installModal__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-installModal__item__ttl span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
  display: block;
}
.p-installModal__item__ttl p:first-child {
  margin-right: 6px;
}
.p-installModal__item__body {
}
.p-installModal__item__body__flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.p-installModal__item__body__flex > *:not(:first-child) {
  margin-left: 8px;
}
.p-installModal__codeBlock {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  white-space: pre-wrap;
  word-break: break-all;
  display: inline-block;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  overflow-x: auto;
  padding: 12px;
}
.p-installModal__codeBlock code {
  font-size: 12px;
  line-height: 1.6;
}