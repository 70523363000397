.chip {
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: .05em;
  color: #ffffff;
  text-align: center;
  line-height:1;
}

@media screen and (max-width: 768px) {
  .chip {
    width: 120px;
  }
}
.chip-new-reservation {
  background-color: #38BA4E;
}

.chip-updated {
  background-color: #B6CC34;
}
.chip-changed {
background-color: #29c8cb;
}
.chip-canceled {
  background-color: #E03994;
}

.chip-withdrawn {
  background-color: #E03994;
}