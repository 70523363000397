.arrow-circle {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #008ec9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-circle img {
  width: 24px;
  height: 24px;
  rotate: -90deg;
}
