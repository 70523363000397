.p-dashboard__header__dateRange {
  min-width: 300px;
}
.p-dashboard__body {
  width: 100%;
  padding-bottom: 32px;
}
.p-dashboard__body > div:not(:first-child) {
  margin-top: 24px;
}

.c-gadgetList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.c-gadgetList__threeColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}