.page-productsRegist__priceMain__price {
  display: flex;
  justify-content: flex-start;
}
.page-productsRegist__priceMain__price p {
  font-weight: bold;
  line-height: 40px;
  padding: 0 8px;
  margin: 0px;
}
