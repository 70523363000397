.nutmeg-multi-calendar-enc-div {
  width: 100%;
  max-width: 1024px;
}

.nutmeg-multi-calendar {
  border: 0;
  background: #fff;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 6px;
  width: 100%;
  max-width: 1024px;
}
