.schedule__businessHours {
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.schedule__businessHours > p {
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0;
}

.schedule__spacer {
  margin: 8px;
}
