.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  border-radius: 12px;
  flex: 2;
}
.container-inner {
  border-radius: 12px;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  white-space: nowrap;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .container-inner {
    margin-top: 16px;
    white-space: initial;
  }
}
.header {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: var(--Color-Gray-700, #3f3f46);
  font-weight: 600;
  line-height: 156%;
  justify-content: center;
  padding: 16px 24px;
  height: 60px;
}
@media (max-width: 991px) {
  .header {
    white-space: initial;
    padding: 0 20px;
  }
}
.header-inner {
  justify-content: space-between;
  display: flex;
  gap: 16px;
}
@media (max-width: 991px) {
  .header-inner {
    white-space: initial;
  }
}
.controls {
  margin-left: auto;
  display: flex;
  gap: 16px;
  align-items: center;
}
.legend {
  display: flex;
  align-items: center;
}
.legend-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 131, 96, 1);
  margin-right: 10px;
}
.legend-label {
  font-size: 14px;
  color: #333;
}
.body {
  height: 560px;
  background-color: #f5f5f5;
}
.map-container {
  height: 480px;
  width: 100%;
}
.pin-icon {
  transform: scale(0.6);
  opacity: 0.75;
  transform-origin: bottom center;
}
.pin-icon.active {
  transform: scale(1);
  opacity: 1;
}
.currentPositionMarker {
  transform: translateY(12px);
  width: 61px;
  height: 61px;
  flex-shrink: 0;
}
.no-events {
  padding: 24px;
  font-size: 14px;
  color: #333;
}
