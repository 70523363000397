/**
 * This code was generated by Builder.io.
 */
.hours {
  padding: 24px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--Black, #1e202f);
  text-transform: capitalize;
  margin-bottom: 24px;
}

.statusToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.toggleOptions {
  display: flex;
  gap: 20px;
}

.toggleButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: var(--Black, #1e202f);
  font-weight: 400;
  text-transform: capitalize;
}

.hoursToggle {
  display: flex;
  margin-bottom: 24px;
}

.activeToggle,
.inactiveToggle {
  padding: 8px 16px;
  font-size: 14px;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
}

.activeToggle {
  background-color: var(--White, #fff);
  border-radius: 5px 0 0 5px;
  border: 1px solid rgba(235, 239, 243, 1);
  font-weight: 600;
}

.inactiveToggle {
  background-color: transparent;
  border-radius: 0 5px 5px 0;
  border: 1px solid rgba(235, 239, 243, 1);
  font-weight: 400;
}

.hoursForm {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(243, 246, 248, 1);
}

.formGroup {
  display: flex;
  gap: 20px;
  flex: 1;
}

.inputWrapper {
  flex: 1;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  color: var(--colour-10, #393d3f);
  font-weight: 400;
}

.input {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid rgba(218, 221, 226, 1);
  padding: 0 10px;
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
}

.addDateButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-17, #3e8cc4);
  font-weight: 700;
  font-size: 16px;
}

.addIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--Grey, #ebeff3);
}

@media (max-width: 991px) {
  .formGroup {
    flex-direction: column;
  }
}
