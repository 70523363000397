.section-box {
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
}
.section-box__ttl {
    font-size: 14px;
    font-weight: bold;
    color: #008ec9;
    margin-top: 16px;
}
