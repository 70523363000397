.p-reservationsDetail__spMenu {
  width: 100%;
  height: 68px;
  margin-bottom: 12px;
  position: sticky;
  top: 56px;
  left: 0;
  z-index: 9;
}
.p-reservationsDetail__spMenu__inner {
  width: calc(100% + 32px);
  padding: 12px 16px;
  position: relative;
  left: -16px;
  transition: all 0.2s ease;
}
.is-scrolled {
  background-color: var(--white);
  box-shadow: var(--shadow-md);
  /* TODO: for now we need these 3 props to ensure sp menu not broken */
  margin-left: -16px;
  margin-right: -16px;
  padding: 0 16px;
  width: auto;
}

/* select */
.c-select {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-select__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-select__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.c-select__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-select__body > i::before {
  line-height: 20px;
}
.c-select__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-select__body label {
  width: 100%;
  position: relative;
}
.c-select__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.c-select__body__selected__input {
  /* width: 100%; */
  width: 64px;
}
.c-select__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-select__body__selected p {
  height: 24px;
  line-height: 24px;
}

.c-select.is-hover {
  border-color: var(--primary200);
}
.c-select.is-active {
  border-color: var(--primary700);
}
.c-select.is-input .c-select__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-select.is-active .c-select__body__title {
  color: var(--primary700);
}

.c-select__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.c-select__menu {
  width: calc(100% - 32px);
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  margin-left: 16px;
  margin-right: 16px;
  z-index: 20;
  display: none;
  overflow-y: auto;
}
.c-select__menu.overflow-no {
  overflow-y: visible;
}
.is-active {
  display: block;
}
.c-select__menu__item {
  padding: 8px 10px;
  position: relative;
}
.c-select__menu__item:hover {
  background-color: var(--gray50);
}
.c-select__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.c-select__menu__item i {
  opacity: 0;
}
.c-select__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.c-select__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.c-select__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  text-align: left;
}
.c-select__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}