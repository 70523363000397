.container {
  border-radius: 4px;
  background-color: var(--Colors-Base-White, #fff);
  display: flex;
  margin-top: 8px;
  gap: 8px;
  font-size: 12px;
  line-height: 133%;
  padding: 12px 25px 12px 12px;
}
.container:hover {
  background-color: var(--Colors-Gray-100, #f4f4f5);
}
.container.selected {
  background: #F0FAFF;
}
.time-range {
  color: var(--Colors-Gray-900, #18181b);
  flex-grow: 1;
  font: 14px/143% Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.duration {
  color: var(--Colors-Gray-600, #52525b);
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin: auto 0;
}
.label {
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  justify-content: center;
  border-radius: 9999px;
  color: var(--Colors-Base-White, #fff);
  font-weight: 500;
  white-space: nowrap;
  padding: 2px 6px;
}
.conversion {
  background-color:  #f59e0b;
}
.bounce {
  background-color: var(--gray400, #a1a1aa);
}
