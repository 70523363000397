.deadline-base {
  height: 32px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: .05em;
  color: #ffffff;
  text-align: center;
  line-height:1;
}

@media screen and (max-width: 768px) {
  .deadline-base {
    width: 120px;
  }
}
.deadline-expired {
  background-color: #B30041;
}

.deadline-24hours {
  background-color: #B30041;
}

.deadline-48hours {
  background-color: #ED30CD;
}

.deadline-3days {
  background-color: #FA86F2;
}

.deadline-4days {
  background-color: #FA86F2;
}

.num-of-task-base {
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.num-of-task-zero {
  background-color: gray;
}

.num-of-task-non-zero {
  background-color: red;
}
