.c-textarea__header {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.c-textarea__header > *:not(:first-child) {
  margin-left: 4px;
}

.c-textarea {
  width: 100%;
  min-height: 60px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  padding: 8px 12px;
  resize: vertical;
}
.c-textarea:hover {
  border-color: var(--primary200);
}
.c-textarea:focus {
  border-color: var(--primary700);
}
.c-textarea:disabled {
  border-color: var(--gray200);
  background-color: var(--gray50);
}
.is-error {
  color: var(--error500);
  border-color: var(--error300);
  background-color: var(--error50);
}
.c-textarea__textCount {
  margin-left: 8px;
  margin-bottom: 8px;
  align-self: flex-end;
  display: inline-block;
}

/* Disable */
.is-disabled {
  color: var(--gray300);
  pointer-events: none;
}