
.p-reservations__bottom {
  width: 100%;
  /* border-top: 1px solid var(--gray200); */
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* For table */
.c-tableFrame {
  position: relative;
}
.c-table {
  /* overflow: auto; */
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
.c-table.thead {
  overflow-x: hidden;
}
.c-table.tbody {
  overflow-x: auto;
}
.c-table__body {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table__body thead th {
  font-size: var(--text-xs-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-xs-height);
  text-align: left;
  color: var(--gray600);
  background-color: var(--gray50);
  border-top: 1px solid var(--gray200);
  border-bottom: 1px solid var(--gray200);
  padding: 12px 16px;
}
.c-table__body thead th > i {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
  color: var(--gray500);
}
.c-table__body thead th.is-active {
  color: var(--primary700);
}
.c-table__body thead th.is-active > i {
  color: var(--primary700);
}
.c-table__body tbody tr:not(:first-child) th,
.c-table__body tbody tr:not(:first-child) td {
  border-top: 1px solid var(--gray200);
}
.c-table__body tbody tr:hover td {
  background-color: var(--primary50);
}
.c-table__body tbody th,
.c-table__body tbody td {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  background-color: var(--white);
  padding: 16px;
}
.c-table__body tbody th a,
.c-table__body tbody td a {
  color: var(--primary600);
  text-decoration: underline;
}

.thead__th {
  min-width: 180px;
  max-width: 320px;
}
.tbody__td {
  min-width: 180px;
  max-width: 320px;
}

.sticky-top {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.sticky-left {
  position: sticky;
  left: 0;
  z-index: 2;
}
.sticky-left::after {
  content: '';
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.05);
}
table thead .sticky-left {
  z-index: 4;
}

.c-table__body thead th i + p {
  font-size: var(--text-xs-size) !important;
  font-weight: var(--text-semibold) !important;
  line-height: var(--text-xs-height) !important;
}
.c-table__body tbody td i + p {
  font-size: var(--text-sm-size) !important;
  font-weight: var(--text-normal) !important;
  line-height: var(--text-sm-height) !important;
}
.c-table__more p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.c-table__more a {
  color: var(--primary700) !important;
  margin-top: 8px;
  display: inline-block;
}

.c-tableFrame.arrow table th:first-child,
.c-tableFrame.arrow table td:first-child {
  padding-left: calc(12px + 28px);
}
.c-tableBtn {
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-tableBtn.left {
  left: 0;
}
.c-tableBtn.right {
  transform: scale(-1, 1);
  right: 0;
}
.c-tableBtn a {
  width: 28px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  /* top: 64px; */
  /* margin-top: 64px; */
  top: calc(50% - 40px);
  cursor: pointer;
}
.c-tableBtn a i {
  font-size: 24px;
  color: var(--white);
  display: block;
}

.c-table__sp {
  display: none;
}

/* Column size */
.short {
  width: 120px;
}
.middle {
  width: 180px;
}
.long {
  width: 220px;
}

@media screen and (max-width: 768px) {
  .p-reservations__fixed__footer__margin {
    margin-bottom: 0;
  }
  .c-table.thead {
    display: none;
  }
  .c-table__body tbody tr:hover td {
    background-color: transparent;
  }
  .c-table tr {
    width: 100%;
    border-radius: var(--radius-xl);
    border: 1px solid var(--gray200);
    background-color: var(--white);
    display: block;
    overflow: hidden;
  }
  .c-table tr:not(:first-child) {
    margin-top: 8px;
  }
  .c-table td {
    width: 100% !important;
    border: none !important;
    display: flex;
    justify-content: flex-end;
  }
  .c-table td::before {
    content: attr(data-text) '';
    font-size: var(--text-xs-size);
    font-weight: var(--text-medium);
    line-height: var(--text-xs-height);
    padding-right: 12px;
    margin-right: auto;
    display: block;
    flex-shrink: 0;
  }
  .c-table td.c-table__spHeader {
    border-bottom: 1px solid var(--gray200) !important;
    justify-content: flex-start !important;
  }
  .c-table td.c-table__spHeader::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-table td.c-table__spHeader::after {
    width: 20px;
    height: 20px;
    box-shadow: none;
    font-family: 'icon-arrows-solid' !important;
    font-style: normal;
    font-size: 20px;
    color: var(--primary600);
    content: '\b454';
    position: absolute;
    top: calc(50% - 10px);
    right: 8px;
  }
  .c-table td.c-table__spHeader label {
    margin-right: 12px;
  }
  .c-table td.c-table__spHeader label p {
    font-size: var(--text-base-size) !important;
    font-weight: var(--text-semibold) !important;
    line-height: var(--text-base-height) !important;
  }

  .c-table__more a {
    float: right;
  }
  .c-table td.c-table__detail {
    border-top: 1px solid var(--gray200) !important;
    justify-content: center;
    padding: 8px 16px;
  }
  .c-table td.c-table__detail a {
    /* font-size: var(--text-sm-size);
    font-weight: var(--text-semibold);
    line-height: var(--text-sm-height); */
    text-decoration: none;
  }
  .c-table td.c-table__detail::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-tableBtn {
    display: none;
  }
  .c-table__sp {
    display: block;
  }
  .c-tableFrame.arrow table th:first-child,
  .c-tableFrame.arrow table td:first-child {
    padding-left: 16px;
  }
  .sticky-left::after {
    box-shadow: none;
  }
  .thead__th {
    max-width: none;
  }
  .tbody__td {
    max-width: none;
  }
}

/* Copied from src/client/base.module.css as this is not handled in new UI/UX CSS */
@media screen and (max-width: 768px) {
  .base-t-spHidden {
    display: none !important;
  }
}