
.form-number {
  width: 128px;
  height: 40px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block;
  position: relative;
  flex-shrink: 0;
  margin: 0 4px;
}
.form-number input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: center;
  padding: 4px 8px;
}
.form-number input::-webkit-outer-spin-button,
.form-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-number span {
  width: 32px;
  height: 32px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #006ABB;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  cursor: pointer;
}
.form-number span.down { left: 4px; }
.form-number span.up { right: 4px; }

