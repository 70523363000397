/* main */
.l-main {
  height: 100vh;
  padding-bottom: 48px;
  overflow-y: auto;
  position: fixed;
  top: 64px;
  transition: width, left 0.4s ease;
}
.l-main.is-open {
  width: calc(100% - 256px);
  left: 256px;
}
.l-main.is-close {
  width: calc(100% - 56px);
  left: 56px;
}
.l-main__header {
  padding: 32px;
}
.l-main__body {
  padding: 0 32px 32px 32px;
  margin-top: 0;
}
.l-main__body__flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.l-main__body__flex__left {
  width: calc(100% - 252px);
}
.l-main__body__flex__left2 {
  width: calc(100% - 332px);
}
.l-main__body__flex__right {
  width: 220px;
  margin-left: 32px;
}
.l-main__body__flex__right2 {
  width: 300px;
  margin-left: 32px;
}

.sticky {
  position: sticky;
  top: 32px;
}

@media screen and (max-width: 768px) {
  .l-main {
    height: 100vh;
    top: 56px;
  }
  .l-main__body__flex {
    display: block;
  }
  .l-main.is-open,
  .l-main.is-close {
    width: 100%;
    left: 0;
  }
  .l-main__header {
    padding: 32px 16px 24px 16px;
  }
  .l-main__body {
    padding: 0 16px;
  }
  .l-main__body__flex__left {
    width: 100%;
  }
  .l-main__body__flex__right {
    display: none;
  }
  .l-main__body__flex__right2 {
    width: 100%;
    height: 100%;
    bottom: 100%;
    left: 0;
    z-index: 20;
  }
  .l-main__body__flex__right {
    display: none;
  }
}

/* Utility classes from here on */

/* margin */
.u-m-0 {
  margin: 0px;
}
.u-mt-0 {
  margin-top: 0px;
}
.u-mr-0 {
  margin-right: 0px;
}
.u-mb-0 {
  margin-bottom: 0px;
}
.u-ml-0 {
  margin-left: 0px;
}

.u-m-05 {
  margin: 2px;
}
.u-mt-05 {
  margin-top: 2px;
}
.u-mr-05 {
  margin-right: 2px;
}
.u-mb-05 {
  margin-bottom: 2px;
}
.u-ml-05 {
  margin-left: 2px;
}

.u-m-1 {
  margin: 4px;
}
.u-mt-1 {
  margin-top: 4px;
}
.u-mr-1 {
  margin-right: 4px;
}
.u-mb-1 {
  margin-bottom: 4px;
}
.u-ml-1 {
  margin-left: 4px;
}

.u-m-2 {
  margin: 8px;
}
.u-mt-2 {
  margin-top: 8px;
}
.u-mr-2 {
  margin-right: 8px;
}
.u-mb-2 {
  margin-bottom: 8px;
}
.u-ml-2 {
  margin-left: 8px;
}

.u-m-3 {
  margin: 12px;
}
.u-mt-3 {
  margin-top: 12px;
}
.u-mr-3 {
  margin-right: 12px;
}
.u-mb-3 {
  margin-bottom: 12px;
}
.u-ml-3 {
  margin-left: 12px;
}

.u-m-4 {
  margin: 16px;
}
.u-mt-4 {
  margin-top: 16px;
}
.u-mr-4 {
  margin-right: 16px;
}
.u-mb-4 {
  margin-bottom: 16px;
}
.u-ml-4 {
  margin-left: 16px;
}

.u-m-5 {
  margin: 20px;
}
.u-mt-5 {
  margin-top: 20px;
}
.u-mr-5 {
  margin-right: 20px;
}
.u-mb-5 {
  margin-bottom: 20px;
}
.u-ml-5 {
  margin-left: 20px;
}

.u-m-6 {
  margin: 24px;
}
.u-mt-6 {
  margin-top: 24px;
}
.u-mr-6 {
  margin-right: 24px;
}
.u-mb-6 {
  margin-bottom: 24px;
}
.u-ml-6 {
  margin-left: 24px;
}

.u-m-8 {
  margin: 32px;
}
.u-mt-8 {
  margin-top: 32px;
}
.u-mr-8 {
  margin-right: 32px;
}
.u-mb-8 {
  margin-bottom: 32px;
}
.u-ml-8 {
  margin-left: 32px;
}

.u-m-10 {
  margin: 40px;
}
.u-mt-10 {
  margin-top: 40px;
}
.u-mr-10 {
  margin-right: 40px;
}
.u-mb-10 {
  margin-bottom: 40px;
}
.u-ml-10 {
  margin-left: 40px;
}

.u-m-12 {
  margin: 48px;
}
.u-mt-12 {
  margin-top: 48px;
}
.u-mr-12 {
  margin-right: 48px;
}
.u-mb-12 {
  margin-bottom: 48px;
}
.u-ml-12 {
  margin-left: 48px;
}

.u-m-16 {
  margin: 64px;
}
.u-mt-16 {
  margin-top: 64px;
}
.u-mr-16 {
  margin-right: 64px;
}
.u-mb-16 {
  margin-bottom: 64px;
}
.u-ml-16 {
  margin-left: 64px;
}

.u-m-20 {
  margin: 80px;
}
.u-mt-20 {
  margin-top: 80px;
}
.u-mr-20 {
  margin-right: 80px;
}
.u-mb-20 {
  margin-bottom: 80px;
}
.u-ml-20 {
  margin-left: 80px;
}

.u-m-24 {
  margin: 96px;
}
.u-mt-24 {
  margin-top: 96px;
}
.u-mr-24 {
  margin-right: 96px;
}
.u-mb-24 {
  margin-bottom: 96px;
}
.u-ml-24 {
  margin-left: 96px;
}

.u-m-32 {
  margin: 128px;
}
.u-mt-32 {
  margin-top: 128px;
}
.u-mr-32 {
  margin-right: 128px;
}
.u-mb-32 {
  margin-bottom: 128px;
}
.u-ml-32 {
  margin-left: 128px;
}

.u-m-40 {
  margin: 160px;
}
.u-mt-40 {
  margin-top: 160px;
}
.u-mr-40 {
  margin-right: 160px;
}
.u-mb-40 {
  margin-bottom: 160px;
}
.u-ml-40 {
  margin-left: 160px;
}

.u-m-48 {
  margin: 192px;
}
.u-mt-48 {
  margin-top: 192px;
}
.u-mr-48 {
  margin-right: 192px;
}
.u-mb-48 {
  margin-bottom: 192px;
}
.u-ml-48 {
  margin-left: 192px;
}

.u-m-56 {
  margin: 224px;
}
.u-mt-56 {
  margin-top: 224px;
}
.u-mr-56 {
  margin-right: 224px;
}
.u-mb-56 {
  margin-bottom: 224px;
}
.u-ml-56 {
  margin-left: 224px;
}

.u-m-64 {
  margin: 256px;
}
.u-mt-64 {
  margin-top: 256px;
}
.u-mr-64 {
  margin-right: 256px;
}
.u-mb-64 {
  margin-bottom: 256px;
}
.u-ml-64 {
  margin-left: 256px;
}

.u-m-80 {
  margin: 320px;
}
.u-mt-80 {
  margin-top: 320px;
}
.u-mr-80 {
  margin-right: 320px;
}
.u-mb-80 {
  margin-bottom: 320px;
}
.u-ml-80 {
  margin-left: 320px;
}

.u-m-96 {
  margin: 384px;
}
.u-mt-96 {
  margin-top: 384px;
}
.u-mr-96 {
  margin-right: 384px;
}
.u-mb-96 {
  margin-bottom: 384px;
}
.u-ml-96 {
  margin-left: 384px;
}

.u-m-120 {
  margin: 480px;
}
.u-mt-120 {
  margin-top: 480px;
}
.u-mr-120 {
  margin-right: 480px;
}
.u-mb-120 {
  margin-bottom: 480px;
}
.u-ml-120 {
  margin-left: 480px;
}

.u-m-140 {
  margin: 560px;
}
.u-mt-140 {
  margin-top: 560px;
}
.u-mr-140 {
  margin-right: 560px;
}
.u-mb-140 {
  margin-bottom: 560px;
}
.u-ml-140 {
  margin-left: 560px;
}

.u-m-160 {
  margin: 640px;
}
.u-mt-160 {
  margin-top: 640px;
}
.u-mr-160 {
  margin-right: 640px;
}
.u-mb-160 {
  margin-bottom: 640px;
}
.u-ml-160 {
  margin-left: 640px;
}

.u-m-180 {
  margin: 720px;
}
.u-mt-180 {
  margin-top: 720px;
}
.u-mr-180 {
  margin-right: 720px;
}
.u-mb-180 {
  margin-bottom: 720px;
}
.u-ml-180 {
  margin-left: 720px;
}

.u-m-192 {
  margin: 768px;
}
.u-mt-192 {
  margin-top: 768px;
}
.u-mr-192 {
  margin-right: 768px;
}
.u-mb-192 {
  margin-bottom: 768px;
}
.u-ml-192 {
  margin-left: 768px;
}

.u-m-256 {
  margin: 1024px;
}
.u-mt-256 {
  margin-top: 1024px;
}
.u-mr-256 {
  margin-right: 1024px;
}
.u-mb-256 {
  margin-bottom: 1024px;
}
.u-ml-256 {
  margin-left: 1024px;
}

.u-m-320 {
  margin: 1280px;
}
.u-mt-320 {
  margin-top: 1280px;
}
.u-mr-320 {
  margin-right: 1280px;
}
.u-mb-320 {
  margin-bottom: 1280px;
}
.u-ml-320 {
  margin-left: 1280px;
}

.u-m-360 {
  margin: 1440px;
}
.u-mt-360 {
  margin-top: 1440px;
}
.u-mr-360 {
  margin-right: 1440px;
}
.u-mb-360 {
  margin-bottom: 1440px;
}
.u-ml-360 {
  margin-left: 1440px;
}

.u-m-400 {
  margin: 1600px;
}
.u-mt-400 {
  margin-top: 1600px;
}
.u-mr-400 {
  margin-right: 1600px;
}
.u-mb-400 {
  margin-bottom: 1600px;
}
.u-ml-400 {
  margin-left: 1600px;
}

.u-m-480 {
  margin: 1920px;
}
.u-mt-480 {
  margin-top: 1920px;
}
.u-mr-480 {
  margin-right: 1920px;
}
.u-mb-480 {
  margin-bottom: 1920px;
}
.u-ml-480 {
  margin-left: 1920px;
}

.u-p-1 {
  padding: 4px;
}
.u-pt-1 {
  padding-top: 4px;
}
.u-pr-1 {
  padding-right: 4px;
}
.u-pb-1 {
  padding-bottom: 4px;
}
.u-pl-1 {
  padding-left: 4px;
}

.u-p-2 {
  padding: 8px;
}
.u-pt-2 {
  padding-top: 8px;
}
.u-pr-2 {
  padding-right: 8px;
}
.u-pb-2 {
  padding-bottom: 8px;
}
.u-pl-2 {
  padding-left: 8px;
}

.u-p-3 {
  padding: 12px;
}
.u-pt-3 {
  padding-top: 12px;
}
.u-pr-3 {
  padding-right: 12px;
}
.u-pb-3 {
  padding-bottom: 12px;
}
.u-pl-3 {
  padding-left: 12px;
}

.u-p-4 {
  padding: 16px;
}
.u-pt-4 {
  padding-top: 16px;
}
.u-pr-4 {
  padding-right: 16px;
}
.u-pb-4 {
  padding-bottom: 16px;
}
.u-pl-4 {
  padding-left: 16px;
}

/*-- width --*/
.u-width-32 {
  width: 32px;
}
.u-width-40 {
  width: 40px;
}
.u-width-48 {
  width: 48px;
}
.u-width-56 {
  width: 56px;
}
.u-width-64 {
  width: 64px;
}
.u-width-80 {
  width: 80px;
}
.u-width-88 {
  width: 88px;
}
.u-width-96 {
  width: 96px;
}
.u-width-104 {
  width: 104px;
}
.u-width-112 {
  width: 112px;
}
.u-width-128 {
  width: 128px;
}
.u-width-144 {
  width: 144px;
}
.u-width-160 {
  width: 160px;
}
.u-width-176 {
  width: 176px;
}
.u-width-192 {
  width: 192px;
}
.u-width-208 {
  width: 208px;
}
.u-width-224 {
  width: 224px;
}
.u-width-240 {
  width: 240px;
}
.u-width-256 {
  width: 256px;
}
.u-width-272 {
  width: 272px;
}
.u-width-288 {
  width: 288px;
}
.u-width-296 {
  width: 296px;
}
.u-width-304 {
  width: 304px;
}
.u-width-320 {
  width: 320px;
}
.u-width-336 {
  width: 336px;
}
.u-width-352 {
  width: 352px;
}
.u-width-368 {
  width: 368px;
}
.u-width-384 {
  width: 384px;
}
.u-width-400 {
  width: 400px;
}
.u-width-416 {
  width: 416px;
}
.u-width-432 {
  width: 432px;
}
.u-width-464 {
  width: 464px;
}
.u-width-480 {
  width: 480px;
}
.u-width-512 {
  width: 512px;
}
.u-width-544 {
  width: 544px;
}
.u-width-576 {
  width: 576px;
}
.u-width-608 {
  width: 608px;
}
.u-width-640 {
  width: 640px;
}

.u-info-msg {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-normal);
  margin-top: 4px;
}

.u-error-msg {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-normal);
  color: var(--error500);
  margin-top: 4px;
}

.u-pcHidden {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .u-pcHidden {
    display: block !important;
  }
  .u-spHidden {
    display: none !important;
  }

  .u-width-100--sp {
    width: 100% !important;
  }
  .u-m-1--sp {
    margin: 4px !important;
  }
  .u-mt-1--sp {
    margin-top: 4px !important;
  }
  .u-mr-1--sp {
    margin-right: 4px !important;
  }
  .u-mb-1--sp {
    margin-bottom: 4px !important;
  }
  .u-ml-1--sp {
    margin-left: 4px !important;
  }

  .u-m-2--sp {
    margin: 8px !important;
  }
  .u-mt-2--sp {
    margin-top: 8px !important;
  }
  .u-mr-2--sp {
    margin-right: 8px !important;
  }
  .u-mb-2--sp {
    margin-bottom: 8px !important;
  }
  .u-ml-2--sp {
    margin-left: 8px !important;
  }

  .u-m-3--sp {
    margin: 12px !important;
  }
  .u-mt-3--sp {
    margin-top: 12px !important;
  }
  .u-mr-3--sp {
    margin-right: 12px !important;
  }
  .u-mb-3--sp {
    margin-bottom: 12px !important;
  }
  .u-ml-3--sp {
    margin-left: 12px !important;
  }

  .u-m-4--sp {
    margin: 16px !important;
  }
  .u-mt-4--sp {
    margin-top: 16px !important;
  }
  .u-mr-4--sp {
    margin-right: 16px !important;
  }
  .u-mb-4--sp {
    margin-bottom: 16px !important;
  }
  .u-ml-4--sp {
    margin-left: 16px !important;
  }
}

/*--------------------------------*/
/*-- old base css --*/
/*--------------------------------*/

/*-- override semantic-ui-react --*/
p.semantic-ui-react-override {
  margin: 0px 0px 0px 0px;
}

/* temporary solution */
p.semantic-ui-react-override:first-child {
  margin: 0 10px 0 0;
}

.base-link {
  color: #008ec9;
}
