.p-manifests__customize {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  margin-bottom: 64px;
}
.p-manifests__customize__header {
  border-bottom: 1px solid var(--gray200);
  padding: 8px 24px 0 24px;
}
.p-manifests__customize__header__tab {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-medium);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-manifests__customize__header__tab li {
  color: var(--gray500);
  padding: 12px 4px;
  margin-right: 20px;
  cursor: pointer;
}
.p-manifests__customize__header__tab li.is-active {
  color: var(--primary700);
  border-bottom: 2px solid var(--primary700);
}
.p-manifests__customize__body {
  padding: 24px;
}
.p-manifests__customize__body .color-gray {
  color: var(--gray400);
}
.p-manifests__customize__body__content {
  display: none;
}
.p-manifests__customize__body__content.is-active {
  display: block;
}
.p-manifests__customize__body__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}
.p-manifests__customize__body__header__ttl {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-semibold);
}
.p-manifests__customize__body__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-manifests__customize__body__header__actions > * {
  margin-left: 12px;
}
.p-manifests__customize__body__box {
}
.p-manifests__customize__body__box:not(:first-child) {
  margin-top: 24px;
}
.p-manifests__customize__body__box__ttl {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  font-weight: var(--text-semibold);
  /* Override semantic */
  margin-bottom: 12px !important;
}
.p-manifests__customize__form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-manifests__customize__form:not(:first-child) {
  margin-top: 16px;
}
.p-manifests__customize__formTtl {
  width: 80px;
  color: var(--gray500);
  flex-shrink: 0;
  margin-right: 8px;
}
.p-manifests__customize__form .c-textarea {
  min-height: 172px;
}

.p-manifests__customize__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-manifests__customize__flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-manifests__customize__flex .c-textarea {
  min-height: 96px;
}

.p-manifests__customize__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.p-manifests__customize__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

.p-manifests__customize__sort {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-manifests__customize__sort i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-manifests__customize__sort i::before,
.p-manifests__customize__sort i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}

.p-manifestsModalCustomize {
}
.p-manifestsModalCustomize__item {
}
.p-manifestsModalCustomize__item:not(:first-child) {
  margin-top: 16px;
}
.p-manifestsModalCustomize__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 6px !important;
  display: flex;
  align-items: center;
}
.p-manifestsModalCustomize__item__ttl span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
  display: block;
}
.p-manifestsModalCustomize__item__body {
}
.p-manifestsModalCustomize__item__body__flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.p-manifestsModalCustomize__item__body__flex > *:not(:first-child) {
  margin-left: 8px;
}

.p-manifestsModalCustomize__dropdown {
}
.p-manifestsModalCustomize__dropdown li {
  max-width: calc(100% - 28px);
}
.p-manifestsModalCustomize__dropdown li p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.p-manifestsModalCustomize__dropdown__sort {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 4px;
  cursor: all-scroll;
}
.p-manifestsModalCustomize__dropdown__sort i {
  width: 10px !important;
  height: 3px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-manifestsModalCustomize__dropdown__sort i::before,
.p-manifestsModalCustomize__dropdown__sort i::after {
  content: '' !important;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}

/* p-sortList */
.p-sortList {
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--gray50);
  padding: 16px;
  position: relative;
}
.p-sortList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item:not(:first-child) {
  margin-top: 12px;
}
.p-sortList__item.is-disabled {
  color: var(--gray400);
  background-color: var(--gray50);
  pointer-events: none;
}
.p-sortList__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item__left p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-sortList__item__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-sortList__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-sortList__item__ic i::before,
.p-sortList__item__ic i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-sortList__item__right {
  margin-left: auto;
}
.p-sortList__item__right > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}

.p-manifests__fixed {
  width: 100%;
  min-height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
    0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: all 0.2s linear;
}
.p-manifests__fixed.is-active {
  bottom: 0;
  opacity: 1;
}
.p-manifests__fixed__main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
}
.p-manifests__fixed__main__ttl {
  margin-left: 8px;
}
.p-manifests__fixed__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
}
.p-manifests__fixed__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-manifests__fixed__actions__select {
  position: relative;
}
.p-manifests__fixed__actions__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  bottom: 40px;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-manifests__fixed__actions__select__menu.is-active {
  display: block;
}
.p-manifests__fixed__actions__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-manifests__fixed__actions__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-manifests__fixed__actions__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.p-manifests__fixed__actions .spacer {
  display: none;
}
.p-manifests__bottomSpace {
  height: 120px;
}

@media screen and (max-width: 768px) {
  .p-manifests__customize {
    margin-bottom: 0;
  }
  .p-manifests__fixed {
    width: 100% !important;
    padding: 20px 16px;
  }
  .p-manifests__fixed__main {
    display: none;
  }
  .p-manifests__fixed__actions {
    width: 100%;
    margin-bottom: -20px;
  }
  /* .p-manifests__fixed__actions > *:first-child {
    margin-right: auto;
  } */
  .p-manifests__fixed__actions .spacer {
    width: 100%;
    display: block;
  }
  .p-manifests__fixed__actions > *:not(.spacer) {
    margin-bottom: 20px;
  }
}

.p-manifests__filterRequiredField {
}
.p-manifests__filterRequiredField > *:not(:first-child) {
  margin-top: 16px;
}
.p-manifests__filterRequiredField__msg {
  margin-bottom: 0 !important;
}

.p-frame {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
}
.p-frame:not(:first-child) {
  margin-top: 16px;
}
.p-frame__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-frame__header__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
}
.p-frame__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-frame__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-frame__body {
  border-top: 1px solid var(--gray200);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  padding: 20px;
  /* TODO: for now set as block
  display: none; */
  display: block;
}
.p-frame__body.gray {
  background-color: var(--gray50);
}
.p-frame__body.is-active {
  display: block;
}
.p-frame__body__note {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
}
.p-frame__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-frame__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.p-manifests__productListInput {
}
.p-manifests__productListInput > *:not(:first-child) {
  margin-top: 8px;
}
.p-manifests__vehicleResourceColumn {
}
.p-manifests__vehicleResourceColumn > *:not(:first-child) {
  margin-top: 8px;
}

.p-manifests__scheduleInput {
}
.p-manifests__scheduleInput__textArea > div {
}
@media screen and (max-width: 768px) {
  .p-manifests__scheduleInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .p-manifests__scheduleInput > div {
    width: 100%;
    text-align: left;
  }
  .p-manifests__scheduleInput > div > p {
    margin-right: 4px;
  }
  .p-manifests__scheduleInput__textArea > div {
    margin-right: 0 !important;
  }
}

.p-manifests__scheduleDisplay {
}
.p-manifests__scheduleDisplay > *:not(:first-child) {
  margin-top: 4px;
}
.p-manifests__scheduleDisplay__excluded {
  display: flex;
  flex-direction: row;
}
.p-manifests__scheduleDisplay__excluded > p:first-child {
  color: var(--gray400);
}
.p-manifests__scheduleDisplay__excluded > p:last-child {
  margin-left: 4px;
}
@media screen and (max-width: 768px) {
  .p-manifests__scheduleDisplay__excluded {
    flex-direction: column;
  }
}
