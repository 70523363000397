.snackbar-container {
  min-width: 300px;
  max-width: 350px;
  border-radius: 2px;
  position: absolute;
  z-index: 11;
  top: 50px;
  margin-left: -16px;
}

.snackbar {
  background: #fff;
  border: 1px solid #cfdae1;
  border-radius: 2px;
  padding: 5px 10px 5px 10px;
  position: relative;
  font-size: 12px;
}

.clear-btn {
  font-weight: bold;
  color: #008ec9;
}
.clear-btn:hover {
  color: #006d9c;
}

.snackbar.fade-out {
  animation: fade-out 10s linear forwards;
}

@keyframes fade-out {
  95% {
    opacity: 1;
    transform: translateY(0px);
  }
  99% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
  }
}

.snackbar-header {
  font-size: 14px;
  font-weight: bold;
}

.snackbar-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.snackbar-close {
  width: 15px;
  height: 15px;
  margin-left: auto;
  position: relative;
}
.snackbar-close:hover {
  opacity: 0.8;
}
.snackbar-close::before,
.snackbar-close::after {
  content: '';
  display: block;
  width: 16px;
  height: 4px;
  border-radius: 4px;
  background: #9bacb5;
  position: absolute;
  top: 4px;
}
.snackbar-close::before {
  transform: rotate(45deg);
}
.snackbar-close::after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .snackbar-container {
    width: 100%;
    max-width: 100%;
    left: 0;
    margin-left: 0;
    top: 56px;
  }
}
