.params-container {
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.dimension-container {
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--gray200);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.dimension-option-category {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: #a1a1aa;
}
.between-inputs-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
