.p-contactCard__body__reply__detail {
  width: 100%;
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-xs);
  padding: 16px;
  margin-top: 20px;
}
.p-contactCard__body__reply__detail__item {
  width: 100%;
}
.p-contactCard__body__reply__detail__item:not(:first-child) {
  margin-top: 16px;
}
.p-contactCard__body__reply__detail__item__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px !important;
}
.p-contactCard__body__reply__detail__item__note {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-top: 6px;
}
.p-contactCard__body__reply__detail__item__add {
  width: fit-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-contactCard__body__reply__detail__item__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
fieldset + .p-contactCard__body__reply__detail__item__add {
  margin-top: 12px;
}
.p-contactCard__body__reply__detail__item__photo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: -16px;
}
.p-contactCard__body__reply__detail__item__photo li {
  width: 128px;
  height: 128px;
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
}
.p-contactCard__body__reply__detail__item__photo li img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.p-contactCard__body__reply__detail__item__photo li a {
  width: 24px;
  height: 24px;
  background-color: rgba(0,0,0,0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
}
.p-contactCard__body__reply__detail__item__photo li a i {
  font-size: 16px;
  color: var(--white);
}
.p-contactCard__body__reply__detail__item__photo li.add {
  border: 1px dashed var(--primary700);
  color: var(--primary700);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.p-contactCard__body__reply__detail__item__photo li.add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.p-contactCard__body__reply__detail__item__photo li.add p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 10px;
}
.p-contactCard__body__reply__detail__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.p-contactCard__body__reply__detail__actions > *:not(:first-child) {
  margin-left: 12px;
}
@media screen and (max-width: 768px) {
  .p-contactCard__body__reply__detail__item__photo li {
    width: 100px;
    height: 100px;
  }
}
