.see-more-buttons {
  display: flex;
}
.see-more-buttons a {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--primary700);
  text-decoration: underline;
  margin-top: 8px;
  display: inline-block;
}
.see-more-buttons a:hover {
  color: var(--primary600);
  text-decoration: underline;
}
.see-more-body {
  line-height: 20px;
  white-space: pre-line;
}
.see-more-body.is-close {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.see-more-body.is-open {
}
