/**
 * This code was generated by Builder.io.
 */
.feedbackModule {
  border-radius: 50px;
  background-color: #fdfdff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.feedbackHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.feedbackTitle {
  font: 600 28px/1 Poppins, sans-serif;
  color: #1e202f;
  margin-bottom: 8px;
}

.feedbackDescription {
  font: 400 16px Inter, sans-serif;
  color: #b5b8bc;
}

.navigationTabs {
  display: flex;
  margin-bottom: 20px;
}

.tabButton {
  padding: 8px 16px;
  font: 600 14px Inter, sans-serif;
  color: #b5b8bc;
  background: none;
  border: none;
  cursor: pointer;
}

.activeTab {
  color: #3e8cc4;
  border-bottom: 2px solid #3e8cc4;
}

.searchFilterContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchInputWrapper {
  position: relative;
  flex-grow: 1;
  margin-right: 10px;
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #dadde2;
  border-radius: 6px;
  font: 400 14px Inter, sans-serif;
}

.searchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.filterButton {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #707282;
  color: #fff;
  border: none;
  border-radius: 4px;
  font: 500 14px Inter, sans-serif;
  cursor: pointer;
}

.filterIcon {
  margin-right: 5px;
}

.averageRatingSection {
  margin-bottom: 20px;
}

.averageRatingTitle {
  font: 600 18px Inter, sans-serif;
  color: #1e202f;
  margin-bottom: 10px;
}

.ratingWrapper {
  display: flex;
  align-items: center;
}

.ratingValue {
  font: 600 16px Inter, sans-serif;
  color: #393d3f;
  margin-right: 5px;
}

.ratingCount {
  font: 400 16px Inter, sans-serif;
  color: #b5b8bc;
  margin-right: 10px;
}

.starRating {
  display: flex;
}

.starIcon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.ratingDistributionCard {
  background-color: #fff;
  border: 1px solid #f3f6f8;
  border-radius: 12px;
  padding: 16px;
}

.distributionRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.starLabel {
  width: 60px;
  font: 700 12px Inter, sans-serif;
  color: #393d3f;
}

.distributionBarWrapper {
  flex-grow: 1;
  background-color: #dadde2;
  height: 12px;
  border-radius: 6px;
  overflow: hidden;
}

.distributionBar {
  height: 100%;
  background-color: #3e8cc4;
}

.feedbackCard {
  background-color: #fff;
  border: 1px solid #ebeff3;
  border-radius: 5px;
  padding: 24px;
  margin-bottom: 20px;
}

.feedbackHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.orderId {
  font: 500 16px Inter, sans-serif;
  color: #3e8cc4;
  margin-right: 8px;
}

.restaurant,
.dish {
  font: 600 16px Inter, sans-serif;
  color: #393d3f;
  margin-right: 8px;
}

.toggleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 27px;
  margin-left: 10px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b5b8bc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  width: 20px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
}

input:checked + .slider {
  background-color: #3e8cc4;
}

input:checked + .slider:before {
  transform: translateX(17px);
}

.feedbackComment {
  font: 400 16px/24px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  color: #393d3f;
  margin-bottom: 16px;
}

.imageGallery {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.feedbackImage {
  width: 92px;
  height: 98px;
  object-fit: cover;
  border-radius: 8px;
}

.authorInfo {
  display: flex;
  align-items: center;
}

.authorAvatar {
  width: 54px;
  height: 53px;
  border-radius: 50%;
  margin-right: 16px;
}

.authorName {
  font: 600 16px Inter, sans-serif;
  color: #393d3f;
  display: block;
}

.feedbackTime {
  font: 400 14px Inter, sans-serif;
  color: #b5b8bc;
}

.feedbackContent {
  display: flex;
  gap: 28px;
}

.feedbackStats {
  flex: 1;
}

.feedbackList {
  flex: 2;
}

@media (max-width: 991px) {
  .feedbackContent {
    flex-direction: column;
  }

  .feedbackStats,
  .feedbackList {
    width: 100%;
  }
}
