.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tab li {
  width: 100%;
  min-width: fit-content;
  min-height: 40px;
  color: var(--gray400);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  text-align: center;
  padding: 10px 16px;
  flex-shrink: 1;
  cursor: pointer;
}
.tab li:not(:last-child) {
  border-right: 1px solid var(--gray200);
}
.tab li.is-active {
  color: var(--primary700);
}
.buttons {
  margin-left: auto;
}
.region-cell {
  white-space: pre-line;
}
