.template-card-row {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;    
    border: 1px solid #e0e0e0;
    margin-top: -16px;
    background: white;
    overflow-x: auto;
}
.template-card-container {
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
}
.buttons-row {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .buttons-row {
        margin-right: 8px;
        flex-wrap: wrap;
    }
    .template-card-row {
        flex-wrap: nowrap;
    }
}
