.title-container {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 700;
  margin: auto 0;
}
.name {
  color: var(--Colors-Gray-900, #18181b);
  flex-grow: 1;
  flex-basis: auto;
  font: 24px/133% Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
