.page-home-tutorial {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 32px;
}
.page-home-tutorial__ttl {
  width: 280px;
  height: 40px;
  border: 1px solid #008ec9;
  font-size: 20px;
  font-weight: bold;
  display: table;
  margin: 0 auto 32px auto;
}
.page-home-tutorial__ttl p:first-child {
  width: 200px;
  color: #fff;
  background: #008ec9;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.page-home-tutorial__ttl p:last-child {
  color: #008ec9;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.page-home-tutorial__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.page-home-tutorial__list li {
  width: calc((100% - 64px) / 3);
  position: relative;
}
.page-home-tutorial__list li:not(:last-child)::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent #cfdae1;
  position: absolute;
  top: calc(50% - 8px);
  right: -24px;
}
.page-home-tutorial__finish {
  border-radius: 8px;
  background: #fff;
  padding: 16px;
  margin-bottom: 32px;
}
.page-home-tutorial__finish__body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.page-home-tutorial__finish__body__pic {
  margin-right: 32px;
  flex-shrink: 0;
}
.page-home-tutorial__finish__body__pic img {
  height: 128px;
}
.page-home-tutorial__finish__body__main {
}
.page-home-tutorial__finish__body__main .page-home-tutorial__ttl {
  margin: 0;
}
.page-home-tutorial__finish__body__main__txt {
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .page-home-tutorial {
    padding: 16px;
    margin-top: 16px;
    border: none;
  }
  .page-home-tutorial__ttl {
    margin-bottom: 16px;
  }
  .page-home-tutorial__list li {
    width: 100%;
  }
  .page-home-tutorial__list li:not(:last-child) {
    margin-bottom: 32px;
  }
  .page-home-tutorial__list li:not(:last-child)::after {
    top: auto;
    right: calc(50% - 8px);
    bottom: -32px;
    transform: rotate(90deg);
  }
  .page-home-tutorial__finish {
    border-radius: 0;
    margin-top: 16px;
  }
  .page-home-tutorial__finish__body {
    display: block;
  }
  .page-home-tutorial__finish__body__pic {
    text-align: center;
    margin-right: 0;
    margin-bottom: 16px;
  }
  .page-home-tutorial__finish__body__main .page-home-tutorial__ttl {
    margin: 0 auto;
  }
  .page-home-tutorial__finish__body__main__txt {
    font-size: 16px;
    text-align: center;
    max-width: 240px;
    margin: 16px auto 0 auto;
  }
}
/*------------------------------------------------------------------------------
  tutorial
  ------------------------------------------------------------------------------*/
.page-tutorial__nav {
  width: calc(100% - 24px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-tutorial__nav li {
  width: calc(100% / 2);
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  padding-left: 32px;
  font-weight: bold;
  color: #4a6c7a;
  position: relative;
}
.page-tutorial__nav li::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 0 24px 24px;
  position: absolute;
  top: 0;
  right: -24px;
}
.page-tutorial__nav li:nth-child(odd) {
  background: #fff;
}
.page-tutorial__nav li:nth-child(even) {
  background: #f9f9f9;
}
.page-tutorial__nav li:nth-child(odd)::after {
  border-color: transparent transparent transparent #fff;
}
.page-tutorial__nav li:nth-child(even)::after {
  border-color: transparent transparent transparent #f9f9f9;
}
.page-tutorial__nav li:nth-child(1) {
  z-index: 5;
}
.page-tutorial__nav li:nth-child(2) {
  z-index: 4;
}
.page-tutorial__nav li:nth-child(3) {
  z-index: 3;
}
.page-tutorial__nav li:nth-child(4) {
  z-index: 2;
}
.page-tutorial__nav li:nth-child(5) {
  z-index: 1;
}
.page-tutorial__nav li.is-active {
  color: #fff;
  background: #008ec9;
}
.page-tutorial__nav li.is-active::after {
  border-color: transparent transparent transparent #008ec9;
}
/*------------------------------------------------*/
.page-tutorial__box {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #f9f9f9;
  padding: 40px 0;
  position: relative;
}
.page-tutorial__box__left {
  background: #fff;
  padding: 16px;
  position: relative;
}
.page-tutorial__box__left {
}
.page-tutorial__box__left__next {
  width: 240px;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  background: #ffaaaa;
  margin-bottom: 8px;
}
.page-tutorial__box__left__desc {
  font-size: 20px;
  font-weight: bold;
  color: #008ec9;
}
.page-tutorial__box__right {
}
.page-tutorial__box::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 64px 0 0;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.page-tutorial__box::after {
  content: attr(data-num) '';
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.page-tutorial__box__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__box__pic {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background: #ebf1f3;
  margin-right: 16px;
  flex-shrink: 0;
}
.page-tutorial__box__pic img {
  width: inherit;
  height: inherit;
}
.page-tutorial__box__body {
}
.page-tutorial__box__body__ttl {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.page-tutorial__box__body__btn a {
  background: #cfdae1 !important;
  pointer-events: none;
}
.page-tutorial__box__body__next {
  width: 64px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  background: #ffaaaa;
  margin-top: 10px;
  position: relative;
  opacity: 0;
}
.page-tutorial__box__body__next::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #ffaaaa transparent;
  position: absolute;
  top: -6px;
  left: calc(50% - 6px);
}
.page-tutorial__box.is-active {
  border: 1px solid #008ec9;
}
.page-tutorial__box.is-active a {
  background: #ffcc00 !important;
  pointer-events: auto;
}
.page-tutorial__box.is-active.new-tutorial a {
  background: #008ec9 !important;
  pointer-events: auto;
}
.page-tutorial__box.is-active .page-tutorial__box__body__next {
  opacity: 1;
}
.page-tutorial__box.is-finish {
  opacity: 0.5;
}

.page-tutorial__box--step2 {
  border: 4px solid #008ec9 !important;
  padding: 40px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__box--step2 .page-tutorial__box__left {
  width: 50%;
  max-width: 560px;
  margin-right: 64px;
}
.page-tutorial__box--step2 .page-tutorial__box__left::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent #008ec9;
  position: absolute;
  top: calc(50% - 16px);
  right: -40px;
}
.page-tutorial__box--step2 .page-tutorial__box__right {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.page-tutorial__box--step3 {
  border: 4px solid #008ec9 !important;
  padding: 16px;
}
.page-new__tutorial__box--step1 {
  border: 4px solid #008ec9 !important;
  padding: 16px;
}
.page-new__tutorial__box--step1 .page-tutorial__box__left {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page-tutorial__box--step3 .page-tutorial__box__left {
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page-tutorial__box--step3 .page-tutorial__box__left::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 16px;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  bottom: -24px;
  left: calc(50% - 16px);
}
.page-tutorial__box--step3 .page-tutorial__box__right {
}
/*------------------------------------------------*/
.page-tutorial__popupFrame {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
}
.page-tutorial__popup {
  width: calc(100% - 32px);
  max-width: 480px;
  border-radius: 4px;
  background: #fff;
  padding: 16px;
  position: absolute;
}
.page-tutorial__popup::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 16px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -16px;
  left: 32px;
}
.page-tutorial__popup.large {
  max-width: 640px;
}
.page-tutorial__popup > * + * {
  margin-top: 16px;
}
.page-tutorial__popup__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-tutorial__popup__ttl img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  flex-shrink: 0;
}
.page-tutorial__popup__ttl p {
  font-size: 18px;
  font-weight: bold;
}
.page-tutorial__popup__desc {
}
.page-tutorial__popup__btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__popupFinish {
  width: calc(100% - 32px);
  max-width: 640px;
  border-radius: 8px;
  background: #fff;
  margin: 80px auto 0 auto;
}
.page-tutorial__popupFinish__header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #cfdae1;
  padding: 16px;
}
.page-tutorial__popupFinish__body {
  padding: 16px;
}
.page-tutorial__popupFinish__body__pic {
  text-align: center;
}
.page-tutorial__popupFinish__body__pic img {
  height: 128px;
}
.page-tutorial__popupFinish__body__desc {
  font-size: 16px;
  text-align: center;
  padding: 16px 0;
}
.page-new-tutorial__popupFinish__body {
  padding: 16px;
}
.page-new-tutorial__popupFinish__body p {
  margin: 0 0 5px 0;
  font-size: 15px;
}
/*------------------------------------------------*/
@media screen and (max-width: 768px) {
  .page-tutorial__nav {
    width: calc(100% - 8px);
    margin-top: 16px;
  }
  .page-tutorial__nav li {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding-left: 16px;
  }
  .page-tutorial__nav li::after {
    border-width: 16px 0 16px 8px;
    right: -8px;
  }
  .page-tutorial__nav li span {
    display: none;
  }
  .page-tutorial__box__left__desc {
    font-size: 16px;
    text-align: center;
  }
  .page-tutorial__box--step2 {
    width: calc(100% - 16px);
    margin: 16px auto;
    padding: 16px;
    flex-direction: column;
  }
  .page-tutorial__box--step2 .page-tutorial__box__left {
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .page-tutorial__box--step2 .page-tutorial__box__left::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #008ec9 transparent transparent transparent;
    position: absolute;
    top: auto;
    bottom: -24px;
    left: calc(50% - 16px);
  }
  .page-tutorial__box--step2 .page-tutorial__box__right {
    width: 100%;
  }
  .page-tutorial__popupFinish {
    margin-top: 16px;
  }
  .page-tutorial__popupFinish__body {
    padding: 16px 8px;
  }
}
