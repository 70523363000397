/**
 * This code was generated by Builder.io.
 */
.promotionStats {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.statCard {
  flex: 1;
  background-color: #fff;
  border: 1px solid #f3f6f8;
  border-radius: 12px;
  overflow: hidden;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #f3f6f8;
}

.cardTitle {
  font-size: 18px;
  font-weight: 600;
  color: #393d3f;
  margin: 0;
}

.cardIcon {
  width: 20px;
  height: 20px;
}

.cardContent {
  padding: 24px;
  display: flex;
  align-items: baseline;
}

.statValue {
  font-size: 24px;
  font-weight: 600;
  color: #393d3f;
}

.currency {
  font-size: 20px;
  color: #393d3f;
  margin-left: 4px;
}

@media (max-width: 991px) {
  .promotionStats {
    flex-direction: column;
  }
}
