/**
 * This code was generated by Builder.io.
 */
.menuContent {
  flex-grow: 1;
  padding: 30px;
}

.contentHeader {
  margin-bottom: 20px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: #1e202f;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  color: #1e202f;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.restaurantSelector {
  display: flex;
  gap: 8px;
}

.restaurantButton,
.addRestaurantButton,
.addMenuButton {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.restaurantButton {
  background-color: #fff;
  color: #b5b8bc;
  border: 1px solid #3e8cc4;
}

.restaurantButton.active {
  background-color: #f1f9ff;
  color: #3e8cc4;
}

.addRestaurantButton,
.addMenuButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #3e8cc4;
  color: #fff;
  border: none;
}

.addIcon {
  width: 13px;
  height: 13px;
}

.menuTable {
  width: 100%;
  border-collapse: collapse;
}

.menuTable th,
.menuTable td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ebeff3;
}

.menuTable th {
  font-size: 14px;
  font-weight: 600;
  color: #b5b8bc;
  text-transform: capitalize;
}
.menuThumbnail {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: cover;
}

.viewDetailsButton,
.previewButton {
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.viewDetailsButton {
  background-color: #f3f6f8;
  color: #262b2d;
  border: none;
}

.previewButton {
  background-color: #fff;
  color: #262b2d;
  border: 1px solid #e6ebff;
}

.statusIndicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
}

.statusIndicator.active {
  background-color: #3e8cc4;
}

.statusIndicator.inactive {
  background-color: #b5b8bc;
}

.activeMenu {
  background-color: #f1f9ff;
}

.inactiveMenu {
  background-color: #fff;
}

.p-availability__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.p-availability__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.red {
  color: #dc2626;
}

@media (max-width: 991px) {
  .menuContent {
    padding: 20px;
  }

  .actions {
    flex-wrap: wrap;
    gap: 10px;
  }

  .menuTable {
    font-size: 14px;
  }
}
