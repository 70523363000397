.c-inputcontent-sm > span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-left: 24px;
  display: block;
}
.c-inputcontent-md > span {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 28px;
  display: block;
}

/* Base properties */
.c-radio {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-radio > i {
  background-color: var(--white);
  border: 1px solid var(--gray300);
  border-radius: var(--radius-4xl);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.c-radio > i::before {
  content: "";
  border-radius: var(--radius-4xl);
}
.c-radio > input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.c-radio > p {
  margin-left: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* radio checked */
.c-radio > input:checked + i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
.c-radio > input:checked + i::before {
  background-color: var(--primary600);

}
/* radio hover */
.c-radio:hover > i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
  cursor: pointer;
}
.c-radio:hover > input:checked + i::before {
  color: var(--primary600);
}
.c-radio input:disabled:hover,
.c-radio input:disabled + i:hover {
  cursor: default;
}
/* radio active */
.c-radio:active > i {
  background-color: var(--white);
  border: 1px solid var(--primary300);
  box-shadow: var(--ring-primary-shadow-xs);
}
/* radio disabled */
.c-radio input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
}
.c-radio:active input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
  box-shadow: var(--ring-primary-shadow-none);
}
.c-radio input:disabled + i::before {
  content: "";
}

.c-radio .is-disabled {
  color: var(--gray400);
}

/* Size */
.sm > i {
  width: 16px;
  height: 16px;
}
.sm > i::before {
  width: 6px;
  height: 6px;
}
.sm > p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

.md > i {
  width: 20px;
  height: 20px;
}
.md > i::before {
  width: 8px;
  height: 8px;
}
.md > p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}
