.detailInfoEdit {
}
.detailInfoEdit + .detailInfoEdit {
  margin-top: 8px;
}
.detailInfoEditBody {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.detailInfoEditBody > * {
  display: table-cell;
  vertical-align: top;
}
.detailInfoEditTtl > *:nth-child(1),
.detailInfoEditBody > *:nth-child(1) {
  padding-right: 8px;
}
.detailInfoEditBody + .detailInfoEditBody {
  margin-top: 8px;
}
.detailInfoEditBody li {
}
