.map-container {
  width: 400px;
  height: 400px;
  min-width: 400px;
}
@media screen and (max-width: 768px) {
  .map-container {
    width: 100%;
    min-width: auto;
    height: 400px;
    margin-bottom: 16px;
  }
}
