/* Base properties */
.c-dropdownWrapper {
  width: 100%;
}
.c-dropdown__label {
  margin-bottom: 8px;
}
.c-dropdown {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
  padding: 0;
  margin: 0;
}
.c-dropdown:hover {
  cursor: pointer;
}
.c-dropdown__body {
  width: 100%;
  height: 100%;
  min-height: 44px;
  min-width: 50px;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-dropdown__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: '\b450';
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.c-dropdown__body__noIcon::before {
  display: none;
}
.c-dropdown__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-dropdown__body > i::before {
  line-height: 20px;
}
.c-dropdown__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-dropdown__body__inputFullWidth label,
.c-dropdown__body__inputFullWidth input {
  width: 100%;
}
.c-dropdown__body input {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.c-dropdown__body label {
  width: 100%;
  position: relative;
  min-height: 24px; /* Need this for label positioning */
}
.c-dropdown__body label:hover {
  cursor: pointer;
}
.c-dropdown__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}

/* Selected option styling */
.c-dropdown__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: calc(100% - 40px);
}
.c-dropdown__body__selected ul {
  display: flex;
  max-width:800px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -4px;
}
.c-dropdown__body__selected > ul > li {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  background-color: var(--gray100);
  border-radius: var(--radius-sm);
  padding: 3px 4px 3px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.c-dropdown__body__selected > ul > li > i {
  width: 20px;
  margin-left: auto;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.c-dropdown__body__selected > ul > li > i::before {
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  color: var(--gray400);
  content: '\e9c8';
}
.c-dropdown__body__selected > div {
  min-height: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

/* Input */

/* Use this to hide/show the input in dropdown */
.c-dropdown__body__selected__input {
  max-width: 0;
  max-height: 0;
}
.c-dropdown__body__selected__input__canSearch {
  width: calc(100% - 40px);
  height: 24px;
}

/* Menu */
.c-dropdown__menu {
  width: 100%;
  max-height: 300px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
  overflow-y: auto;
}
.c-dropdown__menu__portal {
  width: 100%;
  max-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}
.c-dropdown__menu.is-active {
  display: block;
}
.c-dropdown__menu__item {
  padding: 8px 10px;
  position: relative;
}
.c-dropdown__menu__item:hover {
  background-color: var(--gray50);
  cursor: pointer;
}
.c-dropdown__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.c-dropdown__menu__item i {
  opacity: 0;
}
.c-dropdown__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.c-dropdown__menu__item input:checked + i::before {
  color: var(--primary600);
  content: '\e914';
}
.c-dropdown__menu__item input:not(:checked) + i {
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.c-dropdown__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}
.c-dropdown__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}

/* Various styling */
.c-dropdown.is-hover {
  border-color: var(--primary200);
}
.c-dropdown.is-active {
  border-color: var(--primary700);
}
.c-dropdown.is-input .c-dropdown__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-dropdown.is-active .c-dropdown__body__title {
  color: var(--primary700);
}

.c-dropdown__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

/* Error and disabled state */
.is-error {
  color: var(--error500);
  border-color: var(--error300);
  background-color: var(--error50);
}
.is-disabled {
  color: var(--gray300);
  pointer-events: none;
}
.is-disabled *::before {
  color: var(--gray300);
}

.top {
  top: auto;
  bottom: 100%;
  margin-bottom: 4px;
}

@media screen and (max-width: 768px) {
  .c-dropdown__menu__portal {
    max-width: 200px;
    /* Override the current v3 c-modal z-index -> 1000 */
    z-index: 1010;
  }
}

.p-topPage-acBox__header__ttl__ic {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-topPage-acBox__header__ttl__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-topPage-acBox__header__ttl__ic i::before,
.p-topPage-acBox__header__ttl__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}

.p-topPage__dropdown__title {
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
}
