.p-seat__equipment-schedule-list__rule-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.p-seat__equipment-schedule-list__rule-list-item__content {
  width: 100%;
}

.p-seat__equipment-schedule-list__rule-list-item__content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
