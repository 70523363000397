.page-productsTable__header th,
.page-productsTable__header td {
  color: #fff !important;
  background: #4a6c7a !important;
}

@media screen and (max-width: 768px) {
  .page-productsTable__header {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .page-productsTable__header.base-translate-row {
    display: none !important;
  }
}
