.p-dashboard {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
}
.p-dashboard__header {
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.p-dashboard__draggable__icon {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-dashboard__draggable__icon i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-dashboard__draggable__icon i::before,
.p-dashboard__draggable__icon i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-dashboard__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
  margin-bottom: 0;
}
.p-dashboard__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-dashboard__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-dashboard__actions__pin {
  position: relative;
}
.p-dashboard__actions__pin > div {
  bottom: 48px;
  display: none;
}
.p-dashboard__actions__pin:hover > div {
  display: block;
}
.p-dashboard__body {
  height: 100%;
  padding: 16px;
}
.p-dashboard__body > *:not(:first-child) {
  margin-top: 16px;
}
