.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.form-content {
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.subdomain-input {
  display: flex;
  align-items: center;
  width: 100%;
}
.actions {
  margin: 32px 0 32px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.buttons {
  display: flex;
  gap: 16px;
}
.link {
  color: #008ec9;
  cursor: pointer;
}

.confirm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid var(--gray200);
  padding: 16px;
}
.confirm hr {
  width: 100%;
  border: 1px solid var(--gray200);
}
.confirm-item-title {
  font-size: var(--text-md-size);
  line-height: var(--text-md-height);
  color: #000;
  font-weight: bold;
}
.confirm-item-value {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray500);
}

.p-regist__flow {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}
.p-regist__flow ul {
  padding-left: 24px;
}
.p-regist__flow li {
  list-style: decimal;
}
.p-regist__flow li + li {
  margin-top: 16px;
}
.p-regist__flow > p {
  border-bottom: 1px solid #cfdae1;
  padding: 32px 0;
}

.p-regist__send {
  width: 100%;
  max-width: 640px;
  margin: 32px auto 0 auto;
}
.p-regist__send a {
  cursor: pointer;
  color: #008ec9;
  display: inline;
  text-decoration: underline;
  margin-top: 8px;
}
.p-regist__send .p-regist__flash {
  margin-top: 32px;
}

.p-regist__flash {
  width: 100%;
  border: 2px solid #008EC9;
  border-radius: 6px;
  background: rgba(0, 142, 201, 0.1);
  padding: 16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
}
.p-regist__flash p {
  font-weight: bold;
  color: #008EC9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (min-width: 769px) {
  .p-regist__flash p {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .p-regist__flash p {
    font-size: 14px;
  }
}
.p-regist__flash p span {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #008EC9;
  margin-right: 4px;
  display: block;
  flex-shrink: 0;
  position: relative;
}
.p-regist__flash p span::after {
  content: "";
  width: 4px;
  height: 10px;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 8px;
}
