
.c-table-nowrap {
}
.c-table-nowrap table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-nowrap table th {
  font-weight: normal;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.c-table-nowrap table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.c-table-nowrap__bold {
  font-weight: bold;
}
.c-table-nowrap__small {
  font-size: 12px;
}


.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .c-table-nowrap {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-nowrap__cell {
    width: 400px;
  }
  .c-table-nowrap table tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .c-table-nowrap table tr > *:first-child::before {
    content: '';
    width: 100%;
    height: 100%;
    border-left: 1px solid #ddd;
    border-right: 3px solid #ddd;
    position: absolute;
    left: -1px;
    top: 0;
  }
  .c-table-nowrap table tr > *:not(:first-child) {
    position: relative;
    z-index: 0;
  }
}

