.c-customerFormField__title {
  font-size: var(--text-sm-size);
}
.c-customerFormField__body {
}
.c-customerFormField__item {
}
.c-customerFormField__item:not(:first-child) {
  margin-top: 12px;
}
.c-customerFormField__item__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}
