.equipment-editor {
  border: 1px solid #ccc;
  height: calc(100vh - 200px);
  background-color: #fff;
}


.equipment-editor__main {
  border: 1px solid #ccc;
  display: flex;
  height: 100%;
}

.equipment-editor__panel {
  border: 1px solid #ccc;
}
.equipment-editor__panel.is-active {
  width: 100%;
}
.equipment-editor__panel-header {
  display: flex;
  height: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.equipment-editor__panel-body {
  border: 1px solid #ccc;
  display: flex;
  height: calc(100% - 32px);
}

.equipment-editor__panel-sidebar {
  border: 1px solid #ccc;
  width: 128px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.equipment-editor__panel-sidebar__list {
  background-color: #fff;
}

.equipment-editor__panel-sidebar__list > li {
  border: 1px solid #ccc;
  margin: 4px;
}

.equipment-editor__panel-sidebar__list > li > a {
  display: block;
  width: 100%;
  background-color: #f9f9f9;
}

.equipment-editor__panel-sidebar__list > li.is-selected > a{
  background-color: #ccc;
}

.equipment-editor__panel-main {
  border: 1px solid #ccc;
  display: none;
  height: 100%;
}

.equipment-editor__panel-main.is-active {
  width: 100%;
  display: block;
}

.equipment-editor__panel-main > form {
  height: 100%;
}

.equipment-editor__panel-main__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.equipment-editor__panel-main__prop {
  border: 1px solid #ccc;
  margin: 4px;
}

.equipment-editor__panel-main__body {
  border: 1px solid #ccc;
  margin: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.equipment-editor__panel-main__layout {
  margin: 4px;
  border: 1px solid #ccc;
  display: grid;
}

.equipment-editor__panel-main__layout > div {
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-text-stroke: 1px #000000;
  text-stroke: 1px #000000;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}

.equipment-editor__panel-main__layout > div.selected {
  border: 5px solid black;
}

.equipment-block-editor__props {
  margin: 4px;
}

p.up {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

p.right {
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

p.down {
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}

p.left {
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
}



