/*------------------------------------------------------------------------------
table-nowrap
------------------------------------------------------------------------------*/
.summaryTable {
}
.summaryTable table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.summaryTable table th {
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.summaryTable table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.summaryTable__bold {
  font-weight: bold;
}
.summaryTable__small {
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .summaryTable {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .summaryTable__cell {
    width: 400px;
  }
  .summaryTable table tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .summaryTable table tr > *:first-child::before {
    content: '';
    width: 100%;
    height: 100%;
    border-left: 1px solid #ddd;
    border-right: 3px solid #ddd;
    position: absolute;
    left: -1px;
    top: 0;
  }
  .summaryTable table tr > *:not(:first-child) {
    position: relative;
    z-index: 0;
  }
}



