.page-productsRegist__tableBox + .page-productsRegist__tableBox {
  margin-top: 16px;
}
.color-preview-box {
  margin-top: 20px;
}
.translations-button-container {
  margin-top: 10px;
  max-width: 600px;
  display: flex;
  justify-content: flex-end;
}
.custom-domain-input {
  margin-top: 20px;
}
.code-block {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  white-space: pre-wrap;
  word-break: break-all;
  display: inline-block;
}
.form-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}
.form-body {
  font-size: 14px;
  margin-right: 7px;
  width: fit-content;
}
/*------------------------------------------------------------------------------
table-list
------------------------------------------------------------------------------*/
.c-table-list {
}
.c-table-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-list table th,
.c-table-list table td {
  border: 1px solid #cfdae1;
  padding: 8px;
}
.c-table-list table th {
  width: 240px;
  font-weight: normal;
  text-align: left;
  background: #ebf1f3;
}
.c-table-list__note {
  font-size: 14px;
  margin-top: 8px;
}
.c-table-list__note.bold {
  font-weight: bold;
}
.c-table-list__note.red {
  color: #dc3e15;
}
.c-table-list__note.yellow {
  color: #ffcc00;
}
.c-table-list__note.green {
  color: #00bf98;
}
.c-table-list__note.blue {
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .c-table-list table,
  .c-table-list table tbody,
  .c-table-list table tr,
  .c-table-list table th,
  .c-table-list table td {
    width: 100%;
    display: block;
  }
  .c-table-list table tr + tr {
    margin-top: 8px;
  }
  .c-table-list table th {
    width: 100%;
    margin-bottom: -1px;
  }
  .c-table-list table td .base-t-800 {
    width: 100%;
  }
}

/*------------------------------------------------------------------------------
tableChild
------------------------------------------------------------------------------*/
.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul {
    border-bottom: 1px solid #cfdae1;
    margin-bottom: 8px;
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
}
