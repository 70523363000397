/* Top margin for menu scroll, only target sections and divs with ids */
section[id],
div[id] {
  scroll-margin-top: 16px;
}
@media screen and (max-width: 768px) {
  section[id],
  div[id] {
    scroll-margin-top: 136px;
  }
}

/* Account for fixed footer height */
.p-productEditor {
  margin-bottom: 64px;
}
@media screen and (max-width: 768px) {
  .p-productEditor {
    margin-bottom: 32px;
  }
}

.p-products__info_body__label {
  width: fit-content;
  font-size: var(--text-xs-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xs-height);
  color: var(--white);
  border-radius: var(--radius-full);
  padding: 4px 12px;
  flex-shrink: 0;
}
.p-products__info_body__label.status1 {
  background-color: var(--primary600);
}
.p-products__info_body__label.status2 {
  background-color: var(--success500);
}
.p-products__info_body__label.status3 {
  background-color: var(--warning500);
}
.p-products__info_body__label.status4 {
  background-color: var(--gray500);
}
.p-products__info_body__ttl {
  margin-top: 8px;
}

.p-products__ttl {
  font-size: var(--text-xl-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-xl-height);
  margin-bottom: 16px;
}
.p-products__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-products__section:not(:first-child) {
  margin-top: 16px;
}
.p-products__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-products__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-products__section__ttl > span {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 4px;
  display: block;
}
.p-products__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-products__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-products__section__body.is-active {
  display: block;
}
.p-products__section__body__textarea textarea {
  min-height: 44px;
}
.p-products__section__body__textarea2 textarea {
  min-height: 120px;
}
.p-products__section__body__item {
}
.p-products__section__body__item:not(:first-child) {
  margin-top: 16px;
}
.p-products__section__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-products__section__body__item.flex > *:not(:last-child) {
  margin-right: 16px;
}
.p-products__section__body__youtube i {
  color: #eb361c;
}
.p-products__section__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-products__section__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .p-products__section__header {
    padding: 16px;
  }
  .p-products__section__body {
    padding: 16px;
  }
}

.p-list {
}
.p-list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-list__item:not(:first-child) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--gray200);
}
.p-list__item.noBorder {
  padding-top: 0;
  border-top: none;
}
.p-list__item__ttl {
  width: 160px;
  color: var(--gray500);
  flex-shrink: 0;
  margin-right: 32px;
}
.p-list__item__ttl.full {
  width: 100%;
  margin-bottom: 4px;
}
.p-list__item__ttl__txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__ttl__txt > p:first-child {
  margin-right: 4px;
}
.p-list__item__ttl__txt > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__ttl__txt span {
  content: attr(data-required);
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--white);
  background-color: var(--warning500);
  border-radius: var(--radius-full);
  padding: 2px 6px;
  margin-left: auto;
}
.p-list__item__ttl > span {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 4px;
}
.p-list__item__body {
  width: calc(100% - 160px - 32px);
}
.p-list__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -12px;
}
.p-list__item__body.flex > * {
  margin-top: 12px;
}
.p-list__item__body.flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-list__item__body.flex > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.p-list__item__body.flex > div:not(:last-child) {
  margin-right: 12px;
}
.p-list__item__body.flex > div > div:last-child {
  margin-left: 4px;
}
.p-list__item__body.flex > p {
  width: 100%;
}
.p-list__item__body__flex {
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__item__body__flex__main {
  width: 100%;
}
.p-list__item__body__flex__actions {
  width: 36px;
  margin-left: 8px;
  flex-shrink: 0;
}
.p-list__item__body__flex > *:not(.p-list__item__body__flex__main),
.p-list__item__body__flex > *:not(.p-list__item__body__flex__actions) {
  margin-right: 12px;
}
.p-list__item__body__flex > *:last-child {
  margin-right: 0;
}
.p-list__item__body__flex.full {
  width: 100%;
}
.p-list__item__body__flex.full > * {
  width: 100%;
  flex-shrink: 1;
}
.p-list__item__body__photo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: -16px;
}
.p-list__item__body__photo li {
  width: 128px;
  height: 128px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
}
.p-list__item__body__photo li img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.p-list__item__body__photo li a {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
}
.p-list__item__body__photo li a i {
  font-size: 16px;
  color: var(--white);
}
.p-list__item__body__photo li.add {
  border: 1px dashed var(--primary700);
  color: var(--primary700);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.p-list__item__body__photo li.add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.p-list__item__body__photo li.add p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 10px;
}
.p-list__item__body__list:not(:first-child) {
  margin-top: 16px;
}
.p-list__item__body__list__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-bottom: 4px;
}

.p-list__item.row {
  flex-direction: column;
}
.p-list__item.row > .p-list__item__ttl {
  width: 100%;
  margin-bottom: 16px;
  margin-right: 0;
}
.p-list__item.row > .p-list__item__body {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-list__item {
    flex-direction: column;
  }
  .p-list__item__ttl {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .p-list__item__ttl > p {
    width: 100%;
  }
  .p-list__item__ttl > p span {
    margin-left: 8px;
  }
  .p-list__item__body {
    width: 100%;
  }
  .p-list__item__body__photo li {
    width: 100px;
    height: 100px;
  }
  .p-list__item__body__flex.full {
    flex-direction: column;
    margin-top: 16px;
  }
  .p-list__item__body__flex.full > * {
    margin-right: 0;
  }
  .p-list__item__body__flex.full > *:not(:first-child) {
    margin-top: 16px;
  }
}

.p-frame {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
}
.p-frame:not(:first-child) {
  margin-top: 16px;
}
.p-frame__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-frame__header__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
}
.p-frame__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-frame__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-frame__body {
  border-top: 1px solid var(--gray200);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  padding: 20px;
  display: none;
}
.p-frame__body.gray {
  background-color: var(--gray50);
}
.p-frame__body.is-active {
  display: block;
}
.p-frame__body__note {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
}

.p-products__fixed {
  width: 100%;
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
    0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 25;
  transition: bottom 0.2s linear;
}
.p-products__fixed.is-active {
  bottom: 0;
  opacity: 1;
}
.p-products__fixed__main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
  margin-left: 220px;
}
.p-products__fixed__main__ttl {
  margin-left: 8px;
}
.p-products__fixed__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-products__fixed__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-products__fixed__actions__select {
  position: relative;
}
.p-products__fixed__actions__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  bottom: 40px;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-products__fixed__actions__select__menu.is-active {
  display: block;
}
.p-products__fixed__actions__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-products__fixed__actions__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-products__fixed__actions__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
@media screen and (max-width: 768px) {
  .p-products__fixed {
    width: 100% !important;
    padding: 0 16px;
  }
  .p-products__fixed__main {
    display: none;
    margin-left: 0;
  }
  .p-products__fixed__actions {
    width: 100%;
  }
  .p-products__fixed__actions > *:first-child {
    /* TODO: do not use this yet since we don't have other menu to display  */
    /* margin-right: auto; */
  }
}

.p-products__spMenu {
  width: 100%;
  height: 68px;
  margin-bottom: 12px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}
.p-products__spMenu__inner {
  width: calc(100% + 32px);
  padding: 12px 16px;
  position: relative;
  left: -16px;
  transition: all 0.2s ease;
}
.p-products__spMenu__inner.is-active {
  background-color: var(--white);
  box-shadow: var(--shadow-md);
}

.p-productsTranslation:not(:first-child) {
  margin-top: 16px;
}
/* Override semantic */
.p-productsTranslation > .p-productsTranslation__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  margin-bottom: 16px;
}
.p-productsTranslation__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  margin-bottom: 16px;
}
.p-productsTranslation__body {
}
