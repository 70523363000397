.form-body {
  margin-right: 16px;
}

.form-title {
  margin-bottom: 8px;
  font-weight: bold;
}

.delete-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #dc3545;
  text-decoration: none;
  border-radius: 4px;
}

.delete-button:hover {
  color: #dc3545;
}

.delete-button i {
  font-size: 16px;
}

.dns-instructions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
