.notification-bell {
  display: flex;
}
.notification-bell-empty {
  margin-right: 10px;
}
.notification-count-disk {
  position: relative;
  left: -8px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background-color: red;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}