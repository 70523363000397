.p-lumpCancel {
  position: relative;
}
.p-lumpCancel__caution {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--error700);
  background-color: var(--error50);
  border: 1px solid var(--error200);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-xs);
  padding: 16px;
  margin-bottom: 12px;
}
.p-lumpCancel__form {
  position: relative;
}
.p-lumpCancel__form__item:not(:first-child) {
  margin-top: 20px;
}
.p-lumpCancel__form__item__ttl {
  margin-bottom: 6px !important;
}
.p-lumpCancel__form__item textarea {
  width: 100%;
  min-height: 248px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  padding: 8px 12px;
  resize: vertical;
}
.p-lumpCancel__form__item textarea:hover {
  border-color: var(--primary200);
}
.p-lumpCancel__form__item textarea:focus {
  border-color: var(--primary700);
}
