/* Base properties */
.c-calendarWrapper {
  width: 100%;
}
.c-calendar {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
  padding: 0;
  margin: 0;
}
.c-calendar__body {
  width: 100%;
  height: 100%;
  min-height: 44px;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-calendar__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-time-outline' !important;
  font-style: normal;
  color: var(--primary700);
  content: '\f006';
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
}
.c-calendar__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-calendar__body > i::before {
  line-height: 20px;
}
.c-calendar__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-calendar__body label {
  width: 100%;
  position: relative;
  min-height: 24px; /* Need this for label positioning */
}
.c-calendar__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}

.c-calendar__wrapper {
  width: calc(100% - 30px);
}

.c-calendar__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 24px;
  width: 100%;
}
.c-calendar__body__selected > input {
  width: 100%;
}
.c-calendar.is-hover {
  border-color: var(--primary200);
  cursor: pointer;
}
.c-calendar.is-active {
  border-color: var(--primary700);
}
.c-calendar.is-input .c-calendar__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-calendar.is-active .c-calendar__body__title {
  color: var(--primary700);
}

.c-calendar__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.c-calendar__menu {
  /* width: 320px; */
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 4px;
  position: absolute;
  /* left: calc(50% - 160px); */
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.c-calendar__menu.is-active {
  display: block;
}
.c-calendar__menu__header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-calendar__menu__header a {
  width: 40px;
  height: 40px;
  position: relative;
}
.c-calendar__menu__header a::before {
  content: '';
  width: 8px;
  height: 8px;
  border-top: 2px solid var(--primary600);
  border-left: 2px solid var(--primary600);
  transform: rotate(-45deg);
  position: absolute;
  top: 16px;
  left: 16px;
}
.c-calendar__menu__header a:last-child {
  transform: scale(-1, 1);
}
.c-calendar__menu__header a:hover {
  background-color: var(--primary50);
}
.c-calendar__menu__header p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.c-calendar__menu__body__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.c-calendar__menu__body__header li {
  width: calc(100% / 7);
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-calendar__menu__body__day {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-calendar__menu__body__day li {
  width: calc(100% / 7);
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-calendar__menu__body__day li.disabled {
  color: var(--gray300);
  pointer-events: none;
}
.c-calendar__menu__body__day li.start {
  color: var(--white);
  background-color: var(--primary600);
  border-radius: 4px 0 0 4px;
}
.c-calendar__menu__body__day li.end {
  color: var(--white);
  background-color: var(--primary600);
  border-radius: 0 4px 4px 0;
}
.c-calendar__menu__body__day li.between {
  background-color: var(--primary50);
}
.c-calendar__menu__body__day li:not(.start):not(.end):not(.between):hover {
  background-color: var(--gray50);
}
.c-calendar.is-disabled {
  border-color: var(--gray200);
  background-color: var(--gray50);
  pointer-events: none;
}
.c-calendar.is-disabled input {
  color: var(--gray500);
}
.c-calendar.is-disabled .c-calendar__body::before {
  color: var(--gray500);
}