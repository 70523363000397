.see-more-buttons {
  display: flex;
  justify-content: flex-end;
}
.see-more-body {
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
}
.see-more-body.is-close {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.see-more-body.is-open {
}
