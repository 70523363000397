.c-fieldsForm {
}
.c-fieldsForm > div:not(:first-child) {
  margin-top: 16px;
}
.c-fieldsForm__ttl {
  margin-bottom: 6px !important;
}
.c-fieldsForm__item {
  display: flex;
  align-items: center;
}
.c-fieldsForm__item > *:not(:first-child) {
  margin-left: 8px;
}
