.modal-content {
  max-height: calc(100vh - 256px);
  overflow-y: auto;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .modal-content {
    max-height: calc(100vh - 156px);
  }
}
