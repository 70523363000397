.c-subnav {
  list-style-type: none;
  padding: 16px;
  width: 100%;
  background: #fff;
  border-radius: var(--radius-lg);
  overflow-y: auto;
}

.c-subnav > p {
  font-weight: var(--text-semibold);
}

.c-subnav > li {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.c-subnav > li i {
  margin-right: 8px;
}

.c-subnav > li > a {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--gray500);
  font-size: var(--text-md-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-md-height);
  border-radius: var(--radius-sm);
  transition: background-color 0.3s, color 0.3s;
}

.c-subnav > li > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
}

.c-subnav > li > ul > li {
  margin-left: 22px;
  padding: 8px 16px;
}
.c-subnav > li.is-active > ul {
  display: flex;
  flex-direction: column;
}
.c-subnav > li.is-active > a {
  color: var(--primary700);
  font-weight: var(--text-semibold);
}
.c-subnav > li.is-active li.is-active {
  color: var(--primary700);
  font-weight: var(--text-semibold);
}
