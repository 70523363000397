.p-sign-up-defaults {
  background: #fff;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ',
    'Meiryo', 'ＭＳ ゴシック', sans-serif;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
.l-main {
  width: 100%;
  min-height: calc(100vh - 558px);
  position: relative;
}
.p-contact__body {
  padding-top: 32px;
  padding-bottom: 128px;
}
.p-contact__body__form__ttl {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #008ec9;
  padding: 32px;
  margin: 0px;
}
.c-inner--middle {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 769px) {
  .c-inner--middle {
    padding: 0 32px;
  }
}
@media screen and (max-width: 768px) {
  .c-inner--middle {
    padding: 0 16px;
  }
}
.c-inner--middle {
  max-width: 960px;
}
.p-regist__flash {
  width: 100%;
  border: 2px solid #008ec9;
  border-radius: 6px;
  background: rgba(0, 142, 201, 0.1);
  padding: 16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
}
.p-regist__flash p {
  font-weight: bold;
  color: #008ec9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (min-width: 769px) {
  .p-regist__flash p {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .p-regist__flash p {
    font-size: 14px;
  }
}
.p-regist__flash p span {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #008ec9;
  margin-right: 4px;
  display: block;
  flex-shrink: 0;
  position: relative;
}
.p-regist__flash p span::after {
  content: '';
  width: 4px;
  height: 10px;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 8px;
  box-sizing: content-box;
}
.p-regist__note {
  font-size: 14px;
  margin-top: 4px;
}
.c-headline {
  font-weight: bold;
  text-align: center;
  position: relative;
}
.c-headline::after {
  content: '';
  width: 240px;
  height: 2px;
  background: #001d36;
  display: block;
  margin: 8px auto 0 auto;
}
@media screen and (min-width: 769px) {
  .c-headline {
    font-size: 32px;
    margin-bottom: 64px;
  }
  .c-headline.under {
    margin-bottom: 32px;
  }
  .c-headline::after {
    width: 240px;
  }
}
@media screen and (max-width: 768px) {
  .c-headline {
    font-size: 20px;
    margin-bottom: 32px;
  }
  .c-headline.under {
    margin-bottom: 16px;
  }
  .c-headline::after {
    width: 120px;
  }
}
.p-regist__table {
  width: 100%;
  max-width: 640px;
  border-collapse: collapse;
  margin: 0 auto;
}
.p-regist__table td,
.p-regist__table th {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: left;
  border: 1px solid #cfdae1;
  padding: 8px;
}
.p-regist__table th {
  width: 160px;
  background: #f9f9f9;
}
@media screen and (max-width: 768px) {
  .p-regist__table tr,
  .p-regist__table td,
  .p-regist__table th {
    display: block;
  }
  .p-regist__table td {
    position: relative;
  }
  /* CSS magic from https://stackoverflow.com/questions/9789723/css-text-overflow-in-a-table-cell */
  .p-regist__table td:before {
    content: '&nbsp;';
    visibility: hidden;
  }
  .p-regist__table td > span {
    padding-left: 8px;
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .p-regist__table tr + tr {
    margin-top: 8px;
  }
  .p-regist__table th {
    width: 100%;
    margin-bottom: -1px;
  }
}
.c-form {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}
.c-form__box + .c-form__box {
  margin-top: 16px;
}

.c-form__box__header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
.c-form__box__header span {
  color: #ff0000;
  margin-left: 4px;
}

.c-form__btns {
  margin: 32px 0;
}
.c-form__btns > * {
  margin: 0 auto;
}
.c-form__btns > * + * {
  margin-top: 16px;
}

.c-inputtext,
.c-textarea {
  width: 100%;
  font-size: 14px;
  color: #001d36;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #f9f9f9;
  padding: 12px 16px;
}

.c-textarea {
  height: 320px;
  word-break: break-all;
  resize: vertical;
}

.c-select {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: #001d36;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #f9f9f9;
  display: block;
  position: relative;
}
.c-select select {
  width: 100%;
  height: 100%;
  padding: 0 32px 0 16px;
  cursor: pointer;
}
.c-select::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 3px);
  right: 12px;
}

._radio-checkbox,
.c-checkbox,
.c-radio {
  width: 100%;
  font-size: 14px;
  color: #001d36;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
._radio-checkbox input,
.c-checkbox input,
.c-radio input {
  display: none;
}
._radio-checkbox + label,
.c-checkbox + label,
.c-radio + label {
  margin-top: 8px;
}
._radio-checkbox p,
.c-checkbox p,
.c-radio p {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  flex-shrink: 0;
  position: relative;
}
._radio-checkbox input:checked ~ span,
.c-checkbox input:checked ~ span,
.c-radio input:checked ~ span {
  color: #008ec9;
}

.c-radio p {
  background: #fff;
  border-radius: 100%;
  border: 1px solid #008ec9;
}
.c-radio input:checked ~ p::after {
  content: '';
  width: 12px;
  height: 12px;
  background: #008ec9;
  border-radius: 100%;
  position: absolute;
  top: 1px;
  left: 1px;
}
.c-checkbox p {
  background: #fff;
  border-radius: 2px;
  border: 1px solid #008ec9;
}
.c-checkbox input:checked ~ p {
  background: #008ec9;
}
.c-checkbox input:checked ~ p::after {
  content: '';
  width: 5px;
  height: 9px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 4px;
}

.c-error {
  font-size: 14px;
  color: #ff0000;
  margin-top: 4px;
  display: none;
}

.c-error.is-active {
  display: block;
}

.p-regist__search {
  position: relative;
}
.p-regist__search .c-select {
  line-height: 48px;
  padding: 0 32px 0 16px;
  cursor: pointer;
}

.p-regist__search__main {
  width: 100%;
  background: #fff;
  border: 1px solid #cfdae1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 1;
  display: none;
}
.p-regist__search__main.is-active {
  display: block;
}

.p-regist__search__main__header {
  border-bottom: 1px solid #cfdae1;
  padding: 8px;
}

.p-regist__search__main__body {
  height: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.p-regist__search__main__body li {
  font-size: 14px;
  padding: 10px 12px;
  cursor: pointer;
}
.p-regist__search__main__body li:nth-child(odd) {
  background: #fff;
}
.p-regist__search__main__body li:nth-child(even) {
  background: #f9f9f9;
}
.p-regist__search__main__body li:hover {
  opacity: 0.8;
}

.c-btnsMiddle {
  width: 240px;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 769px) {
  .c-btnsMiddle {
    transform: all 0.4s ease;
  }
}
.c-btnsMiddle:disabled {
  background: #cfdae1 !important;
}
.c-btnsMiddle.loading {
  position: relative;
  cursor: default;
  /* text-shadow: none!important; */
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}
.c-btnsMiddle.loading:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}
.c-btnsMiddle.loading:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}

@keyframes button-spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
.c-btns--blue {
  color: #fff;
  background: #008ec9;
}
@media screen and (min-width: 769px) {
  .c-btns--blue:hover {
    opacity: 0.9;
  }
}
.c-btns--shadow {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 769px) {
  .c-btns--shadow:hover {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }
}
