article.base-single {
  margin: 0 auto 64px auto;
}
.base-single {
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .base-single {
    width: 100%;
    padding: 0;
    flex-wrap: wrap;
  }
}
