.assignment__selector {
}

.assignment__body {
  display: flex;
  justify-content: center;
  flex-direction: wrap;
}

.assignment__reservations {
  width: calc((100% - 16px) / 2);
  margin: 4px;
}

.assignment__reservations__item {
}

.assignment__reservations__item.is-hovered {
  background-color: #9bacb5;
}

.assignment__seatmap {
  width: calc((100% - 16px) / 2);
  margin: 4px;
}

.assignment__seatmap__body {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.assignment__seatmap__layout {
  display: grid;
  margin: 10px auto;
}

.assignment__seatmap__layout > div {
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assignment__seatmap__layout > div.selected {
  border: 5px solid black;
}

.assignment__seatmap__layout > div.replaced {
  border: 5px solid green;
}

.assignment__seatmap__layout > div.used {
  border-style: solid;
  border-width: 2px;
}

.assignment__seatmap__layout div.icon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #9bacb5;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assignment__seatmap__layout div.icon > img {
  width: 12px;
  height: 12px;
}

.assignment__seatmap__layout > div.no-border__top {
  border-top: 0px;
}
.assignment__seatmap__layout > div.no-border__bottom {
  border-bottom: 0px;
}
.assignment__seatmap__layout > div.no-border__left {
  border-left: 0px;
}
.assignment__seatmap__layout > div.no-border__right {
  border-right: 0px;
}
.assignment__seatmap__layout > div.header {
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assignment__seatmap__layout > div.hovered-reservation {
  border-color: #ff0000;
  border-width: 5px;
}

.assignment__seatmap__cell__hover {
  position: absolute;
  margin-top: -100px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  z-index: 100;
}

.assignment__seatmap__header__btns {
  display: flex;
}

@media screen and (max-width: 768px) {
  .assignment__body {
    flex-direction: column;
  }
  .assignment__reservations {
    width: 100%;
    margin: 8px 8px 0px 8px;
  }
  .assignment__seatmap {
    width: 100%;
    margin: 8px 8px 0px 8px;
  }
  .assignment__seatmap__header__btns {
    display: block;
  }
}

/* shimada: SeatAssignment.module.css */

/*----------------------------------
// ページネーション修正
----------------------------------*/
.c-pagination > *:not(:last-child) {
  margin-right: 8px;
}
.c-pagination__size p {
  margin-right: 4px;
}
.c-pagination__jump p:first-child {
  margin-right: 0;
}
.c-pagination__jump p:last-child {
  margin-left: 4px;
}

/*----------------------------------
// 割り当て
----------------------------------*/
.seatsBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.seatsBody__sub {
  width: 40%;
  height: calc(100vh - 164px);
  border: 1px solid #cfdae1;
  background: #fff;
  margin-right: 16px;
}
.seatsBody__main {
  width: 60%;
  height: calc(100vh - 164px);
  border: 1px solid #cfdae1;
  background: #fff;
}
.seatsHeader {
  width: 100%;
  border-bottom: 1px solid #cfdae1;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.seatsHeader__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.seatsHeader__left > p {
  font-weight: bold;
  margin-right: 8px;
  flex-shrink: 0;
}
.seatsHeader__right {
  display: flex;
  margin-left: auto;
}

.seatsHeader__right__info {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.seatsHeader__right__info.sp {
  display: none;
}
.seatsHeader__action {
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  border-radius: 4px;
  border: 1px solid #008ec9;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 8px;
  left: 8px;

  display: none;
}
.seatsHeader__action.is-active {
  display: flex;
}
.seatsHeader__action__txt {
  color: #008ec9;
  font-weight: bold;
  margin-right: 8px;
  flex-shrink: 0;
}
.seatsHeader__action__info {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.seatsHeader__action__info__txt {
  font-size: 12px;
  margin-right: 8px !important;
}

.seatsAction {
  position: relative;
}

.seatsAction__info {
  width: 56px;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
}
.seatsAction__info__box {
  width: 56px;
  height: auto;
  font-size: 12px;
  color: #4a6c7a;
  text-align: center;
  background: #ebf1f3;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 4px;
  margin-bottom: 8px;
}
.seatsAction__info__box > div span {
  font-size: 16px;
  font-weight: bold;
}
.seatsAction__info__box > div span.green {
  color: #00bf98;
}
.seatsAction__info__box > div span.red {
  color: #db3d14;
}
.seatsAction__btns {
  width: 28px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.seatsAction__btns > *:hover {
  opacity: 0.8;
}
.seatsAction__btns.reload {
  height: 120px;
}
.seatsAction__btns.reload p {
  width: 28px;
  height: 28px;
  background: #008ec9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.seatsAction__btns.reload p svg,
.seatsAction__btns.reload p img {
  width: 16px;
  height: 16px;
}
.seatsAction__btns a {
  width: 28px;
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seatsAction__cell {
  width: 100%;
  height: calc(100vh - 256px);
  padding: 8px;
  overflow: hidden;
  position: relative;
}

.seatsAction__cell.modal {
  height: calc(100vh - 400px);
}

.seatsAction__cell .content {
  width: 100%;
  height: 100%;
  position: relative;
}
.seatsAction__cell .content ul {
  position: absolute;
  display: grid;
  gap: 0;
}
.seatsAction__cell .content li {
  width: 100%;
  height: 100%;
  font-size: 12px;
  border: 1px dotted #9bacb5;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  color: #000;
  position: relative;
}

.seatsAction__cell .content li .seatView {
  position: relative;
  width: 40px;
  height: 40px;
}
.seatsAction__cell .content li .seatView img {
  width: 100%;
  height: 100%;
}
.seatsAction__cell .content li .seatView .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 20px;
  font-weight: bold;
  background-color: #fff;
  opacity: 0.6;
}
.seatsAction__cell .content li .seatView.nonAssignable {
  opacity: 0.1;
}

.seatsAction__cell .content li .label {
}
.seatsAction__cell .content li .label.assigned {
  border-radius: 0%;
  padding: 1px;
  background-color: #fff;
  color: #000;
}

.seatsAction__cell .content li.decided {
  font-weight: bold;
  border: 2px solid #4a6c7a;
}

.seatsAction__cell .content li.decided span {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.seatsAction__cell .content li.decided span i {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #4a6c7a;
  position: absolute;
  top: -4px;
  right: -4px;
}
.seatsAction__cell .content li.decided span i::before {
  content: '';
  box-sizing: border-box;
  width: 3px;
  height: 5px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: absolute;
  top: 1.5px;
  left: 3.5px;
  transform: rotate(45deg);
}
.seatsAction__cell .content li.decided p {
  width: 14px;
  height: 10px;
  text-align: center;
  border-bottom-right-radius: 2px;
  background: #4a6c7a;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.seatsAction__cell .content li.decided p img {
  height: 8px;
  vertical-align: top;
}

.seatsAction__cell .content li.select {
  border: 2px solid #ffcc00;
}
.seatsAction__cell .content li.move-to {
  border: 2px solid green;
}
.seatsAction__cell .content li.move-from {
  border: 2px solid black;
}
.seatsAction__cell .content li.before {
  border: 2px solid #9bacb5;
  pointer-events: none !important;
}
.seatsAction__cell .content li.hovered-reservation {
  border: 2px solid red;
}
.seatsAction__cell .content li.no-border__top {
  border-top: 0;
}
.seatsAction__cell .content li.no-border__bottom {
  border-bottom: 0;
}
.seatsAction__cell .content li.no-border__left {
  border-left: 0;
}
.seatsAction__cell .content li.no-border__right {
  border-right: 0;
}
.seatsAction__cell .content li.enable {
  pointer-events: auto;
  cursor: pointer;
}
.seatsAction__cell .content li.hide {
  opacity: 0.1;
}
.seatsAction__cell .content .moveMode li.decided {
  color: #9bacb5;
  border: 2px solid #9bacb5;
  pointer-events: none !important;
}

.seatsAction__cell .hover {
  width: 160px;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  padding: 8px;
  position: fixed;
  z-index: 4;
}

.seatsAction__cell .hover::after {
  content: '';
  box-sizing: border-box;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 0 12px;
  border-color: #f9f9f9 transparent transparent transparent;
  position: absolute;
  bottom: -12px;
  left: calc(50% - 12px);
}
.seatsAction__cell .hover ul {
  width: 100%;
}
.seatsAction__cell .hover li {
  font-size: 12px;
  padding-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.seatsAction__cell .hover li:not(:first-child) {
  border-top: 1px solid #cfdae1;
  padding-top: 4px;
}
.seatsAction__cell .hover li p:first-child {
  flex-shrink: 0;
}
.seatsAction__cell .hover li p:last-child {
  margin-left: auto;
  max-width: 80px;
  line-height: 20px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.popup {
  width: 240px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  padding: 8px;
  position: fixed;
  z-index: 11;
}
.popup::after {
  content: '';
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: #fff;
  transform: rotate(45deg);
  position: absolute;
  bottom: -10px;
  left: calc(50% - 8px);
}
.popup.create {
  border: 3px solid #008ec9;
}
.popup.create::after {
  border-right: 3px solid #008ec9;
  border-bottom: 3px solid #008ec9;
}
.popup.edit {
  border: 3px solid #ffcc00;
}
.popup.edit::after {
  border-right: 3px solid #ffcc00;
  border-bottom: 3px solid #ffcc00;
}
.popup__info {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 8px;
}
.popup__info li {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.popup__info li:not(:first-child) {
  padding-top: 4px;
}
.popup__info li p:first {
  flex-shrink: 0;
}
.popup__info li p:last-child {
  margin-left: auto;
}
.popup__listTite {
  font-size: 14px;
  line-height: 24px;
  border-left: 4px solid #ffcc00;
  padding-left: 4px;
  margin-top: 8px;
}
.popup__list {
}
.popup__list li {
  width: 100%;
  height: 32px;
  font-size: 14px;
  color: #4a6c7a;
  padding: 0 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.popup__list li:not(:last-child) {
  border-bottom: 1px solid #cfdae1;
}
.popup__list li:hover {
  opacity: 0.8;
}
.popup__list li::after {
  content: '';
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border-top: 1px solid #cfdae1;
  border-right: 1px solid #cfdae1;
  transform: rotate(45deg);
  margin-left: auto;
  flex-shrink: 0;
}
.popup__list li.bold {
  font-weight: bold;
  color: #008ec9;
}

.overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

/*----------------------------------
// モーダル
----------------------------------*/
.seatsTable {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.seatsTable table {
  width: calc(50% - 16px);
  border-collapse: collapse;
}
.seatsTable th,
.seatsTable td {
  font-weight: normal;
  border: 1px solid #cfdae1;
  padding: 8px;
  display: table-cell;
}
.seatsTable th {
  width: 128px;
  background: #ebf1f3;
}
.seatsTable td {
  background: #fff;
}

/* tag */

.tag {
  width: 100%;
  margin-bottom: -8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.tag li {
  margin-bottom: 8px;
}
.tag li:not(:last-child) {
  margin-right: 8px;
}
.tag li a {
  min-width: 64px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #9bacb5;
  background: #dbe4e9;
  border-radius: 12px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tag li a:hover {
  opacity: 0.8;
}
.tag li a.is-active {
  color: #fff;
  background: #008ec9;
}

.tag li a.is-onsale {
  color: #008ec9;
  background: #008ec9;
}

.tag li a.is-closed {
  color: #9bacb5;
  background: #dbe4e9;
}

.tag li a.is-selected {
  color: #fff;
}

.tag li a.is-unselected {
  background: #fff;
}

.tag li i {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-left: 4px;
  margin-right: -6px;
  position: relative;
  flex-shrink: 0;
}
.tag li i::before,
.tag li i::after {
  content: '';
  box-sizing: border-box;
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 9px;
  left: 5px;
}
.tag li i::before {
  transform: rotate(45deg);
}
.tag li i::after {
  transform: rotate(-45deg);
}
.tag li p {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #dbe4e9;
  position: relative;
  cursor: pointer;
}
.tag li p::before,
.tag li p::after {
  content: '';
  box-sizing: border-box;
  width: 12px;
  height: 2px;
  background: #008ec9;
  position: absolute;
  top: 11px;
  left: 6px;
}
.tag li p::after {
  transform: rotate(90deg);
}
.tag li p:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .seatsBody {
    flex-direction: column;
  }
  .seatsBody__sub {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    height: unset;
  }
  .seatsBody__main {
    width: 100%;
    border: none;
    height: unset;
  }

  .seatsHeader {
    display: block;
  }

  .seatsHeader__left {
    margin-bottom: 8px;
  }

  .seatsHeader__right {
    margin-left: 0;
  }

  .seatsHeader__right__info.sp {
    margin-left: 8px;
    display: flex;
  }

  .seatsHeader__right__info.pc {
    display: none;
  }
}
