
.seats {
  width: 100%;
  height: calc(100vh - 202px);
  border: 1px solid #cfdae1;
  background: #fff;
  display: flex;
}
.seats__side {
  width: 180px;
  overflow-y: auto;
  border-right: 2px solid #cfdae1;
  flex-shrink: 0;
}
.seats__side__list {

}
.seats__side__list li {
  border-bottom: 1px solid #cfdae1;
}
.seats__side__list a {
  font-size: 14px;
  color: #888;
  padding: 10px 8px;
  display: block;
}
.seats__side__list a:hover {
  background: #f9f9f9;
}
.seats__side__list li.is-active a {
  color: #001d36;
  background: #dcf5ff;
}
.seats__side__add {
  padding: 16px 0;
  display: flex;
  justify-content: center;
}
.seats__main {
  width: 100%;
  padding: 16px;
  overflow-y: auto;
}
.seats__preview {
  width: 100%;
  border: 1px solid #cfdae1;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.seats__preview__btns {
  width: 28px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 8px;
  right: 8px;
}
.seats__preview__btns a {
  width: 28px;
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: rgba(0,0,0,0.7);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seats__btn {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.seats__btn > *:not(:first-child) {
  margin-top: 16px;
}
.seats__parts {
  width: 180px;
  overflow-y: auto;
  border-left: 2px solid #cfdae1;
  flex-shrink: 0;
  padding: 16px 8px;
}
.seats__parts__ttl {
  font-size: 16px;
  font-weight: bold;
  color: #008ec9;
}
.seats__parts__note {
  font-size: 12px;
  color: #888;
  margin-top: 4px;
}
.seats__parts__list li {
  margin-top: 8px;
}
.seats__parts__list li:hover {
  opacity: 0.8;
}
.seats__parts__list li a {
  font-size: 14px;
  background: #ebf1f3;
  border-radius: 4px;
  padding: 10px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: grab;
}
.seats__parts__list img,
.seats__parts__list svg {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  margin-right: 4px;
}


.equipment-editor__panel-main__content {
  display: flex;
}

.equipment-editor__panel-main__prop {
  width: 200px;

  display: flex;
  flex-direction: column;
}

.equipment-editor__panel-main__body {
  width: 100%;
  align-items: center;
  border: 1px solid #ccc;
  margin: 4px;
  width: 100%;
}
.equipment-editor__panel-main__layout {
  border: 1px solid #ccc;
  display: grid;
  margin: 4px;
  grid-template-columns: repeat(1, 80px); 
  grid-template-rows: repeat(1, 50px); 
  width: 80px; 
  height: 50px;
}
.equipment-editor__panel-main__layout>div {
  -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000;
  align-items: center;
  border: 1px dashed #000;
  color: #fff;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
}

.rc-color-picker-trigger {
  border: 1px solid #999;
  border-radius: 2px;
  box-shadow: inset 0 0 0 2px #fff;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  padding: 2px;
  -webkit-user-select: none;
  user-select: none;
  width: 20px;
}

.equipment-editor__panel-main__layout {
  margin: 4px;
  border: 1px solid #ccc;
  display: grid;
}

.equipment-editor__panel-main__layout > div.selected {
  border: 5px solid black;
}



.seatsAction {
  position: relative;
}
.seatsAction__btns {
  width: 28px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.seatsAction__btns a {
  width: 28px;
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: rgba(0,0,0,0.7);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seatsAction__cell {
  width: 100%;
  height: calc(100vh - 268px); 
  padding: 8px;
  overflow: hidden;
  position: relative;
}
.seatsAction__cell .content {
  width: 100%;
  height: 100%;
  position: relative;
}
.seatsAction__cell .content ul {
  position: absolute;
  display: grid;
  gap: 0;
}
.seatsAction__cell .content li {
  width: 100%;
  height: 100%;
  font-size: 12px;
  border: 1px dotted #9BACB5;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-text-stroke: 0.2px #000;
  text-stroke: 0.2px #000;
  color: #fff;
}
.seatsAction__cell .content li.decided {
  font-weight: bold;
  border: 2px solid #4A6C7A;
}
.seatsAction__cell .content li.select {
  border: 2px solid black;
}
.seatsAction__cell .content li.move-to {
  border: 2px solid green;
}
.seatsAction__cell .content li.move-from {
  border: 2px solid black;
}
.seatsAction__cell .content li.before {
  border: 2px solid #9BACB5;
  pointer-events: none !important;
}
.seatsAction__cell .content li.enable {
  pointer-events: auto;
  cursor: pointer;
}
.seatsAction__cell .content .moveMode li.decided {
  color: #9BACB5;
  border: 2px solid #9BACB5;
  pointer-events: none !important;
}
.seatsAction__cell .content li .label{
  border-radius: 20%;
  padding: 1px;
  background-color: #fff;
  color: #000;
}

.seatsAction__cell .content li.border__top {
  border-top: 2px solid #FFCC00;
}
.seatsAction__cell .content li.border__left {
  border-left: 2px solid #FFCC00;
}
.seatsAction__cell .content li.border__right {
  border-right: 2px solid #FFCC00;
}
.seatsAction__cell .content li.border__bottom {
  border-bottom: 2px solid #FFCC00;
}
.seatsAction__cell .hover {
  width: 160px;
  background-color: #F9F9F9;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  padding: 8px;
  position: fixed;
  z-index: 2;
}

.seatsAction__cell .hover::after {
  content: "";
  box-sizing: border-box;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 0 12px;
  border-color: #F9F9F9 transparent transparent transparent;
  position: absolute;
  bottom: -12px;
  left: calc(50% - 12px);
}
.seatsAction__cell .hover ul {
  width: 100%;
}
.seatsAction__cell .hover li {
  font-size: 12px;
  padding-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.seatsAction__cell .hover li:not(:first-child) {
  border-top: 1px solid #cfdae1;
  padding-top: 4px;
}
.seatsAction__cell .hover li p:first-child {
  flex-shrink: 0;
}
.seatsAction__cell .hover li p:last-child {
  margin-left: auto;
}


.popup {
  width: 240px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  padding: 8px;
  position: fixed;
  z-index: 11;
}
.popup::after {
  content: "";
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: #fff;
  transform: rotate(45deg);
  position: absolute;
  bottom: -10px;
  left: calc(50% - 8px);
}
.popup.create {
  border: 3px solid #008ec9;
}
.popup.create::after {
  border-right: 3px solid #008ec9;
  border-bottom: 3px solid #008ec9;
}
.popup.edit {
  border: 3px solid #FFCC00;
}
.popup.edit::after {
  border-right: 3px solid #FFCC00;
  border-bottom: 3px solid #FFCC00;
}
.popup__info {
  width: 100%;
  background-color: #F9F9F9;
  border-radius: 4px;
  padding: 8px;
}
.popup__info li {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.popup__info li:not(:first-child) {
  padding-top: 4px;
}
.popup__info li p:first {
  flex-shrink: 0;
}
.popup__info li p:last-child {
  margin-left: auto;
}
.popup__listTite {
  font-size: 14px;
  line-height: 24px;
  border-left: 4px solid #FFCC00;
  padding-left: 4px;
  margin-top: 8px;
}
.popup__list {
  
}
.popup__list li {
  width: 100%;
  height: 32px;
  font-size: 14px;
  color: #4A6C7A;
  padding: 0 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.popup__list li:not(:last-child) {
  border-bottom: 1px solid #cfdae1;
}
.popup__list li:hover {
  opacity: 0.8;
}
.popup__list li::after {
  content: "";
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border-top: 1px solid #cfdae1;
  border-right: 1px solid #cfdae1;
  transform: rotate(45deg);
  margin-left: auto;
  flex-shrink: 0;
}
.popup__list li.bold {
  font-weight: bold;
  color: #008EC9;
}
.overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

/*----------------------------------
// モーダル
----------------------------------*/
.seatsTable {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.seatsTable table {
  width: calc(50% - 16px);
  border-collapse: collapse;
}
.seatsTable th,
.seatsTable td {
  font-weight: normal;
  border: 1px solid #cfdae1;
  padding: 8px;
  display: table-cell;
}
.seatsTable th {
  width: 128px;
  background: #EBF1F3;
}
.seatsTable td {
  background: #fff;
}



