.c-subnav {
  list-style-type: none;
  padding: 16px;
  width: 100%;
  background: #fff;
  border-radius: var(--radius-lg);
  /* Make sure we can scroll menu when there are items exceeding screen height */
  max-height: 70vh;
  overflow-y: auto;
}
.c-subnav__ttl {
  color: var(--gray900);
  margin-bottom: 12px !important;
}
.c-subnav__list {
  color: var(--gray700);
}
.c-subnav__list__item {
}
.c-subnav__list__item:not(:first-child) {
  margin-top: 12px;
}
.c-subnav__list__item a {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-subnav__list__item.is-active > a {
  /* color: var(--primary700); */
}
.c-subnav__list__item.is-active .c-subnav__list__item__children {
  display: block;
}
.c-subnav__list__item__children {
  padding-left: 16px;
  display: none;
}
.c-subnav__list__item__children li {
  margin-top: 8px;
}
.c-subnav__list__item__children li a {
  width: 100%;
  line-height: var(--text-md-height);
}
.c-subnav__list__item__children li a:hover {
color: var(--primary600);
}
.c-subnav__list__item.is-active .c-subnav__list__item__children li.is-active a {
  color: var(--primary700);
  font-weight: var(--text-semibold);
}

.c-subnav > p {
  font-weight: var(--text-semibold);
}
.c-subnav > li {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.c-subnav > li i {
  margin-right: 8px;
}
