.link {
  color: #008ec9;
}
.tags-container {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}
.tag-list {
  width: 100%;
  font-size: 14px;
  color: #008ec9;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.tag-list li {
  margin-right: 8px;
  margin-bottom: 4px;
}
.tag-list li::before {
  content: '#';
  margin-right: 4px;
}
.message {
  padding: 8px;
  border-top: 1px solid #cfdae1;
}
.message.bulk {
  border-top: none;
}
.message-info {
  display: flex;
  align-items: center;
}
.message-from {
  font-weight: bold;
}
.message-date {
  margin-left: auto;
}
.message-body {
  margin-left: 30px;
  margin-top: 8px;
  white-space: pre-line;
}
.email-opened-icon {
  width: 30px;
}
.message-respond-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px
}
.response {
  background: #f9f9f9;;
}
