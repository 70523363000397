.image-container {
  flex-shrink: 0;
  margin-left: 8px;
  width: 240px;
  background-color: #f5f5f5;
  text-align: center;
}
.image-container img {
  height: 80px;
}
.container {
  width: 100%;
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icon-box {
  padding-left: 2px;
  padding-right: 2px;
}

.title {
  font-weight: bold;
}
.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
