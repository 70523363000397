.search__form {
}

.search__form .search__form__label {
  width: 300px;
  border-radius: 1em;
}

.suggest__option {
  display: block;
}

.suggest__option__title {
}

.suggest__option__tag_area {
}

.suggest__option__tag {
  display: inline-block;
  margin: 0 10px 0 0;
  background: lightgray;
  font-size: 12px;
  padding: 0.5em 1em;
  border-radius: 0.5em;
}

@media screen and (max-width: 768px) {
  .search__form .search__form__label {
    width: 100%;
  }
}
