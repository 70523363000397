.c-review {
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 8px rgb(60 64 67 / 10%);
  border-radius: 6px;
  margin-top: 32px;
}
.c-review__ttl {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding: 16px;
}
.c-review__summary {
  padding-bottom: 1px;
}
.c-review__box {
  margin: 16px;
}
.c-review__summary__total {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-review__summary__total__ttl {
  font-size: 16px;
  font-weight: bold;
}
.c-review__summary__total__stars {
  margin: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.c-review__summary__total__stars img {
  width: 24px;
  height: 24px;
}
.c-review__summary__total__num {
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
}
.c-review__summary__total__num span {
  font-size: 18px;
  font-weight: normal;
  color: #888;
  margin-left: 4px;
}
.c-review__summary__main {
  width: 100%;
  display: flex;
}
.c-review__summary__star {
  width: 100%;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 16px;
}
.c-review__summary__star__list {
}
.c-review__summary__star__list__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-review__summary__star__list__item + .c-review__summary__star__list__item {
  margin-top: 8px;
}
.c-review__summary__star__list__item__name {
  width: 48px;
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
}
.c-review__summary__star__list__item__bar {
  width: 100%;
  height: 12px;
  background: #ddd;
  position: relative;
  margin: 0px;
}
.c-review__summary__star__list__item__bar span {
  height: 100%;
  background: #0094cc;
  position: absolute;
  top: 0;
  left: 0;
}
.c-review__summary__star__list__item__num {
  width: 56px;
  font-size: 14px;
  color: #888;
  flex-shrink: 0;
  margin-left: 8px;
}
.c-review__summary__type {
  width: 100%;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 16px;
}
.c-review__summary__type__list {
}
.c-review__summary__type__list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-review__summary__type__list__item.disabled {
  opacity: 0.4;
}
.c-review__summary__type__list__item + .c-review__summary__type__list__item {
  margin-top: 8px;
}
.c-review__summary__type__list__item__name {
  width: 80px;
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
}
.c-review__summary__type__list__item__stars {
  margin: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.c-review__summary__type__list__item__stars img {
  width: 16px;
}
.c-review__summary__type__list__item__num {
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
}
.c-review__summary__type__list__item__num span {
  color: #888;
  font-weight: normal;
  margin-left: 4px;
}

/*-- search --*/
.c-review__search__ttl {
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-review__search__ttl img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.c-review__search__main {
}
.c-review__search__main__inner {
  display: flex;
  justify-content: flex-start;
}
.c-review__search__main__item {
  width: 100%;
}
.c-review__search__main__item + .c-review__search__main__item {
  margin-left: 16px;
}
.c-review__search__main__item__ttl {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
p:first-child.c-review__search__main__item__ttl {
  margin-bottom: 8px;
}
.c-review__search__main__item__checkbox {
  width: 100%;
}
.c-review__search__main__item__checkbox label {
  width: 100%;
  min-height: 40px;
  background: #fff;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-review__search__main__item__checkbox label + label {
  margin-top: 8px;
}
.c-review__search__main__item__checkbox input {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}
.c-review__search__main__item__checkbox p {
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 2px;
  background: #fff;
  flex-shrink: 0;
  margin-right: 8px;
  margin-bottom: 0px;
  position: relative;
}
.c-review__search__main__item__checkbox p::after {
  content: '';
  width: 4px;
  height: 8px;
  border-right: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 4px;
  box-sizing: initial;
}
.c-review__search__main__item__checkbox span {
  position: relative;
  font-size: 14px;
}
.c-review__search__main__item__checkbox input:checked {
  border-color: #0094cc;
}
.c-review__search__main__item__checkbox input:checked ~ p {
  border-color: #0094cc;
  background: #0094cc;
}
.c-review__search__main__item__checkbox input:checked ~ p::after {
  border-right-color: #fff;
  border-bottom-color: #fff;
}
.c-review__search__main__item__checkbox input:disabled {
  opacity: 0.4;
}
.c-review__search__main__item__checkbox input:disabled ~ p {
  opacity: 0.4;
}
.c-review__search__main__item__checkbox input:disabled ~ span {
  opacity: 0.2;
}
.c-review__search__main__item__checkbox__stars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.c-review__search__main__item__checkbox__stars img {
  width: 16px;
}
.c-review__search__main__item__inputText {
  width: 100%;
  min-height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-indent: 8px;
}
.c-review__search__main__item__select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #ddd;
  display: block;
  position: relative;
}
.c-review__search__main__item__select::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 3px);
  right: 8px;
}
.c-review__search__main__item__select select {
  width: 100%;
  height: 100%;
  padding: 0 24px 0 8px;
  cursor: pointer;
}
.c-review__search__main__item__flex {
  display: flex;
  align-items: center;
}
.c-review__search__main__item__flex > * + * {
  margin-left: 8px;
}
.c-review__search__main__item__col + .c-review__search__main__item__col {
  margin-top: 16px;
}
.c-review__search__main__submit {
  width: 160px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #0094cc;
  border-radius: 8px;
  margin: 16px auto 0 auto;
  display: block;
  cursor: pointer;
}
.c-review__search__main__submit:disabled {
  background: #cfdae1 !important;
}
.c-review__search__main__submit.loading {
  position: relative;
  cursor: default;
  /* text-shadow: none!important; */
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}
.c-review__search__main__submit.loading:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}
.c-review__search__main__submit.loading:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}

@keyframes button-spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/*-- ac --*/
.c-review__summary__total.ac,
.c-review__search__ttl.ac {
  background: #f9f9f9;
}
.c-review__summary__total.ac.is-open,
.c-review__search__ttl.ac.is-open {
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #eee;
}
.c-review__summary__total.ac.is-close,
.c-review__search__ttl.ac.is-close {
  border-radius: 4px;
}
.c-review__summary__total.ac .c-review__close,
.c-review__search__ttl.ac .c-review__close {
  width: 16px;
  height: 16px;
  margin-left: auto;
  position: relative;
}
.c-review__summary__total.ac .c-review__close::before,
.c-review__summary__total.ac .c-review__close::after,
.c-review__search__ttl.ac .c-review__close::before,
.c-review__search__ttl.ac .c-review__close::after {
  content: '';
  display: block;
  width: 16px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
}
.c-review__summary__total.ac.is-open .c-review__close::before,
.c-review__search__ttl.ac.is-open .c-review__close::before {
  background: #aaa;
}
.c-review__summary__total.ac.is-open .c-review__close::after,
.c-review__search__ttl.ac.is-open .c-review__close::after {
  display: none;
}
.c-review__summary__total.ac.is-close .c-review__close::before,
.c-review__search__ttl.ac.is-close .c-review__close::before {
  background: #0094cc;
}
.c-review__summary__total.ac.is-close .c-review__close::after,
.c-review__search__ttl.ac.is-close .c-review__close::after {
  background: #0094cc;
  transform: rotate(90deg);
}

.c-review__summary__main.ac.is-open,
.c-review__search__main.ac.is-open {
  background: #f9f9f9;
  padding: 16px;
}
.c-review__summary__main.ac.is-open .c-review__summary__type {
  margin-left: 16px;
}
.c-review__summary__main.ac.is-close,
.c-review__search__main.ac.is-close {
  display: none;
}
.c-review__summary__main.ac > * {
  background: #fff;
}

/*-- comment --*/
.c-review__list {
  padding-bottom: 1px;
}
.c-review__list__box {
  min-height: 300px;
}
.c-review__list__comment {
  padding: 16px;
}
.c-review__list__comment__item {
  width: 100%;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 16px;
}
.c-review__list__comment__item + .c-review__list__comment__item {
  margin-top: 16px;
}
.c-review__list__comment__item__top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-review__list__comment__item__top__review {
  display: flex;
  align-items: center;
}
.c-review__list__comment__item__top__review__stars {
  display: flex;
}
.c-review__list__comment__item__top__review__stars img {
  width: 16px;
  height: 16px;
}
.c-review__list__comment__item__top__review__num {
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
}
.c-review__list__comment__item__top__info__status {
  margin-bottom: 8px;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.c-review__list__comment__item__top__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
}
.c-review__list__comment__item__top__info__date {
  font-size: 14px;
}
.c-review__list__comment__item__top__info__type {
  min-width: 80px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #0094cc;
  border: 1px solid #0094cc;
  border-radius: 4px;
  padding: 2px 4px;
  margin-left: 16px;
}
.c-review__list__comment__item__ttl {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 4px 0 16px 0;
  margin-bottom: 16px;
}
.c-review__list__comment__item__message,
.c-review__list__comment__item__reply__message {
  width: 100%;
  font-size: 14px;
}
.c-review__list__comment__item__message.is-close,
.c-review__list__comment__item__reply__message.is-close {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.c-review__list__comment__item__more,
.c-review__list__comment__item__reply__more {
  width: 100%;
  display: flex;
}
.c-review__list__comment__item__more a,
.c-review__list__comment__item__reply__more a {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  color: #0094cc;
  text-decoration: underline;
  margin-left: auto;
}
.c-review__list__comment__item__pic {
  width: 100%;
  margin-top: 16px;
  display: flex;
  position: relative;
  transform: translate3d(0px, 0, 0);
}
.c-review__list__comment__item__pic__list {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}
.c-review__list__comment__item__pic__list li {
  width: 160px;
  height: 160px;
  margin-right: 16px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}
.c-review__list__comment__item__pic__list img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.c-review__list__comment__item__pic__prev,
.c-review__list__comment__item__pic__next {
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  z-index: 1;
  background: #fff;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 100%;
}
.c-review__list__comment__item__pic__prev::after,
.c-review__list__comment__item__pic__next::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-left: 2px solid #0094cc;
  border-bottom: 2px solid #0094cc;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 5px);
  left: 13px;
}
.c-review__list__comment__item__pic__prev {
  left: -16px;
}
.c-review__list__comment__item__pic__next {
  transform: scale(-1, 1);
  right: -16px;
}
.c-review__list__comment__item__user {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
p:first-child.c-review__list__comment__item__user__ic {
  margin-right: 8px;
}
.c-review__list__comment__item__user__ic {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #ddd;
  font-size: 14px;
  font-weight: bold;
  color: #0094cc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 8px;
}
.c-review__list__comment__item__user__info {
}
p:first-child.c-review__list__comment__item__user__info__name {
  margin-bottom: 4px;
}
.c-review__list__comment__item__user__info__name {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 4px;
}
p:last-child.c-review__list__comment__item__user__info__date {
  margin: 0px;
}
.c-review__list__comment__item__user__info__date {
  font-size: 12px;
  line-height: 1;
  color: #888;
}
.c-review__list__comment__item__reply {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 16px;
  padding-top: 16px;
}
.c-review__list__comment__item__reply__box {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 16px;
}
.c-review__list__comment__item__reply__ttl {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.c-review__list__btn {
  width: 240px;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: #0094cc;
  margin: 0 auto 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-review__list__btn.loading {
  position: relative;
  cursor: default;
  /* text-shadow: none!important; */
  color: transparent !important;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}
.c-review__list__btn.loading:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}
.c-review__list__btn.loading:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}

.c-review__modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.c-review__modal.is-active {
  display: block;
}
.c-review__modal__content {
  width: 100%;
  max-width: 800px;
  height: calc(100vh - 64px);
  background: #fff;
  border-radius: 8px;
  margin: 32px auto;
}
.c-review__modal__content__header {
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #ddd;
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-review__modal__content__header__ttl {
  font-size: 22px;
  font-weight: bold;
}
.c-review__modal__content__header__close {
  width: 32px;
  height: 32px;
  background: #f9f9f9;
  border-radius: 4px;
  position: relative;
  margin-left: auto;
}
.c-review__modal__content__header__close::before,
.c-review__modal__content__header__close::after {
  content: '';
  width: 16px;
  height: 2px;
  background: #0094cc;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}
.c-review__modal__content__header__close::before {
  transform: rotate(45deg);
}
.c-review__modal__content__header__close::after {
  transform: rotate(-45deg);
}
.c-review__modal__content__body {
  width: 100%;
  height: calc(100vh - 64px - 56px);
  overflow-y: auto;
}
.c-reviewPicture__modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-reviewPicture__modal__modal__content {
  position: relative;
}
.c-reviewPicture__modal__modal__content__pic {
}
.c-reviewPicture__modal__modal__content__pic img {
  max-height: 480px;
  object-fit: contain;
}
.c-reviewPicture__modal__modal__content__close {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  position: absolute;
  top: 0;
  right: -40px;
}
.c-reviewPicture__modal__modal__content__close::before,
.c-reviewPicture__modal__modal__content__close::after {
  content: '';
  width: 16px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}
.c-reviewPicture__modal__modal__content__close::before {
  transform: rotate(45deg);
}
.c-reviewPicture__modal__modal__content__close::after {
  transform: rotate(-45deg);
}
.c-review__list__comment__item__tooltip__info {
  display: inline-block;
  position:relative;
}
.c-review__list__comment__item__tooltip__info__body {
  width: 150px;
  font-size: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 12px;
  position: absolute;
  top: 40px;
  left: -80px;
  z-index: 10;
  display: none;
}
.c-review__list__comment__item__tooltip__info:hover  .c-review__list__comment__item__tooltip__info__body{
  display: block;
}

@media screen and (max-width: 768px) {
  .c-review__summary__total__ttl {
    font-size: 14px;
  }
  .c-review__summary__total__stars {
    margin: 0 8px;
  }
  .c-review__summary__total__num {
    font-size: 20px;
  }
  .c-review__summary__total__num span {
    font-size: 16px;
  }
  .c-review__summary__main {
    flex-direction: column;
  }
  .c-review__summary__main.ac.is-open .c-review__summary__type {
    margin-left: 0;
    margin-top: 16px;
  }
  .c-review__search__main__inner {
    flex-direction: column;
  }
  .c-review__search__main__item + .c-review__search__main__item {
    margin-left: 0;
    margin-top: 16px;
  }
  .c-review__list__comment__item {
    padding: 16px 8px;
  }
  .c-review__list__comment__item__top__info {
    margin-left: 8px;
  }
  .c-review__list__comment__item__ttl {
    font-size: 16px;
    padding: 4px 0 8px 0;
    margin-bottom: 8px;
  }
  .c-review__list__comment__item__pic__list li {
    width: 96px;
    height: 96px;
  }
  .c-review__list__comment__item__reply__box {
    padding: 16px 8px;
  }
  .c-review__modal__content {
    height: 100vh;
    border-radius: 0;
    margin: 0;
  }
  .c-review__modal__content__body {
    height: calc(100vh - 56px);
  }
  .c-reviewPicture__modal__modal__content {
    padding: 16px;
  }
  .c-reviewPicture__modal__modal__content__pic img {
    width: 100%;
  }
  .c-reviewPicture__modal__modal__content__close {
    top: -24px;
    right: 16px;
  }
}
