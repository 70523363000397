.p-download__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-download__header__title {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
}
.p-downloadModal {
}
.p-downloadModal__item:not(:first-child) {
  margin-top: 20px;
}
.p-downloadModal__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  margin-bottom: 6px;
}
.p-downloadModal__item__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-downloadModal__item__flex > div {
  width: 100%;
  flex-shrink: 1;
}
.p-downloadModal__item__flex > div:not(:first-child) {
  margin-left: 20px;  
}
.p-downloadModal__item__body {

}
.p-downloadModal__item__body__check {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.p-downloadModal__item__body__check > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-right: 16px;
  flex-shrink: 0;
}
.p-downloadModal__item__body__check > div {
  display: flex;
  justify-content: flex-start;
}
.p-downloadModal__item__body__check > div > * {
  margin-right: 16px;
}
.p-downloadModal__select {
  width: fit-content;
  min-height: 44px;
  position: absolute;
  top: 12px;
  right: 8px;
}
.p-downloadModal__select__body {
  width: 100%;
  height: 100%;
  padding: 8px 40px 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.p-downloadModal__select__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.p-downloadModal__select__body__title {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-downloadModal__select__menu {
  width: 100%;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-downloadModal__select__menu.is-active {
  display: block;
}
.p-downloadModal__select__menu__item {
  padding: 8px 10px;
  position: relative;
}
.p-downloadModal__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-downloadModal__select__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.p-downloadModal__select__menu__item i {
  opacity: 0;
}
.p-downloadModal__select__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.p-downloadModal__select__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.p-downloadModal__select__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}

/* Error and disabled state */
.is-error {
  color: var(--error500);
  border-color: var(--error300);
  background-color: var(--error50);
}
.is-disabled {
  color: var(--gray300);
  pointer-events: none;
}
.is-disabled *::before {
  color: var(--gray300);
}
