.picture {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.picture__item {
  padding-right: 8px;
  padding-bottom: 8px;
}
.picture__item__pic {
  width: 160px;
  height: 160px;
  margin-top: 12px;
  margin-right: 12px;
  position: relative;
}
.picture__item__pic img {
  object-fit: contain;
  width: inherit;
  height: inherit;
}
.price {
  font-size: 1em;
}
