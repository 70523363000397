.overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 10000001 !important;
}

.chatModal {
  border-radius: 20px 20px 0 0;
  border: 1px solid var(--colour-12, #f3f6f8);
  background: var(--white, #fff);
  box-shadow: 0 2px 28.2px 0 rgba(28, 144, 250, 0.15);
  display: flex;
  width: 450px;
  flex-direction: column;
  font-family: Inter, sans-serif;
  padding: 16px;

  position: absolute;
  bottom: 0;
  right: 0;

  height: 600px;
  max-height: 600px;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #f3f6f8;
  border-radius: 50%;
}
.closeButton.clicked {
  background-color: #f3f6f8;
}
.closeIcon {
  width: 24px;
  height: 24px;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.conciergeIconArea {
  padding: 12px 16px;
}

.loadingArea {
  padding: 12px 0;
}

.messagesList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  padding: 8px;

  overflow-y: auto;
}

/*
.messageContainer {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
*/

/*
.userMessage {
  justify-content: flex-end;
}
*/

.messageContent {
  border-radius: 10px;
  background: var(--white, #fff);
  font-size: 14px;
  line-height: 25px;
  color: var(--colour-10, #393d3f);

  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.messageContent a {
  color: blue;
}

/*
.userMessageContent {
  background: var(--grey, #e0e0e0);
}
*/

.avatar {
  border-radius: 100px;
  border: 1px solid var(--colour-12, #f3f6f8);
  background: var(--white, #fff);
  display: flex;
}

.avatarSmall {
  width: 36px;
  height: 36px;
}

.avatarMedium {
  width: 40px;
  height: 40px;
}

.avatarLarge {
  width: 48px;
  height: 48px;
}

.inputContainer {
  display: flex;
  align-items: center;
  border-radius: 80px;
  border: 1px solid var(--colour-12, #f3f6f8);
  background: var(--white, #fff);
  padding: 4px 4px 4px 16px;
  margin-top: 16px;
  gap: 8px;
}

.messageInput {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  resize: none;
  padding: 8px 0;
  min-height: 24px;
  max-height: 150px;
  line-height: 20px;
  font-family: inherit;
  background: transparent;
  transition: height 0.2s ease;
}

.sendButton {
  border-radius: 100px;
  border: 1px solid var(--colour-12, #f3f6f8);
  background: var(--color-15, #3aa0ff);
  width: 36px;
  height: 36px;
  cursor: pointer;
  color: #fff;
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media (max-width: 768px) {
  .chatModal {
    border-radius: 20px 20px 0 0;
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 80px);
    max-height: none;
  }
}

.messageContainer {
  margin: 8px 0;
  display: flex;
  align-items: flex-start;
}

.userMessage {
  justify-content: flex-end;
}

.botMessage {
  justify-content: flex-start;
}

.userMessageContent {
  padding: 12px 16px;
  border-radius: 24px;
  background-color: #f3f6f8;
  color: #000;
}

.botMessageContent {
  color: #000;
}

.userAvatar,
.botAvatar {
  width: 32px;
  height: 32px;
}

/* Stack Component Styles */
.stack {
  display: flex;
  width: 100%;
}

/* Typography Component Styles */
.typography {
  font-size: 14px;
  line-height: 25px;
  color: #333;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}

.container {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
}

.loader {
  width: 20px;
}
