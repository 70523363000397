.fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.language-menu-items-header {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
}
