.c-photo {
  width: 100%;
  position: relative;
}
.c-photo__main {
  position: relative;
  width: 100%;
  transform: translate3d(0px, 0, 0);
  overflow: hidden;
  border-radius: 10px;
}
.c-photo__main__list {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
}
.c-photo__main__list__item {
  width: 100%;
  height: 480px;
  background: #eee;
  flex-shrink: 0;
  transition: transform 500ms;
}
.c-photo__main__list__item img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.c-photo__main__prev,
.c-photo__main__next {
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  z-index: 1;
  background: #fff;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 100%;
}
.c-photo__main__prev {
  left: 8px;
}
.c-photo__main__next {
  transform: scale(-1, 1);
  right: 8px;
}
.c-photo__main__prev::after,
.c-photo__main__next::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-left: 2px solid #0094cc;
  border-bottom: 2px solid #0094cc;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 5px);
  left: 13px;
}

.c-photo__thumbs {
  display: flex;
  position: relative;
  width: 100%;
  background: var(--white);
  transform: translate3d(0px, 0, 0);
  margin-top: 4px;
}
.c-photo__thumbs__list {
  width: calc(100% - 24px - 24px);
  margin: 4px 24px;
  display: flex;
  position: relative;
  z-index: 1;
}
.c-photo__thumbs__list__item {
  width: 100px;
  height: 60px;
  margin-left: 4px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  transition: transform 500ms;
}
.c-photo__thumbs__list__item.is-active::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}
.c-photo__thumbs__list__item img {
  width: 100px;
  height: inherit;
  object-fit: cover;
}
.c-photo__thumbs__list__item i {
  font-size: 20px;
  color: var(--bg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.c-photo__thumbs__prev,
.c-photo__thumbs__next {
  cursor: pointer;
  width: 24px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  z-index: 1;
}
.c-photo__thumbs__prev {
  left: 0;
}
.c-photo__thumbs__next {
  right: 0;
  transform: scale(-1, 1);
}
.c-photo__thumbs__prev::after,
.c-photo__thumbs__next::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-left: 2px solid #0094cc;
  border-bottom: 2px solid #0094cc;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 5px);
  left: 10px;
}
@media screen and (max-width: 768px) {
  .c-photo__main__list__item {
    width: 100%;
    height: 224px;
  }
}
