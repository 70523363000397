.page-tutorial__popupFrame {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
}
.page-tutorial__popupFinish {
  width: calc(100% - 32px);
  max-width: 640px;
  border-radius: 8px;
  background: #fff;
  margin: 80px auto 0 auto;
  z-index: 11;
}
.page-tutorial__popupFinish__header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #cfdae1;
  padding: 16px;
}
.page-tutorial__popupFinish__body {
  padding: 16px;
}
.page-tutorial__popupFinish__body__pic {
  text-align: center;
}
.page-tutorial__popupFinish__body__pic img {
  height: 128px;
}
.page-tutorial__popupFinish__body__desc {
  font-size: 16px;
  text-align: center;
  padding: 16px 0;
}
.page-tutorial__box {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #f9f9f9;
  padding: 40px 0;
  position: relative;
}
.page-tutorial__box__left {
  background: #fff;
  padding: 16px;
  position: relative;
}
.page-tutorial__box__left {
}
.page-tutorial__box__left__next {
  width: 240px;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  background: #ffaaaa;
  margin-bottom: 8px;
}
.page-tutorial__box__left__desc {
  font-size: 20px;
  font-weight: bold;
  color: #008ec9;
}
.page-tutorial__box__right {
}
.page-tutorial__box::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 64px 0 0;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.page-tutorial__box::after {
  content: attr(data-num) '';
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.page-tutorial__box__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__box__pic {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background: #ebf1f3;
  margin-right: 16px;
  flex-shrink: 0;
}
.page-tutorial__box__pic img {
  width: inherit;
  height: inherit;
}
.page-tutorial__box__body {
}
.page-tutorial__box__body__ttl {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.page-tutorial__box__body__btn a {
  background: #cfdae1 !important;
  pointer-events: none;
}
.page-tutorial__box__body__next {
  width: 64px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  background: #ffaaaa;
  margin-top: 10px;
  position: relative;
  opacity: 0;
}
.page-tutorial__box__body__next::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #ffaaaa transparent;
  position: absolute;
  top: -6px;
  left: calc(50% - 6px);
}
.page-tutorial__box.is-active {
  border: 1px solid #008ec9;
}
.page-tutorial__box.is-active a {
  background: #ffcc00 !important;
  pointer-events: auto;
}
.page-tutorial__box.is-active .page-tutorial__box__body__next {
  opacity: 1;
}
.page-tutorial__box.is-finish {
  opacity: 0.5;
}

.page-tutorial__box--step2 {
  border: 4px solid #008ec9 !important;
  padding: 40px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__box--step2 .page-tutorial__box__left {
  width: 50%;
  max-width: 560px;
  margin-right: 64px;
}
.page-tutorial__box--step2 .page-tutorial__box__left::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent #008ec9;
  position: absolute;
  top: calc(50% - 16px);
  right: -40px;
}
.page-tutorial__box--step2 .page-tutorial__box__right {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.page-tutorial__box--step3 {
  border: 4px solid #008ec9 !important;
  padding: 16px;
}
.page-tutorial__box--step3 .page-tutorial__box__left {
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page-tutorial__box--step3 .page-tutorial__box__left::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 16px;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  bottom: -24px;
  left: calc(50% - 16px);
}
.page-tutorial__box--step3 .page-tutorial__box__right {
}
@media screen and (max-width: 768px) {
  .page-tutorial__popupFinish {
    margin-top: 16px;
  }
  .page-tutorial__popupFinish__body {
    padding: 16px 8px;
  }
}
