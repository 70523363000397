/* Override default 'react-table' style to allow dropdown filter */
.ReactTable .rt-thead .rt-th {
  overflow: visible;
}

.product-list-edit-column {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-right: 0;
}
.product-list-edit-column img {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .product-list-edit-column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    border-radius: 4px !important;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    cursor: pointer;
  }
  .product-list-edit-column a::before {
    content: none !important;
  }
  .product-list-edit-column img {
    margin-right: 8px;
  }
}
