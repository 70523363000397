.c-tag {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.c-tag li {
  font-size: 12px;
  color: #333;
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 2px 4px;
  margin-bottom: 4px;
  margin-right: 4px;
}
@media screen and (max-width: 768px) {
  .c-tag {
    margin-top: 0;
    margin-bottom: 16px;
    padding: 0 16px;
  }
}
