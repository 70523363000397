.page-tutorial__ttl {
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 8px !important;
  font-size: 17px;
}
.page-home-tutorial {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 32px;
}
.page-home-tutorial__ttl {
  width: 280px;
  height: 40px;
  border: 1px solid #008ec9;
  font-size: 20px;
  font-weight: bold;
  display: table;
  margin: 0 auto 32px auto;
}
.page-home-tutorial__ttl p:first-child {
  width: 200px;
  color: #fff;
  background: #008ec9;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.page-home-tutorial__ttl p:last-child {
  color: #008ec9;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .page-home-tutorial {
    padding: 16px;
    margin-top: 16px;
    border: none;
  }
  .page-home-tutorial__ttl {
    margin-bottom: 16px;
  }
}
/*------------------------------------------------*/
.page-tutorial__samplesite {
  margin-top: 16px;
}
.page-tutorial__samplesite__block {
  width: 100%;
  margin-bottom: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.page-tutorial__samplesite__block p {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
}
.page-tutorial__samplesite__mobile {
  display: flex;
}
@media screen and (max-width: 768px) {
  .page-tutorial__samplesite__mobile {
    flex-wrap: wrap;
  }
  .page-tutorial__samplesite__block {
    margin-right: 0;
  }
}
