.loginPage .main-content {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  margin-top: 20px;
}

.loginPage .main-text {
  width: 300px;
}

.loginPage .email-box,
.password-box,
.recovery-code-box,
.new-password-box {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.input-box + .input-box {
  margin-top: 16px;
}
.button-box + .button-box {
  margin-left: 8px;
}

/*
.loginPage .main-text input {
  width: 100%;
  margin-top: 10px;
  background: transparent;
  border-style: solid;
  border-color: black;
  border-bottom-width: 1px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
*/

.loginPage .main-text .sub-text {
  cursor: pointer;
  user-select: none;
  color: gray;
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 1rem;
  font-weight: normal;
}

.loginPage .main-text .sub-text:hover {
  color: red;
}

.loginPage .main-text .input-box .password-box-strikethrough {
  margin-top: -1.7rem;
  height: 2px;
  background: black;

  animation: forget-password-strikethrough-animation normal 0.3s linear;
  animation-fill-mode: forwards;
}

.loginPage .button-area {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@keyframes forget-password-strikethrough-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.loginPage .main-text .recovery-code-box {
  margin-top: 30px;
  opacity: 0;

  animation: forget-password-gradual-appear normal 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.loginPage .main-text .new-password-box {
  opacity: 0;
  animation: forget-password-gradual-appear normal 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.loginPage .main-text .notify-box {
  text-align: left;
  opacity: 0;

  width: 100%;
  margin: auto;
  margin-top: 10px;

  animation: forget-password-gradual-appear normal 0.5s linear;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

@keyframes forget-password-gradual-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loginPage .main-text .error-message {
  margin-top: 30px;
  width: 100%;
  text-align: center;
  animation: error-message-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes error-message-shake {
  10%,
  90% {
    transform: translateX(-1px);
  }
  20%,
  80% {
    transform: translateX(2px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }
  40%,
  60% {
    transform: translateX(4px);
  }
}
.loginPage .positive-button {
  width: 120px;
}

@media screen and (max-width: 768px) {
  .loginPage .main-content {
    padding-top: 80px;
  }

  .loginPage .main-text {
    width: 280px;
  }
}
