.color-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 110px;
}

.color-box input[type="checkbox"] {
  display: none;
}

/* カスタムチェックボックスのデザイン */
.color-box label {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 10px;
  line-height: 20px;
}

/* ボックスのデザイン */
.color-box label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #00f;
  background-color: #fff;
  border-radius: 50%;
}

.color-box input[type="checkbox"]:checked + label:before {
  background-color: #00f;
}

.color-pane {
  width: 60px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-custom-pane {
  width: 60px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
