.c-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 64px 16px 32px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.c-modal__content {
  min-width: 560px;
  background-color: var(--white);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-xs);
  margin: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.c-modal__content__header {
  border-bottom: 1px solid var(--gray200);
  padding: 20px 24px;
  flex-shrink: 0;
  align-items: center;
}
.c-modal__content__header__actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.c-modal__content__header > div {
  padding-right: 8px;
}
.c-modal__content__header > button {
  margin-left: auto;
}
.c-modal__content__header p {
  font-size: var(--text-lg-size);
  font-weight: var(--text-bold);
  line-height: var(--text-lg-height);
  color: var(--gray900);
}
.c-modal__content__header span {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  border-radius: none;
  border: none;
  padding: 0;
  background-color: inherit;
}
.c-modal__content__body {
  padding: 20px 24px;
  height: 100%;
  overflow-y: auto;
}
.c-modal__content__body.front {
  overflow-y: visible;
}
.c-modal__content__actions {
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  flex-shrink: 0;
}
.c-modal__content__actions__left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-modal__content__actions__left > *:not(:first-child) {
  margin-left: 12px;
}
.c-modal__content__actions__right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.c-modal__content__actions__right > *:not(:last-child) {
  margin-right: 12px;
}
@media screen and (max-width: 768px) {
  .c-modal {
    padding: 32px 16px;
    height: 100% !important;
  }
  .c-modal__nav__is-open,
  .c-modal__nav__is-closed {
    width: 100%;
    left: 0;
  }
  .c-modal__content {
    min-width: 0;
    width: 100% !important;
  }
}

/* Target large screen */
@media screen and (min-height: 800px) and (min-width: 1200px) {
  .c-modal {
    padding: 16px;
  }

  .c-modal__content {
    max-width: 1000px;
    max-height: 800px;
  }
}
