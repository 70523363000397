.table-header-container {
  position: relative;
  display: inline-block;
  /* position: relative; */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
