.priceSelect {
  width: 100%;
  flex-wrap: wrap;
}
.priceSelect > li {
  width: 240px;
  margin-right: 16px;
  margin-left: 0px;
  margin-bottom: 8px;
  position: relative;
}
.priceSelect .base-info {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.priceSelect .tooltip-box {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
@media screen and (max-width: 768px) {
  .priceSelect {
    flex-direction: column;
    width: 100%;
  }
  .priceSelect li {
    width: 100%;
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
  }
}
