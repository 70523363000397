.c-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination > *:not(:last-child) {
  margin-right: 12px;
}
.c-pagination__cnt {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.c-pagination__select {
  width: 120px;
  min-height: 36px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
  cursor: pointer;
}
.c-pagination__select label {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  box-shadow: var(--shadow-xs);
  padding: 8px 12px;
  display: block;
  position: relative;
  min-width: 110px;
}
.c-pagination__select label::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--gray700);
  content: '\b450';
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.c-pagination__select ul {
  width: 100%;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  bottom: 40px;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.c-pagination__select ul.is-active {
  display: block;
}
.c-pagination__select li {
  padding: 8px 10px;
  position: relative;
}
.c-pagination__select li:hover {
  background-color: var(--gray50);
}
.c-pagination__select li input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.c-pagination__select li input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.c-pagination__select li input:checked + i::before {
  color: var(--primary600);
  content: '\e914';
}
.c-pagination__select li i {
  opacity: 0;
}
.c-pagination__select li p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}
.c-pagination__btn {
  width: 36px;
  height: 36px;
  color: var(--gray700);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-xs);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}
.c-pagination__btn i::before {
  font-size: 20px;
}
.c-pagination__btn.is-disabled {
  color: var(--gray300);
  pointer-events: none;
}
.c-pagination__select.is-disabled {
  color: var(--gray300);
  pointer-events: none;
  background-color: var(--gray50);
}
.c-pagination__select.is-disabled ::before {
  color: var(--gray300);
}
