/* New design CSS here since we don't want to mess up the existing one */
.p-calendarWeekly {
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
}
.p-calendarWeekly__check {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
}
.p-calendarWeekly__sub {
  width: 60px;
  flex-shrink: 0;
  position: sticky !important;
  left: 0;
  z-index: 2;
}
.p-calendarWeekly__time {
  background-color: var(--gray50);
}
.p-calendarWeekly__time__header {
  height: 104px;
  border-right: 1px solid var(--gray200);
}
.p-calendarWeekly__time__item {
  height: 160px;
  border-right: 1px solid var(--gray200);
  position: relative;
}
.p-calendarWeekly__time__item p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  font-weight: var(--text-normal);
  color: var(--gray600);
  text-align: right;
  position: absolute;
  top: -8px;
  right: 8px;
}

.p-calendarWeekly__main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.p-calendarWeekly__item {
  width: calc(100% / 7);
  flex-shrink: 0;
  position: relative;
}
.p-calendarWeekly__item__header {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  border-right: 1px solid var(--gray200);
  background-color: var(--gray50);
  text-align: center;
  /* position: sticky !important; */
  top: 0;
  z-index: 2;
}
.p-calendarWeekly__item__header__main {
  height: 64px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray700);
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray200);
  position: relative;
}
.p-calendarWeekly__item__header__main__day {
  font-weight: var(--text-semibold);
}
.p-calendarWeekly__item__header__main__total {
  margin-top: 8px;
}
.p-calendarWeekly__item__header__main__total span {
  color: var(--gray500);
  margin-right: 4px;
}
.p-calendarWeekly__item__header_actions {
  height: 40px;
  border-bottom: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
}
.p-calendarWeekly__item__header_actions a {
  font-weight: var(--text-semibold);
  color: var(--primary700);
}
.p-calendarWeekly__item__body {
  width: 100%;
  height: calc(100% - 104px);
  position: relative;
}
.p-calendarWeekly__item__body__cells {
  width: 100%;
  height: 100%;
  position: relative;
}
.p-calendarWeekly__item__body__cells li {
  height: 160px;
  border-bottom: 1px solid var(--gray200);
  border-right: 1px solid var(--gray200);
}
.p-calendarWeekly__item__body__cells li:last-child {
  border-bottom: none;
}
.p-calendarWeekly__item__body__scheds {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  top: 4px;
  left: 4px;
}
.p-calendarWeekly__item__body__scheds > a {
  white-space: nowrap;
  position: absolute;
  z-index: 1;
}
.p-calendarWeekly__item:last-child .p-calendarWeekly__item__body__cells li {
  border-right: none;
}
.p-calendarWeekly__line {
  width: calc(100% / 7);
  height: 12px;
  margin-top: -6px;
  display: block;
  position: absolute;
  z-index: 1;
}
.p-calendarWeekly__line::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: var(--error500);
  box-shadow: var(--shadow-sm);
  position: absolute;
  top: 0;
  left: 0;
}
.p-calendarWeekly__line::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: var(--error500);
  box-shadow: var(--shadow-sm);
  position: absolute;
  top: 5px;
  left: 0;
}
@media screen and (max-width: 768px) {
  .p-calendarWeekly__item {
    width: 160px;
  }
}

.p-calendarMonthly {
  position: relative;
}
.p-calendarMonthly__header {
  width: 100%;
  height: 40px;
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  border-top: 1px solid var(--gray200);
  border-bottom: 1px solid var(--gray200);
  background-color: var(--gray50);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
}
.p-calendarMonthly__header li {
  width: 100%;
  flex-shrink: 1;
  text-align: center;
}
.p-calendarMonthly__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.p-calendarMonthly__body__item {
  width: calc(100% / 7);
  min-height: 200px;
  border-top: 1px solid var(--gray200);
}
.p-calendarMonthly__body__item.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.p-calendarMonthly__body__item:nth-child(1),
.p-calendarMonthly__body__item:nth-child(2),
.p-calendarMonthly__body__item:nth-child(3),
.p-calendarMonthly__body__item:nth-child(4),
.p-calendarMonthly__body__item:nth-child(5),
.p-calendarMonthly__body__item:nth-child(6),
.p-calendarMonthly__body__item:nth-child(7) {
  border-top: none;
}
.p-calendarMonthly__body__item:not(:nth-child(8n-1)) {
  border-right: 1px solid var(--gray200);
}
.p-calendarMonthly__body__item:nth-child(8n) {
  width: 100%;
  min-height: 44px;
  border-right: none;
  padding: 8px;
}
.p-calendarMonthly__body__item:nth-child(8n) > a {
  width: 100%;
}
.p-calendarMonthly__body__item__top {
  width: 100%;
  height: 44px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  border-bottom: 1px solid var(--gray200);
  padding: 12px 16px;
}
.p-calendarMonthly__body__item__top__week {
}
.p-calendarMonthly__body__item__top__date {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  margin-bottom: 0;
}
.p-calendarMonthly__body__item__top__total {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 16px;
}
.p-calendarMonthly__body__item__top__total span {
  color: var(--gray900);
  display: block;
}
.p-calendarMonthly__body__item__bottom {
  padding: 4px;
}
.p-calendarMonthly__body__item__bottom > li:not(:first-child) {
  margin-top: 4px;
}
.p-calendarMonthly__body__item__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 44px);;
}
@media screen and (max-width: 768px) {
  .p-calendarMonthly {
    border-top: 1px solid var(--gray200);
  }
  .p-calendarMonthly__body {
    flex-direction: column;
  }
  .p-calendarMonthly__body__item {
    width: 100%;
    min-height: auto;
    display: flex;
  }
  .p-calendarMonthly__body__item.disabled {
    display: none;
  }
  .p-calendarMonthly__body__item:nth-child(1),
  .p-calendarMonthly__body__item:nth-child(2),
  .p-calendarMonthly__body__item:nth-child(3),
  .p-calendarMonthly__body__item:nth-child(4),
  .p-calendarMonthly__body__item:nth-child(5),
  .p-calendarMonthly__body__item:nth-child(6),
  .p-calendarMonthly__body__item:nth-child(7) {
    border-top: 1px solid var(--gray200);
  }
  .p-calendarMonthly__body__item:not(:nth-child(8n-1)) {
    border-right: none;
  }
  .p-calendarMonthly__body__item:nth-child(8n) {
    display: none;
  }
  .p-calendarMonthly__body__item__top {
    width: 80px;
    height: auto;
    background-color: var(--gray50);
    border-right: 1px solid var(--gray200);
    border-bottom: none;
    flex-shrink: 0;
    text-align: center;
  }
  .p-calendarMonthly__body__item__top__date {
    font-size: var(--text-xl-size);
    font-weight: var(--text-medium);
    line-height: var(--text-xl-height);
    margin-top: 4px;
  }
  .p-calendarMonthly__body__item__bottom {
    width: 100%;
    padding: 16px;
  }
  .p-calendarMonthly__body__item__bottom > li > a {
    width: 100%;
  }
}

.p-availabilityCardFrame {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  top: 4px;
  left: 4px;
}
.p-availabilityCardFrame > * {
  white-space: nowrap;
  height: 100%;
  position: absolute;
  top: 0;
}
.p-availabilityCard {
  width: 100%;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  background-color: var(--white);
  border: 1px solid var(--gray200);
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  padding: 12px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  /* Define max width to avoid card follow width of table cell */
  max-width: 200px;
}
.p-calendarMonthly .p-availabilityCard {
  max-width: none;
}
.p-availabilityCard.color1 {
  color: var(--error900);
  background-color: var(--error100);
  border-color: var(--error200);
}
.p-availabilityCard.color2 {
  color: var(--blue900);
  background-color: var(--blue100);
  border-color: var(--blue200);
}
.p-availabilityCard.color3 {
  color: var(--success900);
  background-color: var(--success100);
  border-color: var(--success200);
}
.p-availabilityCard.color4 {
  color: var(--warning900);
  background-color: var(--warning100);
  border-color: var(--warning200);
}
.p-availabilityCard__ttl {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityCard__ttl i {
  font-size: 16px;
  flex-shrink: 0;
}
.p-availabilityCard__ttl p {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-semibold);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.p-availabilityCard__num {
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityCard__num i,
.p-availabilityCard__num img {
  width: 16px;
  height: 16px;
  font-size: 16px;
  flex-shrink: 0;
  margin-right: 4px;
}
.p-availabilityCard__num p {
  font-weight: var(--text-semibold);
}
.p-availabilityCard__info {
  margin-top: 12px;
  overflow: hidden;
}
.p-availabilityCard__info > * {
}
.p-availabilityCard__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityCard__item:not(:first-child) {
  margin-top: 8px;
}
.p-availabilityCard__item i {
  font-size: 16px;
  flex-shrink: 0;
  margin-right: 4px;
}
.p-availabilityCard__item p {
}

.p-calendarWeeklyPopup {
  width: calc(100% - 32px);
  max-width: 560px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 24px;
  position: absolute;
  z-index: 20;
}
.p-calendarWeeklyPopup__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-calendarWeeklyPopup__header p {
  width: 100%;
  font-weight: var(--text-semibold);
  color: var(--primary700);
}
.p-calendarWeeklyPopup__header a {
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: var(--gray600);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
}
.p-calendarWeeklyPopup__body {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  margin-top: 24px;
}
.p-calendarWeeklyPopup__body__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-calendarWeeklyPopup__body__item:not(:first-child) {
  border-top: 1px solid var(--gray200);
  padding-top: 16px;
  margin-top: 16px;
}
.p-calendarWeeklyPopup__body__item.row {
  flex-direction: column;
  align-items: flex-start;
}
.p-calendarWeeklyPopup__body__item__ttl {
  width: 254px;
  color: var(--gray600);
  flex-shrink: 0;
}
.p-calendarWeeklyPopup__body__item__body {
  width: 100%;
}
.p-calendarWeeklyPopup__body__item__body textarea {
  color: var(--gray500);
  background-color: var(--gray50);
}
.p-calendarWeeklyPopup__body__item__body.flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-calendarWeeklyPopup__body__item__body.flex > *:not(:last-child) {
  margin-right: 8px;
}
.p-calendarWeeklyPopup__body__item__body p {
}
.p-calendarWeeklyPopup__body__item__body p.error {
  margin-top: 4px;
  color: var(--error600);
}
.p-calendarWeeklyPopup__body__item__body textarea {
  min-height: 96px;
  margin-top: 4px;
}
.p-calendarWeeklyPopup__actions {
  margin-top: 24px;
}
.p-calendarWeeklyPopup__actions a {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-calendarWeeklyPopup__body__item {
    flex-direction: column;
    align-items: flex-start;
  }
  .p-calendarWeeklyPopup__body__item__ttl {
    margin-bottom: 4px;
  }
  .p-calendarWeeklyPopup__body__item__body.flex {
    justify-content: flex-start;
  }
}

.p-calendar__noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  padding: 32px;
}
.p-availabilityPagination {
  width: 100%;
  height: 76px !important;
  border-top: 1px solid var(--gray200);
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow-y: visible !important;
}