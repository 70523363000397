.salesOverview {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: minmax(240px, 1fr);
  gap: 16px;
}

.salesCard {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cardTitle {
  font-size: 18px;
  font-weight: 600;
  color: #393d3f;
  margin-bottom: 8px;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount {
  font-size: 24px;
  font-weight: 600;
  color: #393d3f;
  margin: 0;
}

.change {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  padding: 6px 12px;
  border-radius: 8px;
}

.positive {
  background-color: #f3f8f5;
  color: #5fd189;
}

.negative {
  background-color: #fff5f5;
  color: #ff8360;
}

.changeIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

@media (max-width: 991px) {
  .salesOverview {
    grid-template-columns: 1fr;
  }
}
