.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  border-radius: 12px;
}
.container-inner {
  border-radius: 12px;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  white-space: nowrap;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .container-inner {
    margin-top: 16px;
    white-space: initial;
  }
}
.header {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: var(--Color-Gray-700, #3f3f46);
  font-weight: 600;
  line-height: 156%;
  justify-content: center;
  padding: 16px 24px;
}
@media (max-width: 991px) {
  .header {
    white-space: initial;
    padding: 0 20px;
  }
}
.header-inner {
  justify-content: space-between;
  display: flex;
  gap: 16px;
}
@media (max-width: 991px) {
  .header-inner {
    white-space: initial;
  }
}
.body {
  display: flex;
  gap: 16px;
  padding: 24px;
}
.chart-comparison {
  width: 100%;
  display: flex;
  align-items: center;
}
.chart-container {
  width: 66%;
  position: relative;
}
.chart {
  height: 200px;
}
.chart-label {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.chart-label > .main {
}
.chart-label > .sub {
 color: var(--Color-Gray-400, #a1a1aa);
 font-size: 12px;
}
.legend {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
}
.legend-item {
  display: flex;
}
.legend-dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: auto 4px auto 0;
}
