/**
 * This code was generated by Builder.io.
 */
.header {
  justify-content: space-between;
  align-items: center;
  background: var(--White, #fff);
  box-shadow: 0 4px 17.3px 0 rgba(230, 235, 255, 0.3);
  display: flex;
  width: 100%;
  padding: var(--Dimension-Spacing-4, 16px) 40px;
  gap: 40px 100px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
    padding: 0 20px;
  }
}

.logo {
  aspect-ratio: 5.85;
  object-fit: contain;
  object-position: center;
  width: 211px;
}

.actionGroup {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 12px;
  justify-content: start;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .actionGroup {
    max-width: 100%;
  }
}

.iconButton {
  border-radius: 8px;
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  justify-content: center;
  width: 40px;
  margin: auto 0;
  padding: 10px;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.primaryButton {
  justify-content: center;
  align-items: center;
  border-radius: var(--Dimension-Radius-md, 8px);
  border: 1px solid var(--color-17, #3e8cc4);
  background: var(--color-17, #3e8cc4);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  align-self: stretch;
  display: flex;
  padding: 10px var(--Dimension-Spacing-4, 16px);
  gap: 8px;
  overflow: hidden;
  color: var(--White, #fff);
  text-transform: capitalize;
  margin: auto 0;
  font: 400 16px Inter, sans-serif;
}

.secondaryButton {
  justify-content: center;
  align-items: center;
  border-radius: var(--Dimension-Radius-md, 8px);
  border: 1px solid var(--Dark-Grey, #707282);
  background: var(--Dark-Grey, #707282);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  align-self: stretch;
  display: flex;
  padding: 10px var(--Dimension-Spacing-4, 16px);
  gap: 8px;
  overflow: hidden;
  color: var(--White, #fff);
  text-transform: capitalize;
  margin: auto 0;
  font: 400 16px Inter, sans-serif;
}

.dropdown {
  align-items: center;
  border-radius: 20px;
  background: var(--Grey, #ebeff3);
  align-self: stretch;
  display: flex;
  gap: 8px;
  color: var(--Dark, #262b2d);
  white-space: nowrap;
  justify-content: start;
  width: 160px;
  margin: auto 0;
  padding: 10px 12px;
  font: 400 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .dropdown {
    white-space: initial;
  }
}

.dropdownText {
  align-self: stretch;
  overflow: hidden;
  flex: 1;
  margin: auto 0;
}

@media (max-width: 991px) {
  .dropdownText {
    white-space: initial;
  }
}

.outlinedButton {
  justify-content: center;
  align-items: center;
  border-radius: var(--Dimension-Radius-md, 8px);
  border: 1px solid var(--Light, #e6ebff);
  background: var(--White, #fff);
  align-self: stretch;
  display: flex;
  padding: 10px var(--Dimension-Spacing-4, 16px);
  gap: 8px;
  overflow: hidden;
  color: var(--Dark, #262b2d);
  text-transform: capitalize;
  margin: auto 0;
  font: 400 16px Inter, sans-serif;
}

.userDropdown {
  align-items: center;
  border-radius: 20px;
  background: var(--Grey, #ebeff3);
  align-self: stretch;
  display: flex;
  gap: 8px;
  color: var(--Dark, #262b2d);
  white-space: nowrap;
  justify-content: start;
  width: 200px;
  margin: auto 0;
  padding: 10px 12px;
  font: 400 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .userDropdown {
    white-space: initial;
  }
}

.userDropdownText {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
  margin: auto 0;
}

@media (max-width: 991px) {
  .userDropdownText {
    white-space: initial;
  }
}

.mainContent {
  display: flex;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .mainContent {
    max-width: 100%;
  }
}

.sidebarContainer {
  display: flex;
}

.sidebar {
  background-color: rgba(81, 107, 120, 1);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0 16px;
}

.sidebarContent {
  display: flex;
  width: 100%;
  max-width: 237px;
  flex-direction: column;
  justify-content: start;
}

.sidebarItem {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 24px;
  color: var(--White, #fff);
  white-space: nowrap;
  text-transform: capitalize;
  justify-content: start;
  padding: 16px;
  font: 700 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .sidebarItem {
    white-space: initial;
  }
}

.sidebarIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 21px;
  fill: var(--White, #fff);
}

.sidebarItemActive {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 24px;
  justify-content: start;
  padding: 16px 0 16px 16px;
}

.sidebarIconContainer {
  display: flex;
  align-items: start;
  overflow: hidden;
  justify-content: center;
  width: 24px;
}

.sidebarItemText {
  flex: 1;
  padding-bottom: 1px;
  color: var(--White, #fff);
  text-transform: capitalize;
  width: 173px;
  font: 700 16px Inter, sans-serif;
}

.sidebarSubItems {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  color: var(--White, #fff);
  white-space: nowrap;
  justify-content: start;
  padding: 0 24px 16px;
  font: 400 14px Inter, sans-serif;
}

@media (max-width: 991px) {
  .sidebarSubItems {
    white-space: initial;
    padding: 0 20px;
  }
}

.sidebarSubItem {
  display: flex;
  align-items: start;
  gap: 8px;
  text-transform: capitalize;
  justify-content: start;
}

@media (max-width: 991px) {
  .sidebarSubItem {
    white-space: initial;
  }
}

.sidebarSubItemBullet {
  border-color: rgba(207, 218, 225, 1);
  border-bottom-width: 2px;
  border-left-width: 2px;
  display: flex;
  width: 8px;
  height: 8px;
}

.sidebarSubItemActive {
  display: flex;
  margin-top: 16px;
  align-items: start;
  gap: 8px;
  font-weight: 700;
  text-transform: capitalize;
  justify-content: start;
}

@media (max-width: 991px) {
  .sidebarSubItemActive {
    white-space: initial;
  }
}

.createReservationButton {
  border-radius: 20px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  justify-content: center;
  padding: 8px 16px;
}

.createReservationText {
  align-self: stretch;
  border-radius: var(--Dimension-Radius-md, 8px);
  background: var(--Dark-Grey, #707282);
  gap: 10px;
  color: var(--White, #fff);
  flex: 1;
  margin: auto 0;
  padding: 10px 28px;
  font: 600 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .createReservationText {
    padding: 0 20px;
  }
}

.createReservationIcon {
  align-items: start;
  border-radius: 100px;
  background: var(--color-17, #3e8cc4);
  box-shadow: 0 4px 8.9px 0 rgba(0, 0, 0, 0.15);
  align-self: start;
  position: absolute;
  z-index: 0;
  display: flex;
  gap: 10px;
  justify-content: start;
  width: 55px;
  height: 55px;
  left: 12px;
  top: -4px;
  padding: 12px;
}

.createReservationIconImage {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 31px;
}

.sidebarDivider {
  background-color: rgba(30, 32, 47, 0.7);
  display: flex;
  width: 6px;
  height: 1041px;
  background: var(--Black, #1e202f);
}

.orderManagementContainer {
  align-self: end;
  display: flex;
  margin-top: 23px;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
}

@media (max-width: 991px) {
  .orderManagementContainer {
    max-width: 100%;
  }
}

.orderManagementContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-transform: capitalize;
  justify-content: start;
}

@media (max-width: 991px) {
  .orderManagementContent {
    max-width: 100%;
  }
}

.orderManagementHeader {
  display: flex;
  max-width: 100%;
  width: 1417px;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.orderManagementTitle {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  color: var(--Black, #1e202f);
  justify-content: start;
  margin: auto 0;
}

@media (max-width: 991px) {
  .orderManagementTitle {
    max-width: 100%;
  }
}

.orderManagementTitleText {
  font: 600 28px/1 Poppins, sans-serif;
}

.orderManagementDescription {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  margin-top: 8px;
  font: 400 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .orderManagementDescription {
    max-width: 100%;
  }
}

.orderManagementTabs {
  background: var(--white, #fff);
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  color: var(--colour-9, #b5b8bc);
  white-space: nowrap;
  justify-content: start;
  margin: auto 0;
  font: 600 14px Inter, sans-serif;
}

@media (max-width: 991px) {
  .orderManagementTabs {
    max-width: 100%;
    white-space: initial;
  }
}

.orderManagementTabList {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .orderManagementTabList {
    max-width: 100%;
    white-space: initial;
  }
}

.orderManagementTab {
  align-self: stretch;
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--colour-12, #f3f6f8);
  background: var(--White, #fff);
  padding: 8px var(--Dimension-Spacing-4, 16px);
  margin: auto 0;
}

@media (max-width: 991px) {
  .orderManagementTab {
    white-space: initial;
  }
}

.orderManagementTabActive {
  align-self: stretch;
  border: 1px solid var(--colour-12, #f3f6f8);
  padding: 8px var(--Dimension-Spacing-4, 16px);
  color: var(--color-17, #3e8cc4);
  margin: auto 0;
}

@media (max-width: 991px) {
  .orderManagementTabActive {
    white-space: initial;
  }
}

.orderManagementActions {
  display: flex;
  margin-top: 29px;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  font: 14px Inter, sans-serif;
}

@media (max-width: 991px) {
  .orderManagementActions {
    max-width: 100%;
    white-space: initial;
  }
}

.searchContainer {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  width: 100%;
  gap: 13px;
  justify-content: start;
  flex-wrap: wrap;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
}

@media (max-width: 991px) {
  .searchContainer {
    max-width: 100%;
    white-space: initial;
  }
}

.searchInput {
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid var(--colour-16, #dadde2);
  background: var(--white, #fff);
  align-self: start;
  display: flex;
  min-width: 240px;
  gap: 40px 100px;
  color: var(--colour-16, #dadde2);
  font-weight: 400;
  letter-spacing: 0.56px;
  flex-wrap: wrap;
  flex: 1;
  flex-basis: 0%;
  padding: 6px 8px;
}

@media (max-width: 991px) {
  .searchInput {
    max-width: 100%;
    white-space: initial;
  }
}

.searchText {
  align-self: stretch;
  margin: auto 0;
}

.searchIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 15px;
  align-self: stretch;
  margin: auto 0;
}

.filterButton {
  align-items: center;
  border-radius: 4px;
  background: var(--colour-18, #707282);
  display: flex;
  gap: 4px;
  color: var(--white, #fff);
  font-weight: 500;
  justify-content: start;
  height: 100%;
  padding: 0 4px;
}

@media (max-width: 991px) {
  .filterButton {
    white-space: initial;
  }
}

.filterIcon {
  aspect-ratio: 0.75;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
  margin: auto 0;
}

.filterText {
  align-self: stretch;
  margin: auto 0;
}

.orderList {
  display: flex;
  margin-top: 29px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .orderList {
    max-width: 100%;
  }
}

.orderListHeader {
  display: flex;
  width: 100%;
  align-items: end;
  gap: 40px 100px;
  text-transform: capitalize;
  justify-content: space-between;
  flex-wrap: wrap;
  font: 14px Inter, sans-serif;
}

@media (max-width: 991px) {
  .orderListHeader {
    max-width: 100%;
  }
}

.orderListTabs {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  color: var(--Black, #1e202f);
  font-weight: 400;
  line-height: 1;
  justify-content: start;
}

.orderListTabList {
  display: flex;
  align-items: center;
  justify-content: start;
}

.orderListTab {
  align-self: stretch;
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--Grey, #ebeff3);
  background: var(--White, #fff);
  padding: 8px var(--Dimension-Spacing-4, 16px);
  gap: 10px;
  font-weight: 600;
  margin: auto 0;
}

.orderListTabActive {
  align-self: stretch;
  border: 1px solid var(--Grey, #ebeff3);
  padding: 8px var(--Dimension-Spacing-4, 16px);
  gap: 10px;
  margin: auto 0;
}

.addOrderButton {
  justify-content: center;
  align-items: center;
  border-radius: var(--Dimension-Radius-md, 8px);
  background: var(--color-17, #3e8cc4);
  display: flex;
  padding: 8px var(--Dimension-Spacing-6, 24px);
  gap: 10px;
  color: var(--White, #fff);
  font-weight: 500;
}

@media (max-width: 991px) {
  .addOrderButton {
    padding: 0 20px;
  }
}

.addOrderIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  align-self: stretch;
  margin: auto 0;
}

.addOrderText {
  align-self: stretch;
  margin: auto 0;
}

.orderListTable {
  display: flex;
  margin-top: 29px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .orderListTable {
    max-width: 100%;
  }
}

.orderListTableHeader {
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  background: var(--colour-12, #f3f6f8);
  display: flex;
  width: 100%;
  gap: 40px 42px;
  color: var(--colour-9, #b5b8bc);
  text-align: center;
  text-transform: capitalize;
  flex-wrap: wrap;
  padding: 8px 0;
  font: 600 15px Inter, sans-serif;
}

@media (max-width: 991px) {
  .orderListTableHeader {
    max-width: 100%;
  }
}

.orderListTableHeaderItem {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 24px;
  justify-content: start;
  width: 1043px;
  margin: auto 0;
}

@media (max-width: 991px) {
  .orderListTableHeaderItem {
    max-width: 100%;
  }
}

.orderListTableHeaderText {
  align-self: stretch;
  width: 127px;
  margin: auto 0;
}

.orderListTableHeaderTextSmall {
  align-self: stretch;
  width: 98px;
  margin: auto 0;
}

.orderListTableHeaderTextLarge {
  align-self: stretch;
  width: 335px;
  margin: auto 0;
}

.orderListSection {
  display: flex;
  margin-top: 14px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .orderListSection {
    max-width: 100%;
  }
}

.orderListSectionTitle {
  color: var(--colour-10, #393d3f);
  text-transform: capitalize;
  font: 600 14px Inter, sans-serif;
  margin: 32px 0 16px 0;
}

.orderListItem {
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--Grey, #ebeff3);
  background: var(--White, #fff);
  display: flex;
  width: 100%;
  padding: var(--Dimension-Spacing-6, 24px);
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .orderListItem {
    max-width: 100%;
    padding: 0 20px;
  }
}

.orderListItemDetails {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 24px;
  color: var(--colour-10, #393d3f);
  justify-content: start;
  flex-wrap: wrap;
  margin: auto 0;
  font: 500 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .orderListItemDetails {
    max-width: 100%;
  }
}

.orderListItemId {
  color: var(--color-17, #3e8cc4);
  font-weight: 600;
  align-self: stretch;
  width: 100px;
  margin: auto 0;
}

.orderListItemText {
  color: var(--Dark, #262b2d);
  text-align: center;
  font-weight: 400;
  text-transform: capitalize;
  align-self: stretch;
  width: 127px;
  margin: auto 0;
}

.orderListItemTextHighlight {
  color: var(--color-17, #3e8cc4);
  text-align: center;
  text-transform: capitalize;
  align-self: stretch;
  width: 127px;
  margin: auto 0;
}

.orderListItemTextSmall {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  text-transform: capitalize;
  width: 127px;
  margin: auto 0;
}

.orderListItemTag {
  align-self: stretch;
  border-radius: 100px;
  background: var(--colour-12, #f3f6f8);
  gap: 10px;
  font-size: 12px;
  color: var(--colour-18, #707282);
  text-align: center;
  text-transform: capitalize;
  width: 127px;
  margin: auto 0;
  padding: 4px 0;
}

.orderListItemStatus {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  color: var(--colour-6, #ff8360);
  white-space: nowrap;
  letter-spacing: 0.56px;
  justify-content: center;
  width: 127px;
  margin: auto 0;
}

.orderListItemColumnContent {
  white-space: pre-line;
}

@media (max-width: 991px) {
  .orderListItemStatus {
    white-space: initial;
  }
}

.orderListItemStatusText {
  align-self: stretch;
  margin: auto 0;
}

.orderListItemStatusIcon {
  aspect-ratio: 1.08;
  object-fit: contain;
  object-position: center;
  width: 13px;
  align-self: stretch;
  margin: auto 0;
}

.orderListItemActions {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: start;
  margin: auto 0;
}

.orderListItemButton {
  align-self: stretch;
  border-radius: var(--Dimension-Radius-md, 8px);
  border: 1px solid var(--color-17, #3e8cc4);
  background: var(--white, #fff);
  padding: 10px var(--Dimension-Spacing-6, 24px);
  gap: 8px;
  overflow: hidden;
  color: var(--color-17, #3e8cc4);
  white-space: nowrap;
  text-transform: capitalize;
  width: 170px;
  margin: auto 0;
  font: 700 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .orderListItemButton {
    white-space: initial;
    padding: 0 20px;
  }
}

.orderListItemMenu {
  transform: rotate(-1.5707963267948966rad);
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 8px;
}

.orderListItemMenuDot {
  aspect-ratio: 1.25;
  object-fit: contain;
  object-position: center;
  width: 4px;
}

.orderListItemMenuDotSpaced {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 4px;
  margin-top: 4px;
}
