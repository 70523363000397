.container {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-right-width: 1px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  cursor: pointer;
}
.header {
  align-items: start;
  background-color: var(--Colors-Base-White, #fff);
  margin-top: 8px;
  color: var(--Colors-Gray-700, #3f3f46);
  justify-content: center;
  padding: 12px 28px;
  font: 600 16px/150% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
