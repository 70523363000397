/*------------------------------------------------------------------------------
table-nowrap
------------------------------------------------------------------------------*/
.c-table-nowrap {
  overflow-x: auto;
}
.c-table-nowrap table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-nowrap table th {
  font-weight: normal;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
  min-width: 32px;
  text-align: center;
}
.c-table-nowrap table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
  min-width: 32px;
}
.c-table-nowrap__bold {
  font-weight: bold;
}
.c-table-nowrap__small {
  font-size: 12px;
}

.table-width-230 {
    width: 230px;
}
.table-width-112-mobile-80 {
    width: 112px;
}

@media screen and (max-width: 768px) {
  .c-table-nowrap {
    width: 100%;
    overflow: auto;
    max-height: 300px;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-nowrap__cell {
    width: 400px;
  }
  .c-table-nowrap table tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .c-table-nowrap table tr:first-child {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .c-table-nowrap table th::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  .c-table-nowrap table tr > *:first-child::before {
    content: '';
    width: 100%;
    height: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    position: absolute;
    left: 0px;
    top: 0;
  }
  .c-table-nowrap table tr > *:not(:first-child) {
    position: relative;
    z-index: 0;
  }
  .table-width-112-mobile-80 {
      width: 80px;
  }
}

.condition-display-box {
  background: #fff;
  padding: 16px;
  width: 100%;
}

.space {
  padding: 16px;
}

.main-content {
  background: #fff;
  padding: 16px;
  width: 100%;
}

.form-pane {
  display: flex;
}

.form-pane > * {
  margin: 0 4px;
}

.form-pane-type-select {
  width: 200px;
  min-width: 200px;
}

.graph-pane-wrapper {
  margin: 50px auto 20px auto;
  width: 100%;
}

.graph-pane-wrapper > * {
  margin: 0 auto;
}

.summary-pane-wrapper > * {
  margin: 0 auto;
}

.summary-pane {
  background: #dcdcdc;
  font-size: 200%;
  width: 50%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontal-radio-button {
  display: flex;
}

.horizontal-radio-button > * {
  width: 200px;
  margin: 0 4px;
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.currency-code-pane {
  margin: 10px 0;
  font-size: 110%;
}

@media screen and (max-width: 768px) {
  .form-pane {
    display: block;
  }

  .horizontal-radio-button {
    display: block;
  }

  .graph-pane-wrapper {
    height: 500px;
  }
}

.accordion-table table .parent-line {
  /* display: flex; */
}

.accordion-table .ic {
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}
.accordion-table .ic p {
  margin: 0;
}
.accordion-table .ic span {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #ebf1f3;
  display: block;
  position: relative;
  margin-left: 4px;
}
.accordion-table .ic span::before,
.accordion-table .ic span::after {
  content: '';
  display: block;
  width: 12px;
  height: 2px;
  background: #008ec9;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 6px);
}
.accordion-table .ic span::after {
  transform: rotate(90deg);
}

.accordion-table .is-active .ic {
  color: #4a6c7a;
}

.accordion-table .is-active .ic span::after {
  transform: rotate(0);
}

.accordion-table .child-line > * {
  background: #f9f9f9;
}

.accordion-table .center-align {
  text-align: center;
}

.accordion-table .right-align {
  text-align: right;
}

.accordion-table .left-align {
  text-align: left;
}

.accordion-table .left-padding-50 {
  padding-left: 50px;
}

.footer-note {
  text-align: right;
}
