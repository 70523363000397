.p-pageContent {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
}

@media screen and (max-width: 768px) {
  .p-pageContent {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
  }
}
