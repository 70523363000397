.c-snackbar {
  width: 100%;  
  position: fixed;
  top: 88px;
  left: 0;
  z-index: 100;
  display: none;
}
.is-active {
  display: block;
}
.c-snackbar__content {
  width: fit-content;
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  border: 1px solid;
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-lg);
  padding: 8px 16px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Color */
.success {
  color: var(--success600);
  border-color: var(--success200);
  background-color: var(--success50);
}
.error {
  color: var(--error600);
  border-color: var(--error200);
  background-color: var(--error50);
}
.warning {
  color: var(--warning600);
  border-color: var(--warning200);
  background-color: var(--warning50);
}
.neutral {
  color: var(--gray600);
  border-color: var(--gray200);
  background-color: var(--gray50);
}

.c-snackbar__content__txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-snackbar__content__txt__top {
  font-weight: var(--text-semibold);
}
.c-snackbar__content__txt i {
  font-size: 20px;
  margin-right: 16px;
}
.c-snackbar__content__txt p {
  
}
.c-snackbar__content__txt p {
  
}
.c-snackbar__content__close {
  width: fit-content;
  font-weight: var(--text-normal);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 36px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  border-radius: var(--radius-md);
  padding: 0 8px;
  margin-left: 16px;
}
.c-snackbar__content__close i {
  font-size: 20px;
  color: var(--gray600);
}