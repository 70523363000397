.c-headline-tab {
  font-size: 14px;
  border: 1px solid #4a6c7a;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-headline-tab li {
  width: 140px;
  height: 32px;
  color: #4a6c7a;
  flex: 1;
  line-height: 1em;
}
.c-headline-tab li:not(:last-child) {
  border-right: 1px solid #4a6c7a;
}
.c-headline-tab li a,
.c-headline-tab li div {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.c-headline-tab li.is-active,
.c-headline-tab li.is-active {
  color: #fff;
  background: #4a6c7a;
}
@media screen and (max-width: 768px) {
  .c-headline-tab {
    font-size: 11px;
    letter-spacing: 0;
  }
}
