.input-box {
  max-width: 600px;
}

.input-box + .input-box {
  margin-top: 16px;
}

.products-input-box {
  width: 100%;
}
