.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  border-radius: 12px;
  flex: 1 1 auto;
  min-height: 0;
  overflow: hidden;
}
.container-inner {
  border-radius: 12px;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  white-space: nowrap;
  margin: 0 auto;
  height: 100%;
}
@media (max-width: 991px) {
  .container-inner {
    margin-top: 16px;
    white-space: initial;
  }
}
.header {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: var(--Color-Gray-700, #3f3f46);
  font-weight: 600;
  line-height: 156%;
  justify-content: center;
  padding: 16px 24px;
  flex-shrink: 0;
}
@media (max-width: 991px) {
  .header {
    white-space: initial;
    padding: 0 20px;
  }
}
.header-inner {
  justify-content: space-between;
  display: flex;
  gap: 16px;
}
@media (max-width: 991px) {
  .header-inner {
    white-space: initial;
  }
}
.body {
  overflow-y: auto;
  min-height: 0;
}
.bodyContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 24px;
  height: 100%; /* Take full height of .body */
}

@media (max-width: 991px) {
  .bodyContainer {
    padding: 0 20px;
  }
}

.listWrapper {
  border-collapse: separate;
  border-spacing: 0;
  height: 100%; /* Take full height of .bodyContainer */
}

.bodyHeader {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 2;
}

@media (max-width: 991px) {
  .bodyHeader {
    white-space: initial;
  }
}
.tableHeaderColumn {
  text-align: left;
}
.locationHeader {
  width: 205px;
}

.locationList {
  margin-top: 8px;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  flex: 1;
  overflow-y: auto;
}

.locationItem {
  cursor: pointer;
}
.locationItem:hover {
  background-color: #f3f6f8;
}
.tableCell {
  padding: 16px 8px;
}
.locationInfo {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
}

.locationNumber {
  font-weight: 400;
  align-self: stretch;
  margin: auto 0;
}

.locationName {
  font-weight: 600;
  align-self: stretch;
  margin: auto 0;
}

.userCount {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
}
.activeLocation td {
  border-top: 1px solid #3aa0ff;
  border-bottom: 1px solid #3aa0ff;
}

.activeLocation td:first-child {
  border-left: 1px solid #3aa0ff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.activeLocation td:last-child {
  border-right: 1px solid #3aa0ff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
