@charset "utf-8";
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}
a {
  /* display: block; */
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
img {
  /* width: 100%;
  height: auto; */
  vertical-align: middle;
}
svg {
  /* width: inherit;
  height: inherit; */
  vertical-align: top;
}
section {
  display: block;
}
html {
  font-size: 16px;
}
input,
textarea,
select,
button {
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
:placeholder-shown,
::-webkit-input-placeholder,
:-ms-input-placeholder {
  color: #aaa !important;
}
select::-ms-expand {
  display: none;
}
body {
  font-family: Helvetica, Arial, Verdana, Roboto, 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #001d36;
  background: #ebf1f3;
  font-size: 14px;
  line-height: 1.5;
  /* letter-spacing: 0.1em; */
  vertical-align: middle;
  overflow-x: hidden;
}

/*------------------------------------------------------------------------------
common
------------------------------------------------------------------------------*/
.base-wrap {
  width: 100%;
}
.base-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.base-subttl {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
.base-spTop {
  display: none;
}
.base-txtCnt {
  margin-left: 8px;
  margin-bottom: 8px;
  align-self: flex-end;
  display: inline-block;
}
.base-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-flex.align_top {
  align-items: flex-start;
}
.base-flex > * + * {
  margin-left: 8px;
}
.base-info {
  margin-left: 8px;
  display: inline-block;
  position: relative;
}
.base-info__ic {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  background: #4a6c7a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.base-info__body {
  width: 320px;
  font-size: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 12px;
  position: absolute;
  top: 20px;
  left: -80px;
  z-index: 10;
  display: none;
}
.base-info:hover .base-info__body {
  display: block;
}
.base-back-arrow {
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .base-back-arrow {
    margin-top: 16px;
    margin-left: 8px;
  }
  .base-spTop.is-active {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: rgba(0, 142, 201, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block;
    position: fixed;
    right: 8px;
    bottom: 72px;
  }
  .base-spTop::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 14px 8px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: 15px;
    left: 16px;
  }
}
/*------------------------------------------------------------------------------
btn
------------------------------------------------------------------------------*/
.base-btn {
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.base-btn.full {
  width: 100%; /* NOTE(goro) cannot change to min-width because cannot be small than the width when mobile view */
  height: 40px;
}
.base-btn.middle {
  width: 160px;
  height: 40px;
  padding: 0px 5px;
}
.base-btn.middle.flex {
  width: initial;
  min-width: 160px;
  height: 40px;
  padding: 0px 5px;
}
.base-btn.small {
  width: 128px;
  height: 32px;
  padding: 0px 5px;
}
.base-btn.small.flex {
  width: initial;
  min-width: 128px;
  height: 32px;
  padding: 0px 5px;
}
.base-btn.tiny {
  width: 112px;
  height: 24px;
  padding: 0px 5px;
}
.base-btn.tiny.flex {
  width: initial;
  min-width: 112px;
  height: 24px;
  padding: 0px 5px;
}
.base-btn.middle img,
.base-btn.small img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.base-btn.icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-btn.icon.narrow {
  margin: 0 5px;
  min-width: 0;
}
.base-btn.icon.inline {
  margin: auto;
}
.base-btn.icon img {
  width: inherit;
  height: inherit;
}
.base-btn.square {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-btn.square img {
  width: 24px;
  height: 24px;
}
.base-btn.blue {
  color: #fff;
  background: #008ec9;
}
.base-btn.green {
  color: #fff;
  background: #00bf98;
}
.base-btn.yellow {
  color: #fff;
  background: #ffcc00;
}
.base-btn.red {
  color: #fff;
  background: #dc3e15;
}
.base-btn.gray {
  color: #4a6c7a;
  background: #fff;
  border: 1px solid #cfdae1;
}
.base-btn.usa {
  color: #fff;
  background: #FF8360;
}
.base-btn.is-disabled {
  color: #fff;
  background: #cfdae1;
  pointer-events: none;
}
.base-btn.middle.more {
  height: 32px;
  color: #008ec9;
  background: #fff;
  border: 1px solid #008ec9;
}
.base-btn.small.more {
  width: auto;
  height: 24px;
  color: #008ec9;
  text-decoration: underline;
  display: inline-block;
}
.base-btn:hover {
  opacity: 0.8;
}
.base-btn.loading {
  position: relative;
  cursor: default;
  /* text-shadow: none!important; */
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}
.base-btn.loading:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}
.base-btn.loading:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}
.base-btn > .anchor {
  text-align: center;
}
@keyframes button-spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/*------------------------------------------------------------------------------
form
------------------------------------------------------------------------------*/
.base-form-box {
  width: 100%;
}
.base-form-box__header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}
.base-form-box__header.required::after {
  content: ' *';
  color: #dc3e15;
}
.base-form-box__header.required.error {
  color: #dc3e15;
}
.base-form-box__body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.base-form-box__err {
  width: 100%;
  font-size: 12px;
  color: #dc3e15;
  margin-top: 4px;
}
.base-form-box input:disabled {
  background: #f9f9f9;
  color: #ccc;
}
.base-form-text {
  width: 100%;
  min-height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  padding: 4px 8px;
}
.base-form-textarea {
  width: 100%;
  height: 240px;
  font-size: 14px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  padding: 4px 8px;
  word-break: break-all;
  resize: vertical;
}
.base-form-select {
  width: 100%;
  height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  display: block;
  position: relative;
}
.base-form-select select {
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 4px 24px 4px 8px;
  cursor: pointer;
}
.base-form-select::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #001d36 transparent transparent transparent;
  position: absolute;
  top: 17px;
  right: 8px;
}
.base-form-calendar {
  width: 100%;
  height: 40px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  display: block;
  position: relative;
}
.base-form-calendar input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 4px 0 4px 8px;
}
.base-form-calendar input:disabled {
  background: #f9f9f9;
  color: #ccc;
}

.base-form-calendar > div {
  width: 100%;
  height: 100%;
  padding: 8px 0 4px 8px;
}

.base-form-calendar input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.base-form-calendar img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.base-form-range {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.base-form-range > label {
  width: calc((100% - 24px) / 2);
}
.base-form-range > p {
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0;
}
.base-form-checkbox,
.base-form-radio {
  width: 100%;
}
.base-form-checkbox + .base-form-checkbox,
.base-form-radio + .base-form-radio {
  margin-top: 8px;
}
.base-form-checkbox label,
.base-form-radio label {
  width: 100%;
  min-height: 40px;
  font-size: 12px;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.base-form-checkbox label.disabled,
.base-form-radio label.disabled {
  color: lightgray;
}

.base-form-toggle.disabled {
  color: lightgray;
  pointer-events: none;
}

.base-form-checkbox label.disabled p,
.base-form-radio label.disabled p {
  background: lightgray;
}

.base-form-checkbox label.dnd {
  width: 200px; /* to grip the background div at drag and drop */
}

.base-form-checkbox input,
.base-form-radio input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.base-form-checkbox input:disabled,
.base-form-radio input:disabled,
.base-form-toggle input:disabled {
  opacity: 0.1;
}
.base-form-checkbox p,
.base-form-radio p {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #008ec9;
  position: relative;
  margin-right: 8px;
  flex-shrink: 0;
}
.base-form-checkbox p {
  border-radius: 2px;
}
.base-form-checkbox input:checked + p {
  background: #008ec9;
}
.base-form-checkbox.disabled p {
  opacity: 0.5;
  cursor: auto;
}
.base-form-checkbox input:checked + p::after {
  content: '';
  width: 4px;
  height: 8px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 3px;
  box-sizing: content-box;
}

.base-form-radio p {
  border-radius: 100%;
}
.base-form-radio input:checked + p::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #008ec9;
  position: absolute;
  top: 1px;
  left: 1px;
}
.base-form-radio input:disabled + p {
  border: 1px solid #cfdae1;
}
.base-form-radio input:disabled + p::after {
  background: #cfdae1;
}
.base-form-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-form-toggle label {
  width: 40px;
  min-width: 40px;
  height: 24px;
  margin-right: 8px;
  display: block;
  position: relative;
}
.base-form-toggle input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}
.base-form-toggle input + p {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #cfdae1;
  position: relative;
  transition: background 0.2s ease;
}
.base-form-toggle input + p::before {
  content: '';
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #cfdae1;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  display: block;
  transition: transform 0.2s ease;
}
.base-form-toggle input:checked + p {
  border: 1px solid #008ec9;
  background: #008ec9;
}
.base-form-toggle input:checked + p::before {
  border: 1px solid #008ec9;
  transform: translateX(18px);
}
.base-form-box__body.three > * {
  margin-top: 0;
}
.base-form-box__body.three > *:not(:nth-child(3n)) {
  margin-right: 16px;
}
.base-form-box__body.three > *:nth-child(1),
.base-form-box__body.three > *:nth-child(2),
.base-form-box__body.three > *:nth-child(3) {
  margin-top: 0;
}
.base-form-box__body.three > * {
  width: calc((100% - 32px) / 3);
  margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .base-form-box__body.three > * {
    width: 100%;
    margin-top: 0;
  }
  .base-form-box__body.three > *:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .base-form-box__body.three > * + * {
    margin-top: 8px !important;
  }
}
/*------------------------------------------------------------------------------
header
------------------------------------------------------------------------------*/
.base-header {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #cfdae1;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.base-header__spMenu {
  display: none;
}
.base-header__logo {
  height: 28px;
  margin-left: 16px;
  margin-right: auto;
}
.base-header__logo img {
  width: inherit;
  height: inherit;
}
.base-header__spAccount {
  display: none;
}
.base-header__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-header__info__btn {
  margin-right: 16px;
}
.base-header__info__btn a {
  width: 196px !important;
}
.base-header__info__btn p {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 8px;
}
.base-header__info__lang {
  width: 160px;
  height: 40px;
  font-weight: bold;
  border-radius: 20px;
  background: #ebf1f3;
  position: relative;
}
.base-header__info__lang:hover {
  opacity: 0.8;
}
.base-header__info__lang img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 16px;
}
.base-header__info__lang select {
  width: 100%;
  height: 100%;
  padding: 0 32px 0 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.base-header__info__lang::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #001d36 transparent transparent transparent;
  position: absolute;
  top: 17px;
  right: 16px;
}
.base-header__info__form {
  width: 200px;
  height: 40px;
  font-weight: bold;
  border-radius: 20px;
  background: #ebf1f3;
  position: relative;
}
.base-header__info__form:hover {
  opacity: 0.8;
}
.base-header__info__account {
  margin-left: 16px;
  position: relative;
  cursor: pointer;
}
.base-header__info__account__ttl {
  width: 200px;
  height: 40px;
  font-weight: bold;
  border-radius: 20px 0 0 20px;
  background: #ebf1f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0;
}
.base-header__info__account__ttl:hover {
  opacity: 0.8;
}
.base-header__info__account__ttl span {
  width: calc(100% - 24px);
  padding-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.base-header__info__account__ttl::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #001d36 transparent transparent transparent;
  position: absolute;
  top: 17px;
  right: 8px;
}
.base-header__info__account__children {
  width: calc(100% - 8px);
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 0;
  position: absolute;
  top: 40px;
  right: 8px;
}
.base-header__info__account__children li > a,
.base-header__info__account__children li > div {
  width: 100%;
  padding: 8px;
  display: block;
}
.base-header__info__account__children li > a:hover,
.base-header__info__account__children li > div:hover {
  opacity: 0.8;
}
.base-header__info__account.is-close .base-header__info__account__children {
  display: none;
}
.base-header__info__account.is-open .base-header__info__account__children {
  display: block;
}
.base-header__info__impersonation {
  width: 160px;
  padding-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .base-header {
    height: 56px;
  }
  .base-header__spMenu {
    width: 56px;
    height: 56px;
    display: block;
    position: relative;
  }
  .base-header__spMenu::before,
  .base-header__spMenu span,
  .base-header__spMenu::after {
    width: 24px;
    height: 2px;
    border-radius: 2px;
    background: #001d36;
    position: absolute;
    left: 16px;
    transition: transform 0.4s ease;
  }
  .base-header__spMenu.is-close::before {
    content: '';
    top: 19px;
  }
  .base-header__spMenu.is-close span {
    top: 27px;
  }
  .base-header__spMenu.is-close::after {
    content: '';
    top: 35px;
  }
  .base-header__spMenu.is-open::before {
    content: '';
    top: 27px;
    transform: rotate(45deg);
  }
  .base-header__spMenu.is-open span {
    top: 27px;
    opacity: 0;
  }
  .base-header__spMenu.is-open::after {
    content: '';
    top: 27px;
    transform: rotate(-45deg);
  }
  .base-header__logo {
    margin-left: 0;
  }
  .base-header__spInfo.is-open::before {
    content: '';
    width: 100%;
    height: calc(100% - 56px);
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 56px;
    left: 0;
  }
  .base-header__spAccount {
    width: 44px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .base-header__spAccount p {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background: #9bacb5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .base-header__spAccount img {
    width: 20px;
    height: 20px;
  }
  .base-header__info {
    width: 100%;
    background: #fff;
    padding: 16px;
    flex-direction: column;
    position: absolute;
    top: 56px;
    left: 0;
    transition: transform 0.4s ease;
  }
  .base-header__spInfo.is-close .base-header__info {
    display: none;
  }
  .base-header__spInfo.is-open .base-header__info {
    display: flex;
  }
  .base-header__info__btn {
    margin-right: 0;
    margin-top: 16px;
    order: 3;
  }
  .base-header__info__lang {
    width: 240px;
    order: 2;
  }
  .base-header__info__account {
    width: 100%;
    margin-left: 0;
    order: 1;
  }
  .base-header__info__account__ttl {
    width: 100%;
    border-radius: 4px;
  }
  .base-header__info__account__ttl::after {
    display: none;
  }
  .base-header__info__account__children {
    display: block !important;
    width: 100%;
    box-shadow: none;
    padding: 0 0 16px 0;
    position: static;
  }
  .base-header__info__account__children li {
    border-bottom: 1px solid #cfdae1;
    position: relative;
  }
  .base-header__info__account__children li::after {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 2px solid #9bacb5;
    border-right: 2px solid #9bacb5;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 12px;
  }
  .base-header__info__impersonation {
    width: 100%;
  }
}

/*------------------------------------------------------------------------------
nav
------------------------------------------------------------------------------*/
.base-nav {
  height: calc(100vh - 64px);
  overflow-x: hidden;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 10;
  transition: width 0.4s ease;
}
.base-nav.is-open {
  width: 220px;
}
.base-nav.is-close {
  width: 48px;
}

.base-nav__menu {
  width: 220px;
  height: 100%;
  background: #4a6c7a;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 1;
}
.base-nav__menu.usa {
  background: #1E202F;
}
.base-nav__menu__item {
}
.base-nav__menu__item > a,
.base-nav__menu__item > div {
  width: 100%;
  height: 48px;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.base-nav__menu__item.is-active > a,
.base-nav__menu__item.is-active > div {
  background: rgba(255, 255, 255, 0.2);
}
.base-nav__menu__item:not(.is-active) a:hover,
.base-nav__menu__item:not(.is-active) div:hover {
  background: rgba(255, 255, 255, 0.1);
}
.base-nav__menu__item > a > p,
.base-nav__menu__item > div > p {
  font-size: 16px;
  color: #fff;
  margin-left: 12px;
}

.base-nav__menu__item.button_link {
  /* margin: 0 12px; */
}

.base-nav__menu__item.button_link > a {
  color: #fff;
  /* background: #00bf98; */
  display: flex;
  justify-content: center;
}

.base-nav__menu__item.button_link > a > p {
  font-size: 14px;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
}
.base-nav__menu__item.button_link > a.green:hover {
  opacity: 0.8;
  background: #00bf98;
}

.base-nav__menu__item > a > img,
.base-nav__menu__item > div > img {
  width: 24px;
  height: 24px;
}
.base-nav__menu__item__children {
  transition: height 0.4s ease;
}
.base-nav__menu__item:not(.is-active) .base-nav__menu__item__children {
  display: none;
}
.base-nav__menu__item__children li {
}
.base-nav__menu__item__children li > a,
.base-nav__menu__item__children li > div {
  width: 100%;
  height: 32px;
  color: #cfdae1;
  padding: 0 16px 0 32px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.base-nav__menu__item__children li > a:hover,
.base-nav__menu__item__children li > div:hover {
  background: rgba(255, 255, 255, 0.1);
}
.base-nav__menu__item__children li > a::before,
.base-nav__menu__item__children li > div::before {
  content: '';
  width: 8px;
  height: 8px;
  border-left: 2px solid #cfdae1;
  border-bottom: 2px solid #cfdae1;
  margin-top: -6px;
  margin-right: 8px;
}
.base-nav__changeWidth {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.base-nav__changeWidth p {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #9bacb5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-nav__changeWidth img {
  width: 24px;
  height: 24px;
}

.base-nav.is-close .base-nav__menu__item > a > p,
.base-nav.is-close .base-nav__menu__item > div > p {
  opacity: 0;
}
.base-nav.is-close .base-nav__menu__item__children {
  height: 0;
  opacity: 0;
}
.base-nav.is-close .base-nav__changeWidth img {
  transform: scale(-1, 1);
}

.base-spNav {
  display: none;
}

@media screen and (max-width: 768px) {
  .base-nav {
    height: calc(100vh - 56px);
    top: 56px;
    transition: left 0.4s ease;
    left: -100%;
  }
  .base-nav__menu__item:not(.is-active) .base-nav__menu__item__children {
    display: block;
  }
  .base-nav.is-close .base-nav__menu__item > a > p,
  .base-nav.is-close .base-nav__menu__item > div > p {
    opacity: 1;
  }
  .base-nav.is-close .base-nav__menu__item__children {
    height: auto;
    opacity: 1;
  }
  .base-nav__changeWidth {
    display: none;
  }
  .base-nav.is-active {
    left: 0;
  }
  .base-nav.is-active::before {
    content: '';
    width: 100%;
    height: calc(100% - 56px);
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 56px;
    left: 0;
  }
  .base-spNav {
    width: 100%;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 16px;
    left: 0;
    z-index: 5;
  }
  .base-spNav ul {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .base-spNav li {
    width: calc(100% / 6);
    height: 48px;
  }
  .base-spNav li a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .base-spNav li a img {
    width: 24px;
    height: 24px;
  }
}

/*------------------------------------------------------------------------------
main
------------------------------------------------------------------------------*/
.base-main {
  height: calc(100vh - 64px);
  position: fixed;
  top: 64px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: width, left 0.4s ease;
}
.base-main.is-open {
  width: calc(100% - 220px);
  left: 220px;
}
.base-main.is-close {
  width: calc(100% - 48px);
  left: 48px;
}
.base-main__headline {
  width: calc(100% - 32px);
  border-bottom: 1px solid #cfdae1;
  padding: 16px 0;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-main__headline__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-main__headline__ttl img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.base-main__headline__ttl h1 {
  font-size: 20px;
  margin: 0px;
}
.base-main__headline__sub {
  margin-left: auto;
}
.base-main__body {
  padding: 16px;
}
.base-main__body > * + * {
  margin-top: 16px;
}
.base-main__body__header {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.base-main__body__header__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-main__body__header__right {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.base-main__body__header__middle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.base-main__body__header__middle.product_availability {
  width: 700px;
}
.base-main__body__header__middle > * {
  margin-right: 16px;
}
.base-main__body__header__left > * {
  margin-right: 16px;
}
.base-main__body__header__right > *:not(:last-child) {
  margin-right: 16px;
}
.base-main__body__header__right__another {
  position: relative;
}
.base-main__body__header__right__another > a,
.base-main__body__header__right__another > p {
  display: none;
}
.base-main__body__header__right__another ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-main__body__header__right__another li:not(:last-child) {
  margin-right: 16px;
}
.base-main__body__half {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.base-main__body__half > * {
  width: calc(50% - 8px);
}
.base-main__body__box {
  border: 1px solid #cfdae1;
  background: #fff;
}
.base-main__body__box__header {
  width: 100%;
  border-bottom: 1px solid #cfdae1;
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-main__body__box__footer {
  width: 100%;
  padding: 12px 16px;
  align-items: center;
}
.base-main__body__box__header__ttl {
  font-size: 16px;
  font-weight: bold;
}
.base-main__body__box__header__btn {
  margin-left: auto;
}
.base-main__body__box__body {
  padding: 16px;
}
.base-main__body__box__btn {
  margin: 16px 0;
  display: flex;
  justify-content: center;
}
.base-main__box__body__bottomBtns {
  margin: 32px 0 16px 0;
  display: flex;
  justify-content: flex-end;
}
.base-main__box__body__bottomBtns > * + * {
  margin-left: 16px;
}
.base-main__box__body__bottomBtns.center {
  margin: 0;
  display: flex;
  justify-content: center;
}
.base-link {
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .base-main {
    width: 100% !important;
    height: auto;
    padding: 56px 0 104px 0;
    position: relative;
    top: 0;
    overflow-y: initial;
    left: 0 !important;
  }
  .base-main__headline {
    width: 100%;
    background: #fff;
    padding: 0 8px;
    align-items: flex-start;
    flex-direction: column;
  }
  .base-main__headline__ttl {
    order: 2;
    padding: 16px 0;
  }
  .base-main__headline__sub {
    width: 100%;
    order: 1;
    margin-left: 0;
    padding-top: 8px;
  }
  .base-main__body {
    padding: 0;
  }
  .base-main__body__header {
    padding: 16px 8px;
    margin-bottom: 0;
  }
  .base-main__body__header .spOrder-1 {
    order: 1;
  }
  .base-main__body__header .spOrder-2 {
    order: 2;
  }
  .base-main__body__header .spOrder-3 {
    order: 3;
  }
  .base-main__body__header__left.spSpacebetween {
    width: 100%;
    margin-bottom: 8px;
    justify-content: space-between;
  }
  .base-main__body__header__left.spSpacebetween > * + * {
    margin: 0;
  }
  .base-main__body__header__middle {
    /* width: 100% !important; NOTE(goro) remove for dispatch */
    /* margin-top: 8px; */
    /* but comment in because the design of product list filter form is collapsed */
    width: 100% !important;
    margin-top: 8px;
    flex-wrap: wrap;
  }
  .base-main__body__header__middle > * {
    margin-right: 0;
  }
  .base-main__body__header__middle > * + * {
    margin-top: 8px;
  }
  .base-main__body__header__right.spSpacebetween {
    width: 100%;
    margin-left: 0;
    justify-content: flex-end;
  }
  .base-main__body__header__right.spSpacebetween a:first-child,
  .base-main__body__header__right.spSpacebetween p:first-child {
    margin-right: auto;
  }
  .base-main__body__header__right__another > a,
  .base-main__body__header__right__another > p {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #cfdae1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .base-main__body__header__right__another > a img,
  .base-main__body__header__right__another > p img {
    width: 24px;
    height: 24px;
  }
  .base-main__body__header__right__another.is-open ul {
    width: 240px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 8px;
    display: block;
    position: absolute;
    top: 44px;
    right: 0px; /* NOTE(goro) change from -48px to 0px*/
    z-index: 2;
  }
  .base-main__body__header__right__another.is-open ul li {
    margin-right: 0;
  }
  .base-main__body__header__right__another.is-open ul li * {
    width: 100% !important;
  }
  .base-main__body__header__right__another.is-open ul li + li {
    margin-top: 8px;
  }
  .base-main__body__header__right__another.is-close ul {
    display: none;
  }
  .base-main__body__header__right > *:not(:last-child) {
    margin-right: 8px;
  }
  .base-main__body__box {
    border: none;
    margin-top: 16px;
    scroll-margin-top: 96px;
  }
  .base-main__body__box__header {
    padding: 8px;
  }
  .base-main__body__box__footer {
    padding: 8px;
  }
  .base-main__body__box__body {
    padding: 16px 8px;
  }
  .base-main__box__body__bottomBtns {
    margin: 32px 0 16px 0;
    display: flex;
    justify-content: center;
  }
  .scroll-target-pane {
    scroll-margin-top: 96px;
  }
}
/*------------------------------------------------------------------------------
small table
------------------------------------------------------------------------------*/
.base-table {
  width: 100%;
  border-collapse: collapse;
}
.base-table th {
  font-weight: normal;
  text-align: left;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.base-table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .base-table {
  }
  .base-table tr {
    width: 100%;
    display: block;
  }
  .base-table tr + tr {
    margin-top: 8px;
  }
  .base-table th {
    width: 100%;
    display: block;
  }
  .base-table td {
    width: 100%;
    margin-top: -1px;
    display: block;
  }
}
/*------------------------------------------------------------------------------
utility
------------------------------------------------------------------------------*/
/*-- margin --*/
.base-margin-top-8 {
  margin-top: 8px;
}
.base-margin-top-16 {
  margin-top: 16px;
}
.base-margin-top-32 {
  margin-top: 32px;
}

.base-margin-bottom-8 {
  margin-bottom: 8px;
}
.base-margin-bottom-16 {
  margin-bottom: 16px;
}
.base-margin-bottom-32 {
  margin-bottom: 32px;
}

.base-margin-topBottom-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.base-margin-topBottom-16 {
  margin-top: 8px;
  margin-bottom: 16px;
}
.base-margin-topBottom-32 {
  margin-top: 8px;
  margin-bottom: 32px;
}

.base-margin-left-8 {
  margin-left: 8px;
}
.base-margin-left-16 {
  margin-left: 16px;
}
.base-margin-left-32 {
  margin-left: 32px;
}

.base-margin-right-8 {
  margin-right: 8px;
}
.base-margin-right-16 {
  margin-right: 16px;
}
.base-margin-right-32 {
  margin-right: 32px;
}

.base-margin-leftRight-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.base-margin-leftRight-16 {
  margin-left: 8px;
  margin-right: 16px;
}
.base-margin-leftRight-32 {
  margin-left: 8px;
  margin-right: 32px;
}

/*-- padding --*/
.base-padding-top-8 {
  margin-top: 8px;
}
.base-padding-top-16 {
  margin-top: 16px;
}
.base-padding-top-32 {
  margin-top: 32px;
}

.base-padding-bottom-8 {
  margin-bottom: 8px;
}
.base-padding-bottom-16 {
  margin-bottom: 16px;
}
.base-padding-bottom-32 {
  margin-bottom: 32px;
}

.base-padding-topBottom-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.base-padding-topBottom-16 {
  margin-top: 8px;
  margin-bottom: 16px;
}
.base-padding-topBottom-32 {
  margin-top: 8px;
  margin-bottom: 32px;
}

.base-padding-left-8 {
  margin-left: 8px;
}
.base-padding-left-16 {
  margin-left: 16px;
}
.base-padding-left-32 {
  margin-left: 32px;
}

.base-padding-right-8 {
  margin-right: 8px;
}
.base-padding-right-16 {
  margin-right: 16px;
}
.base-padding-right-32 {
  margin-right: 32px;
}

.base-padding-leftRight-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.base-padding-leftRight-16 {
  margin-left: 8px;
  margin-right: 16px;
}
.base-padding-leftRight-32 {
  margin-left: 8px;
  margin-right: 32px;
}

.base-padding-16 {
  padding: 16px;
}
.base-padding-32 {
  padding: 32px;
}

.base-f-160 {
  width: 160px;
}
.base-f-320 {
  width: 320px;
}
.base-f-480 {
  width: 480px;
}

/*-- tableWidth --*/
.base-t-32 {
  width: 32px;
}
.base-t-48 {
  width: 48px;
}
.base-t-64 {
  width: 64px;
}
.base-t-80 {
  width: 80px;
}
.base-t-96 {
  width: 96px;
}
.base-t-112 {
  width: 112px;
}
.base-t-128 {
  width: 128px;
}
.base-t-144 {
  width: 144px;
}
.base-t-160 {
  width: 160px;
}
.base-t-176 {
  width: 176px;
}
.base-t-192 {
  width: 192px;
}
.base-t-208 {
  width: 208px;
}
.base-t-224 {
  width: 224px;
}
.base-t-240 {
  width: 240px;
}
.base-t-256 {
  width: 256px;
}
.base-t-272 {
  width: 272px;
}
.base-t-288 {
  width: 288px;
}
.base-t-304 {
  width: 304px;
}
.base-t-320 {
  width: 320px;
}
.base-t-336 {
  width: 336px;
}
.base-t-352 {
  width: 352px;
}
.base-t-368 {
  width: 368px;
}
.base-t-384 {
  width: 384px;
}
.base-t-400 {
  width: 400px;
}
.base-t-416 {
  width: 416px;
}
.base-t-432 {
  width: 432px;
}
.base-t-464 {
  width: 464px;
}
.base-t-480 {
  width: 480px;
}

.base-t-spShow {
  display: none;
}
.base-t-hidden {
  display: none;
}

/*-- tableWidth with min --*/
.base-t-min-32 {
  min-width: 32px;
}
.base-t-min-48 {
  min-width: 48px;
}
.base-t-min-64 {
  min-width: 64px;
}
.base-t-min-80 {
  min-width: 80px;
}
.base-t-min-96 {
  min-width: 96px;
}
.base-t-min-112 {
  min-width: 112px;
}
.base-t-min-128 {
  min-width: 128px;
}
.base-t-min-144 {
  min-width: 144px;
}
.base-t-min-160 {
  min-width: 160px;
}
.base-t-min-176 {
  min-width: 176px;
}
.base-t-min-192 {
  min-width: 192px;
}
.base-t-min-208 {
  min-width: 208px;
}
.base-t-min-224 {
  min-width: 224px;
}
.base-t-min-240 {
  min-width: 240px;
}
.base-t-min-256 {
  min-width: 256px;
}
.base-t-min-272 {
  min-width: 272px;
}
.base-t-min-288 {
  min-width: 288px;
}
.base-t-min-304 {
  min-width: 304px;
}
.base-t-min-320 {
  min-width: 320px;
}
.base-t-min-336 {
  min-width: 336px;
}
.base-t-min-352 {
  min-width: 352px;
}
.base-t-min-368 {
  min-width: 368px;
}
.base-t-min-384 {
  min-width: 384px;
}
.base-t-min-400 {
  min-width: 400px;
}
.base-t-min-416 {
  min-width: 416px;
}
.base-t-min-432 {
  min-width: 432px;
}
.base-t-min-464 {
  min-width: 464px;
}
.base-t-min-480 {
  min-width: 480px;
}

.base-maxwidth-800 {
  max-width: 800px;
}

/*-- reservation table width --*/
.base-r-t-icon {
  width: 20px;
}
.base-r-t-minimal {
  width: 50px;
}
.base-r-t-short {
  width: 80px;
}
.base-r-t-middle {
  width: 120px;
}
.base-r-t-long {
  width: 200px;
}
.base-r-t-longx2 {
  width: 450px;
}

.base-r-t-150 {
  width: 150px;
}

@media screen and (max-width: 768px) {
  .base-t-spHidden {
    display: none !important;
  }
  .base-t-spShow {
    display: block !important;
  }
  .base-t-spShow::before {
    display: none !important;
  }
}

/* Divider (semantic-ui-react) */
.base-text-divider {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  margin-top: 2rem;
  margin-bottom: 2rem;

  margin: 1rem 0;
  line-height: 1;
  height: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.85);
  user-select: none;
}

/* Segment (semantic-ui-react) */
.segment {
  border-radius: 10px 10px 10px 10px;
  border-width: 0px;
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

/* Message (semantic-ui-react) */
.message {
  width: 100%;
  font-size: 1em;
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
    box-shadow 0.1s ease;
  border-radius: 0.28571429rem;
  box-shadow: inset 0 0 0 1px rgba(34, 36, 38, 0.22), 0 0 0 0 transparent;
}

.message.warning {
  background-color: #fffaf3;
  color: #573a08;
}

.message.success {
  background-color: #fcfff5;
  color: #2c662d;
}

.message.error {
  background-color: #fff6f6;
  color: #9f3a38;
}

.message.info {
  background-color: #f8ffff;
  color: #276f86;
}

.message .header {
  display: block;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: -0.14285714em 0 0;
}

.message.warning .header {
  color: #794b02;
}

/* for <title><button> */

.inline-title {
  vertical-align: middle;
}

.inline-icon {
  float: right;
  vertical-align: middle;
}

/*-- override semantic-ui-react --*/
p.semantic-ui-react-override {
  margin: 0px 0px 0px 0px;
}

/* temporary solution */
p.semantic-ui-react-override:first-child {
  margin: 0 10px 0 0;
}

.status-step-inline-block {
  display: flex;
}

.date-list-inline-block {
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.inline-block {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  flex-direction: row;
}

.inline-block-push {
  margin-left: auto;
}

.status-step-btn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .status-step-inline-block {
    display: block;
  }
}

.list-add-btn {
  margin-top: 10px;
}

.payment-link {
  color: blue;
}

ol.product-calendar-edit-modal {
  padding-left: 25px;
}

ol.product-calendar-edit-modal li {
  list-style: decimal;
}

.reservation-detail-email-payment {
  display: flex;
  align-items: center;
  width: 100%;
}

.reservation-detail-email-payment .btn {
  margin-left: auto;
  margin-right: 0px;
}

.product-calendar-table-date-header {
  white-space: nowrap;
}

.product-calendar-child-name {
  font-size: 10px;
  color: gray;
}

.list-input {
  margin: 5px 0px;
}

.base-selectFrame {
  width: 100%;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  overflow: hidden;
}
.base-selectFrame + .base-selectFrame {
  margin-top: 8px;
}
.base-modalSelectFrame {
  width: 100%;
  border: 1px solid #cfdae1;
  border-radius: 4px;
}
.base-modalSelectFrame + .base-modalSelectFrame {
  margin-top: 8px;
}
.base-selectFrame2 {
}
.base-selectFrame__header {
  width: 100%;
  height: 40px;
  background: #ebf1f3;
  border-bottom: 1px solid #cfdae1;
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-selectFrame2__header {
  padding: 8px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-selectFrame__header__ttl {
  font-size: 14px;
  font-weight: bold;
}
.base-selectFrame__header__ttl.blue {
  color: #008ec9;
}
.base-selectFrame__header__delete {
  font-size: 12px;
  color: #dc3e15;
  margin-left: auto;
}
.base-selectFrame__body {
  padding: 8px;
}
.base-selectFrame2__body {
}
.base-selectFrame__body > div + div {
  margin-top: 8px;
}
.base-single__section {
  margin-bottom: 16px;
}
.base-single__section__title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 16px;
}

/* General "error" style for any text */
.error {
  color: #dc3e15;
}

.link {
  color: #008ec9;
  font-weight: bold;
}

.icon-box {
  padding-left: 2px;
  padding-right: 2px;
}

.base-form-number {
  width: 100%;
  max-width: 150px;
  height: 40px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  display: block;
  position: relative;
}
.base-form-number input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: center;
  padding: 4px 8px;
}
.base-form-number input::-webkit-outer-spin-button,
.base-form-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.base-form-number .down,
.base-form-number .up {
  width: 32px;
  height: 32px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #008ec9;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  cursor: pointer;
}
.base-form-number .down {
  left: 4px;
}
.base-form-number .up {
  right: 4px;
}
.base-form-number .down:hover,
.base-form-number .up:hover {
  opacity: 0.8;
}

/* Add specific targets here for iPad */
/* Taken from https://css-tricks.com/snippets/css/media-queries-for-standard-devices/ */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
  .base-main {
    height: calc(100svh - 64px);
  }
  .base-nav {
    height: calc(100svh - 64px);
  }
}

/* ----------- iPad 3, 4, 6 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .base-main {
    height: 100%;
  }
  .base-nav {
    height: calc(100svh - 48px);
  }
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .base-main {
    height: 100%;
  }
  .base-nav {
    height: calc(100svh - 48px);
  }
}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .base-main {
    height: calc(100svh - 64px);
  }
  .base-nav {
    height: calc(100svh - 64px);
  }
}

/* ----------- iPad 7 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 810px) 
  and (max-device-width: 1080px) 
  and (-webkit-min-device-pixel-ratio: 2) {
  .base-main {
    height: calc(100svh - 64px);
  }
  .base-nav {
    height: calc(100svh - 64px);
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .base-main {
    height: calc(100svh - 64px);
  }
  .base-nav {
    height: calc(100svh - 64px);
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .base-main {
    height: calc(100svh - 64px);
  }
  .base-nav {
    height: calc(100svh - 64px);
  }
}