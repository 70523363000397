.picture {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.picture__item {
  padding-right: 8px;
  padding-bottom: 8px;
}
.picture__item__link {
  color: #008ec9;
}
.picture__item__pic {
  width: 160px;
  height: 90px;
  margin-top: 12px;
  margin-right: 12px;
  position: relative;
}
.picture__item__pic img {
  width: inherit;
  height: inherit;
}
.picture__item__delete {
  font-size: 12px;
  color: #dc3e15;
  margin-top: 4px;
}
.picture__item__delete:hover {
  opacity: 0.8;
}
.picture__item__add {
  width: 160px;
  height: 90px;
  margin-top: 12px;
  margin-right: 12px;
  background: #ebf1f3;
  display: block;
  position: relative;
}
.dragActive {
  opacity: 0.5;
}
.picture__item__add::before,
.picture__item__add::after {
  content: '';
  width: 28px;
  height: 6px;
  background: #00bf98;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 14px);
}
.picture__item__add::before {
  transform: rotate(90deg);
}
