.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.delete {
  color: #e53935;
}

.delete:hover {
  background-color: rgba(229, 57, 53, 0.04);
}
