.p-manifests__search {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px 24px;
}
.p-manifests__search__item {
  width: 100%;
  display: flex;
  align-items: center;
}
.p-manifests__search__item:not(:first-child) {
  margin-top: 16px;
}
.p-manifests__search__item > * {
  width: 100%;
  flex-shrink: 1;
}
.p-manifests__search__item > *:not(:last-child) {
  margin-right: 16px;
}
@media screen and (max-width: 768px) {
  .p-manifests__search__item {
    flex-direction: column;
  }
  .p-manifests__search__item > *:first-child {
    margin-right: 0;
  }
  .p-manifests__search__item > *:not(:first-child) {
    margin-top: 16px;
    margin-right: 0;
  }
}

.p-manifests__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-manifests__section:not(:first-child) {
  margin-top: 16px;
}
.p-manifests__section > div:not(:first-child) {
  /* margin-top: 16px; */
}
.p-manifests__group {
  margin-top: 16px;
}
.p-manifests__group > div:not(:first-child) {
  margin-top: 16px;
}
.p-manifests__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-manifests__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-manifests__section__ttl > span {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 4px;
  display: block;
}
.p-manifests__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-manifests__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-manifests__section__body.is-active {
  display: block;
}
.p-manifests__section__body__box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
}
.p-manifests__section__body__box__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--text-base-size);
}
.p-manifests__section__body__box__body {
  border-top: 1px solid var(--gray200);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  /* padding: 20px; */
}
.p-manifestsTable {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: 12px;
  background: var(--white);
  overflow-x: auto;
}
.p-manifestsTable > * {
  border: none;
}
.p-manifestsTable tbody:not(:nth-child(2)) td {
  border-top: 1px solid var(--gray200);
}
.p-manifestsTable tbody .acBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-manifestsTable tbody .acBtn > div {
  flex-shrink: 0;
  margin-right: 8px;
}
.p-manifestsTable tbody .acBtn a {
  text-decoration: none;
}
.p-manifestsTable tbody .spAcBtn a {
  width: 100%;
  text-decoration: none;
}
.p-manifestsTable tfoot td {
  border-top: 1px solid var(--gray200);
  background-color: var(--gray50);
}
.p-manifestsTable2 {
}
.p-manifestsTable2.hidden {
  display: none !important;
}
.p-manifestsTable2 a {
  /* text-decoration: none !important; */
}
.p-manifestsTable2 .c-table__more a {
  text-decoration: underline !important;
}
@media screen and (max-width: 768px) {
  .p-manifestsTable {
    /* TODO: design is incorrect for mobile in this one, need to fix later */
    /* display: none; */
  }
  .p-manifestsTable table > *:not(:last-child) {
    margin-bottom: 16px;
  }
  .p-manifestsTable tbody {
    /* border: 1px solid var(--gray200); */
    border-radius: 12px;
    padding: 12px;
    display: block;
  }
  .p-manifestsTable tbody tr:first-child {
    border: 0;
    padding: 0;
  }
  .p-manifestsTable tfoot td {
    background-color: transparent;
  }
  .p-manifestsTable2 {
    padding: 12px;
  }
  .p-manifestsTable2__spBtn {
    flex-shrink: 0;
  }
  .p-manifestsTable2 .c-table td.c-table__spHeader::after {
    display: none;
  }
}

.p-manifests__select {
  position: relative;
}
.p-manifests__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-manifests__select__menu.is-active {
  display: block;
}
.p-manifests__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-manifests__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-manifests__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

.p-manifests__actions {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-manifests__actions__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-manifests__actions__left > * {
  margin-right: 12px;
}
.p-manifests__actions__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}
.p-manifests__actions__right > * {
  margin-left: 12px;
}
.p-manifests__normal {
}
.p-manifests__normal:last-child {
  margin-bottom: 24px;
}
.p-manifests__normal__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
  padding: 24px;
}
.p-manifests__normal__header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-manifests__normal__header > div:not(:first-child) {
  margin-top: 8px;
}
.p-manifests__normal__header > div i {
  font-size: 20px;
  margin-right: 8px;
}
.p-manifests__normal__header > div > div p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-manifests__normal__header__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.p-manifests__normal__header__left > div:not(:first-child) {
  margin-left: 16px;
}
.p-manifests__normal__header__right {
}
.p-manifests__normal__header__right > button p {
  font-weight: var(--text-normal) !important;
}
.p-manifests__normal__header__memo {
  display: flex;
  align-items: center;
}
.p-manifests__normal__header__memo > div:not(:first-child) {
  margin-left: 8px;
}
.p-manifests__normal__actions {
  margin-top: 16px;
}
.p-manifests__normal__actions > * {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .p-manifests__normal__header {
    padding: 24px 24px 0 24px;
    flex-direction: column;
    align-items: flex-start;
  }
  .p-manifests__normal__header > *:not(:first-child) {
    margin-top: 8px;
  }
  .p-manifests__normal__header > div {
    width: 100%;
  }
  .p-manifests__normal__header__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .p-manifests__normal__header__left > div:not(:first-child) {
    margin-left: 0;
    margin-top: 8px;
  }
  .p-manifests__normal__header__right {
    margin-left: auto;
  }
  .p-manifests__actions {
    margin-top: -12px;
  }
  .p-manifests__actions__left {
    display: contents;
  }
  .p-manifests__actions__right {
    display: contents;
  }
  .p-manifests__actions__right > * {
    margin-left: 0;
    margin-right: 12px;
  }
  .p-manifests__actions__left > *,
  .p-manifests__actions__right > * {
    margin-top: 12px;
  }
}

.p-manifestsSetting {
}
.p-manifestsSetting__list {
}
.p-manifestsSetting__list li {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-manifestsSetting__list li:not(:first-child) {
  margin-top: 16px;
}
.p-manifestsSetting__list li > p {
  margin-right: 12px;
}

.p-manifestsModalAssignments {
  position: relative;
}
.p-manifestsModalAssignments .p-manifests__normal__header {
  padding: 24px 0;
}
@media screen and (max-width: 768px) {
  .p-manifestsModalAssignments .p-manifestsTable2 {
    padding: 0;
  }
}

.p-manifestsDownload {
}
.p-manifestsDownload__check {
}
.p-manifestsDownload__item {
  margin-top: 20px;
}
.p-manifestsDownload__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  /* Override semantic */
  margin-bottom: 6px !important;
}
.p-manifestsDownload__item__body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -16px;
}
.p-manifestsDownload__item__body > * {
  margin-top: 16px;
  margin-right: 16px;
}

.p-manifestsModalResources {
}
.p-manifestsModalResources__item:not(:first-child) {
  margin-top: 20px;
}
.p-manifestsModalResources__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  /* Override semantic */
  margin-bottom: 6px !important;
}
.p-manifestsModalResources__item__body {
}
.p-manifestsModalResources__item__body > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-manifestsModalResources__item__body > div:not(:first-child) {
  margin-top: 12px;
}
.p-manifestsModalResources__item__body > div > *:not(:last-child) {
  margin-right: 12px;
}
.p-manifestsModalResources__item__body__delete {
  width: 36px;
  flex-shrink: 0;
}
.p-manifestsModalResources__item__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.p-manifestsModalResources__item__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

.p-manifestsModalMemo {
}
.p-manifestsModalMemo__item {
}
.p-manifestsModalMemo__item:not(:first-child) {
  margin-top: 16px;
}
.p-manifestsModalMemo__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  /* Override semantic */
  margin-bottom: 6px !important;
}
.p-manifestsModalMemo__item__body {
}

.p-manifestsModalAssigned {
}
.p-manifestsModalAssigned__item {
}
.p-manifestsModalAssigned__item:not(:first-child) {
  margin-top: 16px;
}
.p-manifestsModalAssigned__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  /* Override semantic */
  margin-bottom: 6px !important;
}
.p-manifestsModalAssigned__item__body {
}
.p-manifestDaily__noData {
  margin-top: 16px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-manifestDaily__noData > div {
  padding: 16px;
}

.p-manifestDaily__checkinStatus {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-manifestDaily__checkinStatus > *:not(:first-child) {
  margin-left: 4px;
}

.p-manifestDaily__checkinModal__package__ul {
}
.p-manifestDaily__checkinModal__package__ul li:not(:first-child) {
  margin-top: 8px;
}

.infoBox {
  display: flex;
  align-items: center;
}
.infoBox > *:not(:first-child) {
  margin-left: 4px;
}

/* Override button icon & text properties */
.p-manifests__normal__header > div > div button p {
  font-weight: var(--text-normal);
}
.p-manifests__normal__header > div button i {
  margin-right: 0;
}

.p-manifestsTable__summary__firstCell {
  padding-left: 64px;
}
@media screen and (max-width: 768px) {
  .p-manifestsTable__summary__firstCell {
    padding-left: 0;
  }
  .p-manifestsTable tfoot tr {
    /* Only render border top to avoid stacked border caused by other CSS rules */
    border: none !important;
    border-top: 1px solid var(--gray200) !important;
  }
}

.editContainer {
  display: flex;
  gap: 8px;
}
.collapsed p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.showMore {
  display: block;
  color: var(--primary600);
  cursor: pointer;
  margin-top: 4px;
  text-decoration: underline;
}
.expanded p {
  display: block;
}