.restaurantEdit {
  border-radius: 50px;
  background-color: var(--Light-Blue, #fdfdff);
  display: flex;
  flex-direction: column;
  margin: -16px;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: var(--White, #fff);
  border-radius: 20px;
  border: 1px solid rgba(235, 239, 243, 1);
  margin-bottom: 16px;
}

@media (max-width: 991px) {
  .content {
    padding: 24px 20px;
  }
}
