.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  border-radius: 12px;
}
.container-inner {
  border-radius: 12px;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  white-space: nowrap;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .container-inner {
    margin-top: 16px;
    white-space: initial;
  }
}
.header {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: var(--Color-Gray-700, #3f3f46);
  font-weight: 600;
  line-height: 156%;
  justify-content: center;
  padding: 16px 24px;
}
@media (max-width: 991px) {
  .header {
    white-space: initial;
    padding: 0 20px;
  }
}
.header-inner {
  justify-content: flex-start;
  display: flex;
  gap: 16px;
}
@media (max-width: 991px) {
  .header-inner {
    white-space: initial;
  }
}
.body {
  display: flex;
  padding: 24px;
}
@media (max-width: 991px) {
  .body {
    white-space: initial;
    padding: 0 20px;
  }
}
.amount {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.amount-inner {
  display: flex;
  align-items: flex-end; 
}
.amount-text {
  color: var(--Color-Gray-900, #18181b);
  font: 600 24px/133% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.amount-unit {
  color: var(--Color-Gray-400, #a1a1aa);
  font: 400 16px/140% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.chart {
  position: relative;
  height: 100%;
  width: 150px;
}
.text-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 20px;
  padding-top: 36px;
}
.change {
  border-radius: var(--Dimension-Radius-md, 8px);
  background-color: var(--Color-Gray-100, #f4f4f5);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;
  line-height: 133%;
  justify-content: center;
  margin: auto 0;
  padding: 6px 12px;
}
.inc {
  color: var(--Color-Success-600, #059669);
}
.dec {
  color: var(--Color-Error-600, #dc2626);
}
.change-inner {
  display: flex;
  gap: 4px;
}
.legend-item {
  display: flex;
}
.legend-dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: auto 4px auto 0;
}
.blue {
  background-color: var(--Color-Blue-500, #3b82f6);
}
.gray {
  background-color: var(--Color-Gray-200, #e4e4e7);
}
