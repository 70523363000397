.container {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Colors-Base-White, #fff);
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  padding: 0 8px;
}
.left-pane {
  width: 280px;
}
.right-pane {
  width: calc(100% - 344px);
}
