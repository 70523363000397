.occupations-table {
  width: 100%;
  border: 1px solid #e0e0e0;
}

.occupations-table td,
.occupations-table th {
  padding: 8px;
  text-align:center
}

.quantity-table {
  width: 100%;
  border: 1px solid #e0e0e0;
}

.quantity-table td,
.quantity-table th {
  width: 50%;
  padding: 8px;
}

/*------------------------------------------------------------------------------
table-nowrap
------------------------------------------------------------------------------*/
.c-table-nowrap {
}
.c-table-nowrap table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-nowrap table th {
  font-weight: normal;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.c-table-nowrap table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.c-table-nowrap__bold {
  font-weight: bold;
}
.c-table-nowrap__small {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .c-table-nowrap {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-nowrap__cell {
    width: 400px;
  }
}

