.aligned-input-button {
  margin-top: 22px;
}

.commission-percent-label {
  display: block;
}

.commission-percent {
  display: flex;
}
