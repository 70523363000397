.buttons-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.button-container + .button-container {
  margin-left: 8px;
}
.mobile-expand-button {
  display: none;
}
@media screen and (max-width: 768px) {
  .buttons-row {
    margin-left: 8px;
    position: relative;
  }
  .button-container + .button-container {
    margin-left: 0px;
    margin-top: 8px;
  }
  .mobile-expand-button {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #cfdae1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-expand-button img {
    width: 24px;
    height: 24px;
  }
  .buttons-wrapper {
    display: none;
  }
  .buttons-row.expanded-on-mobile > .buttons-wrapper {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 8px;
    display: block;
    position: absolute;
    top: 0;
    left: 44px;
    width: 200px;
    /* Need to go over sp menu which has z-index of 8 but under navbar with z-index of 10 */
    z-index: 9;
  }
}

.base-form-box .c-form-single > .c-list {
  display: block;
  width: 300px;
  right: 0;
  top: 44px;
  border-radius: 8px;
}
@media screen and (max-width: 768px) {
  .base-form-box .c-form-single > .c-list {
    display: block;
    left: 0;
    top: 44px;
    border-radius: 8px;
  }
}

/* Taken from old style CSS */
.base-form-box {
  width: 100%;
}
.base-form-box__body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.c-form-multiple,
.c-form-single {
  width: 100%;
  position: relative;
}
.c-form-multiple .select,
.c-form-single .select {
  width: 100%;
  min-height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  cursor: pointer;
}
.c-form-multiple .placeholder,
.c-form-single .placeholder {
  color: #888;
  position: relative;
}
.c-form-multiple .selected,
.c-form-single .selected {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-form-multiple .selected li,
.c-form-single .selected li {
  font-size: 12px;
  padding: 4px 0 4px 4px;
  border-radius: 4px;
  background: #ebf1f3;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.c-form-multiple .selected p,
.c-form-single .selected p {
  max-width: 90%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.c-form-multiple .selected li .delete,
.c-form-single .selected li .delete {
  width: 22px;
  height: 22px;
  position: relative;
}
.c-form-multiple .selected li .delete::before,
.c-form-multiple .selected li .delete::after,
.c-form-single .selected li .delete::before,
.c-form-single .selected li .delete::after {
  content: '';
  width: 12px;
  height: 2px;
  background: #4a6c7a;
  position: absolute;
  top: 11px;
  left: 6px;
}
.c-form-multiple .selected li .delete::before,
.c-form-single .selected li .delete::before {
  transform: rotate(45deg);
}
.c-form-multiple .selected li .delete::after,
.c-form-single .selected li .delete::after {
  transform: rotate(-45deg);
}
.c-form-multiple .select input,
.c-form-single .select input {
  line-height: 30px;
}

.c-form-single .select input {
  width: 100%;
}

.c-form-multiple > .option,
.c-form-single > .option {
  width: 608px;
  max-height: 240px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 8px;
  position: absolute;
  z-index: 100;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}
.c-form-multiple .select.is-active + .option,
.c-form-single .select.is-active + .option {
  display: block;
}
.c-form-multiple .option li,
.c-form-single .option li {
  width: 100%;
  padding: 8px;
  cursor: pointer;
  display: block;
}
.c-form-multiple .option li:hover,
.c-form-single .option li:hover {
  background: #ebf1f3;
}
@media screen and (max-width: 768px) {
  .c-form-multiple > .option,
  .c-form-single > .option {
    width: 100%;
  }
}
