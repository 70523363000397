.bold {
  font-weight: bold;
}
.gray-background {
  background: #f9f9f9 !important;
}
.outlined-cell {
  border: 2px solid black !important;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
