.c-sns {
  width: 300px;
  height: 28px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.c-sns__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  margin-right: 0px;
}

.fb_iframe_widget > span {
  vertical-align: baseline !important;
}

@media screen and (max-width: 768px) {
  .c-sns {
    margin-top: 0px;
    justify-content: flex-start;
    padding: 0px 16px;
    height: inherit;
  }
  .c-sns div {
    margin-left: 0px;
    margin-right: 4px;
  }
}
