.c-annualPassStage {
}
.c-annualPassStage > div:not(:first-child) {
  margin-top: 16px;
}
.c-annualPassStage__content {
  display: grid;
  grid-template-columns: minmax(120px, auto) repeat(4, 1fr);
  gap: 4px;
}
.c-annualPassStage__content__item {
  position: relative;
  padding: 8px;
  border-radius: var(--radius-md);
  border: 1px solid var(--white);
  background-color: var(--gray50);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.c-annualPassStage__content__item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.02);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  border-radius: var(--radius-md);
}
.c-annualPassStage__content__item:hover::before {
  opacity: 1;
}
.c-annualPassStage__content__header {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--gray50);
}
.topHeaderColSpan {
  grid-column: span 4;
}
.top {
  grid-column: 2 / span 4;
  display: grid;
  grid-template-rows: auto auto;
}
.topHeader {
  padding: 8px;
  text-align: center;
}
.topSubheader {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: #71717a;
}
.topSubheader > div {
  border-top: 1px solid var(--gray200);
  padding: 8px;
  text-align: center;
}
.topSubheader > div:not(:first-child) {
  border-left: 1px solid var(--gray200);
}
.left {
  grid-column: 1 / span 1;
  display: grid;
  grid-template-columns: auto auto;
}
.leftHeader {
  padding: 8px;
  text-align: center;
  border-right: 1px solid var(--gray200);
}
.leftSubheader {
  display: grid;
  word-wrap: break-word;
  min-width: 120px;
}
.leftSubheader > div {
  padding: 16px;
  height: 90px;
  max-width: 160px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.leftSubheader > div:not(:first-child) {
  border-top: 1px solid var(--gray200);
}
.leftSubheader .leftSubheaderBreakdown {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.verticalText {
  text-orientation: upright;
  writing-mode: vertical-rl;
}
.content {
  height: 90px;
}
.total {
  margin-top: 12px;
}
.totalHeader {
  border: 1px solid var(--gray200);
}
.comparisonData {
  font-size: var(--text-xs-size);
}
.breakdown {
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
.c-annualPassStage__legend {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}
.c-annualPassStage__legend > div {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.c-annualPassStage__legend > div:not(:first-child) {
  margin-left: 16px;
}
.c-annualPassStage__legend i {
  display: inline-block;
  height: 16px;
  width: 16px;
  margin-right: 6px;
}

.c-annualPassStage__legend p {
  color: var(--gray00);
}

@media screen and (max-width: 768px) {
  .c-annualPassStage__content {
    grid-template-columns: 180px repeat(4, 1fr);
  }
  .c-annualPassStage__content__container {
    overflow-x: auto;
  }
  .c-annualPassStage__content__item {
    min-width: 140px;
  }
  .c-annualPassStage__content__item.totalHeader {
    min-width: fit-content;
  }
  .c-annualPassStage__legend {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
