.header-actions-container {
    min-width: 300px;
}
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.buttons {
  margin-left: auto;
}

.p-availability__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.p-availability__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.red {
  color: #dc2626;
}
