.l-main {
  width: 100%;
  min-height: calc(100vh - 558px);
  position: relative;
}
@media screen and (min-width: 769px) {
  .l-main {
    padding-top: 80px;
  }
}
@media screen and (max-width: 768px) {
  .l-main {
    padding-top: 56px;
  }
}

.p-regist__terms {
  width: 100%;
  max-width: 640px;
  margin: 16px auto;
}

.p-regist__terms__header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.p-regist__terms__body {
  width: 100%;
  height: 200px;
  font-size: 14px;
  border: 1px solid #CFDAE1;
  padding: 8px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
}

.p-newsArchive__body {
  padding: 16px 0 64px 0;
}

.c-inner,
.c-inner--middle,
.c-inner--large {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 769px) {
  .c-inner,
  .c-inner--middle,
  .c-inner--large {
    padding: 0 32px;
  }
}
@media screen and (max-width: 768px) {
  .c-inner,
  .c-inner--middle,
  .c-inner--large {
    padding: 0 16px;
  }
}

.c-headline {
  font-weight: bold;
  text-align: center;
  position: relative;
}
.c-headline::after {
  content: '';
  width: 240px;
  height: 2px;
  background: #001d36;
  display: block;
  margin: 8px auto 0 auto;
}
@media screen and (min-width: 769px) {
  .c-headline {
    font-size: 32px;
    margin-bottom: 64px;
  }
  .c-headline.under {
    margin-bottom: 32px;
  }
  .c-headline::after {
    width: 240px;
  }
}
@media screen and (max-width: 768px) {
  .c-headline {
    font-size: 20px;
    margin-bottom: 32px;
  }
  .c-headline.under {
    margin-bottom: 16px;
  }
  .c-headline::after {
    width: 120px;
  }
}

.terms-block {
  margin-bottom: 20px;
}
