.l-header {
  width: 100%;
  height: 80px;
}
.c-header__inner {
  width: 100%;
  height: 100%;
  padding-left: 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-header__lang {
  padding-right: 50px;
}

.c-header__nav__item__itemselectLang {
  height: 32px;
  background: #ebf1f3;
  border-radius: 16px;
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 128px;
}

.c-header__nav__item__itemselectLang ::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #9bacb5 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 3px);
  right: 12px;
}

.c-header__nav__item__itemselectLang img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.c-header__nav__item__itemselectLang select {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  color: #001d36;
  padding-left: 4px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .l-header {
    height: 56px;
  }

  .c-header__inner {
    padding-left: 8px;
  }

  .c-header__logo {
    height: 24px;
  }
  .c-header__lang {
    padding-right: 10px;
  }
}
