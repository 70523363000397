.salesTrends {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.trendsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.trendsTitle {
  font-size: 18px;
  font-weight: 600;
  color: #393d3f;
  margin: 0;
}

.periodSelector {
  display: flex;
  align-items: center;
}

.periodLabel {
  font-size: 12px;
  color: #b5b8bc;
  margin-right: 8px;
}

.periodSelect {
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  color: #393d3f;
  background-color: #fff;
  min-width: 300px;
}

.trendsChart {
  margin-bottom: 16px;
}

.chartImage {
  width: 100%;
  height: auto;
}

.legend {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.legendItem {
  display: flex;
  align-items: center;
}

.legendColor {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.colorBase {
  background-color: #3e8cc4;
}

.colorComparison {
  background-color: #ff8360;
}

.legendText {
  font-size: 12px;
  color: #b5b8bc;
}

@media (max-width: 991px) {
  .trendsHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .periodSelector {
    margin-top: 8px;
  }
}

.graph-container {
  height: 500px;
  justify-content: center;
  overflow-x: auto;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 12px;
  color: var(--Color-Gray-500, #71717a);
  font-weight: 400;
  line-height: 133%;
  padding: 17px 24px;
}
