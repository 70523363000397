/*------------------------------------------------------------------------------
reservations
------------------------------------------------------------------------------*/
.page-reservations {
}
.page-reservations__spNav {
  display: none;
}
.page-reservations__basicInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.page-reservations__basicInfo__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-reservations__basicInfo__ttl__label {
  min-width: 96px;
  min-height: 24px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background: #008ec9;
  padding: 2px;
  margin-right: 8px;
  display: inline-block;
}
.page-reservations__basicInfo__ttl__name {
  font-size: 16px;
  font-weight: bold;
}
.page-reservations__basicInfo__btn {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}
.page-reservations__basicInfo__btn :not(:last-child) {
  margin-right: 4px;
}
.page-reservations__basicFrame {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.page-reservations__basicFrame > div:first-child {
  width: calc(60% - 8px);
}
.page-reservations__basicFrame > div:last-child {
  width: calc(40% - 8px);
}
.page-reservations__detailFrame {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.page-reservations__detailFrame > div:first-child {
  width: calc(50% - 8px);
}
.page-reservations__detailFrame > div:last-child {
  width: calc(50% - 8px);
}
.page-reservations__detailFrame2 {
  margin-top: 16px;
}
.page-reservations__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.page-reservations__list li {
  flex: 1;
  position: relative;
}
.page-reservations__list li + li {
  margin-left: 32px;
}
.page-reservations__list li:not(:last-child)::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent #cfdae1;
  position: absolute;
  top: calc(50% - 16px);
  right: -24px;
}
.page-reservations__status {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #f9f9f9;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.page-reservations__status.is-active {
  background: #ebf1f3;
}
.page-reservations__status__ic {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.page-reservations__status__info {
}
.page-reservations__status__info__ttl {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
}
.page-reservations__status__info__date {
  font-size: 14px;
  text-align: center;
  margin-bottom: 8px;
}
.page-reservations__desc {
  min-height: 48px;
  margin-bottom: 8px;
}
.page-reservations__desc__ttl {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}
.page-reservations__desc__body {
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
}
.page-reservations__desc__body.is-close {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.page-reservations__desc__body.is-open {
}
.page-reservations__desc__more {
  display: flex;
  justify-content: flex-end;
}
.page-reservations__history {
}
.page-reservations__history li {
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-start;
}
.page-reservations__history li + li {
  border-top: 1px solid #ebf1f3;
  padding-top: 16px;
}
.page-reservations__resend_email {
  display: flex;
  justify-content: center;
}
.page-reservations__email_title {
  width: 480px;
}

.page-reservations__modal {
}
.page-reservations__modal__box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-reservations__modal__box + .page-reservations__modal__box {
  margin-top: 16px;
}
.page-reservations__modal__box > *:not(:last-child) {
  margin-right: 16px;
}
.page-reservations__modal__box.two > * {
  width: calc((100% - 16px) / 2);
}
.page-reservations__modal__box.three > * {
  width: calc((100% - 32px) / 3);
}
.page-reservations__reference {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-reservations__reference_text {
  width: '50%';
  margin-right: 'auto';
}

.page-reservations__reference_btn {
  margin-left: 'auto';
}

.base-main__body__half {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.base-main__body__half > * {
  width: calc(50% - 8px);
}

@media screen and (max-width: 768px) {
  .page-reservations__spNav {
    margin-top: 16px;
    display: block;
    position: sticky;
    top: 56px;
    z-index: 9;
  }
  .page-reservations__spNav ul {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #cfdae1;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .page-reservations__spNav li {
    width: 25%;
    height: 100%;
    position: relative;
  }
  .page-reservations__spNav li:not(:last-child)::after {
    content: '';
    width: 1px;
    height: 32px;
    background: #cfdae1;
    position: absolute;
    top: 4px;
    right: 0;
  }
  .page-reservations__spNav li a {
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #9bacb5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-reservations__spNav li.is-active a {
    color: #008ec9;
  }

  .page-reservations__basicInfo {
    display: block;
  }
  .page-reservations__basicInfo__ttl {
    display: block;
  }
  .page-reservations__basicInfo__ttl__name {
    font-size: 14px;
    margin-top: 8px;
  }
  .page-reservations__basicInfo__btn {
    margin-top: 16px;
    margin-left: 0;
  }
  .page-reservations__basicInfo__btn > * {
    margin-top: 8px;
  }
  .page-reservations__basicFrame {
    flex-direction: column;
  }
  .page-reservations__basicFrame > * + * {
    margin-top: 8px;
  }
  .page-reservations__basicFrame > div:first-child,
  .page-reservations__basicFrame > div:last-child {
    width: 100%;
  }
  .page-reservations__detailFrame {
    flex-direction: column;
  }
  .page-reservations__detailFrame > div + * {
    margin-top: 16px;
  }
  .page-reservations__detailFrame > div:first-child,
  .page-reservations__detailFrame > div:last-child {
    width: 100%;
  }
  .page-reservations__list {
    flex-direction: column;
  }
  .page-reservations__list li + li {
    margin-left: 0;
    margin-top: 8px;
  }
  .page-reservations__list li:not(:last-child)::after {
    border-width: 16px 16px 0 16px;
    border-color: #cfdae1 transparent transparent transparent;
    top: auto;
    bottom: -12px;
    right: calc(50% + -16px);
    z-index: 1;
  }
  .page-reservations__status {
    flex-direction: row;
    align-items: flex-start;
  }
  .page-reservations__status__ic {
    margin-right: 8px;
  }
  .page-reservations__status__info__ttl,
  .page-reservations__status__info__date {
    text-align: left;
    margin-top: 0;
  }
  .base-main__body__half > * {
    width: 100%;
  }
  .page-reservations__history li {
    padding-bottom: 8px;
    flex-direction: column;
  }
  .page-reservations__history li + li {
    padding-top: 8px;
  }
  .page-reservations__modal__box > *:not(:last-child) {
    margin-right: 0;
  }
  .page-reservations__modal__box.two > * {
    width: 100%;
  }
  .page-reservations__modal__box.three > * {
    width: 100%;
  }

  .page-reservations__resend_email {
    margin: 0 0 1em;
  }

  .page-reservations__email_title {
    margin: 1em 0 0;
    width: 100%;
  }
}

/*------------------------------------------------------------------------------
manifests
------------------------------------------------------------------------------*/
.page-manifests__modal {
}
.page-manifests__modal__box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-manifests__modal__box + .page-manifests__modal__box {
  margin-top: 16px;
}
.page-manifests__modal__box > *:not(:last-child) {
  margin-right: 16px;
}
.page-manifests__modal__box.two > * {
  width: calc((100% - 16px) / 2);
}
.page-manifests__modal__box.three > * {
  width: calc((100% - 32px) / 3);
}
.page-manifests__modal__box__products {
  width: 100%;
}
.page-manifests__modal__box__products__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-manifests__modal__box__products__item + * {
  margin-top: 8px;
}
.page-manifests__modal__box__products__item > label {
  width: calc(100% - 40px - 8px);
}
.page-manifests__modal__box__products__item > a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 8px;
}
.page-manifests__modal__box__products__item > a:hover {
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .page-manifests {
    margin-top: 16px;
  }
  .page-manifests__modal__box > *:not(:last-child) {
    margin-right: 0;
  }
  .page-manifests__modal__box.two > * {
    width: 100%;
  }
  .page-manifests__modal__box.three > * {
    width: 100%;
  }
}

/*------------------------------------------------------------------------------
products availability
------------------------------------------------------------------------------*/
.page-availability__datepick {
  width: 240px;
  height: 40px;
  border-radius: 4px;
  /* border: 1px solid #cfdae1; Note(goro) : 20211122 remove to resolve design collapse*/
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}
.page-availability__datepick__btn {
  width: 40px;
  height: 100%;
  flex-shrink: 0;
  position: relative;
}
.page-availability__datepick__btn.prev::after,
.page-availability__datepick__btn.next::after {
  content: '';
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 5px);
}
.page-availability__datepick__btn.prev::after {
  border-bottom: 2px solid #008ec9;
  border-left: 2px solid #008ec9;
  left: 18px;
}
.page-availability__datepick__btn.next::after {
  border-top: 2px solid #008ec9;
  border-right: 2px solid #008ec9;
  right: 18px;
}
.page-availability__datepick__main {
  width: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 1px solid #cfdae1;
  position: relative;
}
.page-availability__datepick__main input {
  width: 100%;
  height: 100%;
  text-align: center;
}
.page-availability__datepick__main input::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.page-availability__modal {
}
.page-availability__modal__ttl {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  background: #ebf1f3;
  padding: 8px;
}
.page-availability__modal__num {
}
.page-availability__modal__num__header {
  width: 100%;
  border-bottom: 1px solid #cfdae1;
  padding: 16px 0;
}
.page-availability__modal__num__header__img {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-availability__modal__num__header__img p {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #9bacb5;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-availability__modal__num__header__img p img {
  width: 16px;
  height: 16px;
}
.page-availability__modal__num__header__uncounted__units {
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
}
.page-availability__modal__num__header__label {
  margin-left: auto;
}
.page-availability__modal__num__header__label span {
  min-width: 56px;
  padding: 0 4px;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-availability__modal__num__header__label .red {
  background: #dc3e15;
}
.page-availability__modal__num__header__label .yellow {
  background: #ffcc00;
}
.page-availability__modal__num__header__label .green {
  background: #00bf98;
}
.page-availability__modal__num__header__label .blue {
  background: #008ec9;
}
.page-availability__modal__non__confirmed {
  margin-top: 16px;
}
.page-availability__modal__non__confirmed img {
  height: 20px;
  margin-left: 12px;
  margin-right: 4px;
}
.page-availability__modal__num__body {
}
.page-availability__modal__num__body li {
  width: 100%;
  border-bottom: 1px solid #ebf1f3;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-availability__modal__num__body li p:first-child {
  margin: 0px;
}
.page-availability__modal__num__body li p:last-child {
}

.page-availability__modal__table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5px;
}

.page-availability__modal__table th {
  width: 128px;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.page-availability__modal__table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.page-availability__modal__table td span {
  font-weight: bold;
}
.page-availability__modal__table td .red {
  color: #dc3e15;
}
.page-availability__modal__table td .yellow {
  color: #ffcc00;
}
.page-availability__modal__table td .green {
  color: #00bf98;
}
.page-availability__modal__table td .blue {
  color: #008ec9;
}
