.p-seat__equipment-schedule-list__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.p-seat__equipment-schedule-list__start-time {
  box-sizing: border-box;

  width: 64px;
  height: 24px;

  background: #f0faff;
  border: 1px solid #b9e9fe;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;
}

.p-seat__equipment-schedule-list__start-time__label {
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  color: #0171a3;
}
