.modal__table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

.modal__table th {
  width: 128px;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.modal__table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.modal__table td span {
  font-weight: bold;
}
