.page-container {
  display: flex;
  position: relative;
}
.loading-container {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  pointer-events: none;
}
.page-outline {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  padding: 1.143em;
  background: #fff;
  flex-grow: 1;
}
.page-sidebar {
  width: 350px;
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
  margin-left: 16px;
  flex-shrink: 0;
}
.page-sidebar-window {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  padding: 1.143em;
  background: #fff;
}
.page-sidebar-window + .page-sidebar-window {
  margin-top: 16px;
}
.page-sidebar-header {
  font-weight: 700;
  margin-bottom: 8px;
}
.link {
  margin-left: 8px;
  font-weight: 700;
  color: #008ec9;
}

.field {
  /* margin: 0 0 1em; */
  margin: 0 0 1.143em;
}

.field-margin-top {
  margin: 1.143em 0 0 0;
}

.field-label {
  font-weight: bold;
  margin-bottom: 0.286em;
}

.error-box {
  border: 1px solid #e0b4b4;
  border-radius: 5px;
  background-color: #fff6f6;
  color: #9f3a38;
  padding: 1em 1.5em;
  margin: 1em 0;
}

.error-header {
  font-size: 1.286em;
  display: block;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.error-list {
  margin: 0.714em 1.286em;
  padding: 0 0;
}

.error-list-item {
  list-style-type: initial;
  margin: 2px 0;
  font-size: 1.143em;
}

.segment {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  padding: 1.143em;
  margin: 0 0 1.143em;
}

.segment.error {
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

.unit {
  width: 23%;
  margin: 0 1.429em 1.429em 0;
}

.info-message {
  border: 1px solid #a9d5de;
  border-radius: 5px;
  background-color: #f8ffff;
  color: #276f86;
  padding: 1em 1.5em;
  margin: 1em 0;
}

.message {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  background-color: #f8f8f9;
  padding: 1em 1.5em;
  margin: 1em 0;
}

.table-list-th {
  text-align: center;
}

.table-list-td {
  margin: 1em 0;
}
.table-list-td__error {
  color: #9f3a38;
  background: #fff8f8 !important;
}
.fare-summary-table {
  position: relative;
}
.fare-summary-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.fare-summary-table table th {
  font-weight: normal;
  text-align: center;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 0.8em;
}
.fare-summary-table table td {
  border: 1px solid #cfdae1;
  text-align: center;
  background: #fff;
  padding: 0.571em;
}
.fare-summary-table__bold {
  font-weight: bold;
}
.fare-summary-table__small {
  font-size: 12px;
}
.fare-summary-table input {
  width: 80px;
}
.order-summary-close {
  width: 15px;
  height: 15px;
  margin-left: auto;
  position: relative;
  display: none;
}
.order-summary-close:hover {
  opacity: 0.8;
}
.order-summary-close::before,
.order-summary-close::after {
  content: '';
  display: block;
  width: 16px;
  height: 4px;
  border-radius: 4px;
  background: #9bacb5;
  position: absolute;
  top: 4px;
}
.order-summary-close::before {
  transform: rotate(45deg);
}
.order-summary-close::after {
  transform: rotate(-45deg);
}
.delete-cell {
  width: 50px;
}

@media screen and (max-width: 768px) {
  .page-container {
    scroll-margin-top: 64px;
  }
  .unit {
    width: 100%;
    margin: 0 0 1.429em 0;
  }

  .table-list-th {
    text-align: left;
  }
  .desktop-only {
    display: none;
  }
  .booking-summary-popup {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 5px;
    padding: 1.143em;
    background: #fff;
    width: 80%;
    max-width: 350px;
    margin-left: -125px;
    position: fixed;
    z-index: 1;
    right: 8px;
    bottom: 64px;
  }
  .order-summary-close {
    display: block;
  }
  .delete-cell {
    width: 100%;
  }
}
