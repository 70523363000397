.reservationsHeader {
  width: 100%;
  border-bottom: 1px solid #cfdae1;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-end;
}
.reservationsHeader > div {
  width: calc((100% - 16px) / 2.3);
}
.reservationsHeader > div:first-child {
  margin-right: 16px;
}
.reservationsHeader > div:nth-child(3) {
  width: 100%;
  margin-top: 16px;
}

.tag {
  width: 100%;
  margin-bottom: -8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.tag li {
  margin-bottom: 8px;
}
.tag li:not(:last-child) {
  margin-right: 8px;
}
.tag li a {
  min-width: 64px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: #9bacb5;
  background: #dbe4e9;
  border-radius: 12px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dbe4e9;
}
.tag li a:hover {
  opacity: 0.8;
}
.tag li a.is-disabled {
  color: #9bacb5;
  background: #dbe4e9;
}
.tag li a.is-active {
  color: #fff;
  background: #008ec9;
}

.tag li a.is-onsale {
  color: #008ec9;
  background: #008ec9;
}

.tag li a.is-closed {
  color: #9bacb5;
  background: #dbe4e9;
}

.tag li a.is-selected {
  color: #fff;
}

.tag li a.is-unselected {
  background: #fff;
}

.tag li i {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-left: 4px;
  margin-right: -6px;
  position: relative;
  flex-shrink: 0;
}
.tag li i::before,
.tag li i::after {
  content: '';
  box-sizing: border-box;
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 9px;
  left: 5px;
}
.tag li i::before {
  transform: rotate(45deg);
}
.tag li i::after {
  transform: rotate(-45deg);
}
.tag li p {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #dbe4e9;
  position: relative;
  cursor: pointer;
}
.tag li p::before,
.tag li p::after {
  content: '';
  box-sizing: border-box;
  width: 12px;
  height: 2px;
  background: #008ec9;
  position: absolute;
  top: 11px;
  left: 6px;
}
.tag li p::after {
  transform: rotate(90deg);
}
.tag li p:hover {
  opacity: 0.8;
}

.equipmentSortModalIcon {
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .reservationsHeader {
    display: block;
  }
  .equipmentSortModalIcon {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .reservationsHeader > div {
    margin-bottom: 10px;
  }
}
