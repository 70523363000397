.snackbar-container {
  min-width: 300px;
  max-width: 350px;
  margin-left: -125px;
  border-radius: 2px;
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 20px;
}

.snackbar {
  background: #fff;
  border: 1px solid #cfdae1;
  border-radius: 2px;
  position: relative;
  padding: 10px;
}

.snackbar img {
  width: 100%;
}

.snackbar.fade-out {
  animation: fade-out 10s linear forwards;
}

@keyframes fade-out {
  95% {
    opacity: 1;
    transform: translateY(0px);
  }
  99% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
  }
}

.snackbar-title {
  font-size: 20px;
  font-weight: bold;
}

.snackbar-description {
}

.snackbar-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.snackbar-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.snackbar-close:hover {
  opacity: 0.8;
}
.snackbar-close::before,
.snackbar-close::after {
  content: '';
  display: block;
  width: 16px;
  height: 4px;
  border-radius: 4px;
  background: #9bacb5;
  position: absolute;
  top: 4px;
}
.snackbar-close::before {
  transform: rotate(45deg);
}
.snackbar-close::after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .snackbar-container {
    max-width: 60vw;
    min-width: auto;
    right: 10px;
    bottom: 10px;
  }
}
