.sessionContainer {
  border-radius: 20px;
  border: 1px solid var(--colour-12, #f3f6f8);
  background: var(--white, #fff);
  box-shadow: 0 4px 10.3px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  width: 100%;
  padding: var(--Dimension-Spacing-4, 16px) var(--Dimension-Spacing-6, 24px);
  flex-direction: column;
  justify-content: center;
  gap: var(--Dimension-Spacing-6, 24px);
  cursor: pointer;
}
.sessionContainer.active {
  border: 1px solid var(--color-15, #3aa0ff);
}
@media (max-width: 991px) {
  .sessionContainer {
    max-width: 100%;
    padding: 0 20px;
  }
}

.sessionContent {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  justify-content: start;
}

@media (max-width: 991px) {
  .sessionContent {
    max-width: 100%;
  }
}

.sessionDetails {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
}

@media (max-width: 991px) {
  .sessionDetails {
    max-width: 100%;
  }
}

.timeContainer {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 16px;
  text-transform: capitalize;
  justify-content: start;
  margin: auto 0;
  font: 600 16px Inter, sans-serif;
}

.sessionTime {
  color: var(--color-15, #3aa0ff);
  line-height: 32px;
  align-self: stretch;
  margin: auto 0;
}

.sessionInfo {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: start;
  margin: auto 0;
}

.sessionNameDuration {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: start;
  margin: auto 0;
}

.sessionName {
  color: #393d3f;
  line-height: 32px;
  align-self: stretch;
  margin: auto 0;
  color: #393d3f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  text-transform: capitalize;
}

.sessionDuration {
  color: #b5b8bc;
  align-self: stretch;
  margin: auto 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}

.sessionImage {
  aspect-ratio: 1.59;
  object-fit: contain;
  object-position: center;
  width: 51px;
  align-self: stretch;
  gap: -15px;
}

.iconContainer {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: start;
  margin: auto 0;
}

.icon {
  aspect-ratio: 0.81;
  object-fit: contain;
  object-position: center;
  width: 13px;
  align-self: stretch;
  margin: auto 0;
}
.iconLabel {
  color: #3aa0ff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 200% */
  text-transform: capitalize;
}

.arrowIcon {
  aspect-ratio: 2;
  object-fit: contain;
  object-position: center;
  width: 10px;
  stroke-width: 2px;
  stroke: var(--colour-10, #393d3f);
  align-self: stretch;
  margin: auto 0;
}
.chips {
  display: flex;
  align-items: center;
}
.chip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 33px;
  height: 33px;
  padding: 0 8px;
}
.chip.ticket {
  background: #44bc7f;
}
.chip.mobileOrder {
  background: #ff8360;
}
.chipIcon {
  width: 15px;
}
.highlighted {
  border: 1px solid var(--color-15, #3aa0ff);
}
