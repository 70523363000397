.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}
.container-row {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .container-row {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
