.p-availabilityTable {
  height: calc(100% - 76px) !important;
  position: relative;
}
.p-availabilityTable__header {
  overflow-x: hidden;
}
.p-availabilityTable__header > table {
  border-top: 1px solid var(--gray200);
}
.p-availabilityTable__body {
  overflow-x: auto;
  position: relative;
}
.p-availabilityTable__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.p-availabilityTable__table th {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  font-weight: var(--text-normal);
  background-color: var(--gray50);
}
.p-availabilityTable__table td {
  background-color: var(--white);
  padding: 12px 16px;
  position: relative;
}
/* .p-availabilityTable__table tr:nth-child(odd) td {
  background-color: var(--gray50);
} */
.p-availabilityTable__table th,
.p-availabilityTable__table td {
  width: 136px;
  border-top: none;
  border-right: 1px solid var(--gray200);
  border-bottom: 1px solid var(--gray200);
  border-left: none;
}
.p-availabilityTable__table th:first-child,
.p-availabilityTable__table td:first-child {
  border-left: none;
}
.p-availabilityTable__table th:last-child,
.p-availabilityTable__table td:last-child {
  border-right: none;
}
.p-availabilityTable__table__th {
  height: 64px;
  color: var(--gray700);
  padding: 12px 16px;
  position: relative;
}
.p-availabilityTable__table__th p:first-child {
  font-weight: var(--text-semibold);
}
.p-availabilityTable__table__th p:nth-child(2) {
  margin-top: 8px;
}
.p-availabilityTable__table__th p:nth-child(2) span {
  color: var(--gray500);
  margin-right: 4px;
}
.p-availabilityTable__table__td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-availabilityTable__table__td img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.p-availabilityTable__table__td p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-semibold);
}
.p-availabilityTable__table__ttl {
}
.p-availabilityTable__table__ttl .link1 {
  width: fit-content;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-semibold);
  color: var(--primary600);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityTable__table__ttl .link2 {
  width: fit-content;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityTable__table__ttl .link2 i {
  font-size: 16px;
  margin-right: 4px;
}
.p-availabilityTable__table__ttl .link2 span {
  text-decoration: underline;
}

.p-availabilitySticky-top {
  position: sticky;
  top: 0;
  z-index: 3;
}
.p-availabilitySticky-left {
  width: 240px !important;
  border-right: none !important;
  position: sticky !important;
  left: 0;
  z-index: 2;
}
.p-availabilitySticky-left::after {
  content: '';
  width: 0;
  height: 100%;
  border-right: 1px solid var(--gray200);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
.p-availabilitySticky-left2 {
  width: 96px !important;
  border-right: none !important;
  text-align: center;
  position: sticky !important;
  left: 240px;
  z-index: 2;
}
.p-availabilitySticky-left2::before {
  content: '';
  width: 0;
  height: 100%;
  border-right: 1px solid var(--gray200);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
.p-availabilitySticky-left2::after {
  content: '';
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.05);
}
.p-availabilityTable__table
  td:not(.p-availabilitySticky-left):not(.p-availabilitySticky-left2):hover {
  background-color: var(--primary50);
}

.p-availabilityPagination {
  width: 100%;
  height: 76px !important;
  border-top: 1px solid var(--gray200);
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow-y: visible !important;
}

.p-availabilityTable__table__check {
  width: 16px;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  left: 12px;
  z-index: 1;
}

.p-availabilityTable__table__spHeader {
  display: none;
}
.p-availabilityTable__table__spTdHeader {
  display: none;
}
@media screen and (max-width: 768px) {
  .p-availabilityTable {
    border-top: 1px solid var(--gray200);
    overflow: hidden;
  }
  .p-availabilityTable__body {
  }
  .p-availabilityTable__body > div {
    width: 100%;
    overflow-x: auto;
  }
  .p-availabilitySticky-left {
    display: none;
  }
  .p-availabilitySticky-left2 {
    left: 0;
  }
  .p-availabilityTable__table__spHeader {
    display: table-row;
  }
  .p-availabilityTable__table__spTdHeader {
    border-bottom: 1px solid var(--gray200);
    padding: 12px 16px;
    display: block;
    position: sticky;
    left: 0;
  }
}

.p-availabilityTable__table td:hover {
  cursor: pointer;
}
.p-availabilityTable__table td.red1 {
  background-color: var(--error100);
}
.p-availabilityTable__table td.red2 {
  background-color: var(--error200);
}
.p-availabilityTable__table td.red3 {
  background-color: var(--error300);
}
.p-availabilityTable__table td.red4 {
  background-color: var(--error400);
}
.p-availabilityTable__table td.red5 {
  background-color: var(--error600);
}
.p-availabilityTable__table td.gray {
  color: var(--gray400);
  background-color: var(--gray100);
}
