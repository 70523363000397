.container {
  display: flex;
  gap: 16px;
  padding: 8px 12px;
  background: #FFFBEB;
}
.timeline {
  display: flex;
  padding-bottom: 28px;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: var(--Colors-Gray-500, #71717a);
  font-weight: 400;
  white-space: nowrap;
  line-height: 133%;
}
.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #F59E0B;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon i {
  font-size: 20px;
  color: black;
}
.img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 40px;
  align-self: center;
}
.duration {
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  margin: auto 0;
  width: 100%;
}
.header {
  display: flex;
  gap: 16px;
  white-space: nowrap;
}
.title {
  color: var(--Colors-Gray-900, #18181b);
  flex-shrink: 1;
  flex-basis: auto;
  font: 500 16px/150% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.timestamp {
  color: var(--Colors-Gray-500, #71717a);
  margin: auto 0 auto auto;
  font: 400 14px/143% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.body {
  border-radius: 12px;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  font-size: 12px;
  color: var(--Colors-Gray-900, #18181b);
  font-weight: 400;
  line-height: 16px;
  justify-content: center;
  padding: 12px;
}
.body-inner {
  display: flex;
  gap: 16px;
}
.thumbnail {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 32px;
  border-radius: 8px;
}
.description {
  display: flex;
  gap: 8px;
  flex-shrink: 1;
  width: 100%;
}
.page-title {
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  flex-shrink: 1;
  flex-basis: auto;
  /*
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  */
  /* height: 32px; */
}
.link {
  margin-left: auto;
}
.link i {
  font-size: 20px;
}
.attr {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
.attr-name {
  color: var(--Colors-Gray-500, #71717a);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.attr-value {
  color: var(--Colors-Gray-900, #18181b);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
