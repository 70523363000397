:root {
  --bg: #EBF1F3;
  --nav: #4A6A78;
  --nav2: #9BACB5;
  --nav3: #CFDAE1;
  
  --black: #000000;
  --white: #FFFFFF;
  /* Gray */
  --gray50:  #FAFAFA;
  --gray100: #F4F4F5;
  --gray200: #E4E4E7;
  --gray300: #D4D4D8;
  --gray400: #A1A1AA;
  --gray500: #71717A;
  --gray600: #52525B;
  --gray700: #3F3F46;
  --gray800: #27272A;
  --gray900: #18181B;
  --gray950: #09090B;
  /* Primary */
  --primary50:  #F0FAFF;
  --primary100: #E0F3FE;
  --primary200: #B9E9FE;
  --primary300: #7CD9FD;
  --primary400: #36C7FA;
  --primary500: #0CB0EB;
  --primary600: #008EC9;
  --primary700: #0171A3;
  --primary800: #065F86;
  --primary900: #0B4F6F;
  --primary950: #07324A;
  /* Error */
  --error50:  #FEF2F2;
  --error100: #FEE2E2;
  --error200: #FECACA;
  --error300: #FCA5A5;
  --error400: #F87171;
  --error500: #EF4444;
  --error600: #DC2626;
  --error700: #B91C1C;
  --error800: #991B1B;
  --error900: #7F1D1D;
  --error950: #450A0A;
  /* Warning */
  --warning50:  #FFFBEB;
  --warning100: #FEF3C7;
  --warning200: #FDE68A;
  --warning300: #FCD34D;
  --warning400: #FBBF24;
  --warning500: #F59E0B;
  --warning600: #D97706;
  --warning700: #B45309;
  --warning800: #92400E;
  --warning900: #78350F;
  --warning950: #451A03;
  /* Success */
  --success50:  #ECFDF5;
  --success100: #D1FAE5;
  --success200: #A7F3D0;
  --success300: #6EE7B7;
  --success400: #34D399;
  --success500: #10B981;
  --success600: #059669;
  --success700: #047857;
  --success800: #065F46;
  --success900: #064E3B;
  --success950: #022C22;
  /* Orange */
  --orange50:  #FFF7ED;
  --orange100: #FFEDD5;
  --orange200: #FED7AA;
  --orange300: #FDBA74;
  --orange400: #FB923C;
  --orange500: #F97316;
  --orange600: #EA580C;
  --orange700: #C2410C;
  --orange800: #9A3412;
  --orange900: #7C2D12;
  --orange950: #431407;
  /* Yellow */
  --yellow50:  #FEFCE8;
  --yellow100: #FEF9C3;
  --yellow200: #FEF08A;
  --yellow300: #FDE047;
  --yellow400: #FACC15;
  --yellow500: #EAB308;
  --yellow600: #CA8A04;
  --yellow700: #A16207;
  --yellow800: #854D0E;
  --yellow900: #713F12;
  --yellow950: #422006;
  /* Lime */
  --lime50:  #F7FEE7;
  --lime100: #ECFCCB;
  --lime200: #D9F99D;
  --lime300: #BEF264;
  --lime400: #A3E635;
  --lime500: #84CC16;
  --lime600: #65A30D;
  --lime700: #4D7C0F;
  --lime800: #3F6212;
  --lime900: #365314;
  --lime950: #1A2E05;
  /* Teal */
  --teal50:  #F0FDFA;
  --teal100: #CCFBF1;
  --teal200: #99F6E4;
  --teal300: #5EEAD4;
  --teal400: #2DD4BF;
  --teal500: #14B8A6;
  --teal600: #0D9488;
  --teal700: #0F766E;
  --teal800: #115E59;
  --teal900: #134E4A;
  --teal950: #042F2E;
  /* Emerald */
  --emerald50:  #ECFDF5;
  --emerald100: #D1FAE5;
  --emerald200: #A7F3D0;
  --emerald300: #6EE7B7;
  --emerald400: #34D399;
  --emerald500: #10B981;
  --emerald600: #059669;
  --emerald700: #047857;
  --emerald800: #065F46;
  --emerald900: #064E3B;
  --emerald950: #022C22;
  /* Sky */
  --sky50:  #F0F9FF;
  --sky100: #E0F2FE;
  --sky200: #BAE6FD;
  --sky300: #7DD3FC;
  --sky400: #38BDF8;
  --sky500: #0EA5E9;
  --sky600: #0284C7;
  --sky700: #0369A1;
  --sky800: #075985;
  --sky900: #0C4A6E;
  --sky950: #082F49;
  /* Blue */
  --blue50:  #EFF6FF;
  --blue100: #DBEAFE;
  --blue200: #BFDBFE;
  --blue300: #93C5FD;
  --blue400: #60A5FA;
  --blue500: #3B82F6;
  --blue600: #2563EB;
  --blue700: #1D4ED8;
  --blue800: #1E40AF;
  --blue900: #1E3A8A;
  --blue950: #172554;
  /* Indigo */
  --indigo50:  #EEF2FF;
  --indigo100: #E0E7FF;
  --indigo200: #C7D2FE;
  --indigo300: #A5B4FC;
  --indigo400: #818CF8;
  --indigo500: #6366F1;
  --indigo600: #4F46E5;
  --indigo700: #4338CA;
  --indigo800: #3730A3;
  --indigo900: #312E81;
  --indigo950: #1E1B4B;
  /* Violet */
  --violet50:  #F5F3FF;
  --violet100: #EDE9FE;
  --violet200: #DDD6FE;
  --violet300: #C4B5FD;
  --violet400: #A78BFA;
  --violet500: #8B5CF6;
  --violet600: #7C3AED;
  --violet700: #6D28D9;
  --violet800: #5B21B6;
  --violet900: #4C1D95;
  --violet950: #2E1065;
  /* Purple */
  --purple50:  #FAF5FF;
  --purple100: #F3E8FF;
  --purple200: #E9D5FF;
  --purple300: #D8B4FE;
  --purple400: #C084FC;
  --purple500: #A855F7;
  --purple600: #9333EA;
  --purple700: #7E22CE;
  --purple800: #6B21A8;
  --purple900: #581C87;
  --purple950: #3B0764;
  /* Fuchsia */
  --fuchsia50:  #FDF4FF;
  --fuchsia100: #FAE8FF;
  --fuchsia200: #F5D0FE;
  --fuchsia300: #F0ABFC;
  --fuchsia400: #E879F9;
  --fuchsia500: #D946EF;
  --fuchsia600: #C026D3;
  --fuchsia700: #A21CAF;
  --fuchsia800: #86198F;
  --fuchsia900: #701A75;
  --fuchsia950: #701A75;
  /* Pink */
  --pink50:  #FDF2F8;
  --pink100: #FCE7F3;
  --pink200: #FBCFE8;
  --pink300: #F9A8D4;
  --pink400: #F472B6;
  --pink500: #EC4899;
  --pink600: #DB2777;
  --pink700: #BE185D;
  --pink800: #9D174D;
  --pink900: #831843;
  --pink950: #500724;
  /* Rose */
  --rose50:  #FFF1F2;
  --rose100: #FFE4E6;
  --rose200: #FECDD3;
  --rose300: #FDA4AF;
  --rose400: #FB7185;
  --rose500: #F43F5E;
  --rose600: #E11D48;
  --rose700: #BE123C;
  --rose800: #9F1239;
  --rose900: #881337;
  --rose950: #4C0519;
  /* weight */
  --text-normal: 400;
  --text-medium: 500;
  --text-semibold: 600;
  --text-bold: 700;
  /* text-xs */
  --text-xs-size: 12px;
  --text-xs-height: 16px;
  /* text-sm */
  --text-sm-size: 14px;
  --text-sm-height: 20px;
  /* text-base */
  --text-base-size: 16px;
  --text-base-height: 24px;
  /* text-lg */
  --text-lg-size: 18px;
  --text-lg-height: 28px;
  /* text-xl */
  --text-xl-size: 20px;
  --text-xl-height: 28px;
  /* text-2xl */
  --text-2xl-size: 24px;
  --text-2xl-height: 32px;
  /* text-3xl */
  --text-3xl-size: 30px;
  --text-3xl-height: 36px;
  /* text-4xl */
  --text-4xl-size: 36px;
  --text-4xl-height: 40px;
  /* text-5xl */
  --text-5xl-size: 48px;
  --text-5xl-height: 48px;
  /* text-6xl */
  --text-6xl-size: 60px;
  --text-6xl-height: 60px;
  /* text-7xl */
  --text-7xl-size: 72px;
  --text-7xl-height: 72px;
  /* text-8xl */
  --text-8xl-size: 96px;
  --text-8xl-height: 96px;
  /* text-9xl */
  --text-9xl-size: 128px;
  --text-9xl-height: 128px;
  /* radius */
  --radius-none: 0px;
  --radius-xxs: 2px;
  --radius-xs: 4px;
  --radius-sm: 6px;
  --radius-md: 8px;
  --radius-lg: 10px;
  --radius-xl: 12px;
  --radius-2xl: 16px;
  --radius-3xl: 20px;
  --radius-4xl: 24px;
  --radius-full: 9999px;
  /* shadow */
  --shadow-none: 0;
  --shadow-xs: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  --shadow-sm: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
              0 1px 3px 0 rgba(16, 24, 40, 0.1);
  --shadow-md: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
              0 4px 8px -2px rgba(16, 24, 40, 0.1);
  --shadow-lg: 0 2px 6px -2px rgba(16, 24, 40, 0.03),
              0 12px 16px -4px rgba(16, 24, 40, 0.08);         
  --shadow-xl: 0 8px 8px -4px rgba(16, 24, 40, 0.03),
              0 20px 24px -4px rgba(16, 24, 40, 0.08);
  --shadow-2xl: 0 24px 48px -12px rgba(16, 24, 40, 0.18);
  --shadow-3xl: 0 32px 64px -12px rgba(16, 24, 40, 0.14);
  /* focus */
  --ring-primary-shadow-xs: 0 0 0 4px var(--primary100);
  --ring-primary-shadow-sm: 0 0 0 4px var(--primary100);
  --ring-gray-shadow-xs: 0 0 0 4px var(--gray100);
  --ring-gray-shadow-sm: 0 0 0 4px var(--gray100);
  --ring-error-shadow-xs: 0 0 0 4px var(--error100);
}