.bulk_cancel_button {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.bulk_cancel_button > *:not(:last-child) {
  margin-right: 16px;
}
.header_right_other_block {
  display: flex;
}
.header_right_other_block > *:not(:last-child) {
  margin-right: 16px;
}
.header_right_other_block_mobile {
}
.mobile__right__header {
  display: flex;
}
.mobile__right__header__bulk_cancel_mode {
}
.reservation__suggest__block {
}

@media screen and (max-width: 1085px) {
  .header_right_other_block_mobile {
    margin-top: 5px;
    justify-content: flex-end;
  }
  .mobile__right__header {
    margin-top: 5px;
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
  }
  .mobile__right__header__bulk_cancel_mode {
    display: block;
  }
  .reservation__suggest__block {
    margin-left: auto;
  }
}
