.input-container {
  margin-bottom: 16px;
}

.input-container:last-child {
  margin-bottom: 0;
}

.dragHandle {
  cursor: grab;
  display: flex;
  align-items: center;
  color: var(--gray500);
}

.dragHandle:active {
  cursor: grabbing;
}
