
.c-pagination {
  width: 100%;
  margin: 16px 0 8px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.c-pagination > *:not(:last-child) {
  margin-right: 32px;
}
.c-pagination__size {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__size p {
  margin-right: 8px;
}
.c-pagination__size label {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #fff;
  display: block;
  position: relative;
}
.c-pagination__size label select {
  width: 100%;
  height: 100%;
  padding: 0 24px 0 8px;
  cursor: pointer;
}
.c-pagination__size label::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #001d36 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 4px);
  right: 8px;
}
.c-pagination__jump {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__jump p:first-child {
  margin-right: 8px;
}
.c-pagination__jump p:last-child {
  margin-left: 8px;
}
.c-pagination__jump input {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 0 8px;
}
.c-pagination__btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__btns > *:not(:last-child) {
  margin-right: 16px;
}
.c-pagination__btn {
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff !important;
  border-radius: 4px;
  background: #4a6c7a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-pagination__btn.disable {
  background: #cfdae1;
  cursor: default;
  pointer-events: none;
}
.c-pagination__btn:hover {
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .c-pagination {
    margin: 0;
    padding: 0 16px 32px 16px;
    justify-content: center;
  }
  .c-pagination > *:not(:last-child) {
    margin-right: 0;
  }
  .c-pagination__size {
    order: 1;
    margin-right: 16px !important;
  }
  .c-pagination__jump {
    order: 1;
  }
  .c-pagination__btns {
    width: 100%;
    margin: 32px 16px;
    order: 0;
  }
  .c-pagination__btn {
    flex: 1;
  }
}

.reservationsAction {
  height: calc(100% - 220px);
  display: flex;
  flex-direction: column;
  position: relative;
}
.reservationsAction__header {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.reservationsAction__header__clear {
  margin-left: auto;
  color: #dc3e15;
}
.reservationsAction__header__clear:hover {
  color: #dc3e15;
  opacity: 0.8;
}
.reservationsAction__body {
  height: 100%;
}
.reservationsAction__body__action {
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.reservationsAction__body__action__mode {
  width: 72px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.reservationsAction__body__action__mode:hover {
  opacity: 0.8;
}
.reservationsAction__body__action__mode .number {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-right: 1px solid #EBF1F3;
  display: block;
  flex-shrink: 0;
}
.reservationsAction__body__action__mode .text {
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: center;
}
.reservationsAction__body__action__mode .text.decided {
  color: #9BACB5;
}
.reservationsAction__body__action__mode .text.normal {
  color: #008ec9;
}
.reservationsAction__body__action__another {
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  margin-left: 4px;
  position: relative;
}
.reservationsAction__body__action__another i,
.reservationsAction__body__action__another::before,
.reservationsAction__body__action__another::after {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #008ec9;
  position: absolute;
  top: calc(50% - 2px);
}
.reservationsAction__body__action__another::before,
.reservationsAction__body__action__another::after {
  content: "";
  box-sizing: border-box;
}
.reservationsAction__body__action__another::before { left: calc(50% - 8px); }
.reservationsAction__body__action__another i { left: calc(50% - 2px); }
.reservationsAction__body__action__another::after { left: calc(50% + 5px); }
.reservationsAction__body__action__another:hover {
  opacity: 0.8;
}

.reservationsAction__body__action__memo {
  margin-left: 4px;
}

.table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
/* .table tr:nth-child(odd) {
  background: #fff;
}
.table tr:nth-child(even) {
  background: #f9f9f9;
} */
/* original
.table tr.disable {
  position: relative;
}
.table tr.disable::after {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
*/
.table tr.disable {
  opacity: 0.4;
}
.table th,
.table td {
  padding: 8px;
  border: 1px solid #cfdae1;
  background: #fff;
  z-index: 0;
  position: relative;
}
.table th {
  height: 32px;
  text-align: center;
}

.stickyTable {
  overflow: auto;
  width: 100%;
  height: 100%;
}
.stickyTable table thead .sticky-top {
  border-top: none;
  position: sticky;
  top: 0;
  z-index: 1;
}
.stickyTable table .sticky-top::before {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-top: 1px solid #cfdae1;
  border-bottom: 3px solid #cfdae1;
  position: absolute;
  bottom: 0;
  left: 0;
}
.stickyTable table .sticky-left {
  border-left: none;
  position: sticky;
  left: 0;
  z-index: 2;
}
.stickyTable table thead .sticky-left {
  z-index: 3;
}
.stickyTable table .sticky-left::after {
  content: "";
  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  right: 0;
}


.reservationsAction__pagination {
  padding: 16px;
}
.reservationsAction__pagination .c-pagination {
  margin: 0;
}
.reservationsAction__pagination .c-pagination__btn {
  width: 64px;
  height: 32px;
}
.reservationsAction__pagination .c-pagination > *:not(:last-child) {
  margin-right: 16px;
}
.reservationsAction__pagination .c-pagination__btns > *:not(:last-child) {
  margin-right: 8px;
}


