.p-comparison {
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.p-comparison.is-hover {
  border-color: var(--primary200);
}
.p-comparison.is-active {
  border-color: var(--primary700);
}
.p-comparison.is-disabled {
  background-color: var(--gray100);
  pointer-events: none;
}
.p-comparison.is-disabled .p-comparison__body::before {
  color: var(--gray500);
}
.p-comparison__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  position: relative;
  cursor: pointer;
}
.p-comparison__body::before {
  content: '';
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: '\b450';
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.p-comparison__main {
  height: 24px;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.p-comparison__sub {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  margin-top: 4px;
}
.p-comparison__sub span {
  color: var(--gray400);
  margin-right: 4px;
}
.p-comparison__menu {
  width: 100%;
  width: 560px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
}
.p-comparison__menu__body {
  padding: 16px;
}
.p-comparison__menu__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.p-comparison__menu__flex > * {
  flex-shrink: 1;
}
.p-comparison__menu__flex > *:first-child {
  margin-right: 12px;
  min-width: 262px;
}
.date {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
  margin-bottom: 0;
}
.date.active {
  border-color: var(--blue500)
}
.p-comparison__menu__toggle {
  margin-top: 16px;
}
.p-comparison__menu__calendar {
  margin-top: 16px;
}
.p-comparison__menu__actions {
  width: 100%;
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-comparison__menu__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-comparisonCalendar__menu {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
}
.p-comparisonCalendar__menu__header {
  width: 100%;
  border-bottom: 1px solid var(--gray200);
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-comparisonCalendar__menu__header a {
  width: 40px;
  height: 40px;
  position: relative;
}
.p-comparisonCalendar__menu__header a::before {
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid var(--primary600);
  border-left: 2px solid var(--primary600);
  transform: rotate(-45deg);
  position: absolute;
  top: 16px;
  left: 16px;
}
.p-comparisonCalendar__menu__header a:last-child {
  transform: scale(-1, 1);
}
.p-comparisonCalendar__menu__header a:hover {
  background-color: var(--primary50);  
}
.p-comparisonCalendar__menu__header p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  margin-bottom: 0;
}
.p-comparisonCalendar__menu__body__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-comparisonCalendar__menu__body__header li {
  width: calc(100% / 7);
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-comparisonCalendar__menu__body__day {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.p-comparisonCalendar__menu__body__day li {
  width: calc(100% / 7);
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.p-comparisonCalendar__menu__body__day li.disabled {
  color: var(--gray300);
  pointer-events: none;
}
.p-comparisonCalendar__menu__body__day li.start {
  /* color: var(--white); */
  border-radius: 4px 0 0 4px;
}
.p-comparisonCalendar__menu__body__day li.end {
  /* color: var(--white); */
  border-radius: 0 4px 4px 0;
}
.p-comparisonCalendar__menu__body__day li.between {
  
}
.p-comparisonCalendar__menu__body__day li:not(.start):not(.end):not(.between):hover {
  background-color: var(--gray50);
}
.p-comparisonCalendar__menu__body__day li.start.blue,
.p-comparisonCalendar__menu__body__day li.end.blue {
  background-color: var(--blue500);
  color: var(--white);
}
.p-comparisonCalendar__menu__body__day li.between.blue {
  background-color: var(--blue50);
  color: var(--gray900);
}
.p-comparisonCalendar__menu__body__day li.start.pink,
.p-comparisonCalendar__menu__body__day li.end.pink {
  background-color: var(--pink500);
  color: var(--white);
}
.p-comparisonCalendar__menu__body__day li.between.pink {
  background-color: var(--pink50);
  color: var(--gray900);
}
/* Scenario where comparison date range is within base range or vice versa */
.p-comparisonCalendar__menu__body__day li.blue.between.start.pink,
.p-comparisonCalendar__menu__body__day li.blue.between.end.pink {
  color: var(--gray900);
}
.p-comparisonCalendar__note {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-comparisonCalendar__note p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-comparisonCalendar__note p:not(:last-child) {
  margin-right: 16px;
}
.p-comparisonCalendar__note p span {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  display: block;
}
.p-comparisonCalendar__note p span.blue {
  background-color: var(--blue500);
}
.p-comparisonCalendar__note p span.pink {
  background-color: var(--pink500);
}
@media screen and (max-width: 768px) {
  .p-comparison__menu {
    width: 100%;
  }
  .p-comparison__menu__flex {
    flex-direction: column;
  }
  .p-comparison__menu__flex > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 8px;
}
}
.dropdown-subtitle {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
}