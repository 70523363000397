/* NEW STYLE */
.c-fileUpload__input {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -16px;
}
.c-fileUpload__input li {
  width: 128px;
  height: 128px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
}
.c-fileUpload__input__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
.c-fileUpload__input__item > *:not(:first-child) {
  margin-left: 8px;
}
.c-fileUpload__input__link {
  color: var(--primary600);
}
.c-fileUpload__input__link:hover {
  color: var(--primary500);
}
.c-fileUpload__input__delete {
  font-size: var(--text-base-size);
  display: flex;
  align-items: center;
}
.c-fileUpload__input li div,
.c-fileUpload__input li img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.c-fileUpload__input li a {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
}
.c-fileUpload__input li a i {
  font-size: 16px;
  color: var(--white);
}
.c-fileUpload__input li.add {
  border: 1px dashed var(--primary700);
  color: var(--primary700);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.c-fileUpload__input li.add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.c-fileUpload__input li.add p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .c-fileUpload__input li {
    width: 100px;
    height: 100px;
  }
}
.c-fileUpload__helperText {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
}
.c-fileUpload__helperText > p:not(:first-child) {
  margin-top: 4px;
}
.c-fileUpload__youtube i {
  color: #eb361c;
}
