.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.subMenuContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.subMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subMenuTitle {
  font-size: 0.875rem;
  font-weight: 500;
}

.subMenuItem {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  text-decoration: none;
  border-radius: 4px;
}

.button.delete {
  color: #dc3545;
}

.button.delete:hover {
  color: #dc3545;
}

.button i {
  font-size: 16px;
}
