.g-section {
  padding: 0 32px;
}
.p-download {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-download__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-download__header__title {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
}
.p-download__body {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  position: relative;
}
.p-download__body__item {
  width: 360px;
}
.p-download__body__item__num {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-download__body__item__num::before,
.p-download__body__item__num::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--gray300);
}
.p-download__body__item:first-child .p-download__body__item__num::before,
.p-download__body__item:last-child .p-download__body__item__num::after {
  background-color: transparent;
}
.p-download__body__item__num p {
  width: 24px;
  height: 24px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  font-weight: var(--text-medium);
  border-radius: 100%;
  color: var(--white);
  background-color: var(--gray300);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  flex-shrink: 0;
}
.p-download__body__item__num p.is-active {
  background-color: var(--primary600);
}

.p-download__body__item__ttl {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-semibold);
  text-align: center;
  padding: 0 16px;
  margin-top: 12px;
}
.p-download__body__item__action {
  width: 100%;
  padding: 0 16px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
}
.p-download__body__item__edit {
  padding: 0 16px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-download__body__item__edit p {
  max-width: 180px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  font-weight: var(--text-normal);
  color: var(--gray400);
  margin-right: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
@media screen and (max-width: 768px) {
  .g-section {
    padding: 0 16px;
  }
  .p-download__body {
    flex-direction: column;
  }
  .p-download__body__item {
    width: 100%;
  }
  .p-download__body__item:not(:first-child) {
    margin-top: 16px;
  }
  .p-download__body__item__num {
    justify-content: flex-start;
  }
  .p-download__body__item__num p {
    margin-left: 0;
  }
  .p-download__body__item__num::before {
    display: none;
  }
  .p-download__body__item:last-child .p-download__body__item__num::after {
    background-color: var(--gray300);
  }
  .p-download__body__item__ttl {
    text-align: left;
    padding: 0;
  }
  .p-download__body__item__action {
    justify-content: flex-end;
    padding: 0;
  }
  .p-download__body__item__edit {
    justify-content: flex-start;
    padding: 0;
  }
}


.p-csv {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-csv__header {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-csv__header__ttl {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
}
.p-csv__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-csv__actions > * {
  margin-left: 12px;
}
.p-csv__body__btn a {
  text-decoration: none !important;
}
.p-csv__bottom {
  width: 100%;
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .p-csv {
    width: 100%;
    border-radius: var(--radius-none);
    border: none;
    background-color: transparent;
  }
  .p-csv__header {
    padding: 0;
    margin-bottom: 16px;
  }
  .p-csv__bottom {
    padding: 16px 0;
  }
}



.p-downloadModal {

}
.p-downloadModal__item:not(:first-child) {
  margin-top: 20px;
}
.p-downloadModal__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  margin-bottom: 6px;
}
.p-downloadModal__item__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-downloadModal__item__flex > div {
  width: 100%;
  flex-shrink: 1;
}
.p-downloadModal__item__flex > div:not(:first-child) {
  margin-left: 20px;  
}
.p-downloadModal__item__body {

}
.p-downloadModal__item__body__check {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.p-downloadModal__item__body__check > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-right: 16px;
  flex-shrink: 0;
}
.p-downloadModal__item__body__check > div {
  display: flex;
  justify-content: flex-start;
}
.p-downloadModal__item__body__check > div > * {
  margin-right: 16px;
}
.p-downloadModal__select {
  width: fit-content;
  min-height: 44px;
  position: absolute;
  top: 12px;
  right: 8px;
}
.p-downloadModal__select__body {
  width: 100%;
  height: 100%;
  padding: 8px 40px 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.p-downloadModal__select__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.p-downloadModal__select__body__title {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-downloadModal__select__menu {
  width: 100%;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-downloadModal__select__menu.is-active {
  display: block;
}
.p-downloadModal__select__menu__item {
  padding: 8px 10px;
  position: relative;
}
.p-downloadModal__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-downloadModal__select__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.p-downloadModal__select__menu__item i {
  opacity: 0;
}
.p-downloadModal__select__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.p-downloadModal__select__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.p-downloadModal__select__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}


/* p-sortList */
.p-sortList {
  position: relative;
}
.p-sortList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item:not(:first-child) {
  margin-top: 12px;
}
.p-sortList__item.is-disabled {
  color: var(--gray400);
  background-color: var(--gray50);
  pointer-events: none;
}
.p-sortList__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item__left p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-sortList__item__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-sortList__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-sortList__item__ic i::before,
.p-sortList__item__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-sortList__item__right {
  margin-left: auto;
}
.p-sortList__item__right > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}

/* select */
.c-select {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-select__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-select__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.c-select__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-select__body > i::before {
  line-height: 20px;
}
.c-select__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-select__body label {
  width: 100%;
  position: relative;
}
.c-select__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.c-select__body__selected__input {
  /* width: 100%; */
  width: 64px;
}
.c-select__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-select__body__selected p {
  height: 24px;
  line-height: 24px;
}

.c-select.is-hover {
  border-color: var(--primary200);
}
.c-select.is-active {
  border-color: var(--primary700);
}
.c-select.is-input .c-select__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-select.is-active .c-select__body__title {
  color: var(--primary700);
}

.c-select__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.c-select__menu {
  width: calc(100% - 32px);
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  margin-left: 16px;
  margin-right: 16px;
  z-index: 20;
  display: none;
  overflow-y: auto;
}
.c-select__menu.overflow-no {
  overflow-y: visible;
}
.is-active {
  display: block;
}
.c-select__menu__item {
  padding: 8px 10px;
  position: relative;
}
.c-select__menu__item:hover {
  background-color: var(--gray50);
}
.c-select__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.c-select__menu__item i {
  opacity: 0;
}
.c-select__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.c-select__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.c-select__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  text-align: left;
}
.c-select__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}

/* Error and disabled state */
.is-error {
  color: var(--error500);
  border-color: var(--error300);
  background-color: var(--error50);
}
.is-disabled {
  color: var(--gray300);
  pointer-events: none;
}
.is-disabled *::before {
  color: var(--gray300);
}
