.c-breadcrumbs {
  width: 100%;
  margin-bottom: 20px;
}
.c-breadcrumbs ul {
  width: 100%;
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
}
.c-breadcrumbs ul li {
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.c-breadcrumbs ul li:not(:last-child)::after {
  font-family: 'icon-arrows-outline' !important;
  content: "\d454";
  font-size: 16px;
  color: var(--gray300);
  margin: 0 8px;
}
.c-breadcrumbs ul li a {
  color: var(--primary700);
  padding: 0 8px;
}
.c-breadcrumbs ul li p {
  padding: 0 8px;
}