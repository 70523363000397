.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  border-radius: 12px;
}
.container-inner {
  border-radius: 12px;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  white-space: nowrap;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .container-inner {
    margin-top: 16px;
    white-space: initial;
  }
}
.header {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: var(--Color-Gray-700, #3f3f46);
  font-weight: 600;
  line-height: 156%;
  justify-content: center;
  padding: 16px 24px;
}
@media (max-width: 991px) {
  .header {
    white-space: initial;
    padding: 0 20px;
  }
}
.header-inner {
  justify-content: space-between;
  display: flex;
  gap: 16px;
}
@media (max-width: 991px) {
  .header-inner {
    white-space: initial;
  }
}
.body {
}
