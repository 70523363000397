.actions {
  display: flex;
  flex-direction: column;
}

.actions > *:last-child {
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  .actions {
    flex-direction: row;
  }

  .actions > *:last-child {
    margin-top: 0;
  }
}
