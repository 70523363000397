.container {
}

.sliderContainer {
  width: 100%;
  padding: 0;
  z-index: 10;
  position: relative;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #ddd;
  outline: none;
  border-radius: 2px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #3aa0ff;
  border: 4px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #3aa0ff;
  cursor: pointer;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: none;
  z-index: 10;
}

.slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #3aa0ff;
  border: 4px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.timelineContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
  z-index: 1;
}

@media (max-width: 991px) {
  .timelineContainer {
    max-width: 100%;
  }
}

.timelineDividers {
  align-self: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
  height: 50px;
}
.dividerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2px;
}

.divider {
  background-color: #393d3f;
  align-self: stretch;
  width: 0;
  height: 5px;
  margin: auto 0;
  border: 1px solid rgba(57, 61, 63, 1);
}
.hidden {
  border: 1px solid #fff;
}

.timeLabels {
  display: flex;
  width: 100%;
  align-items: center;
  color: #393d3f;
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: -0.41px;
  justify-content: space-between;
  font: 400 14px/1 Inter, sans-serif;
}

@media (max-width: 991px) {
  .timeLabels {
    max-width: 100%;
    white-space: initial;
  }
}

.timeLabel {
  align-self: stretch;
  margin: auto 0;
}
