.page-productsRegist__timeline {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.page-productsRegist__timeline__item {
  width: 200px;
  height: 48px;
  border-right: 1px solid #cfdae1;
  margin-bottom: 8px;
  position: relative;
}
.page-productsRegist__timeline__item__txt {
  font-size: 12px;
  padding: 4px 8px 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-productsRegist__timeline__item__txt p.charcoal {
  font-weight: bold;
  color: #4a6c7a;
}
.page-productsRegist__timeline__item__txt p.blue {
  font-weight: bold;
  color: #0094cc;
}
.page-productsRegist__timeline__item__arrow {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.page-productsRegist__timeline__item__arrow::before {
  content: '';
  width: calc(100% - 20px);
  height: 8px;
  background: #cfdae1;
}
.page-productsRegist__timeline__item__arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent #cfdae1;
}
.page-productsRegist__timeline__last {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 48px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #0094cc;
  background: var(--theme-primary-color);
  border-radius: 8px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*------------------------------------------------*/
.page-productsRegist__reserve {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-productsRegist__reserve__item {
  min-width: 200px;
}
.page-productsRegist__reserve__item > p {
}
.page-productsRegist__reserve__item > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
}
.page-productsRegist__reserve__item > div label {
  width: 128px;
}
.page-productsRegist__reserve__item > div label:nth-child(1) {
  border-radius: 4px 0 0 4px;
}
.page-productsRegist__reserve__item > div label:nth-child(2) {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .page-productsRegist__timeline {
    background: #f9f9f9;
    padding: 8px;
  }
  .page-productsRegist__timeline__item {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #cfdae1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
  }
  .page-productsRegist__timeline__item__txt {
    width: calc(100% - 24px);
  }
  .page-productsRegist__timeline__item__arrow {
    width: 24px;
    height: 48px;
    flex-direction: column;
  }
  .page-productsRegist__timeline__item__arrow::before {
    width: 8px;
    height: calc(48px - 20px);
  }
  .page-productsRegist__timeline__item__arrow::after {
    border-width: 20px 12px 0 12px;
    border-color: #cfdae1 transparent transparent transparent;
  }
  .page-productsRegist__timeline__last {
    margin-left: 0;
  }
}
