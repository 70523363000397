.p-search__select {
  width: 100%;
  position: relative;
}
.p-search__select__body {
  width: 100%;
  height: 100%;
  padding: 8px 40px 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.p-search__select__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: '\b450';
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.p-search__select__body__title {
  height: 24px;
  line-height: 24px;
}
.p-search__select__menu {
  min-width: 400px;
  max-width: 800px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-search__select__menu.is-active {
  display: block;
}
.p-search__select__menu__item {
  padding: 8px 10px;
  position: relative;
}
.p-search__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-search__select__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.p-search__select__menu__item i {
  opacity: 0;
}
.p-search__select__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.p-search__select__menu__item input:checked + i::before {
  color: var(--primary600);
  content: '\e914';
}
.p-search__select__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  margin-right: 24px;
}

@media screen and (max-width: 768px) {
  .p-search__select__menu {
    min-width: 0;
    width: 70vw;
    max-height: none;
    right: 0;
  }
}
