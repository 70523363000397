.options-container {
}
.field-wrapper {
  margin: 16px 0;
}

.options-header {
  margin-bottom: 24px;
  display: flex;
}
.option-actions {
  display: flex;
}
.option-delete {
  margin-left: auto;
}
.options-container {
  padding: 16px;
  background-color: #fafafa;
  border: 1px solid #e4e4e7;
  border-radius: 8px;
}
.option-container {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e4e4e7;
}
.option-container:not(:last-child) {
  margin-bottom: 16px;
}
.add-new {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
