.c-tab {
}
.c-tab__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.c-tab__header__item {
  width: 200px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #aaa;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px 6px 0 0;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-tab__header__item__text {
  font-size: 14px;
  font-weight: bold;
}
.c-tab__header__item br {
  display: none;
}
.c-tab__header__item + .c-tab__header__item {
  margin-left: 8px;
}
.c-tab__header__item.is-active {
  color: #0094cc;
  background: #f9f9f9;
  border-bottom: 0;
}
.c-tab__header__item:not(.is-active):hover {
  background: #f9f9f9;
}
.c-tab__body {
}
.c-tab__body__item {
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 0 4px 4px 4px;
  padding: 16px;
  margin-top: -1px;
  display: none;
}
.c-tab__body__item.is-active {
  display: block;
}
@media screen and (max-width: 768px) {
  .c-tab__header__item {
    padding: 8px 0;
  }
  .c-tab__header__item__text {
    font-size: 12px;
  }
  .c-tab__header__item br {
    display: block;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .c-tab__header__item br {
    display: inline-block;
  }
  _::-webkit-full-page-media,
  _:future,
  :root .c-tab__header__item br::before {
    content: '';
    margin-right: -0.2em;
    display: inline-block !important;
  }
  .c-tab__header__item + .c-tab__header__item {
    margin-left: 4px;
  }
}
