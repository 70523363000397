.container {
  margin-left: 8px;
  margin-right: 8px;
}
.code-block {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  white-space: pre-wrap;
  word-break: break-all;
  display: inline-block;
}
.input-box {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 600px;
}
.booking-link {
}
.booking-link a {
  color: #008ec9;
  font-weight: bold;
}
