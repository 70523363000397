/* Override Semantic UI default scrollbar styles for timepicker */

.react-timepicker-override {
  width: 100%;

  .rc-time-picker {
    vertical-align: middle;
    width: 100%;
  }

  .rc-time-picker-input {
    width: 100%;
    min-height: 46px;
    font-size: 14px;
    background-color: var(--white);
    border: 1px solid var(--gray200);
    border-radius: var(--radius-md);
    padding: 4px 8px;
    color: #000000;
  }
  .rc-time-picker-input[disabled] {
    color: var(--gray500);
    border-color: var(--gray200);
    background-color: var(--gray50);
    pointer-events: none;
  }
  .rc-time-picker-input:hover {
    border-color: var(--primary200);
  }

  .rc-time-picker-clear {
    top: 8px;
  }

  .rc-time-picker-overlay {
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.4); */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
