.container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  border-radius: 12px;
}
.container-inner {
  border-radius: 12px;
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Color-Base-White, #fff);
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  white-space: nowrap;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .container-inner {
    margin-top: 16px;
    white-space: initial;
  }
}
.header {
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 18px;
  color: var(--Color-Gray-700, #3f3f46);
  font-weight: 600;
  line-height: 156%;
  justify-content: center;
  padding: 16px 24px;
}
@media (max-width: 991px) {
  .header {
    white-space: initial;
    padding: 0 20px;
  }
}
.header-inner {
  justify-content: space-between;
  display: flex;
  gap: 16px;
}
@media (max-width: 991px) {
  .header-inner {
    white-space: initial;
  }
}
.body {
  padding: 24px;
}
@media (max-width: 991px) {
  .body {
    white-space: initial;
    padding: 0 20px;
  }
}
.text-section {
  display: flex;
  gap: 16px;
}

.amount-wrapper {
  gap: 4px;
  flex: 1;
}

.amount {
  display: flex;
  gap: 4px;
  flex: 1;
}
.amount-text {
  color: var(--Color-Gray-900, #18181b);
  font: 600 24px/133% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.amount-unit {
  color: var(--Color-Gray-400, #a1a1aa);
  font: 400 20px/140% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.previous {
  display: flex;
  gap: 4px;
  flex: 1;
}
.previous-text {
  color: var(--Color-Gray-400, #a1a1aa);
  font: 600 12px/133% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.previous-unit {
  color: var(--Color-Gray-400, #a1a1aa);
  font: 400 10px/140% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.progress-bar {
  position: relative;
  border-radius: 100px;
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.progress-bar-filled {
  position: absolute;
  border-radius: 100px;
  background-color: var(--Color-Primary-600, #008ec9);
  height: 15px;
}
.progress-bar-gray {
  position: absolute;
  border-radius: 100px;
  background-color: var(--Color-Primary-100, #e0f3fe);
  height: 15px;
}
