.c-listEditor {
  width: 100%;
}
.c-listEditor__ttl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px !important;
}
.c-listEditor__ttl > *:not(:first-child) {
  margin-left: 8px;
}
.c-listEditor__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.c-listEditor__body__list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.c-listEditor__body__list > *:not(:first-child) {
  margin-top: 8px;
}
.c-listEditor__body__list__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.c-listEditor__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
.c-listEditor__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
