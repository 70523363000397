.c-tableFrame {
  position: relative;
}
/* Note: need to add overflow as its own class here instead of included in c-table.thead and c-table.tbody
or else for some reason the original CSS for horizontal scroll in table header and body won't work properly
This is not happening for tableSmall so perhaps there is a better solution? */
.c-overflowRef {
  overflow-x: auto;
}
.c-table {
  /* overflow: auto; */
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
.c-table.thead {
  /* overflow-x: hidden; */
}
.c-table.tbody {
  /* overflow-x: auto; */
}
.c-table__body {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table__body thead th {
  font-size: var(--text-xs-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-xs-height);
  text-align: center;
  color: var(--gray600);
  background-color: var(--gray50);
  border-bottom: 1px solid var(--gray200);
  padding: 12px 16px;
}

.c-table__body thead th:first-child {
  border-radius: 12px 0 0 0;
}
.c-table__body thead th:last-child {
  border-radius: 0 12px 0 0;
}

.c-table__body thead th > i {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
  color: var(--gray500);
}
.c-table__body thead th.is-active {
  color: var(--primary700);
}
.c-table__body thead th.is-active > i {
  color: var(--primary700);
}
.c-table__body tbody tr:not(:first-child) td {
  border-top: 1px solid var(--gray200);
}

.c-table__body tbody tr:hover td {
  background-color: var(--primary50);
}
.c-table__body tbody td {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  background-color: var(--white);
  padding: 16px;
}
.c-table__body tbody td a {
  color: var(--primary600);
  text-decoration: underline;
}

.sticky-top {
  position: sticky;
  top: -1px;
  /* z-index: 1; */
}
.sticky-left {
  position: sticky;
  left: 0;
  z-index: 2;
}
.sticky-left::after {
  content: '';
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.05);
}
table thead .sticky-left {
  z-index: 4;
}

.c-table__body thead th i + p {
  font-size: var(--text-xs-size) !important;
  font-weight: var(--text-medium) !important;
  line-height: var(--text-xs-height) !important;
}
.c-table__body tbody td i + p {
  font-size: var(--text-sm-size) !important;
  font-weight: var(--text-normal) !important;
  line-height: var(--text-sm-height) !important;
}
.c-table__more p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.c-table__more a {
  color: var(--primary700) !important;
  margin-top: 8px;
  display: inline-block;
}

.c-overflowRef.arrow table th:first-child,
.c-overflowRef.arrow table td:first-child {
  padding-left: calc(12px + 28px);
}
.c-tableBtn {
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-tableBtn.left {
  left: 0;
}
.c-tableBtn.right {
  transform: scale(-1, 1);
  right: 0;
}
.c-tableBtn a {
  width: 28px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 64px;
  margin-top: 64px;
  /* top: calc(50% - 40px); */
  cursor: pointer;
}
.c-tableBtn a i {
  font-size: 24px;
  color: var(--white);
  display: block;
}

.c-table__sp {
  display: none;
}

@media screen and (max-width: 768px) {
  .c-table.thead {
    display: none;
  }
  .c-table__body tbody tr:hover td {
    background-color: transparent;
  }
  .c-table tr {
    width: 100%;
    border-radius: var(--radius-xl);
    border: 1px solid var(--gray200);
    background-color: var(--white);
    display: block;
    overflow: hidden;
  }
  .c-table tr:not(:first-child) {
    margin-top: 8px;
  }
  .c-table td {
    width: 100% !important;
    border: none !important;
    display: flex;
    justify-content: flex-end;
  }
  .c-table td::before {
    content: attr(data-text) '';
    font-size: var(--text-xs-size);
    font-weight: var(--text-medium);
    line-height: var(--text-xs-height);
    padding-right: 12px;
    margin-right: auto;
    display: block;
    flex-shrink: 0;
  }
  .c-table td.c-table__spHeader {
    border-bottom: 1px solid var(--gray200) !important;
    justify-content: flex-start !important;
  }
  .c-table td.c-table__spHeader::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-table td.c-table__spHeader::after {
    width: 20px;
    height: 20px;
    box-shadow: none;
    font-family: 'icon-arrows-solid' !important;
    font-style: normal;
    font-size: 20px;
    color: var(--primary600);
    content: '\b454';
    position: absolute;
    top: calc(50% - 10px);
    right: 8px;
  }
  .c-table td.c-table__spHeader label {
    margin-right: 12px;
  }
  .c-table td.c-table__spHeader label p {
    font-size: var(--text-base-size) !important;
    font-weight: var(--text-semibold) !important;
    line-height: var(--text-base-height) !important;
  }

  .c-table__more a {
    float: right;
  }
  .c-table td.c-table__detail {
    border-top: 1px solid var(--gray200) !important;
    justify-content: center;
    padding: 8px 16px;
  }
  .c-table td.c-table__detail a {
    /* font-size: var(--text-sm-size);
    font-weight: var(--text-semibold);
    line-height: var(--text-sm-height); */
    text-decoration: none;
  }
  .c-table td.c-table__detail::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-tableBtn {
    display: none;
  }
  .c-table__sp {
    display: block;
  }
  .c-overflowRef.arrow table th:first-child,
  .c-overflowRef.arrow table td:first-child {
    padding-left: 16px;
  }

  .hidden {
    display: none !important;
  }
}

.c-pagination__wrapper {
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--white);
  border-radius: 0 0 12px 12px;
}
.c-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination > *:not(:last-child) {
  margin-right: 12px;
}
.c-pagination__cnt {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.c-pagination__select {
  width: 90px;
  min-height: 36px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-pagination__select label {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  box-shadow: var(--shadow-xs);
  padding: 8px 12px;
  display: block;
  position: relative;
}
.c-pagination__select label::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--gray700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.c-pagination__select ul {
  width: 100%;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  bottom: 40px;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.c-pagination__select ul.is-active {
  display: block;
}
.c-pagination__select li {
  padding: 8px 10px;
  position: relative;
}
.c-pagination__select li:hover {
  background-color: var(--gray50);
}
.c-pagination__select li input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.c-pagination__select li input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.c-pagination__select li input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.c-pagination__select li i {
  opacity: 0;
}
.c-pagination__select li p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}
.c-pagination__btn {
  width: 36px;
  height: 36px;
  color: var(--gray700);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-xs);
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-pagination__btn i::before  {
  font-size: 20px;
}
.c-pagination__btn.is-disabled {
  color: var(--gray300);
  pointer-events: none;
}
