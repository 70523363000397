.right-side {
  right: 0;
}
.p-search__body__item {
  display: flex;
}
.p-search__body__item > * {
  flex-shrink: 1;
}
/*
.p-search__body__item:not(:first-child) > * {
  margin-top: 16px;
}
*/
.p-search__body__item > *:not(:last-child) {
  margin-right: 16px;
}
.c-dropdown {
  width: 250px;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-dropdown__body {
  width: 100%;
  height: 100%;
  padding: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-dropdown__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-dropdown__body > i::before {
  line-height: 20px;
}
.c-dropdown__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-dropdown__body label {
  width: 100%;
  position: relative;
}
.c-dropdown__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.c-dropdown__body__selected__input {
  width: 100%;
  /* width: 64px; */
}
.c-dropdown__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-dropdown__body__selected ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -4px;
}
.c-dropdown__body__selected ul li {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  background-color: var(--gray100);
  border-radius: var(--radius-sm);
  padding: 3px 4px 3px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-dropdown__body__selected ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.c-dropdown__body__selected ul li i::before {
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  color: var(--gray400);
  content: "\e9c8";
}
.c-dropdown.is-hover {
  /* border-color: var(--primary200); */
}
.c-dropdown.is-active {
  border-color: var(--primary700);
}
.c-dropdown.is-active .c-dropdown__body__title {
  color: var(--primary700);
}
.c-dropdown__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}
.c-dropdown__menu {
  width: 608px;
  min-width: 160px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  /* left: 0; */
  margin-top: 4px;
  z-index: 20;
  display: none;
  overflow-y: auto;
}
.c-dropdown__menu.overflow-no {
  overflow-y: visible;
}
.c-dropdown__menu.is-active {
  display: block;
}
.c-dropdown__menu__item {
  padding: 8px 10px;
  position: relative;
}
.c-dropdown__menu__item:hover {
  background-color: var(--gray50);
}
.c-dropdown__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.c-dropdown__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  text-align: left;
}
.c-dropdown__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}
.suggestReservationInfo {
  display: flex;
}
.suggestReservationInfoLeft {
  display: block;
}
.suggestReservationInfoRight {
  display: block;
  overflow: hidden;
  margin-left: 50px;
}
.infoBlock {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

/* Base properties */
.c-badge  {
  width: fit-content;
  border-radius: var(--radius-full);
  border: 1px solid;
  flex-shrink: 0;
}
.md {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  height: 24px;
  padding: 1px 10px;
}
/* success */
.success {
  color: var(--success700);
  background-color: var(--success50);
  border-color: var(--success200);
}
.success::before {
  background-color: var(--success700);
}
/* blue */
.blue {
  color: var(--blue700);
  background-color: var(--blue50);
  border-color: var(--blue200);
}
.blue::before {
  background-color: var(--blue700);
}
/* error */
.error {
  color: var(--error700);
  background-color: var(--error50);
  border-color: var(--error200);
}
.error::before {
  background-color: var(--error700);
}
/* gray */
.gray {
  color: var(--gray700);
  background-color: var(--gray100);
  border-color: var(--gray200);
}
.gray::before {
  background-color: var(--gray700);
}
/* warning */
.warning {
  color: var(--warning700);
  background-color: var(--warning50);
  border-color: var(--warning200);
}
.warning::before {
  background-color: var(--warning700);
}
/* primary */
.primary {
  color: var(--primary700);
  background-color: var(--primary50);
  border-color: var(--primary200);
}
.primary::before {
  background-color: var(--primary700);
}

@media screen and (max-width: 1085px) {
  .c-dropdown__menu {
    width: 500px;
    right: 1px;
  }
  .right-side {
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .suggest__body__form > .option {
    width: 100%;
  }
  .p-search__body__item {
    width: 160px;
  }
  .c-dropdown__menu {
    width: 80vw;
    right: 1px;
  }
  .c-dropdown {
    width: 100%;
  }
  .suggestReservationInfo {
    display: block;
  }
  .suggestReservationInfoRight {
    margin-left: 0;
  }
  .right-side {
    left: 0;
  }
}
