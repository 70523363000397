.tabs-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 240px;
  height: 40px;
  margin: 16px 0px;
  border-radius: 12px;
}

.tabs-container > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 120px;
  height: 40px;
  background: #ffffff;
  border-right: 1px solid #e4e4e7;
  cursor: pointer;
  white-space: nowrap;
}

.tabs-container > button:first-child {
  border-radius: 8px 0px 0px 8px;
}

.tabs-container > button:last-child {
  border-radius: 0px 8px 8px 0px;
}

.tabs-container > button.active {
  background: #f4f4f5;
  cursor: default;
}
