.change {
  border-radius: var(--Dimension-Radius-md, 8px);
  background-color: var(--Color-Gray-100, #f4f4f5);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;
  line-height: 133%;
  justify-content: center;
  padding: 6px 12px;
  width: fit-content;
}
.inc {
  color: var(--Color-Success-600, #059669);
}
.dec {
  color: var(--Color-Error-600, #dc2626);
}
.change-inner {
  display: flex;
  gap: 4px;
}
