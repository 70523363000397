.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}
.controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
}
.map-selector {
  width: 330px;
}
.date-range-container {
  width: 330px;
}
.gadget-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
}
.stack {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 33%;
  height: 620px;
}
.link {
  color: #008ec9;
  text-decoration: none !important;
  font-weight: bold;
}
.view-button {
  color: #3e8cc4;
  border-radius: 8px;
  border: 1px solid #3e8cc4;
  background: #fff;
  padding: 8px 16px;
}
.view-button:hover {
  background-color: #e6f3ff;
}
.onsite-gadget {
  flex: 2;
  height: 560px;
}
