.c-view {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.c-badge {
  width: fit-content;
  border-radius: 8px;
  border: 1px solid;
  flex-shrink: 0;
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  min-height: 24px;
  padding: 2px 10px;
}
.blue {
  color: #1d4ed8;
  background-color: #eff6ff;
  border-color: #bfdbfe;
}
.yellow {
  color: #b45309;
  background-color: #fffbeb;
  border-color: #fde68a;
}
.green {
  color: #047857;
  background-color: #ecfdf5;
  border-color: #a7f3d0;
}
.red {
  color: #b91c1c;
  background-color: #fef2f2;
  border-color: #fecaca;
}
.purple {
  color: #7e22ce;
  background-color: #faf5ff;
  border-color: #e9d5ff;
}
.pink {
  color: #be185d;
  background-color: #fdf2f8;
  border-color: #fbcfe8;
}
.sky {
  color: #0369a1;
  background-color: #f0f9ff;
  border-color: #bae6fd;
}
.orange {
  color: #c2410c;
  background-color: #ffedd5;
  border-color: #fed7aa;
}
.gray {
  background-color: #fdfeff;
  border-color: #bbbbbb;
}
