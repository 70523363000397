.page-tutorial__popup {
  width: calc(100% - 32px);
  max-width: 480px;
  border-radius: 4px;
  background: #fff;
  padding: 16px;
  position: absolute;
  margin-top: 60px;
  z-index: 11;
}
.page-tutorial__popup::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 16px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -16px;
  left: 32px;
}
.page-tutorial__popup.large {
  max-width: 640px;
}
.page-tutorial__popup > * + * {
  margin-top: 16px;
}
.page-tutorial__popup__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-tutorial__popup__ttl img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  flex-shrink: 0;
}
.page-tutorial__popup__ttl p {
  font-size: 18px;
  font-weight: bold;
}
.page-tutorial__popup__desc {
}
.page-tutorial__popup__btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
