/* Base properties */
.c-fieldWrapper {
  width: 100%;
}
.c-field {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
  padding: 0;
  margin: 0;
}
.c-field__body {
  width: 100%;
  height: 100%;
  min-height: 46px;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-field__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-field__body > i::before {
  line-height: 20px;
}
.c-field__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-field__body label {
  width: 100%;
  height: 100%;
  position: relative;
}
.c-field__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.c-field__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-field__body__selected input {
  width: 100%;
}
.c-field.is-hover {
  border-color: var(--primary200);
}
.c-field.is-active {
  border-color: var(--primary700);
}
.c-field.is-input .c-field__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-field.is-active .c-field__body__title {
  color: var(--primary700);
}
.c-field.is-disabled {
  border-color: var(--gray200);
  background-color: var(--gray50);
  pointer-events: none;
}
.c-field.is-disabled .c-field__body__selected input {
  color: var(--gray400);
}
.c-field.is-input .c-field__body__title.has-icon {
  left: -28px;
}

/* Input */
.c-field__body__selected__input {
  min-height: 24px;
}

.is-error {
  border-color: var(--error300);
  background-color: var(--error50);
}
.is-error .c-field__body__selected input {
  color: var(--error500);
}

/* Disable */
.is-disabled {
  color: var(--gray300);
  pointer-events: none;
}