.c-accordion {
}
.c-accordion__list {
  border-radius: 4px;
  border: 1px solid #eee;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  overflow: hidden;
}
.c-accordion__list + .c-accordion__list {
  margin-top: 16px;
}
.c-accordion__list__header {
  width: 100%;
  background: #fff;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.c-accordion__list__header:hover {
  background: #f9f9f9;
}
.c-accordion__list__header__text {
  margin-bottom: 0;
  width: calc(100% - 16px - 8px);
  font-size: 16px;
  font-weight: bold;
  color: #0094cc;
}
.c-accordion__list__header__ic {
  width: 16px;
  height: 16px;
  position: relative;
}
.c-accordion__list__header__ic::before,
.c-accordion__list__header__ic::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #0094cc;
  position: absolute;
  top: 7px;
  left: 0;
}
.c-accordion__list__header__ic::after {
  transition: all 0.4s ease;
  transform: rotate(-90deg);
}
.is-active .c-accordion__list__header__ic::before {
  opacity: 0;
}
.is-active .c-accordion__list__header__ic::after {
  background: #aaa;
  transform: rotate(0);
}
.c-accordion__list__body {
  border-top: 1px solid #eee;
  background: #f9f9f9;
  padding: 16px;
  display: none;
}
.is-active.c-accordion__list__body {
  display: block;
}
