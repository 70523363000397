.page-productsRegist__file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__file > p {
  margin-right: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.page-productsRegist__file > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__file > div label {
  width: 128px;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}
.page-productsRegist__file > div label input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.page-productsRegist__file > div span {
  font-size: 12px;
  font-weight: bold;
  color: #4a6c7a;
}
.page-productsRegist__preview {
  width: 100%;
  max-width: 280px;
  min-height: 64px;
  max-height: 80px;
  padding: 8px;
  margin-top: 8px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-productsRegist__preview img {
  width: 100%;
  height: auto;
  max-height: 80px;
  object-fit: contain;
}
.page-productsRegist__preview p {
  font-size: 14px;
  font-weight: bold;
  color: #cfdae1;
}
@media screen and (max-width: 768px) {
  .page-productsRegist__file {
    flex-direction: column;
    align-items: flex-start;
  }
  .page-productsRegist__file > div {
    margin-top: 8px;
  }
}
