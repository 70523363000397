.p-dashboard__body__plane:not(:first-child) {
  margin-top: 24px;
}
.p-dashboard__body__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.p-dashboard__body__header > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 24px;
}
.p-dashboard__body__header > div i {
  font-size: 20px;
  margin-right: 8px;
}
.p-dashboard__body__header > div p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-dashboard__body__table {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: 12px;
  background: var(--white);
  overflow-x: auto;
}
.p-dashboard__body__table > * {
  border: none !important;
}
.p-dashboard__body__table td a {
  color: var(--primary600);
  text-decoration: underline;
}
.p-dashboard__body__actions {
  margin-top: 16px;
}
.p-dashboard__body__actions > * {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-dashboardFlex {
    flex-direction: column;
  }
  .p-dashboardFlex > *:not(:first-child) {
    margin-top: 24px;
  }
  .p-dashboard__header {
    padding: 16px;
  }
  .p-dashboard__body {
    padding: 16px;
  }
  .p-dashboard__body__table {
    border: none;
  }
  .p-dashboard__body__table > * {
  }
}

.p-transition {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}
.p-transition > * {
  flex-shrink: 0;
}
.p-transition > *:not(:first-child) {
  margin-top: 16px;
}
.p-transition__products {
  /* TODO: Better not to set fixed height */
  /* height: 40px; */
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
/* .p-transition__comparison {
  margin-top: 24px;
} */
.p-transition__body {
  height: 100%;
  flex-shrink: unset;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.p-transition__body__graph {
  height: 100%;
  margin-top: 16px;
  position: relative;
}
.p-transition__body__summary {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-transition__body__summary p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray500);
}
.p-transition__body__summary p:not(:last-child) {
  margin-right: 12px;
}
.p-transition__body__summary p span {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  color: var(--gray700);
}
.p-transition__body__table {
  margin-top: 16px;
}
/* .p-transition__body__table td {
  text-align: right;
} */
.p-transition__body__table td span {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 4px;
  display: block;
}
@media screen and (max-width: 768px) {
  /* .p-transition__body__table td {
    text-align: left;
  } */
}

.p-accessReport__cell {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.p-accessReport__cell > p:not(:first-child) {
  margin-left: 12px;
}
.p-accessReport__cell > p:last-child {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray500);
}
