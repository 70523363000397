.template-card {
  width: 250px;
  border: 1px solid #e0e0e0;
  padding: 8px;
  background: white;
  height: 100%;
}
.template-card.selected {
  border: 2px solid #008ec9;
}
.template-card__header {
  font-weight: bold;
  height: 36px;
}
.template-card__body {
  margin-top: 8px;
  height: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.template-card__img {
  margin: 8px auto;
  width: 100%;
  height: 176px;
  background: #ddd;
  border-radius: 8px;
  overflow: hidden;
}
.template-card__img > img {
  width: 100%;
}
.template-card__actions {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
