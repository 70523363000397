.resource_list__display_box__column {
  display: flex;
}

.resource_list__display_box__wrapper {
  width: 100%;
}

.resource_list__display_box__button {
  width: 50px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}
