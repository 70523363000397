/* Top margin for menu scroll */
.g-section {
  scroll-margin-top: 16px;
}
@media screen and (max-width: 768px) {
  .g-section {
    scroll-margin-top: 136px;
  }
}

.p-reservationsDetail {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservationsDetail__header {
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsDetail__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-reservationsDetail__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservationsDetail__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservationsDetail__actions__pin {
  position: relative;
}
.p-reservationsDetail__actions__pin > div {
  bottom: 48px;
  display: none;
}
.p-reservationsDetail__actions__pin:hover > div {
  display: block;
}
.p-reservationsDetail__body {
  padding: 24px;
}
@media screen and (max-width: 768px) {
  .p-reservationsDetail__header {
    align-items: flex-start;
  }
  .p-reservationsDetail__body {
    padding: 16px;
  }
  .p-reservationsDetail__header.contact {
    display: block;
  }
  .p-reservationsDetail__actions.contact {
    margin-top: 16px;
    justify-content: flex-start;
  }
}

/* primary */
.p-primary {
}
.p-primary__header {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-primary__header__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-primary__header__ttl__id {
  margin: 0 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.p-primary__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-primary__header__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-primary__header__actions > * {
  margin-bottom: 8px;
}
.p-primary__body {
  display: flex;
}
.p-primary__body > * {
  flex-shrink: 1;
}
.p-primary__body > *:not(:last-child) {
  margin-right: 24px;
}
.p-primary__body__flex {
}
.p-primary__body__tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -8px;
}
.p-primary__body__tags li {
  margin-bottom: 8px;
}
.p-primary__body__tags li:not(:last-child) {
  margin-right: 8px;
}
.p-primary__body__confirmationNumber {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.p-primary__body__confirmationNumber > *:not(:first-child) {
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .p-primary__body {
    flex-direction: column;
  }
  .p-primary__header__actions {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

/* secondary */
.p-secondary {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.p-secondary__item {
  width: calc(50% - 12px);
}
.p-secondary__item:nth-child(even) {
  margin-left: 24px;
}
.max {
  width: 100%;
  margin-top: 48px;
}
.p-secondary__item__header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.p-secondary__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  margin-bottom: 16px;
}
.p-secondary__item__ttl:first-child {
  /* Override semantic margin */
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .p-secondary__item {
    width: 100%;
  }
  .p-secondary__item:not(:first-child) {
    margin-top: 16px;
  }
  .p-secondary__item:nth-child(even) {
    margin-left: 0;
  }
  .max {
    margin-top: 16px;
  }
}

/* participants */
.p-participants {
}

/* claim */
.p-claim {
}

.p-claim__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  margin-top: 48px;
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .p-claim__cardinfo {
    display: flex;
    flex-direction: column;
  }
}
/* review */
.p-review {
}
.p-review__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  padding: 16px;
}
.p-review > li:not(:first-child) {
  margin-top: 20px;
}
.p-reviewCard {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
}
.p-reviewCard__header {
  width: 100%;
  border-bottom: 1px solid var(--gray200);
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
  align-items: center; */
}
.p-reviewCard__header__productName {
  margin-bottom: 12px;
}
.p-reviewCard__header__contents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reviewCard__header__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reviewCard__header__left > * {
  margin-right: 16px;
}
.p-reviewCard__header__left > p:first-child {
  margin-right: 16px;
}
.p-reviewCard__header__left span {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
}
.p-reviewCard__header__right {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reviewCard__header__right > *:not(:last-child) {
  margin-right: 12px;
}
.p-reviewCard__body {
  padding: 20px;
}
.p-reviewCard__body__star {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}
.p-reviewCard__body__star img {
  width: 16px;
  margin-right: 2px;
}
.p-reviewCard__body__star p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  margin-left: 4px;
}
.p-reviewCard__body__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
  margin-bottom: 16px;
}
.p-reviewCard__body__desc {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-reviewCard__body__photo {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-reviewCard__body__photo li {
  width: 180px;
  height: 180px;
  margin-right: 16px;
  margin-bottom: 16px;
}
.p-reviewCard__body__photo li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--radius-md);
}
.p-reviewCard__body__user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reviewCard__body__user__pic {
  width: 32px;
  height: 32px;
  background-color: var(--gray100);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.p-reviewCard__body__user__pic p {
  font-size: var(--text-md-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-md-height);
  color: var(--primary700);
}
.p-reviewCard__body__user__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-reviewCard__body__user__txt p:first-child {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-reviewCard__body__user__txt p:last-child {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}
.p-reviewCard__body__reply {
  border-top: 1px solid var(--gray200);
  margin-top: 20px;
  padding-top: 20px;
}
.p-reviewCard__body__reply div {
  border-radius: var(--radius-md);
  background-color: var(--gray100);
  padding: 16px;
}
.p-reviewCard__body__reply__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  margin-bottom: 8px;
}
.p-reviewCard__body__reply__desc {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-reviewCard__body__desc__more,
.p-reviewCard__body__reply__more {
  width: 100%;
  display: flex;
}
.p-reviewCard__body__desc__more a,
.p-reviewCard__body__reply__more a {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  color: #0094cc;
  text-decoration: underline;
  margin-left: auto;
}
.p-reviewCard__body__desc.is-close,
.p-reviewCard__body__reply__desc.is-close {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-bottom: 12px;
}
@media screen and (max-width: 768px) {
  .p-reviewCard__header {
    display: block;
  }
  .p-reviewCard__header__contents {
    flex-direction: column;
    align-items: flex-start;
  }
  .p-reviewCard__header__right {
    margin-top: 16px;
    margin-left: 0;
  }
  .p-reviewCard__body__photo li {
    width: 112px;
    height: 112px;
  }
}

/* agreement */
.p-agreement {
}
.p-agreement > button {
  margin-bottom: 16px;
}
.p-agreement__bottom {
  margin-top: 24px;
  margin-left: auto;
}
.p-agreement__clearWaiver {
  margin-bottom: 8px;
}
.p-agreement__table__guests {
  flex-direction: column;
}
.p-agreement__table__guests > *:not(:first-child) {
  margin-top: 8px;
}
.p-agreement__table__guests__details {
  display: flex;
  align-items: center;
}
.p-agreement__table__guests__details > *:not(:first-child) {
  margin-left: 8px;
}
.p-agreement__table__actions {
  display: flex;
  flex-direction: column;
}
.p-agreement__table__actions > *:not(:first-child) {
  margin-top: 8px;
}
.p-agreement__finger__print {
}
.p-agreement__finger__print__items {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}
@media screen and (max-width: 768px) {
  .p-agreement__table__digitalFingerprints {
    width: 100%;
  }
  .p-agreement__table__guests__details {
    margin-left: auto;
  }
  .p-agreement__finger__print {
    display: block !important;
  }
}

/* photos */
.p-photos {
}

/* contact */
.p-contact {
}
.p-contact > li:not(:first-child) {
  margin-top: 20px;
}
.p-contactCard {
  width: 100%;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
}
.p-contactCard__header {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-contactCard__header__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-contactCard__header__info__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  margin-right: 12px !important;
}
.p-contactCard__header__info__tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  /* margin-bottom: -12px;  */
}
.p-contactCard__header__info__tab li {
  margin-right: 12px;
  margin-bottom: 12px;
  flex-shrink: 0;
}
/* .p-contactCard__header__action {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.p-contactCard__header__action > *:not(:first-child) {
  margin-left: 8px;
} */
.p-contactCard__header__toggle {
  margin-left: auto;
  flex-shrink: 0;
}
.p-contactCard__body {
  border-top: 1px solid var(--gray200);
  display: none;
}
.p-contactCard__body.is-active {
  display: block;
}
.p-contactCard__body__top__border {
  border-bottom: 1px solid var(--gray200);
  margin-top: 20px;
  margin-bottom: 20px;
}
.p-contactCard__body__top__border:last-child {
  border-bottom: none;
  margin-top: 20px;
  margin-bottom: 20px;
}
.p-contactCard__body__inner {
  padding: 20px;
}
.p-contactCard__body__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.p-contactCard__body__info__date {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-left: auto;
}
.p-contactCard__body__info__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-contactCard__body__info__ttl > div {
  margin-right: 8px;
}
.p-contactCard__body__info__ttl > span {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-contactCard__body__desc {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-contactCard__body__link {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--primary700);
  text-decoration: underline;
  margin-top: 16px;
  display: inline-block;
}
.p-contactCard__body__reply {
  border-top: 1px solid var(--gray200);
  margin-top: 20px;
  padding-top: 20px;
}
.p-contactCard__body__reply__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}
.p-contactCard__body__reply__info__date {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-left: auto;
}
.p-contactCard__body__reply__info__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
}
.p-contactCard__body__reply__date {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
}
.p-contactCard__body__reply__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  margin-bottom: 8px;
}
.p-contactCard__body__reply__desc {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
/* Comment out this so module css in component takes precedence */
/* .p-contactCard__body__reply__desc p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
} */
.p-contactCard__body__reply__desc a {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--primary700);
  text-decoration: underline;
  margin-top: 8px;
  display: inline-block;
}
.p-contactCard__body__reply__btn {
  margin-top: 20px;
}
.p-contactCard__body__reply__btn button {
  margin-left: auto;
}
.p-contactCard__body__bottom {
  width: 100%;
  border-top: 1px solid var(--gray200);
  background-color: var(--gray50);
  border-radius: 0 0 var(--radius-xl) var(--radius-xl);
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-contactCard__body__bottom__tags {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-right: auto;
  margin-bottom: -12px;
}
.p-contactCard__body__bottom__tags p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  margin-right: 12px;
  margin-bottom: 12px;
}
.p-contactCard__body__bottom__tags p::before {
  content: '#';
}
.p-contactCard__select {
  position: relative;
}
.p-contactCard__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-contactCard__select__menu.is-active {
  display: block;
}
.p-contactCard__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-contactCard__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-contactCard__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.p-contactCard__body__reply__detail {
  width: 100%;
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-xs);
  padding: 16px;
  margin-top: 20px;
}
.p-contactCard__body__reply__detail__item {
  width: 100%;
}
.p-contactCard__body__reply__detail__item:not(:first-child) {
  margin-top: 16px;
}
.p-contactCard__body__reply__detail__item__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}
.p-contactCard__body__reply__detail__item__note {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-top: 6px;
}
.p-contactCard__body__reply__detail__item__add {
  width: fit-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-contactCard__body__reply__detail__item__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
fieldset + .p-contactCard__body__reply__detail__item__add {
  margin-top: 12px;
}
.p-contactCard__body__reply__detail__item__photo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: -16px;
}
.p-contactCard__body__reply__detail__item__photo li {
  width: 128px;
  height: 128px;
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
}
.p-contactCard__body__reply__detail__item__photo li img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.p-contactCard__body__reply__detail__item__photo li a {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
}
.p-contactCard__body__reply__detail__item__photo li a i {
  font-size: 16px;
  color: var(--white);
}
.p-contactCard__body__reply__detail__item__photo li.add {
  border: 1px dashed var(--primary700);
  color: var(--primary700);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.p-contactCard__body__reply__detail__item__photo li.add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.p-contactCard__body__reply__detail__item__photo li.add p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 10px;
}
.p-contactCard__body__reply__detail__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.p-contactCard__body__reply__detail__actions > *:not(:first-child) {
  margin-left: 12px;
}
@media screen and (max-width: 768px) {
  .p-contactCard__header__info__tab {
    margin-top: 12px;
  }
  .p-contactCard__body__info {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .p-contactCard__body__info__date {
    margin-left: 0;
    margin-bottom: 16px;
  }
  .p-contactCard__body__reply__info {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .p-contactCard__body__reply__info__date {
    margin-left: 0;
    margin-bottom: 16px;
  }
  .p-contactCard__body__reply__detail__item__photo li {
    width: 100px;
    height: 100px;
  }
}

/* guides */
.p-guides {
}

/* questionnaires */
.p-questionnaires {
}

/* relations */
.p-relations {
  overflow-x: auto;
}
.p-relations__cardlink {
  color: blue;
}
.p-relations__emailpayment {
  display: flex;
  align-items: center;
  width: 100%;
}

/* supplements */
.p-supplements {
}

/* histories */
.p-histories {
}

/* mails */
.p-mails {
}
.p-mails__status__error {
  cursor: pointer;
}

/* modal */
.p-pin {
  width: 100%;
}
.p-pin__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-pin__item:not(:first-child) {
  margin-top: 16px;
}
.p-pin__item__flex {
  width: 100%;
}
.p-pin__item__fix {
  width: 128px;
  margin-left: 20px;
  flex-shrink: 0;
}
.p-pin__item__ttl {
  margin-bottom: 6px !important;
}

.p-voucher {
  width: 100%;
}
.p-voucher__item {
  width: 100%;
}
.p-voucher__item:not(:first-child) {
  margin-top: 16px;
}
.p-voucher__item__ttl {
  margin-bottom: 6px;
}

.p-reservationNum {
  width: 100%;
}
.p-reservationNum__item {
  width: 100%;
}
.p-reservationNum__item:not(:first-child) {
  margin-top: 16px;
}
.p-reservationNum__item__ttl {
  margin-bottom: 6px !important;
}

.p-reservationTag {
  width: 100%;
}
.p-reservationTag__item {
  width: 100%;
}
.p-reservationTag .c-dropdown__body__selected > ul > li {
  padding: 0;
}
.p-reservationTag__item__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.p-reservationTag__item__menu__edit {
}
.p-reservationTag__item__menu__edit > i {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  color: var(--gray500);
  cursor: pointer;
}
.p-reservationTag__item__menu__edit__body {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5px;
  left: 0;
  display: none;
}
.p-reservationTag__item__menu__edit__body.is-active {
  display: block;
}
.p-reservationTag__item__menu__edit__body .c-field {
  min-height: 36px;
}
.p-reservationTag__item__menu__edit__body .c-field__body {
  height: 36px;
}
.p-reservationTag__item__menu__edit__body__menu {
  width: 20px;
  height: 20px;
  border: 1px solid var(--gray200);
  border-radius: 100%;
  background-color: var(--gray50);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 5px);
  right: 8px;
  cursor: pointer;
}
.p-reservationTag__item__menu__edit__body__menu i {
  opacity: 1;
  color: var(--gray500);
}
.p-reservationTag__item__menu__edit__body__menu ul {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  top: 24px;
  right: -4px;
  z-index: 20;
  display: none;
}
.p-reservationTag__item__menu__edit__body__menu ul.is-active {
  display: block;
}
.p-reservationTag__item__menu__edit__body__menu ul li {
  width: 100%;
  padding: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.p-reservationTag__item__menu__edit__body__menu ul li:hover {
  background-color: var(--gray50);
}
.p-reservationTag__item__menu__edit__body__menu ul li div {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid;
  margin-right: 8px;
  display: block;
}
.p-reservationTag__item__menu__edit__body__menu ul li div.gray {
  background-color: var(--gray100);
  border-color: var(--gray200);
}
.p-reservationTag__item__menu__edit__body__menu ul li div.orange {
  background-color: var(--orange50);
  border-color: var(--orange200);
}
.p-reservationTag__item__menu__edit__body__menu ul li div.green {
  background-color: var(--success50);
  border-color: var(--success200);
}
.p-reservationTag__item__menu__edit__body__menu ul li div.yellow {
  background-color: var(--warning50);
  border-color: var(--warning200);
}
.p-reservationTag__item__menu__edit__body__menu ul li div.blue {
  background-color: var(--blue50);
  border-color: var(--blue200);
}
.p-reservationTag__item__menu__edit__body__menu ul li div.purple {
  background-color: var(--purple50);
  border-color: var(--purple200);
}
.p-reservationTag__item__menu__edit__body__menu ul li div.pink {
  background-color: var(--pink50);
  border-color: var(--pink200);
}
.p-reservationTag__item__menu__edit__body__menu ul li div.red {
  background-color: var(--error50);
  border-color: var(--error200);
}
.p-reservationTag__item__menu__edit__body__menu ul li input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.p-reservationTag__item__menu__edit__body__menu ul li i {
  opacity: 0;
}
.p-reservationTag__item__menu__edit__body__menu ul li input:checked + i {
  opacity: 1;
}

.p-secondaryContent {
  width: 100%;
}
.p-secondaryContent__box {
  padding-bottom: 24px;
}
.p-secondaryContent__box:not(:first-child) {
  margin-top: 24px;
}
.p-secondaryContent__box:not(:last-child) {
  border-bottom: 1px solid var(--gray200);
}
.p-secondaryContent__box__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  margin-bottom: 16px;
}
.p-secondaryContent__box__form {
  width: 100%;
}
.p-secondaryContent__box__form__item {
  width: 100%;
}
.p-secondaryContent__box__form__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-secondaryContent__box__form__item:not(:first-child) {
  margin-top: 16px;
}
.p-secondaryContent__box__form__item__ttl {
  margin-bottom: 6px;
}
.p-secondaryContent__box__action {
  margin-top: 16px;
}
.p-secondaryContent__box__action a {
  width: 100%;
  border-radius: 0 !important;
}
.p-secondaryContent__box__form__item__flex {
  width: 100%;
}
.p-secondaryContent__box__form__item__fix {
  width: 128px;
  margin-left: 20px;
  flex-shrink: 0;
}
.p-secondaryContent__box__form__item__note {
  margin-top: 6px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  display: block;
}
.p-secondaryContent__box__check {
  width: 100%;
  background-color: var(--gray100);
  margin-bottom: 24px;
}
.p-secondaryContent__box__check label {
  width: 100%;
  height: 100%;
  padding: 16px;
  cursor: pointer;
}

.p-reviewEdit {
  width: 100%;
}
.p-reviewEdit__item {
  width: 100%;
}
.p-reviewEdit__item:not(:first-child) {
  margin-top: 16px;
}
.p-reviewEdit__item__ttl {
  margin-bottom: 6px;
}
.p-reviewEdit__item__ttl span {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
}
.p-reviewEdit__item__check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reviewEdit__item__check > * {
  margin-right: 12px;
}

.p-contactSms {
  width: 100%;
}
.p-contactSms__item {
  width: 100%;
}
.p-contactSms__item:not(:first-child) {
  margin-top: 16px;
}
.p-contactSms__item__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}
.p-contactSms__item__ttl .required {
  width: fit-content;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--white);
  background-color: var(--warning500);
  border-radius: 999px;
  padding: 2px 6px;
  margin-left: auto;
  flex-shrink: 0;
}
.p-contactSms__item__templateToggle {
  margin-bottom: 6px;
}
.p-contactSms__item__templateSelect {
  margin-bottom: 12px;
  display: none;
}
.p-contactSms__item__templateSelect.is-active {
  display: block;
}

.p-contactSms__note {
  margin-top: 20px;
  padding-left: 16px;
}
.p-contactSms__note li {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray700);
  list-style: disc;
}
.p-contactSms__note li a {
  color: var(--primary600);
}

.p-contactEmail {
}
.p-contactEmail__item {
  width: 100%;
}
.p-contactEmail__item:not(:first-child) {
  margin-top: 16px;
}
.p-contactEmail__item__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px !important;
}
.p-contactEmail__item__note {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-top: 6px;
}
.p-contactEmail__item__add {
  width: fit-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-contactEmail__item__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
fieldset + .p-contactEmail__item__add {
  margin-top: 12px;
}
.p-contactEmail__item__templateToggle {
  margin-bottom: 6px !important;
}
.p-contactEmail__item__templateSelect {
  margin-bottom: 12px;
  display: none;
}
.p-contactEmail__item__templateSelect.is-active {
  display: block;
}

.p-supplementsEdit {
  width: 100%;
}
.p-supplementsEdit__box:not(:first-child) {
  margin-top: 24px;
}
.p-supplementsEdit__box:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--gray200);
}
.p-supplementsEdit__item:not(:first-child) {
  margin-top: 16px;
}
.p-supplementsEdit__item__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
}
.p-supplementsEdit__item__ttl .required {
  width: fit-content;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--white);
  background-color: var(--warning500);
  border-radius: 999px;
  padding: 2px 6px;
  margin-left: auto;
  flex-shrink: 0;
}
.p-supplementsEdit__item__note {
  margin-top: 6px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  display: block;
}

.p-priceChange {
  width: 100%;
}
.p-priceChange__box {
}
.p-priceChange__box:not(:first-child) {
  margin-top: 24px;
}
.p-priceChange__box:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--gray200);
}
.p-priceChange__item {
  width: 100%;
}
.p-priceChange__item:not(:first-child) {
  margin-top: 16px;
}
.p-priceChange__item__ttl {
  margin-bottom: 6px !important;
}
.p-priceChange__item__check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-priceChange__item__check > * {
  margin-right: 12px;
}
.p-priceChange__item__ac {
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-xs);
}
.p-priceChange__item__ac__header {
  width: 100%;
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-priceChange__item__ac__header__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-priceChange__item__ac__header__ic {
  flex-shrink: 0;
}
.p-priceChange__item__ac__body {
  border-top: 1px solid var(--gray200);
  padding: 16px;
  display: none;
}
.p-priceChange__item__ac__body.is-active {
  display: block;
}
.p-priceChange__item__ac__body__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.p-priceChange__item__ac__body__flex__item {
}
.p-priceChange__item__ac__body__flex__item:not(:last-child) {
  margin-right: 16px;
}
.p-priceChange__item__ac__body__flex__item__ttl {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  margin-bottom: 6px !important;
}
.p-priceChange__item__ac__body__note {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--error700);
  margin-top: 16px;
}
.p-priceChange__item__table {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  border: 1px solid var(--gray200);
  border-collapse: separate;
  border-spacing: 0px;
  border-radius: 12px;
}
.p-priceChange__item__table th {
  font-weight: var(--text-medium);
  text-align: left;
  color: var(--gray600);
  background-color: var(--gray50);
  padding: 16px;
}
.p-priceChange__item__table td {
  font-weight: var(--text-normal);
  padding: 16px;
}
.p-priceChange__item__table tr > *:not(:last-child) {
  border-right: 1px solid var(--gray200);
}
.p-priceChange__item__table tr:not(:first-child) > * {
  border-top: 1px solid var(--gray200);
}
.p-priceChange__item__table tr:first-child th:first-child {
  border-radius: 12px 0 0 0;
}
.p-priceChange__item__table tr:first-child th:last-child {
  border-radius: 0 12px 0 0;
}
.p-priceChange__item__table tr:last-child th:first-child {
  border-radius: 0 0 0 12px;
}
.p-priceChange__item__table tr:last-child th:last-child {
  border-radius: 0 0 12px 0;
}
.p-priceChange__item__card {
  width: 100%;
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--primary700);
  background-color: var(--primary50);
  border-radius: 8px;
  padding: 12px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-priceChange__item__card i {
  font-size: 20px;
  margin-right: 8px;
}
.p-priceChange__item__cardCheck {
  margin-top: 12px;
}

.link {
  color: #008ec9;
}
.link:hover {
  cursor: pointer;
}

.c-field {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.c-field__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-field__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.c-field__body > i::before {
  line-height: 20px;
}
.c-field__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.c-field__body label {
  width: 100%;
  height: 100%;
  position: relative;
}
.c-field__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.c-field__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-field__body__selected input {
  width: 100%;
}
.c-field__body__selected input::-webkit-outer-spin-button,
.c-field__body__selected input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  -moz-appearance: textfield !important;
}
.c-field.is-hover {
  border-color: var(--primary200);
}
.c-field.is-active {
  border-color: var(--primary700);
}
.c-field.is-input .c-field__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  color: var(--gray700);
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.c-field.is-active .c-field__body__title {
  color: var(--primary700);
}
.c-field.is-disabled {
  border-color: var(--gray200);
  background-color: var(--gray50);
  pointer-events: none;
}
.c-field.is-disabled .c-field__body__selected input {
  color: var(--gray500);
}
.c-field.is-input .c-field__body__title.has-icon {
  left: -28px;
}
.c-field.is-error {
  border-color: var(--error300);
  background-color: var(--error50);
}
.c-field.is-error .c-field__body__selected input {
  color: var(--error500);
}

[class^='c-button'] {
  width: fit-content;
  font-weight: var(--text-normal);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
[class^='c-button']:hover {
  transition: all 200ms linear;
}

[class^='c-button'] > *:not(:first-child) {
  margin-left: 8px;
}

/* type */
[class^='c-button'][class*='bg'] {
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-xs);
}
[class^='c-button'][class*='link'] > *:not(i) {
  text-decoration: underline;
}
[class^='c-button'][class*='link'][disabled] > * {
  text-decoration: none;
}
[class^='c-button'][class*='close'] {
  border-radius: var(--radius-md);
}
[class^='c-button'][class*='close'][class*='sm'] > i::before {
  font-size: 20px;
}
[class^='c-button'][class*='close'][class*='md'] > i::before {
  font-size: 20px;
}
[class^='c-button'][class*='close'][class*='lg'] > i::before {
  font-size: 24px;
}

/* size */
[class^='c-button'][class*='xs'] {
  height: 28px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  padding: 0 8px;
}
[class^='c-button'][class*='xs'] > i::before {
  font-size: 16px;
}
[class^='c-button'][class*='sm'] {
  height: 36px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 0 8px;
}
[class^='c-button'][class*='sm'] > i::before {
  font-size: 20px;
}
[class^='c-button'][class*='md'] {
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 0 10px;
}
[class^='c-button'][class*='md'] > i::before {
  font-size: 20px;
}
[class^='c-button'][class*='lg'] {
  height: 44px;
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  padding: 0 12px;
}
[class^='c-button'][class*='lg'] > i::before {
  font-size: 20px;
}

/* bg primary */
[class^='c-button'][class*='primary'] {
  color: var(--white);
  background-color: var(--primary600);
}
[class^='c-button'][class*='primary'][class*='bg']:hover {
  background-color: var(--primary700);
}
[class^='c-button'][class*='primary'][class*='bg']:active {
  background-color: var(--primary600);
  box-shadow: var(--ring-primary-shadow-xs);
}
[class^='c-button'][class*='primary'][disabled] {
  color: var(--white);
  background-color: var(--gray200);
  pointer-events: none;
}

/* bg red */
[class^='c-button'][class*='red'] {
  color: var(--white);
  background-color: var(--error600);
}
[class^='c-button'][class*='red'][class*='bg']:hover {
  background-color: var(--error700);
}
[class^='c-button'][class*='red'][class*='bg']:active {
  background-color: var(--error600);
  box-shadow: var(--ring-error-shadow-xs);
}
[class^='c-button'][class*='red'][disabled] {
  color: var(--white);
  background-color: var(--gray200);
  pointer-events: none;
}

/* bg white */
[class^='c-button'][class*='white'] {
  color: var(--gray700);
  background-color: var(--white);
  border: 1px solid var(--gray200);
}
[class^='c-button'][class*='white'][class*='bg']:hover {
  background-color: var(--gray50);
}
[class^='c-button'][class*='white'][class*='bg']:active {
  background-color: var(--white);
  box-shadow: var(--ring-gray-shadow-xs);
}
[class^='c-button'][class*='white'][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg secondary */
[class^='c-button'][class*='secondary'] {
  color: var(--primary800);
  background-color: var(--primary50);
}
[class^='c-button'][class*='secondary'][class*='bg']:hover {
  background-color: var(--primary100);
}
[class^='c-button'][class*='secondary'][class*='bg']:active {
  background-color: var(--primary50);
  box-shadow: var(--ring-primary-shadow-xs);
}
[class^='c-button'][class*='secondary'][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg tertiaryred */
[class^='c-button'][class*='tertiaryred'] {
  color: var(--error500);
  background-color: var(--error50);
}
[class^='c-button'][class*='tertiaryred'][class*='bg']:hover {
  background-color: var(--error100);
}
[class^='c-button'][class*='tertiaryred'][class*='bg']:active {
  background-color: var(--error50);
  box-shadow: var(--ring-error-shadow-xs);
}
[class^='c-button'][class*='tertiaryred'][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg gray */
[class^='c-button'][class*='-gray'][class*='bg'] {
  color: var(--gray800);
  background-color: var(--gray100);
}
[class^='c-button'][class*='gray'][class*='bg']:hover {
  background-color: var(--gray200);
}
[class^='c-button'][class*='gray'][class*='bg']:active {
  background-color: var(--gray100);
  box-shadow: var(--ring-gray-shadow-xs);
}
[class^='c-button'][class*='gray'][class*='bg'][disabled] {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* bg tertiarygray */
[class^='c-button'][class*='tertiarygray'] {
  color: var(--gray700);
  background-color: transparent;
  box-shadow: var(--shadow-none);
}
[class^='c-button'][class*='tertiarygray'][class*='bg']:hover {
  background-color: var(--gray100);
}
[class^='c-button'][class*='tertiarygray'][class*='bg']:active {
  color: var(--gray700);
  background-color: transparent;
}
[class^='c-button'][class*='tertiarygray'][disabled] {
  color: var(--gray300);
  pointer-events: none;
}

/* bg tertiarycolor */
[class^='c-button'][class*='tertiarycolor'] {
  color: var(--primary800);
  background-color: transparent;
  box-shadow: var(--shadow-none);
}
[class^='c-button'][class*='tertiarycolor'][class*='bg']:hover {
  background-color: var(--primary100);
}
[class^='c-button'][class*='tertiarycolor'][class*='bg']:active {
  color: var(--primary800);
  background-color: transparent;
}
[class^='c-button'][class*='tertiarycolor'][disabled] {
  color: var(--gray300);
  pointer-events: none;
}

/* link gray */
[class^='c-button'][class*='link'][class*='gray'] {
  color: var(--gray800);
}
[class^='c-button'][class*='link'][class*='gray'][disabled] {
  color: var(--gray300);
  pointer-events: none;
}
[class^='c-button'][class*='link'][class*='color'] {
  color: var(--primary700);
}
[class^='c-button'][class*='link'][class*='color'][disabled] {
  color: var(--gray300);
  text-decoration: none;
  pointer-events: none;
}

/* close secondary */
[class^='c-button'][class*='close'][class*='secondary'] {
  color: var(--primary700);
  background-color: transparent;
}
[class^='c-button'][class*='close'][class*='secondary']:hover {
  background-color: var(--primary50);
}
[class^='c-button'][class*='close'][class*='secondary']:active {
  background-color: var(--primary50);
  box-shadow: var(--ring-primary-shadow-xs);
}

/* close gray */
[class^='c-button'][class*='close'][class*='gray'] {
  color: var(--gray600);
  background-color: transparent;
}
[class^='c-button'][class*='close'][class*='gray']:hover {
  background-color: var(--gray50);
}
[class^='c-button'][class*='close'][class*='gray']:active {
  background-color: var(--gray50);
}
