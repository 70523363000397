.table-pane {
  background-color: white;
  padding: 4px;
}

.table-pane .title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.boarder {
  border: 1px solid #cfdae1;
}
