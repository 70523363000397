.c-table1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.c-table1__list {
  width: 50%;
  display: flex;
  align-items: flex-start;
}
.c-table1__list__title {
  width: 120px;
  border: 1px solid #ddd;
  text-align: center;
  padding: 4px;
}
.c-table1__list__body {
  padding: 4px 8px;
}
.c-schedule__select {
  width: 100%;
  max-width: 320px;
  height: 32px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 16px;
  display: block;
  position: relative;
}
.c-schedule__select::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: 14px;
  right: 8px;
}
.c-schedule__select select {
  width: 100%;
  height: 100%;
  color: inherit;
  padding: 0 24px 0 8px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .c-table1__list {
    width: 100%;
  }
  .c-table1__list + .c-table1__list {
    margin-top: 8px;
  }
}

.c-table2 {
  width: 100%;
  border: 1px solid #ddd;
  display: flex;
  flex-wrap: wrap;
}
.c-table2__list {
  width: 50%;
  border-bottom: 1px solid #ddd;
  display: flex;
}
.c-table2__list:nth-child(odd) {
  border-right: 1px solid #ddd;
}
.c-table2__list:nth-child(odd).c-table2__list:nth-last-child(1),
.c-table2__list:nth-child(odd).c-table2__list:nth-last-child(2),
.c-table2__list:nth-child(even).c-table2__list:nth-last-child(1) {
  border-bottom: 0;
}

.c-table2__list__title {
  width: 160px;
  border-right: 1px solid #ddd;
  background: #f9f9f9;
  text-align: center;
  padding: 4px;
}
.c-table2__list__body {
  padding: 4px 8px;
}
@media screen and (max-width: 768px) {
  .c-table2__list {
    width: 100%;
  }
  .c-table2__list:nth-child(odd) {
    border-right: 0;
  }
  .c-table2__list:nth-child(odd).c-table2__list:nth-last-child(2) {
    border-bottom: 1px solid #ddd;
  }
  .c-table2__list__title {
    text-align: left;
    padding: 4px 8px;
  }
}
