
.availability-table-wrapper {
  position: relative;
  scroll-margin-top: 96px;
  width: 100%;
}
.availability-table-header {
  font-size: 12px;
  width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: sticky;
  top: -1px;
  z-index: 2;
}
.availability-table-header table {
  background: #fff;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.availability-table-body-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-top: 0px;
}
.pax-count-cell {
  display: flex;
  justify-content: center;
}
.pax-count-icon {
  align-items: center;
  background: #9bacb5;
  border-radius: 100%;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 4px;
  width: 16px;
}
.pax-count-icon img {
  height: 12px;
  width: 12px;
}
.product-icon-header,
.product-name-header,
.start-time-header,
.pax-count-header {
  position: sticky;
  position: -webkit-sticky;
  z-index: 3;
  background: #fff;
}
.product-icon-cell,
.product-name-cell,
.start-time-cell {
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  background: #fff;
}
.start-time-cell,
.start-time-header,
.pax-count-header {
  border-right: 3px solid #cfdae1 !important;
}
.product-icon-cell,
.product-icon-header {
  left: 0;
}
.product-name-cell,
.product-name-header {
  left: 49px;
  min-width: 272px;
  width: 272px;
}
.start-time-cell,
.start-time-header,
.pax-count-header {
  left: 321px;
}
.product-name-header {
  text-align: left;
  min-width: 272px;
  width: 272px;
}
.product-name-content {
  color: #008ec9;
  font-weight: 700;
}
.product-icon-content,
.product-name-content,
.start-time-content {
  padding: 8px;
}
.date-availability-item {
  align-items: center;
  display: flex;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 16px 8px;
}
.availability-table td > a:hover,
.availability-table th > a:hover {
  color: #008ec9;
}
.red100 {
  background: #ff7878;
}
.red75 {
  background: #ffaaaa;
}
.red50 {
  background: #ffd4d4;
}
.red25 {
  background: #ffebeb;
}
.yellow {
  background: #fff4c7;
}
.gray {
  background: #ebf1f3;
  color: #9bacb5;
}
.no-allotment {
  width: 100%;
  height: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.availability-icon {
  height: 16px;
  margin-right: 4px;
  width: 16px;
}
.scroll-wrapper {
  /* 
  "inline-block" sets the div to the width of the contents so the product icon and product name columns
  are in a scroll context that is the full length of the table so they don't get pushed off screen when
  scrolling right.
  */
  display: inline-block;
}

.selected-cell {
  border: 2px solid #008ec9 !important;
}
.deselected-cell:hover {
  border: 2px solid #86d6f4 !important;
}

@media screen and (max-width: 768px) {
  .scroll-wrapper {
    display: block;
  }
  .mobile-start-time-header {
    z-index: 3;
  }
  .deselected-cell:hover {
    border: none !important;
  }
  .availability-table td > a:hover,
  .availability-table th > a:hover {
    color: black;
  }
}
