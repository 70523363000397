.c-inputcontent.sm > span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-left: 24px;
  display: block;
}
.c-inputcontent.md > span {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 28px;
  display: block;
}

/* Base properties */
.c-checkbox {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-checkbox i {
  font-family: 'icon-general-solid' !important;
  background-color: var(--white);
  border: 1px solid var(--gray300);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.c-checkbox i::before {
  content: '\e914';
  line-height: 1;
  color: var(--white);
  margin-left: -2px;
}
.c-checkbox input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.c-checkbox p {
  margin-left: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* checkbox checked */
.c-checkbox input:checked + i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
.c-checkbox input:checked + i::before {
  color: var(--primary600);
}
/* checkbox indeterminate */
.c-checkbox input:indeterminate + i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
}
.c-checkbox input:indeterminate + i::before {
  content: '\e982';
  color: var(--primary600);
}
/* checkbox hover */
.c-checkbox:hover i {
  background-color: var(--primary50);
  border: 1px solid var(--primary600);
  cursor: pointer;
}
.c-checkbox:hover input:checked + i::before {
  color: var(--primary600);
}
/* checkbox active */
.c-checkbox:active i {
  background-color: var(--white);
  border: 1px solid var(--primary300);
  box-shadow: var(--ring-primary-shadow-xs);
}
/* checkbox disabled */
.c-checkbox input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
}
.c-checkbox:active input:disabled + i {
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
  box-shadow: var(--ring-primary-shadow-none);
}
.c-checkbox input:disabled + i::before {
  color: transparent;
}
.c-checkbox input:disabled:hover,
.c-checkbox input:disabled + i:hover {
  cursor: default;
}


.c-checkbox__menu__item {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.c-checkbox__menu__item .is-disabled {
  color: var(--gray400);
}

/* Size */
.sm i {
  width: 16px;
  height: 16px;
  border-radius: var(--radius-xs);
}
.sm i::before {
  font-size: 12px;
}
.sm p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

.md i {
  width: 20px;
  height: 20px;
  border-radius: var(--radius-xs);
}
.md i::before {
  font-size: 14px;
}
.md p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}

fieldset {
  padding: 0 !important;
  margin: 0;
}

.is-error {
  color: var(--error500);
}
.is-error .c-checkbox__menu__item i {
  border-color: var(--error300);
}