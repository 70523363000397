.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.code-container {
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  padding: 16px;
  width: 100%;
}
.code-block {
  background-color: #0000000a;
  border-radius: 3px;
  display: inline-block;
  white-space: pre-wrap;
  width: 100%;
  word-break: break-all;
}
.code-label {
  color: var(--gray700);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
