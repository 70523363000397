.c-dateListInput {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.c-dateListInput__list {
  display: flex;
  flex-direction: column;
}
.c-dateListInput__list > *:not(:first-child) {
  margin-top: 8px;
}
.c-dateListInput__list__item {
  display: flex;
}
.c-dateListInput__list__item > button {
  margin-left: 8px;
}
.c-dateListInput__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-dateListInput__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
