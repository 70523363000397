.page-tutorial__ttl {
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 16px;
}
.page-home-tutorial {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 32px;
}
.page-home-tutorial__ttl {
  width: 280px;
  height: 40px;
  border: 1px solid #008ec9;
  font-size: 20px;
  font-weight: bold;
  display: table;
  margin: 0 auto 32px auto;
}
.page-home-tutorial__ttl p:first-child {
  width: 200px;
  color: #fff;
  background: #008ec9;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.page-home-tutorial__ttl p:last-child {
  color: #008ec9;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
.page-home-tutorial__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.page-home-tutorial__list li {
  width: calc((100% - 64px) / 3);
  position: relative;
}
.page-home-tutorial__list li:not(:last-child)::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent #cfdae1;
  position: absolute;
  top: calc(50% - 8px);
  right: -24px;
}
.page-home-tutorial__finish {
  border-radius: 8px;
  background: #fff;
  padding: 16px;
  margin-bottom: 32px;
}
.page-home-tutorial__finish__body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.page-home-tutorial__finish__body__pic {
  margin-right: 32px;
  flex-shrink: 0;
}
.page-home-tutorial__finish__body__pic img {
  height: 128px;
}
.page-home-tutorial__finish__body__main {
}
.page-home-tutorial__finish__body__main .page-home-tutorial__ttl {
  margin: 0;
}
.page-home-tutorial__finish__body__main__txt {
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .page-home-tutorial {
    padding: 16px;
    margin-top: 16px;
    border: none;
  }
  .page-home-tutorial__ttl {
    margin-bottom: 16px;
  }
  .page-home-tutorial__list li {
    width: 100%;
  }
  .page-home-tutorial__list li:not(:last-child) {
    margin-bottom: 32px;
  }
  .page-home-tutorial__list li:not(:last-child)::after {
    top: auto;
    right: calc(50% - 8px);
    bottom: -32px;
    transform: rotate(90deg);
  }
  .page-home-tutorial__finish {
    border-radius: 0;
    margin-top: 16px;
  }
  .page-home-tutorial__finish__body {
    display: block;
  }
  .page-home-tutorial__finish__body__pic {
    text-align: center;
    margin-right: 0;
    margin-bottom: 16px;
  }
  .page-home-tutorial__finish__body__main .page-home-tutorial__ttl {
    margin: 0 auto;
  }
  .page-home-tutorial__finish__body__main__txt {
    font-size: 16px;
    text-align: center;
    max-width: 240px;
    margin: 16px auto 0 auto;
  }
}
/*------------------------------------------------------------------------------
    tutorial
    ------------------------------------------------------------------------------*/
.page-tutorial__nav {
  width: calc(100% - 24px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-tutorial__nav li {
  width: calc(100% / 3);
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  padding-left: 32px;
  font-weight: bold;
  color: #4a6c7a;
  position: relative;
}
.page-tutorial__nav li::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 0 24px 24px;
  position: absolute;
  top: 0;
  right: -24px;
}
.page-tutorial__nav li:nth-child(odd) {
  background: #fff;
}
.page-tutorial__nav li:nth-child(even) {
  background: #f9f9f9;
}
.page-tutorial__nav li:nth-child(odd)::after {
  border-color: transparent transparent transparent #fff;
}
.page-tutorial__nav li:nth-child(even)::after {
  border-color: transparent transparent transparent #f9f9f9;
}
.page-tutorial__nav li:nth-child(1) {
  z-index: 5;
}
.page-tutorial__nav li:nth-child(2) {
  z-index: 4;
}
.page-tutorial__nav li:nth-child(3) {
  z-index: 3;
}
.page-tutorial__nav li:nth-child(4) {
  z-index: 2;
}
.page-tutorial__nav li:nth-child(5) {
  z-index: 1;
}
.page-tutorial__nav li.is-active {
  color: #fff;
  background: #008ec9;
}
.page-tutorial__nav li.is-active::after {
  border-color: transparent transparent transparent #008ec9;
}
/*------------------------------------------------*/
.page-tutorial__box {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #f9f9f9;
  padding: 40px 0;
  position: relative;
}
.page-tutorial__box__left {
  background: #fff;
  padding: 16px;
  position: relative;
}
.page-tutorial__box__left {
}
.page-tutorial__box__left__next {
  width: 240px;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  background: #ffaaaa;
  margin-bottom: 8px;
}
.page-tutorial__box__left__desc {
  font-size: 20px;
  font-weight: bold;
  color: #008ec9;
}
.page-tutorial__box__right {
}
.page-tutorial__box::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 64px 0 0;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.page-tutorial__box::after {
  content: attr(data-num) '';
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.page-tutorial__box__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__box__pic {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background: #ebf1f3;
  margin-right: 16px;
  flex-shrink: 0;
}
.page-tutorial__box__pic img {
  width: inherit;
  height: inherit;
}
.page-tutorial__box__body {
}
.page-tutorial__box__body__ttl {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.page-tutorial__box__body__btn a {
  background: #cfdae1 !important;
  pointer-events: none;
}
.page-tutorial__box__body__next {
  width: 64px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  background: #ffaaaa;
  margin-top: 10px;
  position: relative;
  opacity: 0;
}
.page-tutorial__box__body__next::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #ffaaaa transparent;
  position: absolute;
  top: -6px;
  left: calc(50% - 6px);
}
.page-tutorial__box.is-active {
  border: 1px solid #008ec9;
}
.page-tutorial__box.is-active a {
  background: #ffcc00 !important;
  pointer-events: auto;
}
.page-tutorial__box.is-active .page-tutorial__box__body__next {
  opacity: 1;
}
.page-tutorial__box.is-finish {
  opacity: 0.5;
}

.page-tutorial__box--step2 {
  border: 4px solid #008ec9 !important;
  padding: 40px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__box--step2 .page-tutorial__box__left {
  width: 50%;
  max-width: 560px;
  margin-right: 64px;
}
.page-tutorial__box--step2 .page-tutorial__box__left::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent #008ec9;
  position: absolute;
  top: calc(50% - 16px);
  right: -40px;
}
.page-tutorial__box--step2 .page-tutorial__box__right {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.page-tutorial__box--step3 {
  border: 4px solid #008ec9 !important;
  padding: 16px;
}
.page-tutorial__box--step3 .page-tutorial__box__left {
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page-tutorial__box--step3 .page-tutorial__box__left::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 16px;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  bottom: -24px;
  left: calc(50% - 16px);
}
.page-tutorial__box--step3 .page-tutorial__box__right {
}
/*------------------------------------------------*/
.page-tutorial__popupFrame {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
}
.page-tutorial__popup {
  width: calc(100% - 32px);
  max-width: 480px;
  border-radius: 4px;
  background: #fff;
  padding: 16px;
  position: absolute;
}
.page-tutorial__popup::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 16px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -16px;
  left: 32px;
}
.page-tutorial__popup.large {
  max-width: 640px;
}
.page-tutorial__popup > * + * {
  margin-top: 16px;
}
.page-tutorial__popup__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-tutorial__popup__ttl img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  flex-shrink: 0;
}
.page-tutorial__popup__ttl p {
  font-size: 18px;
  font-weight: bold;
}
.page-tutorial__popup__desc {
}
.page-tutorial__popup__btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-tutorial__popupFinish {
  width: calc(100% - 32px);
  max-width: 640px;
  border-radius: 8px;
  background: #fff;
  margin: 80px auto 0 auto;
}
.page-tutorial__popupFinish__header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #cfdae1;
  padding: 16px;
}
.page-tutorial__popupFinish__body {
  padding: 16px;
}
.page-tutorial__popupFinish__body__pic {
  text-align: center;
}
.page-tutorial__popupFinish__body__pic img {
  height: 128px;
}
.page-tutorial__popupFinish__body__desc {
  font-size: 16px;
  text-align: center;
  padding: 16px 0;
}
/*------------------------------------------------*/
@media screen and (max-width: 768px) {
  .page-tutorial__nav {
    width: calc(100% - 8px);
    margin-top: 16px;
  }
  .page-tutorial__nav li {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding-left: 16px;
  }
  .page-tutorial__nav li::after {
    border-width: 16px 0 16px 8px;
    right: -8px;
  }
  .page-tutorial__nav li span {
    display: none;
  }
  .page-tutorial__box__left__desc {
    font-size: 16px;
    text-align: center;
  }
  .page-tutorial__box--step2 {
    width: calc(100% - 16px);
    margin: 16px auto;
    padding: 16px;
    flex-direction: column;
  }
  .page-tutorial__box--step2 .page-tutorial__box__left {
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .page-tutorial__box--step2 .page-tutorial__box__left::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #008ec9 transparent transparent transparent;
    position: absolute;
    top: auto;
    bottom: -24px;
    left: calc(50% - 16px);
  }
  .page-tutorial__box--step2 .page-tutorial__box__right {
    width: 100%;
  }
  .page-tutorial__popupFinish {
    margin-top: 16px;
  }
  .page-tutorial__popupFinish__body {
    padding: 16px 8px;
  }
}

/*------------------------------------------------------------------------------
    products regist
    ------------------------------------------------------------------------------*/
/*------------------------------------------------*/
.page-productsRegist__priceSelect {
}
.page-productsRegist__priceSelect > li {
  width: 240px;
  margin-right: 8px;
  position: relative;
}
.page-productsRegist__priceSelect .tooltip-box {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
/*------------------------------------------------*/
.page-productsRegist__priceMain {
  margin-top: 8px;
}
.page-productsRegist__priceMain__ttl {
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 8px;
}
.page-productsRegist__priceMain__age {
  display: flex;
  justify-content: flex-start;
}
.page-productsRegist__priceMain__age p {
  line-height: 40px;
  padding: 0 8px;
}
.page-productsRegist__priceMain__price {
  display: flex;
  justify-content: flex-start;
}
.page-productsRegist__priceMain__price p {
  font-weight: bold;
  line-height: 40px;
  padding: 0 8px;
  margin: 0px;
}
/*------------------------------------------------*/
.page-productsRegist__range {
}
/*------------------------------------------------*/
.page-productsRegist__date {
}
.page-productsRegist__date:not(:last-child) {
  /* border-bottom: 1px solid #cfdae1; */
  padding-bottom: 8px;
  margin-bottom: 8px;
}
p.page-productsRegist__date__ttl {
  margin-bottom: 8px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
/*------------------------------------------------*/
.page-productsRegist__date__range {
  width: 100%;
  max-width: 320px;
}
/*------------------------------------------------*/
.page-productsRegist__date__week {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-productsRegist__date__week > div {
  width: 96px;
  margin-top: 0 !important;
  margin-right: 8px;
  margin-bottom: 8px;
}
/*------------------------------------------------*/
.page-productsRegist__date__exclusion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__date__exclusion + .page-productsRegist__date__exclusion {
  margin-top: 8px;
}
.page-productsRegist__date__exclusion label {
  width: 160px;
  margin-right: 8px;
}
/*------------------------------------------------*/
.page-productsRegist__timeStock {
}
.page-productsRegist__timeStock__stock {
}
.page-productsRegist__timeStock__stock .base-form-toggle {
  flex-shrink: 0;
  margin-left: 8px;
}
.page-productsRegist__timeStock__note {
}
.page-productsRegist__timeStock__note input {
  max-width: 360px;
}
.request-booking-toggle-container {
  min-width: 180px;
}
.request-booking-toggle {
  margin-top: 4px;
}
/*------------------------------------------------*/
.page-productsRegist__timeline {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.page-productsRegist__timeline__item {
  width: 200px;
  height: 48px;
  border-right: 1px solid #cfdae1;
  margin-bottom: 8px;
  position: relative;
}
.page-productsRegist__timeline__item__txt {
  font-size: 12px;
  padding: 4px 8px 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-productsRegist__timeline__item__txt p:first-child {
  font-weight: bold;
  color: #4a6c7a;
}
.page-productsRegist__timeline__item__txt p.blue {
  color: #008ec9;
}
.page-productsRegist__timeline__item__txt p.yellow {
  color: #ffcc00;
}
.page-productsRegist__timeline__item__arrow {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.page-productsRegist__timeline__item__arrow::before {
  content: '';
  width: calc(100% - 20px);
  height: 8px;
  background: #cfdae1;
}
.page-productsRegist__timeline__item__arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent #cfdae1;
}
.page-productsRegist__timeline__last {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 48px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #008ec9;
  border-radius: 8px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*------------------------------------------------*/
.page-productsRegist__reserve {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-productsRegist__reserve__item {
  min-width: 200px;
}
.page-productsRegist__reserve__item > p {
}
.page-productsRegist__reserve__item > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;
}
.page-productsRegist__reserve__item > div label {
  width: 128px;
}
/*------------------------------------------------*/
.page-productsRegist__cancel {
}
.page-productsRegist__cancel__limit {
  display: flex;
  justify-content: flex-start;
}
.page-productsRegist__cancel__limit label {
  width: 128px;
}
.page-productsRegist__cancel__limit label:first-child {
  border-radius: 4px 0 0 4px;
}
.page-productsRegist__cancel__limit label:last-child {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}
/*------------------------------------------------*/
.page-productsRegist__aclist {
}
/*------------------------------------------------*/
.page-productsRegist__tableBox + .page-productsRegist__tableBox {
  margin-top: 16px;
}
.page-productsRegist__subttl {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__subttl p {
  font-size: 16px;
  font-weight: bold;
}
.page-productsRegist__url {
}
.page-productsRegist__url a {
  color: #008ec9;
  font-weight: bold;
}
.page-productsRegist__url p {
  margin-top: 4px;
}
.page-productsRegist__google {
}
.page-productsRegist__google p {
  margin-top: 4px;
}
.page-productsRegist__google a {
  color: #008ec9;
  font-weight: bold;
  text-decoration: underline;
}
.page-productsRegist__checkbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.page-productsRegist__checkbox > div {
  width: 128px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.page-productsRegist__checkbox.width160 > div {
  width: 160px;
}
.page-productsRegist__checkbox > div + div {
  margin-top: 0 !important;
}
.page-productsRegist__file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__file > p {
  margin-right: 24px;
}
.page-productsRegist__file > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__file > div label {
  width: 128px;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}
.page-productsRegist__file > div label input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.page-productsRegist__file > div span {
  font-size: 12px;
  font-weight: bold;
  color: #4a6c7a;
}
.page-productsRegist__preview {
  width: 100%;
  max-width: 280px;
  min-height: 64px;
  max-height: 80px;
  padding: 8px;
  margin-top: 8px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-productsRegist__preview img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.page-productsRegist__preview p {
  font-size: 14px;
  font-weight: bold;
  color: #cfdae1;
}
.page-productsRegist__businessHours {
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-productsRegist__businessHours > label {
  width: calc((100% - 24px) / 2);
}
.page-productsRegist__businessHours > p {
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.page-productsRegist__credit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__credit__item {
}
.page-productsRegist__credit__item > p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
.page-productsRegist__credit__item > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-productsRegist__credit__item > div > input {
  width: 128px;
}
.page-productsRegist__credit__item > div > p {
  margin: 0 4px;
}
/*------------------------------------------------*/
@media screen and (max-width: 768px) {
  .page-productsRegist__picture__item {
    width: calc(50% - 4px);
  }
  .page-productsRegist__picture__item:nth-child(odd) {
    margin-right: 8px;
  }
  .page-productsRegist__priceSelect {
    flex-direction: column;
  }
  .page-productsRegist__priceSelect li {
    width: 100%;
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
  }
  .page-productsRegist__priceMain__list {
    display: block;
  }
  .page-productsRegist__priceMain__age label {
    min-width: 60px;
  }
  .page-productsRegist__date__week > div {
    width: calc(50% - 4px);
    margin-right: 0;
  }
  .page-productsRegist__date__week > div:nth-child(odd) {
    margin-right: 8px;
  }
  .page-productsRegist__timeStock .c-tableChild ul:not(:first-child) {
    display: flex;
    flex-direction: column;
  }
  .page-productsRegist__timeStock .c-tableChild ul .base-flex {
    order: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .page-productsRegist__timeStock .c-tableChild ul .base-flex input {
    margin-bottom: 8px;
  }
  .page-productsRegist__timeline {
    background: #f9f9f9;
    padding: 8px;
  }
  .page-productsRegist__timeline__item {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #cfdae1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
  }
  .page-productsRegist__timeline__item__txt {
    width: calc(100% - 24px);
  }
  .page-productsRegist__timeline__item__arrow {
    width: 24px;
    height: 48px;
    flex-direction: column;
  }
  .page-productsRegist__timeline__item__arrow::before {
    width: 8px;
    height: calc(48px - 20px);
  }
  .page-productsRegist__timeline__item__arrow::after {
    border-width: 20px 12px 0 12px;
    border-color: #cfdae1 transparent transparent transparent;
  }
  .page-productsRegist__timeline__last {
    margin-left: 0;
  }
  .page-productsRegist__reserve__item {
    margin-bottom: 8px;
  }
  .page-productsRegist__cancel__limit label {
    width: 100px;
  }
  .page-productsRegist__file {
    flex-direction: column;
    align-items: flex-start;
  }
  .page-productsRegist__file > div {
    margin-top: 8px;
  }
  .page-productsRegist__checkbox > div,
  .page-productsRegist__checkbox.width160 > div {
    width: calc(50% - 4px);
    margin-right: 0;
  }
  .page-productsRegist__checkbox > div:nth-child(odd) {
    margin-right: 8px;
  }
}
/*------------------------------------------------------------------------------
  table-list
  ------------------------------------------------------------------------------*/
.c-table-list {
}
.c-table-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-list table th,
.c-table-list table td {
  border: 1px solid #cfdae1;
  padding: 8px;
}
.c-table-list table th {
  width: 240px;
  font-weight: normal;
  text-align: left;
  background: #ebf1f3;
}
.c-table-list__note {
  font-size: 14px;
  margin-top: 8px;
}
.c-table-list__note.bold {
  font-weight: bold;
}
.c-table-list__note.red {
  color: #dc3e15;
}
.c-table-list__note.yellow {
  color: #ffcc00;
}
.c-table-list__note.green {
  color: #00bf98;
}
.c-table-list__note.blue {
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .c-table-list table,
  .c-table-list table tbody,
  .c-table-list table tr,
  .c-table-list table th,
  .c-table-list table td {
    width: 100%;
    display: block;
  }
  .c-table-list table tr + tr {
    margin-top: 8px;
  }
  .c-table-list table th {
    width: 100%;
    margin-bottom: -1px;
  }
  .c-table-list table td .base-t-800 {
    width: 100%;
  }
}

/*------------------------------------------------------------------------------
  tableChild
  ------------------------------------------------------------------------------*/
.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul {
    border-bottom: 1px solid #cfdae1;
    margin-bottom: 8px;
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
}
