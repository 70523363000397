.weeks {
  display: table;
  table-layout: fixed;
  margin-top: 16px;
  gap: 20px;
  font-size: 12px;
  color: var(--Colors-Gray-800, #27272a);
  font-weight: 400;
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  white-space: nowrap;
  text-align: center;
  line-height: 133%;
  justify-content: space-between;
}
.week-title {
  display: table;
  table-layout: fixed;
}
.week {
  display: table;
  table-layout: fixed;
}
.day-title {
  width: 32px;
  display: table-cell;
}
.day {
  height: 32px;
  width: 32px;
  vertical-align: middle;
  display: table-cell;
  position: relative;
}
.session-marker {
  border-radius: 80px;
  background-color: var(--Colors-Primary-100, #e0f3fe);
  cursor: pointer;
}
.conversion-marker {
  background-color: var(--Warning-500, #f59e0b);
  border-radius: 50%;
  margin-left: 12px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
}
.day.disabled {
  color: var(--Colors-Gray-400, #a3a3a8);
}
