.c-schedule2 {
}
.c-schedule2__list {
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.c-schedule2__list + .c-schedule2__list {
  border-top: 1px solid #eee;
  padding-top: 16px;
}
.c-schedule2__list:last-child {
  padding-bottom: 0;
}
.c-schedule2__list__item__title {
  width: 240px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.c-schedule2__list__item__body {
  width: calc(100% - 240px);
}
.c-schedule2__list__item__body__text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-schedule2__list__item__body__text__th {
  width: 96px;
  font-size: 14px;
  font-weight: bold;
}
.c-schedule2__list__item__body__text__td {
  width: calc(100% - 96px);
  font-size: 14px;
  color: #666;
}
.c-schedule2__list__item__body__price {
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-schedule2__list__item__body__price__th {
  width: 96px;
  font-size: 14px;
  font-weight: bold;
}
.c-schedule2__list__item__body__price__td {
  width: calc(100% - 96px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-schedule2__list__item__body__price__td p {
  margin-right: 8px;
  margin-bottom: 4px;
}
.c-schedule2__list__item__body__price__td p span {
  border: 1px solid #ddd;
  background: #fff;
  padding: 2px 4px;
  margin-right: 4px;
}
@media screen and (max-width: 767px) {
  .c-schedule2__list__item__title {
    width: 100%;
    margin-bottom: 16px;
  }
  .c-schedule2__list__item__body {
    width: 100%;
  }
  .c-schedule2__list__item__body__text__th {
    width: 100%;
    margin-bottom: 8px;
  }
  .c-schedule2__list__item__body__text__td {
    width: 100%;
  }
  .c-schedule2__list__item__body__price__th {
    width: 100%;
    margin-bottom: 8px;
  }
  .c-schedule2__list__item__body__price__td {
    width: 100%;
  }
}
