.p-dashboardDataList__item__header__actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.p-dashboardDataList__item__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-dashboardDataList__item__header__actions a {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-dashboardDataList__item__header__actions .on {
  font-size: 32px;
  color: var(--primary500);
}
.p-dashboardDataList__item__header__actions .off {
  font-size: 32px;
  color: var(--gray300);
}
