.cdx-list--ordered * {
  list-style: decimal;
}

.cdx-list--unordered * {
  list-style: disc;
}

.image-tool__image {
  display: flex;
  justify-content: center;
}
