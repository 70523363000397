.fade-out {
  animation: fade-out 10s linear forwards;
}

@keyframes fade-out {
  95% {
    opacity: 1;
    transform: translateY(0px);
  }
  99% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
  }
}
