.buttons-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.button-container + .button-container {
  margin-left: 8px;
}
.mobile-expand-button {
  display: none;
}
@media screen and (max-width: 768px) {
  .buttons-row {
    margin-left: 8px;
    position: relative;
  }
  .button-container + .button-container {
    margin-left: 0px;
    margin-top: 8px;
  }
  .mobile-expand-button {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #cfdae1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-expand-button img {
    width: 24px;
    height: 24px;
  }
  .buttons-wrapper {
    display: none;
  }
  .buttons-row.expanded-on-mobile > .buttons-wrapper {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 8px;
    display: block;
    position: absolute;
    top: 44px;
    left: 0px;
    /* right: 0px; */ /* NOTE(goro) change from -48px to 0px*/
    z-index: 2;
  }
}
