.company-name-box {
  padding-right: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button-box {
  margin-top: 24px;
}

.button-box + .button-box {
  margin-left: 8px;
}

.input-box {
  max-width: 400px;
}

.input-box + .input-box {
  margin-top: 16px;
}

.notification-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.notification-time div {
  max-width: 150px;
}

.notification-time div {
  max-width: 150px;
}

.scheds__body__content {
  width: 100%;
}
.scheds__body__content a {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .notification-time {
    display: block;
  }
  .notification-time div {
    max-width: 800px;
    padding: 1px;
  }
}
