.p-assignments {
}
.p-assignments__section {
  border-bottom: 1px solid var(--gray200);
  padding-bottom: 32px;
  margin-bottom: 32px;
}
.p-assignments__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-assignments__ttl > i {
  font-size: 24px;
  color: var(--primary600);
  margin-right: 8px;
}
.p-assignments__ttl > p {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-semibold);
  margin-bottom: 0;
  margin-right: 8px;
}
.p-assignments__ttl > div {
  margin-right: 8px;
}

.p-assignmentsModal {
  width: 100%;
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  padding: 16px;
}
.p-assignmentsModal__search {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -16px;
}
.p-assignmentsModal__search__item {
  width: calc(50% - 8px);
  margin-bottom: 16px;
}
.p-assignmentsModal__search__item:nth-child(odd) {
  margin-right: 16px;
}
.p-assignmentsModal__search__item__ttl {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-medium);
  /* Override semantic */
  margin-bottom: 8px !important;
}
.p-assignmentsModal__search__item__body {
}

@media screen and (max-width: 768px) {
  .p-assignmentsModal__search {
    flex-direction: column;
  }
  .p-assignmentsModal__search__item {
    width: 100%;
  }
  .p-assignmentsModal__search__item:nth-child(odd) {
    margin-right: 0;
  }
}