.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #dc3545;
  text-decoration: none;
  border-radius: 4px;
}
.button.delete {
  color: #dc3545;
}
.button.edit {
  color: #007bff;
}

.button.delete:hover {
  color: #dc3545;
}

.button.edit:hover {
  color: #007bff;
}

.button i {
  font-size: 16px;
}

.fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.subMenuItem {
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}
