.c-pageHeader {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.c-pageHeader__title > h1 {
  font-size: var(--text-2xl-size);
  font-weight: var(--text-bold);
  line-height: var(--text-2xl-height);
  margin: 0;
}
.c-pageHeader__title > span {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
}
.c-pageHeader__descriptionLink > a {
  color: var(--primary700);
  text-decoration: underline;
}
.c-pageHeader__descriptionLink > a:hover {
  color: var(--primary600);
  text-decoration: underline;
}
.c-pageHeader__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-top: -12px;
}
.c-pageHeader__actions > * {
  margin-top: 12px;
}
.c-pageHeader__actions > *:not(:last-child) {
  margin-right: 12px;
}
.c-pageHeader__actions__noTitle {
  width: 100%;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
}

@media screen and (max-width: 768px) {
  .c-pageHeader {
    flex-direction: column;
  }
  .c-pageHeader__actions {
    margin-top: 16px;
    margin-left: 0;
    justify-content: flex-start;
  }
  .c-pageHeader__actions > *:first-child {
    margin-left: 0;
  }
}
