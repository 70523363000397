.daterange {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.datepicker {
  min-width: 200px;
  z-index: 9;
}
.custom-range-header {
  display: flex;
  align-items: center;
  margin-top: -8px;
}
.buttons {
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .custom-range-header {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .daterange {
    margin-top: 12px;
    margin-left: 8px;
  }
  .datepicker {
    min-width: 150px;
    z-index: 9;
  }
  .buttons {
    margin-left: 8px;
  }
}
