.tooltip {
  font-size: 12px;
  font-weight: 700;
  display: block;
  position: absolute;
  z-index: 10000;
  border: 1px;
  top: 0;
  left: 0;
  background-color: white;
  border: 1px solid;
  padding: 3px;
  width: 120px;
  border-radius: 4px;
}
