.price-table {
}
.price-table__thead {
  background: #fff;
  border-bottom: 2px solid #cfdae1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: -1px;
  z-index: 2;
}
.price-table__thead__left {
  width: calc(100% - 1120px);
  min-width: 320px;
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.price-table__thead__left > div {
  border-left: 1px solid #cfdae1;
  border-top: 1px solid #cfdae1;
  border-bottom: 1px solid #cfdae1;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.price-table__thead__left > div:nth-child(2) {
  width: calc(100% - 48px);
}
.price-table__thead__right {
}
.price-table__thead__right table {
  width: 1120px;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
}
.price-table__thead__right th {
  height: 32px;
  padding: 8px;
  border: 1px solid #cfdae1;
}
.price-table__thead__right th:first-child {
  background: #fff;
}
.price-table__thead__right th.left {
  text-align: left;
}

.price-table__tbody {
  overflow-x: auto;
}
.price-table__tbody__box {
  background: #fff;
  margin-top: -1px;
  display: flex;
  justify-content: flex-start;
}
.price-table__tbody__box__left {
  width: calc(100% - 1120px);
  min-width: 320px;
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.price-table__tbody__box__left > div {
  border-left: 1px solid #cfdae1;
  border-top: 1px solid #cfdae1;
  border-bottom: 1px solid #cfdae1;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.price-table__tbody__box__left > div:nth-child(2) {
  width: calc(100% - 48px);
}
.price-table__tbody__box__left > div a {
  font-weight: bold;
  color: #008ec9;
}
.price-table__tbody__box__right {
}
.price-table__tbody__box__right table.price-table {
  width: 1120px;
  height: calc(100% - 1px);
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
}
.price-table__tbody__box__right tr.price-table:first-child {
  display: none;
}
.price-table__tbody__box__right tr td,
.price-table__tbody__box__right tr th {
  text-align: center;
  vertical-align: middle;
}
.price-table__tbody__box__right td.price-table,
.price-table__tbody__box__right th.price-table {
  height: 32px;
  border: 1px solid #cfdae1;
}
.price-table__tbody__box__right td.price-table.left,
.price-table__tbody__box__right th.price-table.left {
  text-align: left;
}
.price-table__tbody__box__right td.price-table > *,
.price-table__tbody__box__right th.price-table > * {
  padding: 16px 8px;
}
.price-table__tbody__box__right td.price-table input,
.price-table__tbody__box__right th.price-table input {
  width: 80px;
}
.price-table__tbody__box__right td.price-table img.price-table {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
@media screen and (max-width: 768px) {
  .price-table__thead {
    display: none;
  }
  .price-table__thead__right {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .price-table__thead__left {
    display: none;
  }
  .price-table__tbody__box {
    flex-direction: column;
    position: relative;
    border-bottom: none;
    margin-top: 16px;
  }
  .price-table__tbody__box__left {
    width: 100%;
  }
  .price-table__tbody__box__left > div:first-child {
    display: none;
  }
  .price-table__tbody__box__left > div:nth-child(2) {
    width: 100%;
  }
  .price-table__tbody__box__left > div {
    border-bottom: none;
  }
  .price-table__tbody__box__right {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .price-table__tbody__box__right tr.price-table:first-child {
    display: table-row;
  }
  .price-table__tbody__box__right th.price-table {
    padding: 0 4px;
  }
}
