.container {
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: #FAFAFA;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.condition-group-divider {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: '';
    background: #818078;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: #818078;

    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}
