.p-page {
  margin: -16px;
}

@media screen and (max-width: 768px) {
  .p-page {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
