.c-tooltip {
  padding: 12px;
  background-color: var(--white);
  border-color: var(--gray300);
  border-width: 1px;
  border-style: solid;
}
.c-tooltip__header {
  font-size: var(--text-sm-size);
  margin-bottom: 4%;
}
.c-tooltip__header__ttl {
}
.c-tooltip__body {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.c-tooltip__body__item {
}
