.fab {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.fab:hover {
  transform: scale(1.1);
}

/* Size Variants */
.small {
  width: 40px;
  height: 40px;
  font-size: 14px;
}

.medium {
  width: 56px;
  height: 56px;
  font-size: 16px;
}

.large {
  width: 72px;
  height: 72px;
  font-size: 18px;
}

/* Color Variants */
.primary {
  background-color: #007bff;
  color: white;
}

.primary:hover {
  background-color: #0056b3;
}

.secondary {
  background-color: #6c757d;
  color: white;
}

.secondary:hover {
  background-color: #5a6268;
}

.default {
  background-color: #e0e0e0;
  color: black;
}

.default:hover {
  background-color: #bdbdbd;
}
