.tags-container {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}
.tag-list {
  width: 100%;
  font-size: 14px;
  color: #008ec9;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}
.tag-list li::before {
  content: '#';
  margin-right: 4px;
}
.autotag-list {
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}


.staff-memo {
  width: 100%;
}

.c-review__list__comment__item__top__review__stars {
  display: flex;
}
.c-review__list__comment__item__top__review__stars img {
  width: 16px;
  height: 16px;
}
