.err {
  font-size: 16px;
  color: #dc3e15;
}

.c-table-nowrap {
}
.c-table-nowrap table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-nowrap table th {
  font-weight: normal;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.c-table-nowrap table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
  word-break: break-all;
}
.c-table-nowrap__bold {
  font-weight: bold;
}
.c-table-nowrap__small {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .c-table-nowrap {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

/*------------------------------------------------------------------------------
table-main
------------------------------------------------------------------------------*/
.c-table-main {
  position: relative;
  scroll-margin-top: 96px;
  width: 100%;
  background: #ebf1f3;
}
.c-table-main__thead {
  background: #fff;
  border-bottom: 2px solid #cfdae1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: sticky;
  top: -1px;
  z-index: 2;
}
.c-table-main__thead .desc,
.c-table-main__thead .asc {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
}
.c-table-main__thead th:hover {
  background: #f9f9f9;
}
.c-table-main__thead .desc {
  border-width: 6px 4px 0 4px;
  border-color: #008ec9 transparent transparent transparent;
  margin-top: -2px;
}
.c-table-main__thead .asc {
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #008ec9 transparent;
  margin-top: -4px;
}
.c-table-main__tbody {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.c-table-main__tbody__header {
}
.c-table-main table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table-main tr:nth-child(odd) {
  background: #fff;
}
.c-table-main tr:nth-child(even) {
  background: #f9f9f9;
}
.c-table-main th,
.c-table-main td {
  padding: 8px;
  border: 1px solid #cfdae1;
  word-break: break-all;
}
.c-table-main th {
  height: 32px;
}
.c-table-main th:first-child {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
}
.c-table-main.mobile-header th:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
  pointer-events: none;
}
.c-table-main td {
}
.c-table-main td button,
.c-table-main th button {
  margin: auto;
}
.c-table-main td:first-child {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
}
.c-table-main.mobile-header td:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
  pointer-events: none;
}
.c-table-main td a,
.c-table-main th a {
  font-weight: bold;
  color: #008ec9;
}
.c-table-main td a:hover {
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .c-table-main {
    padding: 0 4px;
  }
  .c-table-main__thead {
    display: none;
  }
  .c-table-main__tbody__header {
    display: none !important;
  }
  .c-table-main__tbody table {
  }
  .c-table-main__tbody tr {
    background: #fff !important;
    /* border-bottom: 1px solid #CFDAE1; */
    padding: 8px;
    display: block;
  }
  .c-table-main tr + * {
    margin-top: 16px;
  }
  .c-table-main tr > * + * {
    margin-top: 8px;
  }
  .c-table-main th,
  .c-table-main td {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .c-table-main th[class^='base-t-'],
  .c-table-main td[class^='base-t-'] {
    width: 100% !important;
  }
  .c-table-main th {
    height: 48px !important;
  }
  .c-table-main th::before {
    display: none;
  }
  .c-table-main th a {
    width: 100% !important;
    height: 48px !important;
    border-radius: 4px !important;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 8px;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .c-table-main th a img {
    width: 32px !important;
    height: 32px !important;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .c-table-main th a::after {
    content: attr(data-text) '';
  }
  .c-table-main th a::before {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 2px solid #9bacb5;
    border-right: 2px solid #9bacb5;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 12px;
  }
  .c-table-main td::before {
    content: attr(data-text) '';
    width: 96px;
    min-height: 24px;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
  }
  .c-table-main__tbody__spMore {
    width: calc(100% + 16px);
    height: 32px;
    font-weight: bold;
    color: #008ec9;
    border-top: 1px solid #cfdae1;
    margin-left: -8px;
    margin-bottom: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-table-main__tbody__spMore::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #008ec9 transparent transparent transparent;
    display: block;
    margin-left: 8px;
  }
  .c-table-main__tbody__spMore.arrowUp::after {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #008ec9 transparent;
  }

  .c-table-main td button,
  .c-table-main th button {
    width: 100%;
  }
}
