/* Base properties */
.c-button {
  width: fit-content;
  font-weight: var(--text-normal);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.c-button:hover {
  transition: all 200ms linear;
}
.c-button > *:not(:first-child) {
  margin-left: 8px;
}
.c-button > p {
  margin-bottom: 0;
}


.full-width {
  width: 100%;
}

/* UI type */
.bg {
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-xs);
}
.link {
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-xs);
}
.link > *:not(i) {
  text-decoration: underline;
}
.link[disabled] > * {
  text-decoration: none;
}
.close {
  border-radius: var(--radius-md);
}
.close[class*="sm"] > i::before {
  font-size: 20px;
}
.close[class*="md"] > i::before {
  font-size: 20px;
}
.close[class*="lg"] > i::before {
  font-size: 24px;
}

/* Size */
.icon {
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 0 10px;
}
.icon i::before {
  font-size: 20px;
}
.xs {
  height: 28px;
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  padding: 0 8px;
}
.xs i::before {
  font-size: 16px;
}
.sm {
  height: 36px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 0 8px;
}
.sm i::before {
  font-size: 20px;
}
.md {
  height: 40px;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 0 10px;
}
.md i::before {
  font-size: 20px;
}
.lg {
  height: 44px;
  min-width: 140px;
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  padding: 0 12px;
}
.lg i::before {
  font-size: 20px;
}

/* Color */
/* primary */
.primary {
  color: var(--white);
  background-color: var(--primary600);
}
.primary:hover {
  background-color: var(--primary700);
}
.primary:active {
  background-color: var(--primary600);
  box-shadow: var(--ring-primary-shadow-xs);
}
.primary:disabled {
  color: var(--white);
  background-color: var(--gray200);
  pointer-events: none;
}

/* secondary */
.secondary {
  color: var(--primary800);
  background-color: var(--primary50);
}
.secondary:hover {
  background-color: var(--primary100);
}
.secondary:active {
  background-color: var(--primary50);
  box-shadow: var(--ring-primary-shadow-xs);
}
.secondary:disabled {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* secondarycolor */
.secondarycolor {
  color: var(--primary700);
  background-color: var(--gray100);
}
.secondarycolor:hover {
  background-color: var(--gray200);
}
.secondarycolor:active {
  background-color: var(--gray100);
  box-shadow: var(--ring-gray-shadow-xs);
}
.secondarycolor:disabled {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* tertiarycolor */
.tertiarycolor {
  color: var(--primary800);
  background-color: transparent;
  box-shadow: var(--shadow-none);
}
.tertiarycolor:hover {
  background-color: var(--primary100);
}
.tertiarycolor:active {
  color: var(--primary800);
  background-color: transparent;
}
.tertiarycolor:disabled {
  color: var(--gray300);
  pointer-events: none;
}

/* red */
.red {
  color: var(--white);
  background-color: var(--error600);
}
.red:hover {
  background-color: var(--error700);
}
.red:active {
  background-color: var(--error600);
  box-shadow: var(--ring-error-shadow-xs);
}
.red:disabled {
  color: var(--white);
  background-color: var(--gray200);
  pointer-events: none;
}

/* tertiaryred */
.tertiaryred {
  color: var(--error500);
  background-color: var(--error50);
}
.tertiaryred:hover {
  background-color: var(--error100);
}
.tertiaryred:active {
  background-color: var(--error50);
  box-shadow: var(--ring-error-shadow-xs);
}
.tertiaryred:disabled {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* white */
.white {
  color: var(--gray700);
  background-color: var(--white);
  border: 1px solid var(--gray200);
}
.white:hover {
  background-color: var(--gray50);
}
.white:active {
  background-color: var(--white);
  box-shadow: var(--ring-gray-shadow-xs);
}
.white:disabled {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* gray */
.gray {
  color: var(--gray800);
  background-color: var(--gray100);
}
.gray:hover {
  background-color: var(--gray200);
}
.gray:active {
  background-color: var(--gray100);
  box-shadow: var(--ring-gray-shadow-xs);
}
.gray:disabled {
  color: var(--gray300);
  background-color: var(--gray50);
  pointer-events: none;
}

/* tertiarygray */
.tertiarygray {
  color: var(--gray700);
  background-color: transparent;
  box-shadow: var(--shadow-none);
}
.tertiarygray:hover {
  background-color: var(--gray100);
}
.tertiarygray:active {
  color: var(--gray700);
  background-color: transparent;
}
.tertiarygray:disabled {
  color: var(--gray300);
  pointer-events: none;
}

/* Loading */
.loading {
  position: relative;
  cursor: default;
  /* text-shadow: none!important; */
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}
.loading:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}
.loading:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}
@keyframes button-spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}