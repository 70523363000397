.map-container {
  width: 80%;
  height: 600px;
  min-width: 400px;
}
@media screen and (max-width: 768px) {
  .map-container {
    width: 100%;
    height: 400px;
    margin-bottom: 16px;
  }
}
