
.btn {
  width: 100%;
  max-width: 240px;
  height: 48px;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  padding: 0 24px;
}
.btn.width-128 {
  width: 128px;
}
.btn--cancel {
  color: #888888;
  background: #eeeeee;
}
.btn--submit {
  color: #fff;
  background: #006abb;
}
