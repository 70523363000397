.c-tab-box {
}
.c-tab-box__tab {
  width: 100%;
  margin-bottom: -1px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.c-tab-box__tab li {
  width: 160px;
  min-height: 40px;
  font-size: 13px;
  font-weight: bold;
  color: #9bacb5;
  border-radius: 8px 8px 0 0;
  border: 1px solid #cfdae1;
  background: #cfdae1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-tab-box__tab li.is-active {
  color: #008ec9;
  background: #fff;
  border-bottom: 1px solid #fff;
}
.c-tab-box__tab li + li {
  margin-left: 8px;
}
.c-tab-box__tab li a {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0px 4px;
}
.c-tab-box__box {
  display: none;
}
.c-tab-box__box.is-active {
  display: block;
}
.page-productsEdit__select {
  margin-bottom: 8px;
  width: 100%;
}
.page-productsEdit__select__ttl {
  margin-bottom: 4px;
}
.page-productsEdit__select__box {
  display: flex;
}
.page-productsEdit__select__box a {
  height: 40px !important;
  margin-left: 16px;
  flex-shrink: 0;
}
.page-productsRegist__date {
}
.page-productsRegist__date:not(:last-child) {
  /* border-bottom: 1px solid #cfdae1; */
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.page-productsRegist__date__ttl {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 4px !important;
}
.page-productsRegist__date__range {
  width: 100%;
  max-width: 320px;
}
.page-productsRegist__per__group__unit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}
.page-productsRegist__per__group__unit__select {
  max-width: 200;
}
.page-productsRegist__priceMain__ttl {
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 8px !important;
}
.page-productsRegist__priceMain__ttl.borderTop {
  border-top: 1px solid #cfdae1;
  padding-top: 8px;
  margin-top: 8px;
}
.back-arrow-circle {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #008ec9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-arrow-circle img {
  width: 24px;
  height: 24px;
}
.package-start-time-section + .package-start-time-section {
  border-top: 1px solid #cfdae1;
  padding-top: 8px;
}
.package-start-time-label {
  padding-top: 8px;
  padding-bottom: 8px;
}
.package-start-time-component-section + .package-start-time-component-section {
  padding-top: 16px;
}
@media screen and (max-width: 768px) {
  .c-tab-box__tab {
    padding: 0 8px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-tab-box__tab li {
    width: 112px;
    font-size: 12px;
    flex-shrink: 0;
  }
  .c-tab-box__box {
    margin-top: 0;
  }
  .header-product-title {
    margin-left: 8px;
    margin-right: 8px;
  }
}
.acBody {
}
.acBody__btns {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.acBody__btns > * + * {
  margin-left: 8px;
}
.c-tableChild {
  width: 100%;
  display: table;
}
.c-tableChild > ul {
  display: table-row;
}
.c-tableChild > ul > li {
  padding-right: 16px;
  padding-bottom: 8px;
  /* vertical-align: middle; */
  display: table-cell;
}
@media screen and (max-width: 768px) {
  .c-tableChild {
    display: block;
  }
  .c-tableChild > ul {
    border-bottom: 1px solid #cfdae1;
    margin-bottom: 8px;
    display: block;
  }
  .c-tableChild > ul:first-child {
    display: none;
  }
  .c-tableChild > ul > li {
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-tableChild > ul > li::before {
    content: attr(data-title) '';
    min-width: 120px;
    flex-shrink: 0;
  }
}

.addon__item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.addon__item__selector {
  width: calc((100% - 80px) / 2);
  margin-right: 8px;
}
