.select {
  margin-bottom: 8px;
  width: 100%;
}
.select__ttl {
  margin-bottom: 4px;
}
.select__box {
  display: flex;
  align-items: flex-end;
}
.select__box__note {
  margin-left: 8px;
  font-size: 12px;
}
.select__box a {
  height: 40px !important;
  flex-shrink: 0;
}
.priceMain__ttl {
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 8px;
}
