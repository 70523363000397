.c-inputcontent-sm-toggle > span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-left: 44px;
  display: block;
}
.c-inputcontent-md-toggle > span {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 52px;
  display: block;
}

/* toggle */
.c-toggle {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.c-toggle:hover {
  cursor: pointer;
}
.c-toggle > i {
  background-color: var(--gray200);
  border-radius: var(--radius-4xl);
  display: block;
  flex-shrink: 0;
  position: relative;
  transition: all 200ms linear;
}
.c-toggle > i::before {
  content: '';
  background-color: var(--white);
  border-radius: var(--radius-4xl);
  box-shadow: var(--shadow-sm);
  display: block;
  position: relative;
  top: 2px;
  left: 2px;
  transition: all 200ms linear;
}
.c-toggle > input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.c-toggle > p {
  margin-left: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* toggle checked */
.c-toggle > input:checked + i {
  background-color: var(--primary600);
}
/* toggle hover */
.c-toggle:hover > i {
  background-color: var(--gray300);
}
/* toggle active */
.c-toggle:active > i {
  box-shadow: var(--ring-primary-shadow-xs);
}
/* toggle disabled */
.c-toggle input:disabled + i {
  background-color: var(--gray200);
}
.c-toggle:active input:disabled + i {
  background-color: var(--gray200);
  box-shadow: var(--ring-primary-shadow-none);
}
.c-toggle input:disabled + i::before {
  background-color: var(--gray100);
}

/* toggle sm */
.sm > i {
  width: 36px;
  height: 20px;
}
.sm > i::before {
  width: 16px;
  height: 16px;
}
.sm > input:checked + i::before {
  left: 18px;
}
.sm > p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

/* toggle md */
.md > i {
  width: 44px;
  height: 24px;
}
.md > i::before {
  width: 20px;
  height: 20px;
}
.md > input:checked + i::before {
  left: 22px;
}
.md > p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}
