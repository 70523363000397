.container {
  background: #fff;
  height: 80px;
}

.sliderContainer {
  width: 100%;
  padding: 16px 16px 0;
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.controlContainer {
  position: relative;
  width: 100%;
}
.sliderBackground {
  position: absolute;
  height: 4px;
  background-color: #ddd; /* Change this to your desired shade color */
  pointer-events: none;
  margin-top: 9px;
  z-index: 2;
  width: 100%;
}
.progress {
  position: absolute;
  height: 4px;
  background-color: #3aa0ff; /* Change this to your desired shade color */
  pointer-events: none;
  margin-top: 9px;
  z-index: 2;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: transparent;
  outline: none;
  border-radius: 2px;
  position: relative;
  z-index: 3;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #3aa0ff;
  border: 4px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #3aa0ff;
  cursor: pointer;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #3aa0ff;
  border: 4px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.sliderLabel {
  color: #393d3f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.slidingLabel {
  display: flex;
  justify-content: center;
  width: 100px;
  position: absolute;
  top: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #3aa0ff;
}
