.p-reservationsCreate__search {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservationsCreate__search__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreate__search__header__title {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
}
.p-reservationsCreate__search__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservationsCreate__search__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-reservationsCreate__search__body {
  width: 100%;
  padding: 16px 24px;
}
.p-reservationsCreate__search__body__item {
  display: flex;
}
.p-reservationsCreate__search__body__item > * {
  flex-shrink: 1;
}
.p-reservationsCreate__search__body__item:not(:first-child) > * {
  margin-top: 16px;
}
.p-reservationsCreate__search__body__item > *:not(:last-child) {
  margin-right: 16px;
}
.p-reservationsCreate__search__body__item__flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.p-reservationsCreate__search__body__item__flex > *:not(:last-child) {
  margin-right: 16px;
}
.p-reservationsCreate__search__body__item__radiotitle {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
}
.p-reservationsCreate__search__body__item__radio > label {
  padding: 8px 0;
}
@media screen and (max-width: 768px) {
  .p-reservationsCreate__search__body__item {
    display: block;
  }
  .p-reservationsCreate__search__body__item > * {
    margin-right: 0 !important;
  }
  .p-reservationsCreate__search__body__item__radiotitle {
    width: 100%;
    margin-bottom: 8px;
  }
}

.p-reservationsCreate__calendar {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservationsCreate__calendar__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreate__calendar__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-reservationsCreate__calendar__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservationsCreate__calendar__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservationsCreate__calendar__body {
  padding-top: 24px;
}
@media screen and (max-width: 768px) {
  .p-reservationsCreate__calendar__header {
    padding: 16px;
  }
  .p-reservationsCreate__calendar__body {
    padding: 16px;
  }
}

.p-calendar {
  width: 100%;
}
.p-calendar__header {
  width: 100%;
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-calendar__header__date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
}
.p-calendar__header__date__select {
  width: 190px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
}
.p-calendar__header__date__select a {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-calendar__header__date__select a:first-child {
  border-right: 1px solid var(--gray200);
}
.p-calendar__header__date__select a:last-child {
  border-left: 1px solid var(--gray200);
}
.p-calendar__header__date__select a i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: var(--gray700);
}
.p-calendar__header__date__select p {
  width: 100%;
  text-align: center;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-calendar__header__date__today {
  color: var(--primary700);
  text-decoration: underline;
}
.p-calendar__header__timezone {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray700);
}
.p-calendar__body {
  width: 100%;
  position: relative;
}
.p-calendar__body__header {
  width: 100%;
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  border-top: 1px solid var(--gray200);
  background-color: var(--gray50);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-calendar__body__header p {
  width: 100%;
  height: 24px;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.p-calendar__body__header p.red {
  color: var(--error500);
}
.p-calendar__body__header p.blue {
  color: var(--primary600);
}
.p-calendar__body__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.p-calendar__body__body__item {
  width: calc(100% / 7);
  border-top: 1px solid var(--gray200);
}
.p-calendar__body__body__item__loading {
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .p-calendar {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  .p-calendar__header {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid var(--gray200);
    padding: 0 16px 16px 16px;
  }
  .p-calendar__header__timezone {
    margin-top: 16px;
  }
  .p-calendar__body {
    padding: 16px;
  }
  .p-calendar__body__body {
    flex-direction: column;
  }
  .p-calendar__body__body__item {
    width: 100%;
    border-top: none;
  }
  .p-calendar__body__body__item:first-child {
    border-top: none;
  }
}

.p-calendarCell {
  width: 100%;
  height: 128px;
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  padding: 8px 4px;
  position: relative;
}
.p-calendarCell.disabled {
  opacity: 0.2;
  pointer-events: none;
}
.p-calendarCell__top {
  text-align: center;
  color: var(--gray400);
}
.p-calendarCell__top__week {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  margin-top: 12px;
  margin-bottom: 4px;
}
.p-calendarCell__top__week.red {
  color: var(--error500);
}
.p-calendarCell__top__week.blue {
  color: var(--primary600);
}
.p-calendarCell__top__day {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 auto 4px auto;
  display: flex;
  justify-content: center;
  align-items: cen ter;
}
.p-calendarCell__top__day.today {
  color: var(--white);
  background-color: var(--primary600);
}
.p-calendarCell__bottom {
  margin-top: 4px;
}
.p-calendarCell__bottom__cell {
  width: 100%;
  height: 20px;
  color: var(--gray600);
  background-color: var(--gray50);
  border-radius: 4px;
  padding: 0 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.p-calendarCell__bottom__cell:not(:first-child) {
  margin-top: 4px;
}
.p-calendarCell__bottom__cell.red1 {
  background-color: var(--error100);
}
.p-calendarCell__bottom__cell.red2 {
  background-color: var(--error200);
}
.p-calendarCell__bottom__cell.red3 {
  background-color: var(--error300);
}
.p-calendarCell__bottom__cell.red4 {
  background-color: var(--error400);
}
.p-calendarCell__bottom__cell.red5 {
  background-color: var(--error600);
}
.p-calendarCell__bottom__cell.gray {
  color: var(--gray400);
  background-color: var(--gray200);
}
.p-calendarCell__bottom__cell.selected {
  color: var(--white) !important;
  background-color: var(--primary600) !important;
}
.p-calendarCell__bottom__cell div span {
  font-weight: var(--text-semibold);
  margin-left: 4px;
}
.p-calendarCell__bottom__cell img {
  width: 16px;
  height: 16px;
}
.p-calendarCell__bottom__cell__event {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.p-calendarCell__bottom__cell__event__time {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.p-calendarCell__bottom__cell__menu {
  width: 240px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-calendarCell__bottom__cell__menu.is-active {
  display: block;
}
.p-calendarCell__bottom__cell__menu__header {
  width: 100%;
  border-bottom: 1px solid var(--gray200);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
}
.p-calendarCell__bottom__cell__menu__header p {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  margin-right: auto;
}
.p-calendarCell__bottom__cell__menu__header a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-calendarCell__bottom__cell__menu__header a i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: var(--gray600);
}
.p-calendarCell__bottom__cell__menu__body {
  /* max-height: 160px; */
  overflow-y: auto;
  padding: 16px;
}
.p-calendarCell__bottom__cell__morePC {
  color: var(--primary700);
  font-weight: var(--text-semibold);
  text-decoration: underline;
  margin-top: 4px;
}
.p-calendarCell__bottom__cell__moreSp {
  padding: 8px 8px 0 8px;
}
.p-calendarCell__bottom__cell__moreSp a {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-calendarCell {
    height: auto;
    padding: 8px 0;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--gray200);
  }
  .p-calendarCell.disabled {
    display: none;
  }
  .p-calendarCell__top {
    width: 80px;
    flex-shrink: 0;
    color: var(--gray600);
  }
  .p-calendarCell__top__day {
    width: 28px;
    height: 28px;
    font-size: var(--text-xl-size);
    font-weight: var(--text-medium);
    line-height: var(--text-xl-height);
  }
  .p-calendarCell__top__day.red {
    color: var(--error500);
  }
  .p-calendarCell__top__day.blue {
    color: var(--primary600);
  }
  .p-calendarCell__bottom {
    width: 100%;
  }
  .p-calendarCell__bottom__cell {
    height: 30px;
  }
  .p-calendarCell__bottom__cell__event {
    padding-top: 7px;
  }
  .p-calendarCell__bottom__cell__menu {
    width: 100%;
    position: relative;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .p-calendarCell__bottom__cell__menu__body {
    max-height: max-content;
    overflow-y: visible;
    padding: 0;
  }
}

/* Styling for free format reservation */
.p-reservationsCreate__freeFormatReservation {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservationsCreate__freeFormatReservation__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreate__freeFormatReservation__header__title {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
}
.p-reservationsCreate__freeFormatReservation__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservationsCreate__freeFormatReservation__header__actions
  > *:not(:last-child) {
  margin-right: 12px;
}
.p-reservationsCreate__freeFormatReservation__body {
  width: 100%;
  padding: 16px 24px;
}
.p-reservationsCreate__freeFormatReservation__body__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreate__freeFormatReservation__body__item > * {
  flex-shrink: 1;
}
.p-reservationsCreate__freeFormatReservation__body__item:not(:first-child) > * {
  margin-top: 16px;
}
.p-reservationsCreate__freeFormatReservation__body__item > *:not(:last-child) {
  margin-right: 16px;
}
.p-reservationsCreate__freeFormatReservation__body__item__ttl {
  width: 240px;
  flex-shrink: 0;
  margin-right: 32px;
}
.p-reservationsCreate__freeFormatReservation__body__item__ttl__txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreate__freeFormatReservation__body__item__body {
}
.p-reservationsCreate__freeFormatReservation__body__item__flex {
  display: flex;
  flex-wrap: wrap;
}
.p-reservationsCreate__freeFormatReservation__body__item__flex
  > *:not(:last-child) {
  margin-right: 16px;
}

.p-reservationsCreate__info {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 24px;
}
.p-reservationsCreate__info__item {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-reservationsCreate__info__item:not(:first-child) {
  border-top: 1px solid var(--gray200);
  margin-top: 20px;
  padding-top: 20px;
}
.p-reservationsCreate__info__item__ttl {
  color: var(--gray600);
  /* Override semantic */
  margin-bottom: 4px !important;
}
.p-reservationsCreate__info__item__body__note > div {
  color: var(--gray500);
}
.p-reservationsCreate__info__item__body__note textarea {
  background-color: var(--gray50);
}
.p-reservationsCreate__info__item__body {
}
.p-reservationsCreate__info__item__body .red {
  color: var(--error500);
}
.total {
  font-weight: var(--text-semibold);
}
.p-reservationsCreate__info__item__body textarea {
  min-height: 114px;
}
.p-reservationsCreate__info__item__body__flex {
  display: flex;
  justify-content: flex-start;
}
.p-reservationsCreate__info__item__body__flex > *:not(:last-child) {
  margin-right: 8px;
}
.p-reservationsCreate__info__actions {
  margin-top: 20px;
}
.p-reservationsCreate__info__actions a {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-reservationsCreate__info {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 100%;
    left: 0;
    transition: top 0.4s ease;
  }
  .p-reservationsCreate__info.is-active {
    top: 0;
  }
  .p-reservationsCreate__info__header {
    width: 100%;
    height: 64px;
    background-color: var(--white);
    border-bottom: 1px solid var(--gray200);
    padding: 0 24px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
  }
  .p-reservationsCreate__info__header p {
    font-size: var(--text-lg-size);
    font-weight: var(--text-bold);
    line-height: var(--text-lg-height);
  }
  .p-reservationsCreate__info__body {
    height: 100%;
    padding: 20px 24px;
    overflow-y: auto;
  }
  .p-reservationsCreate__info__actions {
    width: 100%;
    height: 80px;
    border-top: 1px solid var(--gray200);
    padding: 0 24px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
}

/* modals */
.p-reservationsCreateStock {
}
.p-reservationsCreateStock__check {
  width: 100%;
  background-color: var(--gray100);
  border-radius: 8px;
  padding: 16px;
}
.p-reservationsCreateStock__check ul {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -16px;
  list-style: none;
}
.p-reservationsCreateStock__check li {
  margin-right: 16px;
  margin-top: 16px;
  list-style: none;
}
.p-reservationsCreateStock__table {
  margin-top: 16px;
}
.p-reservationsCreateStock__memo {
  margin-top: 16px;
}
.p-reservationsCreateStock__memo__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  margin-bottom: 4px;
}
.p-reservationsCreateStock__memo__body {
}
.p-reservationsCreateStock__note {
  width: 100%;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  background-color: var(--gray100);
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}
.p-reservationsCreateStock__note__item:not(:first-child) {
  margin-top: 8px;
}
.p-reservationsCreateStock__note__item span {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}

.p-reservationsCreateStock__note__item ol,
.p-reservationsCreateStock__note__item ul {
  padding-left: 16px;
}
.p-reservationsCreateStock__note__item ol li {
  list-style: decimal;
}
.p-reservationsCreateStock__note__item ul li {
  list-style: disc;
}

.p-reservationsCreateList {
}
.p-reservationsCreateList__total {
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreateList__total i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: var(--gray700);
  margin-right: 8px;
}
.p-reservationsCreateList__total p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-reservationsCreateList__table {
}

/* Error box */
.p-errorBox {
  margin-bottom: 24px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--error700);
  border: 1px solid var(--error200);
  background-color: var(--error50);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-xs);
  padding: 16px;
}
.p-errorBox__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
}
.p-errorBox__body {
  margin: 8px 16px;
  padding: 0;
}
.p-errorBox__body li {
  list-style-type: initial;
  margin: 2px 0;
  font-size: 1.143em;
}

.c-tableFrame {
  position: relative;
}
.c-table {
  /* overflow: auto; */
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
.c-table.thead {
  overflow-x: hidden;
}
.c-table.tbody {
  overflow-x: auto;
}
.c-table__body {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table__body thead th {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  text-align: left;
  color: var(--gray600);
  background-color: var(--gray50);
  border-top: 1px solid var(--gray200);
  border-bottom: 1px solid var(--gray200);
  padding: 12px 16px;
}
.c-table__body thead th > i {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
  color: var(--gray500);
}
.c-table__body thead th.is-active {
  color: var(--primary700);
}
.c-table__body thead th.is-active > i {
  color: var(--primary700);
}
.c-table__body tbody tr:not(:first-child) td {
  border-top: 1px solid var(--gray200);
}
.c-table__body tbody tr:hover td {
  background-color: var(--primary50);
}
.c-table__body tbody td {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  background-color: var(--white);
  padding: 16px;
}
.c-table__body tbody td a {
  color: var(--primary600);
  text-decoration: underline;
}

.sticky-top {
  position: sticky;
  top: -21px;
  z-index: 1;
}
.sticky-left {
  position: sticky;
  left: 0;
  z-index: 2;
}
.sticky-left::after {
  content: '';
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.05);
}
table thead .sticky-left {
  z-index: 4;
}

.c-table__body thead th i + p {
  font-size: var(--text-xs-size) !important;
  font-weight: var(--text-medium) !important;
  line-height: var(--text-xs-height) !important;
}
.c-table__body tbody td i + p {
  font-size: var(--text-sm-size) !important;
  font-weight: var(--text-normal) !important;
  line-height: var(--text-sm-height) !important;
}
.c-table__more p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.c-table__more a {
  color: var(--primary700) !important;
  margin-top: 8px;
  display: inline-block;
}

.c-tableFrame.arrow table th:first-child,
.c-tableFrame.arrow table td:first-child {
  padding-left: calc(12px + 28px);
}
.c-tableBtn {
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-tableBtn.left {
  left: 0;
}
.c-tableBtn.right {
  transform: scale(-1, 1);
  right: 0;
}
.c-tableBtn a {
  width: 28px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 64px;
  margin-top: 48px;
  /* top: calc(50% - 40px); */
  cursor: pointer;
}
.c-tableBtn a i {
  font-size: 24px;
  color: var(--white);
  display: block;
}

.c-table__sp {
  display: none;
}

@media screen and (max-width: 768px) {
  .c-table.thead {
    display: none;
  }
  .c-table__body tbody tr:hover td {
    background-color: transparent;
  }
  .c-table tr {
    width: 100%;
    border-radius: var(--radius-xl);
    border: 1px solid var(--gray200);
    background-color: var(--white);
    display: block;
    overflow: hidden;
  }
  .c-table tr:not(:first-child) {
    margin-top: 8px;
  }
  .c-table td {
    width: 100% !important;
    border: none !important;
    display: flex;
    justify-content: flex-end;
  }
  .c-table td::before {
    content: attr(data-text) '';
    font-size: var(--text-xs-size);
    font-weight: var(--text-medium);
    line-height: var(--text-xs-height);
    padding-right: 12px;
    margin-right: auto;
    display: block;
    flex-shrink: 0;
  }
  .c-table td.c-table__spHeader {
    border-bottom: 1px solid var(--gray200) !important;
    justify-content: flex-start !important;
  }
  .c-table td.c-table__spHeader::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-table td.c-table__spHeader::after {
    width: 20px;
    height: 20px;
    box-shadow: none;
    font-family: 'icon-arrows-solid' !important;
    font-style: normal;
    font-size: 20px;
    color: var(--primary600);
    content: '\b454';
    position: absolute;
    top: calc(50% - 10px);
    right: 8px;
  }
  .c-table td.c-table__spHeader label {
    margin-right: 12px;
  }
  .c-table td.c-table__spHeader label p {
    font-size: var(--text-base-size) !important;
    font-weight: var(--text-semibold) !important;
    line-height: var(--text-base-height) !important;
  }

  .c-table__more a {
    float: right;
  }
  .c-table td.c-table__detail {
    border-top: 1px solid var(--gray200) !important;
    justify-content: center;
    padding: 8px 16px;
  }
  .c-table td.c-table__detail a {
    /* font-size: var(--text-sm-size);
    font-weight: var(--text-semibold);
    line-height: var(--text-sm-height); */
    text-decoration: none;
  }
  .c-table td.c-table__detail::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-tableBtn {
    display: none;
  }
  .c-table__sp {
    display: block;
  }
  .c-tableFrame.arrow table th:first-child,
  .c-tableFrame.arrow table td:first-child {
    padding-left: 16px;
  }
}
.slots {}
.slots > li {
  display: flex;
  flex-direction: row;
}
.slots > li:not(:first-child) {
  margin-top: 8px;
}
.slots > li > p:last-child {
  margin-left: auto;
}
.nonConfirmedReservations {
}
.nonConfirmedReservations > p {
  margin: 0;
}
.nonConfirmedReservations > p:not(:first-child) {
  margin-top: 8px;
}


/* These items are taken from pages.module.css */
.page-availability__modal__ttl {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #008ec9;
  background: #ebf1f3;
  padding: 8px;
}
.page-availability__modal__num__header__label {
  margin-left: auto;
}
.page-availability__modal__num__header__label span {
  width: 100%;
  padding: 0 4px;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-md);
}
.page-availability__modal__num__header__label .red {
  background: #dc3e15;
}
.page-availability__modal__num__header__uncounted__units {
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
}
