.title {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  color: #333;
  /* padding-top: 16px; */
  /* padding-bottom: 16px; */
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .title {
    width: 100%;
    font-size: 18px;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
