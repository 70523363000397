/**
 * This code was generated by Builder.io.
 */
.promotionsPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fdfdff;
}

.mainContent {
  display: flex;
  flex: 1;
}

.promotionsSection {
  flex: 1;
  padding: 24px;
}

.pageTitle {
  font-size: 28px;
  font-weight: 600;
  color: #393d3f;
  margin-bottom: 8px;
}

.pageDescription {
  font-size: 16px;
  color: #393d3f;
  margin-bottom: 24px;
}

.actionBar {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.searchInput {
  flex: 1;
  padding: 8px 16px;
  border: 1px solid #f3f6f8;
  border-radius: 8px;
  font-size: 16px;
}

.createButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  background-color: #3e8cc4;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.plusIcon {
  width: 12px;
  height: 12px;
}

@media (max-width: 991px) {
  .promotionsSection {
    padding: 16px;
  }
}
