/*------------------------------------------------------------------------------
table-nowrap
------------------------------------------------------------------------------*/
.c-table-nowrap {
}
.c-table-nowrap table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-nowrap table th {
  font-weight: normal;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.c-table-nowrap table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.c-table-nowrap__bold {
  font-weight: bold;
}
.c-table-nowrap__small {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .c-table-nowrap {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-nowrap__cell {
    width: 400px;
  }
  .c-table-nowrap table tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .c-table-nowrap table tr > *:first-child::before {
    content: '';
    width: 100%;
    height: 100%;
    border-left: 1px solid #ddd;
    border-right: 3px solid #ddd;
    position: absolute;
    left: -1px;
    top: 0;
  }
  .c-table-nowrap table tr > *:not(:first-child) {
    position: relative;
    z-index: 0;
  }
}

.condition-display-box {
  background: #fff;
  padding: 16px;
  width: 100%;
}

.space {
  padding: 16px;
}

.main-content {
  background: #fff;
  padding: 16px;
  width: 100%;
}

.form-pane {
  display: flex;
}

.form-pane > * {
  margin: 0 4px;
}

.form-pane-type-select {
  width: 200px;
  min-width: 200px;
}

.graph-pane-wrapper {
  margin: 50px auto 20px auto;
  width: 100%;
}

.graph-pane-wrapper > * {
  margin: 0 auto;
}

.summary-pane-wrapper > * {
  margin: 0 auto;
}

.summary-pane {
  background: #dcdcdc;
  font-size: 200%;
  width: 50%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontal-radio-button {
  display: flex;
}

.horizontal-radio-button > * {
  width: 200px;
  margin: 0 4px;
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.currency-code-pane {
  margin: 10px 0;
  font-size: 110%;
}

@media screen and (max-width: 768px) {
  .form-pane {
    display: block;
  }

  .horizontal-radio-button {
    display: block;
  }

  .graph-pane-wrapper {
    height: 500px;
  }
}

.setting-form__box {
  display: flex;
}

.setting-form__box > div {
  width: 300px;
  height: 70px;
  margin: 0px 8px;
}
