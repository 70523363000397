.c-gadget {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
}
.c-gadget__header {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-shrink: 0;
  border-bottom: 1px solid var(--gray200);
}
.c-gadget__header > div:last-child {
  margin-left: 8px;
}
.c-gadget__header__title {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.c-gadget__body {
  width: 100%;
  padding: 16px;
}
.noData {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-gadget__body_chart {
}
@media screen and (max-width: 768px) {
  .c-gadget__body_chart {
    min-height: 250px;
  }
}
