/* Base properties */
.c-badge {
  width: fit-content;
  border-radius: var(--radius-full);
  border: 1px solid;
  flex-shrink: 0;
}

/* override semantic UI */
p.c-badge {
  margin: 0 !important;
}

/* Size */
.sm {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  min-height: 20px;
  padding: 1px 8px;
}
.md {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  min-height: 24px;
  padding: 2px 10px;
}
.lg {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  min-height: 28px;
  padding: 3px 12px;
}

/* point */
.point {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.point::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: var(--radius-full);
  display: block;
}
.sm .point::before {
  margin-right: 4px;
}
.md .point::before {
  margin-right: 6px;
}
.lg .point::before {
  margin-right: 8px;
}

/* gray */
.gray {
  color: var(--gray700);
  background-color: var(--gray100);
  border-color: var(--gray200);
}
.gray::before {
  background-color: var(--gray700);
}

/* green */
.gray {
  color: var(--gray700);
  background-color: var(--gray100);
  border-color: var(--gray200);
}
.gray::before {
  background-color: var(--gray700);
}

/* gray-contained */
.gray-contained {
  color: var(--white);
  background-color: var(--gray500);
  border-color: var(--gray500);
}
.gray-contained::before {
  background-color: var(--gray600);
}

/* error */
.error {
  color: var(--error700);
  background-color: var(--error50);
  border-color: var(--error200);
}
.error::before {
  background-color: var(--error700);
}

/* error-contained */
.error-contained {
  font-weight: var(--text-semibold);
  color: var(--white);
  background-color: var(--error700);
  border-color: var(--error700);
}
.error-contained::before {
  background-color: var(--error800);
}

/* orange */
.orange {
  color: var(--orange700);
  background-color: var(--orange50);
  border-color: var(--orange200);
}
.orange::before {
  background-color: var(--orange700);
}

/* warning */
.warning {
  color: var(--warning700);
  background-color: var(--warning50);
  border-color: var(--warning200);
}
.warning::before {
  background-color: var(--warning700);
}

/* warning-contained */
.warning-contained {
  font-weight: var(--text-semibold);
  color: var(--white);
  background-color: var(--warning500);
  border-color: var(--warning500);
}
.warning-contained::before {
  background-color: var(--warning800);
}

/* success */
.success {
  color: var(--success700);
  background-color: var(--success50);
  border-color: var(--success200);
}
.success::before {
  background-color: var(--success700);
}

/* primary */
.primary {
  color: var(--primary700);
  background-color: var(--primary50);
  border-color: var(--primary200);
}
.primary::before {
  background-color: var(--primary700);
}

/* primary-contained */
.primary-contained {
  font-weight: var(--text-semibold);
  color: var(--white);
  background-color: var(--primary600);
  border-color: var(--primary600);
}
.primary-contained::before {
  background-color: var(--primary600);
}

/* blue */
.blue {
  color: var(--blue700);
  background-color: var(--blue50);
  border-color: var(--blue200);
}
.blue::before {
  background-color: var(--blue700);
}

/* green */
.green {
  color: var(--emerald700);
  background-color: var(--emerald50);
  border-color: var(--emerald200);
}
.green::before {
  background-color: var(--emerald700);
}

/* yellow */
.yellow {
  color: var(--yellow700);
  background-color: var(--yellow50);
  border-color: var(--yellow200);
}
.yellow::before {
  background-color: var(--yellow700);
}

/* indigo */
.indigo {
  color: var(--indigo700);
  background-color: var(--indigo50);
  border-color: var(--indigo200);
}
.indigo::before {
  background-color: var(--indigo700);
}

/* purple */
.purple {
  color: var(--purple700);
  background-color: var(--purple50);
  border-color: var(--purple200);
}
.purple::before {
  background-color: var(--purple700);
}

/* pink */
.pink {
  color: var(--pink700);
  background-color: var(--pink50);
  border-color: var(--pink200);
}
.pink::before {
  background-color: var(--pink700);
}

/* red */
.red {
  color: var(--rose700);
  background-color: var(--rose50);
  border-color: var(--rose200);
}
.red::before {
  background-color: var(--rose700);
}
