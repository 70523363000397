.p-availabilityTab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityTab li {
  width: fit-content;
  min-width: 62px;
  min-height: 40px;
  color: var(--gray400);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  padding: 10px 16px;
  cursor: pointer;
}
.p-availabilityTab *:not(:last-child) {
  border-right: 1px solid var(--gray200);
}
.p-availabilityTab li.is-active {
  color: var(--primary700);
}

.p-availability {
  width: 100%;
  /* height: calc(100vh - 64px - 106px - 48px); */
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.p-availability__fixedActive {
  height: calc(100vh - 64px - 106px - 48px - 80px);
}
.p-availability__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
.p-availability__header__left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availability__header__left > * {
  position: relative;
}
.p-availability__header__left > *:not(:last-child) {
  margin-right: 12px;
}
.p-availability__header__left__date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-availability__header__left__date.range {
  width: 320px;
}
.p-availability__header__left__date > button {
  width: 40px;
  flex-shrink: 0;
}
.p-availability__header__left__date > fieldset {
  width: 96px;
  height: 40px;
  font-size: var(--text-base-size);
  font-weight: var(--text-bold);
  line-height: var(--text-base-height);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
}
.p-availability__header__left__date > *:not(:first-child) {
  margin-left: 4px;
}
.p-availability__header__left__tab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availability__header__left__tab li {
  width: fit-content;
  width: 40px;
  height: 40px;
  color: var(--gray400);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.p-availability__header__left__tab div:not(:last-child) {
  border-right: 1px solid var(--gray200);
}
.p-availability__header__left__tab li.is-active {
  color: var(--primary700);
}
.p-availability__header__left__tab li i {
  font-size: 20px;
}
.p-availability__header__left__filter {
  /* TODO: Temporary style until we implement filter functionality */
  display: flex;
  align-items: center;
}
.p-availability__header__left__filter > fieldset:not(:first-child) {
  margin-left: 8px;
}
.p-availability__header__left__filter span {
  width: fit-content;
  border-radius: var(--radius-full);
  border: 1px solid;
  flex-shrink: 0;
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  height: 20px;
  padding: 1px 8px;
  color: var(--gray700);
  background-color: var(--white);
  border-color: var(--gray200);
}
.p-availability__header__left__result {
  width: 320px;
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray400);
}
.p-availability__header__left__select {
  position: relative;
}
.p-availability__header__left__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-availability__header__left__select__menu.is-active {
  display: block;
}
.p-availability__header__left__select__menu li {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-availability__header__left__select__menu li:hover {
  background-color: var(--gray50);
}

.p-availability__header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-availability__header__right > * {
  position: relative;
}
.p-availability__header__right > *:not(:last-child) {
  margin-right: 12px;
}

.p-availability__select {
  margin-left: auto;
  position: relative;
}
.p-availability__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-availability__select__menu.is-active {
  display: block;
}
.p-availability__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-availability__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-availability__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

.p-availability__body {
  /* height: calc(100% - 82px); */
}
.p-availability__body > div {
  height: 100%;
  /* overflow-y: auto; */
  /* display: flex;
  flex-direction: column; */
}
.p-availability__body .p-calendarWeekly__table.thead {
  /* height: 106px; */
  height: fit-content;
  flex-shrink: 0;
}
/* .p-availability__body .p-calendarWeekly__table.tbody {
  overflow-y: auto;
} */
@media screen and (max-width: 768px) {
  .p-availability {
    /* height: calc(100vh - 64px - 106px - 48px - 44px); */
    height: auto;
  }
  /* Moved this from AvailabilityTable.module.css */
  .p-availability__body.table {
    height: calc(100% - 140px - 94px);
  }
  .p-availability__header {
    padding: 16px;
  }
  .p-availability__header__left {
    width: 100%;
    flex-wrap: wrap;
  }
  .p-availability__header__left > * {
    margin-bottom: 12px;
  }
  .p-availability__select {
    margin-left: 0;
  }
  .p-availability__select__menu {
    right: -40px;
    /* left: 20px; */
  }
  .p-availability__body {
    height: calc(100% - 140px);
  }
  .p-availability__body > div {
    height: auto;
    overflow-y: hidden;
  }
}
@media screen and (min-width: 769px) and (max-width: 820px) {
  .p-availability__header__left {
    flex-wrap: wrap;
    row-gap: 8px;
  }
}

.p-filterPopup {
  width: 380px;
  /* max-height: 500px; */
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 24px;
  position: absolute;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-filterPopup.left {
  left: 0;
}
.p-filterPopup.right {
  right: 0;
}
.p-filterPopup.is-active {
  display: block;
}
.p-filterPopup__header {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
}
.p-filterPopup__body {
  margin-top: 20px;
}
.p-filterPopup__body__box:not(:first-child) {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid var(--gray200);
}
.p-filterPopup__body__box__ttl {
  width: 100%;
  height: 24px;
  /* Set important to override semantic */
  margin-bottom: 16px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.p-filterPopup__body__box__ttl i {
  margin-left: auto;
  color: var(--primary700);
  font-size: 20px;
}
.p-filterPopup__body__box__main {
  display: none;
}
.p-filterPopup__body__box__main.is-active {
  display: block;
}
.p-filterPopup__body__box__main > li:not(:first-child) {
  margin-top: 12px;
}
.p-filterPopup__body__box__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -16px;
}
.p-filterPopup__body__box__flex > * {
  margin-right: 16px;
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .p-filterPopup {
    width: 85vw;
  }
}

.p-availabilityModal {
}
.p-availabilityModal__item:not(:first-child) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--gray200);
}
.p-availabilityModal__item__ttl {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-normal);
  color: var(--gray700);
  margin-bottom: 6px;
}
.p-availabilityModal__item__body {
}
.p-availabilityModal__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityModal__item__body.flex > * {
  flex-shrink: 1;
}
.p-availabilityModal__item__body.flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-availabilityModal__item__body > p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-top: 6px;
}
.p-availabilityModal__item__body__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-availabilityModal__item__body__flex > *:not(:last-child) {
  margin-right: 12px;
}

.p-availabilitySetting {
}
.p-availabilitySetting__box:not(:first-child) {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid var(--gray200);
}
.p-availabilitySetting__box__ttl {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  font-weight: var(--text-normal);
  color: var(--gray700);
  margin-bottom: 6px !important;
}
.p-availabilitySetting__box__flex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -16px;
}
.p-availabilitySetting__box__flex > * {
  margin-right: 16px;
  margin-bottom: 16px;
}

.p-availability__fixed {
  width: 100%;
  min-height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
    0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: all 0.2s linear;
}
.p-availability__fixed.is-active {
  bottom: 0;
  opacity: 1;
}
.p-availability__fixed__main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
}
.p-availability__fixed__main__ttl {
  margin-left: 8px;
}
.p-availability__fixed__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
}
.p-availability__fixed__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-availability__fixed__actions__select {
  position: relative;
}
.p-availability__fixed__actions__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  bottom: 40px;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-availability__fixed__actions__select__menu.is-active {
  display: block;
}
.p-availability__fixed__actions__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-availability__fixed__actions__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-availability__fixed__actions__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.p-availability__fixed__actions .spacer {
  display: none;
}
.p-availability__bottomSpace {
  height: 120px;
}
@media screen and (max-width: 768px) {
  .p-availability__fixed {
    width: 100% !important;
    padding: 16px;
    /* height: 100px; */
  }
  .p-availability__fixed__main {
    display: none;
  }
  .p-availability__fixed__actions {
    width: 100%;
    margin-bottom: -20px;
  }
  /* .p-availability__fixed__actions > div:first-child {
    margin-right: 0;
    display: flex;
    justify-content: space-between;
  } */
  .p-availability__fixed__actions .spacer {
    width: 100%;
    display: block;
  }
  /* .p-availability__fixed__actions > div:nth-child(2) {
    margin-top: 10px;
  } */
  .p-availability__fixed__actions > *:not(.spacer) {
    margin-bottom: 20px;
  }
}
