.editButton {
  align-items: center;
  margin-top: 8px;
  margin-left: 3px;
  display: flex;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
}

.editIcon {
  width: 22px;
  height: 22px;
  margin-right: 7px;
}

.editText {
  color: #000;
  font-size: 14px;
}
