.c-draggable__box {
  width: 100%;
  max-width: 600px;
  border: 1px dashed gray;
  padding: 8px;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: move;
}
.c-draggable__flex {
  display: flex;
  align-items: center;
}
.c-draggable__flex > div:not(:first-child) {
  margin-left: 8px;
}
.c-draggable__flex > button {
  margin-left: 8px;
}
.c-draggable__icon {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 4px;
  cursor: all-scroll;
}
.c-draggable__icon i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-draggable__icon i::before,
.c-draggable__icon i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
