.g-section {
  padding: 0 32px;
}

/* search */
.p-reports {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reports__header {
  width: 100%;
  padding: 16px 24px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reports__header__title {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-bold);
}
.p-reports__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reports__header__actions > *:not(:last-child) {
  margin-right: 12px;
}

.p-reports__select {
  width: 100%;
  min-height: 44px;
  position: relative;
}
.p-reports__select__body {
  width: 100%;
  height: 100%;
  padding: 8px 40px 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.p-reports__select__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.p-reports__select__body__title {
  height: 24px;
  line-height: 24px;
}
.p-reports__select__menu {
  width: 100%;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-reports__select__menu.is-active {
  display: block;
}
.p-reports__select__menu__item {
  padding: 8px 10px;
  position: relative;
}
.p-reports__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-reports__select__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.p-reports__select__menu__item i {
  opacity: 0;
}
.p-reports__select__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.p-reports__select__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.p-reports__select__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
}

.p-reports__body {
  width: 100%;
  padding: 16px 24px;
}
.p-reports__body__item {
  display: flex;
  flex-wrap: wrap;
}

.p-reports__body__item--4col .p-reports-dropdown {
    width: calc(50% - 8px);
    flex-shrink: 0;
}
.p-reports__body__item > * {
  flex-shrink: 1;
}
.p-reports__body__item:not(:first-child) > * {
  margin-top: 16px;
}
.p-reports__body__item > *:not(:last-child) {
  margin-right: 16px;
}
.p-reports__body__item__flex {
  display: flex;
  flex-wrap: wrap;
}
.p-reports__body__item__flex > *:not(:last-child) {
  margin-right: 16px;
}
.p-reports__body__item__radiotitle {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
}
.p-reports__bottom {
  width: 100%;
  border-top: 1px solid var(--gray200);
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reports__bottom.center {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-reports__bottom .center > *:not(:last-child) {
  margin-right: 12px;
}

.p-reports__body__ac {
  padding-top: 16px;
  transition: all 0.3s ease-out;
}
.p-reports__body__acbtn > * {
  width: 100%;
}
.p-reports__body__ac .p-reports__body__item:last-child {
  padding-bottom: 16px;
}

@media screen and (min-width: 1025px) {

  /*.p-reports__body__item--custom .p-reports-dropdown{
    width: 152px;
  }*/

/*  .p-reports__body__item--custom .c-calendar{
    width: 264px;
  }*/
}

/* .p-reservationsSelectProducts */
.p-reservationsSelectProducts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
}
.p-reservationsSelectProducts > p {
  margin-right: 12px;
  flex-shrink: 0;
}
.p-reservationsSelectProducts > div {
  width: 100%;
}
.p-reservationsSelectProducts > div span {
  margin-right: 16px;
}
@media screen and (max-width: 768px) {
  .p-reports__body__item {
    display: block;
  }
  .p-reports__body__item > * {
    margin-right: 0 !important;
  }
  .p-reports__body__ac .p-reports__body__item > *:not(:first-child) {
    margin-top: 16px;
  }
  .p-reports__body__item__radiotitle {
    width: 100%;
    margin-bottom: 8px;
  }
}


/* reservations */
.p-reservations {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservations__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.p-reservations__header .p-reports-dropdown {
    width: 160px;
}

.p-reservations__dropdown {
    display: flex;
    align-items: center;
    gap: 8px;
}

.p-reservations__dropdown-txt {
  color: var(--gray500);
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  flex-shrink: 0;
}

.p-reservations__body {
  padding: 30px 24px 0;
  display: flex;
  justify-content: flex-end;
}

.p-reservations__tab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray300);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.p-reservations__tab li {
  width: fit-content;
  /* min-width: 62px; */
  min-height: 40px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  padding: 10px 16px;
  cursor: pointer;
}
.p-reservations__tab li:not(:last-child) {
  border-right: 1px solid var(--gray300);
}
.p-reservations__tab li.is-active {
  color: var(--primary700);
  background-color: var(--gray50);
}
.p-reservations__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__actions > *:not(:first-child) {
  margin-left: 12px;
}

.p-reservations__graf {
  padding: 16px 24px;
}

.p-reservations__graf img{
  display: block;
  width: 100%;
}
.p-reservations__bottom {
  width: 100%;
  /* border-top: 1px solid var(--gray200); */
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__empty {
  text-align: center;
  padding: 64px 24px;
}
.p-reservations__fixed {
  width: 100%;
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
              0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: bottom 0.2s linear;
}
.p-reservations__fixed.is-active {
  bottom: 0;
  opacity: 1;
}
.p-reservations__fixed > p {
  font-size: var(--text-base-size);
  font-weight: var(--text-normal);
  line-height: var(--text-base-height);
  position: absolute;
  left: 32px;
}
.p-reservations__fixed > p b {
  font-size: var(--text-xl-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xl-height);
}
.p-reservations__fixed > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-reservations__fixed > div > *:not(:first-child) {
  margin-left: 12px;
}

@media screen and (max-width: 768px) {
  .p-reservations {
    width: 100%;
    /*border-radius: var(--radius-none);*/
    /*border: none;*/
    /*background-color: transparent;*/
  }
/*  .p-reservations__header {
    padding: 16px 0;
  }
  .p-reservations__bottom {
    padding: 16px 0;
  }*/
  .p-reservations__fixed {
    width: 100% !important;
    padding: 0 16px;
  }
  .p-reservations__fixed > p {
    font-size: var(--text-xs-size);
    font-weight: var(--text-normal);
    line-height: var(--text-xs-height);
    position: relative;
    left: 0;
    margin-right: auto;
  }
  .p-reservations__fixed > p span {
    display: block;
  }
  .p-reservations__fixed > p b {
    font-size: var(--text-base-size);
    font-weight: var(--text-bold);
    line-height: var(--text-base-height);
  }
  .p-reports__body__item--4col .p-reports-dropdown {
    width: 100%;
  }

}
@media screen and (max-width: 375px) {
  .p-reservations__header {
    flex-wrap: wrap;
  }
  .p-reservations__actions {
    margin-left: 0;
    margin-top: 16px;
  }
}






/* p-sortList */
.p-sortList {
  position: relative;
}
.p-sortList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item:not(:first-child) {
  margin-top: 12px;
}
.p-sortList__item.is-disabled {
  color: var(--gray400);
  background-color: var(--gray50);
  pointer-events: none;
}
.p-sortList__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item__left p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-sortList__item__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-sortList__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-sortList__item__ic i::before,
.p-sortList__item__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-sortList__item__right {
  margin-left: auto;
}
.p-sortList__item__right > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}

/* lumpCancel */
.p-lumpCancel {
  position: relative;
}
.p-lumpCancel__caution {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--error700);
  background-color: var(--error50);
  border: 1px solid var(--error200);
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-xs);
  padding: 16px;
  margin-bottom: 12px;
}
.p-lumpCancel__form {
  position: relative;
}
.p-lumpCancel__form__item:not(:first-child) {
  margin-top: 20px;
}
.p-lumpCancel__form__item__ttl {
  margin-bottom: 6px;
}
.p-lumpCancel__form__item textarea {
  width: 100%;
  min-height: 248px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  padding: 8px 12px;
  resize: vertical;
}
.p-lumpCancel__form__item textarea:hover {
  border-color: var(--primary200);
}
.p-lumpCancel__form__item textarea:focus {
  border-color: var(--primary700);
}


/*  dropdown(3点リーダー有)
--------------------------------------------- */
/* dropdown */
.p-reports-dropdown {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.p-reports-dropdown__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.p-reports-dropdown__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.p-reports-dropdown__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.p-reports-dropdown__body > i::before {
  line-height: 20px;
}
.p-reports-dropdown__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.p-reports-dropdown__body label {
  width: 100%;
  position: relative;
}
.p-reports-dropdown__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.p-reports-dropdown__body__selected__input {
  /* width: fit-content; */
  display: none;
}

.p-reports-dropdown__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-reports-dropdown__body__selected ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -4px;
}
.p-reports-dropdown__body__selected ul li {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  background-color: var(--gray100);
  border-radius: var(--radius-sm);
  padding: 3px 4px 3px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reports-dropdown__body__selected ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.p-reports-dropdown__body__selected ul li i::before {
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  color: var(--gray400);
  content: "\e9c8";
}

.p-reports-dropdown.is-hover {
  border-color: var(--primary200);
}
.p-reports-dropdown.is-active {
  border-color: var(--primary700);
}
.p-reports-dropdown.is-input .p-reports-dropdown__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.p-reports-dropdown.is-active .p-reports-dropdown__body__title {
  color: var(--primary700);
}

.p-reports-dropdown__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.p-reports-dropdown__menu {
  width: 100%;
  min-width: 160px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
  overflow-y: auto;
}
.p-reports-dropdown__menu.overflow-no {
  overflow-y: visible;
}
.p-reports-dropdown__menu.is-active {
  display: block;
}
.p-reports-dropdown__menu__item {
  padding: 8px 10px;
  position: relative;
}
.p-reports-dropdown__menu__item:hover {
  background-color: var(--gray50);
}
.p-reports-dropdown__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.p-reports-dropdown__menu__item i {
  opacity: 0;
}
.p-reports-dropdown__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.p-reports-dropdown__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.p-reports-dropdown__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  text-align: left;
}
.p-reports-dropdown__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}

/* override */
.p-reports-dropdown__body__selected ul li span{
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.p-reports-dropdown__body {
  padding: 8px 42px 8px 12px;
  flex-direction: column;
}


/*  override
--------------------------------------------- */
/* c-calendar */
.c-calendar:disabled {
  color: var(--gray400);
}


/*  placeholder
--------------------------------------------- */
::placeholder {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
}
