/*------------------------------------------------------------------------------
headline-tab
------------------------------------------------------------------------------*/
.c-headline-tab {
  font-size: 14px;
  border: 1px solid #4a6c7a;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-headline-tab li {
  width: 140px;
  height: 32px;
  color: #4a6c7a;
  flex: 1;
  line-height: 1em;
}
.c-headline-tab li:not(:last-child) {
  border-right: 1px solid #4a6c7a;
}
.c-headline-tab li a,
.c-headline-tab li div {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-headline-tab li.is-active,
.c-headline-tab li.is-active {
  color: #fff;
  background: #4a6c7a;
}
@media screen and (max-width: 768px) {
  .c-headline-tab {
    font-size: 11px;
    letter-spacing: 0;
  }
}
/*------------------------------------------------------------------------------
pagination
------------------------------------------------------------------------------*/
.c-pagination {
  width: 100%;
  margin: 16px 0 8px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.c-pagination > *:not(:last-child) {
  margin-right: 32px;
}
.c-pagination__size {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__size p {
  margin-right: 8px;
}
.c-pagination__size label {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #fff;
  display: block;
  position: relative;
}
.c-pagination__size label select {
  width: 100%;
  height: 100%;
  padding: 0 24px 0 8px;
  cursor: pointer;
}
.c-pagination__size label::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #001d36 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 4px);
  right: 8px;
}
.c-pagination__jump {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__jump p:first-child {
  margin-right: 8px;
}
.c-pagination__jump p:last-child {
  margin-left: 8px;
}
.c-pagination__jump input {
  width: 64px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 0 8px;
}
.c-pagination__btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-pagination__btns > *:not(:last-child) {
  margin-right: 16px;
}
.c-pagination__btn {
  width: 96px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff !important;
  border-radius: 4px;
  background: #4a6c7a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-pagination__btn.disable {
  background: #cfdae1;
  cursor: default;
  pointer-events: none;
}
.c-pagination__btn:hover {
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .c-pagination {
    margin: 0;
    padding: 0 16px 32px 16px;
    justify-content: center;
  }
  .c-pagination > *:not(:last-child) {
    margin-right: 0;
  }
  .c-pagination__size {
    order: 1;
    margin-right: 16px !important;
  }
  .c-pagination__jump {
    order: 1;
  }
  .c-pagination__btns {
    width: 100%;
    margin: 32px 16px;
    order: 0;
  }
  .c-pagination__btn {
    flex: 1;
  }
}
/*------------------------------------------------------------------------------
form-multiple
------------------------------------------------------------------------------*/
.c-form-multiple,
.c-form-single {
  width: 100%;
  position: relative;
}
.c-form-multiple .select,
.c-form-single .select {
  width: 100%;
  min-height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  cursor: pointer;
}
.c-form-multiple .placeholder,
.c-form-single .placeholder {
  color: #888;
  position: relative;
}
.c-form-multiple .selected,
.c-form-single .selected {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.c-form-multiple .selected li,
.c-form-single .selected li {
  font-size: 12px;
  padding: 4px 0 4px 4px;
  border-radius: 4px;
  background: #ebf1f3;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.c-form-multiple .selected p,
.c-form-single .selected p {
  max-width: 90%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.c-form-multiple .selected li .delete,
.c-form-single .selected li .delete {
  width: 22px;
  height: 22px;
  position: relative;
}
.c-form-multiple .selected li .delete::before,
.c-form-multiple .selected li .delete::after,
.c-form-single .selected li .delete::before,
.c-form-single .selected li .delete::after {
  content: '';
  width: 12px;
  height: 2px;
  background: #4a6c7a;
  position: absolute;
  top: 11px;
  left: 6px;
}
.c-form-multiple .selected li .delete::before,
.c-form-single .selected li .delete::before {
  transform: rotate(45deg);
}
.c-form-multiple .selected li .delete::after,
.c-form-single .selected li .delete::after {
  transform: rotate(-45deg);
}
.c-form-multiple .select input,
.c-form-single .select input {
  line-height: 30px;
}

.c-form-single .select input {
  width: 100%;
}

.c-form-multiple > .option,
.c-form-single > .option {
  width: 608px;
  max-height: 240px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 8px;
  position: absolute;
  z-index: 100;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}
.c-form-multiple .select.is-active + .option,
.c-form-single .select.is-active + .option {
  display: block;
}
.c-form-multiple .option li,
.c-form-single .option li {
  width: 100%;
  padding: 8px;
  cursor: pointer;
  display: block;
}
.c-form-multiple .option li:hover,
.c-form-single .option li:hover {
  background: #ebf1f3;
}
@media screen and (max-width: 768px) {
  .c-form-multiple > .option,
  .c-form-single > .option {
    width: 100%;
  }
}
/*------------------------------------------------------------------------------
headline-search
------------------------------------------------------------------------------*/
.c-headline-search {
  width: 100%;
  border-radius: 4px;
  background: #fff;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;

  /* vertical spacing when wrapped */
  margin-top: -10px;
}
.c-headline-search > * {
  /* vertical spacing when wrapped */
  margin-top: 10px;
}
.c-headline-search__item {
  width: 240px;
  margin-right: 16px;
}
.c-headline-search_spMore {
  display: none;
}
@media screen and (max-width: 768px) {
  .c-headline-search {
    width: calc(100% - 16px);
    margin: 8px 8px 0 8px;
    flex-direction: column;
  }
  .c-headline-search__item {
    width: 100%;
  }
  .c-headline-search__item + .c-headline-search__item {
    margin-top: 16px;
  }
  .c-headline-search_spMore {
    width: calc(100% + 32px);
    height: 32px;
    font-weight: bold;
    color: #008ec9;
    border-top: 1px solid #cfdae1;
    border-radius: 0 0 4px 4px;
    margin-top: 16px;
    margin-left: -16px;
    margin-bottom: -16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-headline-search_spMore::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #008ec9 transparent transparent transparent;
    display: block;
    margin-left: 8px;
  }
  .c-headline-search_spMore.arrowUp::after {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #008ec9 transparent;
  }
}
/*------------------------------------------------------------------------------
tab-box
------------------------------------------------------------------------------*/
.c-tab-box {
}
.c-tab-box__tab {
  width: 100%;
  margin-bottom: -1px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-x: auto;
}
.c-tab-box__tab li {
  min-width: 160px; /* NOTE(goro) change this param from width -> min-width to nowrap inner text */
  min-height: 40px;
  font-size: 13px;
  font-weight: bold;
  color: #9bacb5;
  border-radius: 8px 8px 0 0;
  border: 1px solid #cfdae1;
  background: #cfdae1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-tab-box__tab li.is-active {
  color: #008ec9;
  background: #fff;
  border-bottom: 1px solid #fff;
}
.c-tab-box__tab li + li {
  margin-left: 8px;
}
.c-tab-box__tab li a {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0px 4px;
}
.c-tab-box__box {
  display: none;
}
.c-tab-box__box.is-active {
  display: block;
}
.c-tab-box__box.no-margin-top {
  margin-top: 0px;
}
@media screen and (max-width: 768px) {
  .c-tab-box__tab {
    padding: 0 8px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-tab-box__tab li {
    width: 112px;
    font-size: 12px;
    flex-shrink: 0;
  }
  .c-tab-box__box {
    margin-top: 0;
  }
}
/*------------------------------------------------------------------------------
table-main
------------------------------------------------------------------------------*/
.c-table-main {
  position: relative;
  scroll-margin-top: 96px;
  width: 100%;
}
.c-table-main__thead {
  background: #fff;
  border-bottom: 2px solid #cfdae1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: sticky;
  top: -1px;
  z-index: 2;
}
.c-table-main__thead .desc,
.c-table-main__thead .asc {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
}
.c-table-main__thead th:hover {
  background: #f9f9f9;
}
.c-table-main__thead .desc {
  border-width: 6px 4px 0 4px;
  border-color: #008ec9 transparent transparent transparent;
  margin-top: -2px;
}
.c-table-main__thead .asc {
  border-width: 0 4px 6px 4px;
  border-color: transparent transparent #008ec9 transparent;
  margin-top: -4px;
}
.c-table-main__tbody {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.c-table-main__tbody__header {
}
.c-table-main table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table-main tr:nth-child(odd) {
  background: #fff;
}
.c-table-main tr:nth-child(even) {
  background: #f9f9f9;
}
.c-table-main th,
.c-table-main td {
  padding: 8px;
  border: 1px solid #cfdae1;
}
.c-table-main th {
  height: 32px;
  text-align: center;
}
.c-table-main th:first-child {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
}
.c-table-main.mobile-header th:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
  pointer-events: none;
}
.c-table-main td {
}
.c-table-main td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.c-table-main tr:nth-child(odd) td:first-child  {
  background: #fff;
}
.c-table-main tr:nth-child(even) td:first-child {
  background: #f9f9f9;
}

.c-table-main.mobile-header td:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
  pointer-events: none;
}
.c-table-main td a,
.c-table-main th a {
  font-weight: bold;
  color: #008ec9;
}
.c-table-main td a:hover {
  opacity: 0.8;
}
.c-table-main__btn {
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-table-main__btn.prev {
  left: -16px;
}
.c-table-main__btn.next {
  right: -16px;
}
.c-table-main__btn p {
  width: 24px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  position: sticky;
  /* top: calc(50% - 40px); NOTE(goro) probably something wring */
  cursor: pointer;
}
.c-table-main__btn p::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(-45deg);
  top: calc(50% - 8px);
}
.c-table-main__btn.prev p {
  border-radius: 0 8px 8px 0;
}
.c-table-main__btn.next p {
  border-radius: 8px 0 0 8px;
}
.c-table-main__btn.prev p::after {
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  left: 6px;
}
.c-table-main__btn.next p::after {
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  right: 8px;
}
.c-table-main__btn p:hover {
  background: rgba(0, 0, 0, 0.6);
}
.c-table-main__btn.next::before {
  content: '';
  width: 48px;
  height: calc(100% - 2px);
  border-right: 1px solid #cfdae1;
  position: absolute;
  top: 1px;
  right: 16px;
}
@media screen and (max-width: 768px) {
  .c-table-main__thead {
    display: none;
  }
  .c-table-main__tbody__header {
    display: none !important;
  }
  .c-table-main__tbody table {
  }
  .c-table-main__tbody tr {
    background: #fff !important;
    /* border-bottom: 1px solid #CFDAE1; */
    padding: 8px;
    display: block;
  }
  .c-table-main tr + * {
    margin-top: 16px;
  }
  .c-table-main tr > * + * {
    margin-top: 8px;
  }
  .c-table-main th,
  .c-table-main td {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .c-table-main th[class^='base-t-'],
  .c-table-main td[class^='base-t-'] {
    width: 100% !important;
  }
  .c-table-main th {
    height: 48px !important;
  }
  .c-table-main th::before {
    display: none;
  }
  .c-table-main th a {
    width: 100% !important;
    height: 48px !important;
    border-radius: 4px !important;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 8px;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .c-table-main th a img {
    width: 32px !important;
    height: 32px !important;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .c-table-main th a::after {
    content: attr(data-text) '';
  }
  .c-table-main th a::before {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 2px solid #9bacb5;
    border-right: 2px solid #9bacb5;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 12px;
  }
  .c-table-main td::before {
    content: attr(data-text) '';
    width: 96px;
    min-height: 24px;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
  }
  .c-table-main__tbody__spMore {
    width: calc(100% + 16px);
    height: 32px;
    font-weight: bold;
    color: #008ec9;
    border-top: 1px solid #cfdae1;
    margin-left: -8px;
    margin-bottom: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-table-main__tbody__spMore::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #008ec9 transparent transparent transparent;
    display: block;
    margin-left: 8px;
  }
  .c-table-main__tbody__spMore.arrowUp::after {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #008ec9 transparent;
  }
  .c-table-main__btn {
    display: none;
  }
}
/*------------------------------------------------------------------------------
table-manifests
------------------------------------------------------------------------------*/
.c-table-manifests {
  position: relative;
}
.c-table-manifests + .c-table-manifests {
  margin-top: 16px;
}
.c-table-manifests__tbody {
  /* overflow-x: auto;
  -webkit-overflow-scrolling: touch; */
}
.c-table-manifests__tbody__ttl {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-table-manifests__tbody__ttl p:first-child {
  /* NOTE(goro) overwride semantic-ui-react */
  font-size: 16px;
  font-weight: bold;
  margin-right: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.c-table-manifests__tbody__ttl__time {
  font-size: 16px;
  font-weight: bold;
  margin-right: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.c-table-manifests__tbody__ttl__num {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-table-manifests__tbody__ttl__num p,
.c-table-manifests__tbody__ttl__num p:first-child {
  /* NOTE(goro) override semantic-ui-react */
  width: 24px;
  height: 24px;
  background: #9bacb5;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.c-table-manifests__tbody__ttl__num img {
  width: 16px;
  height: 16px;
}
.c-table-manifests__tbody__ttl__ic {
}
.c-table-manifests__tbody__body {
}
.c-table-manifests table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table-manifests tr:nth-child(2n) {
  background: #fff;
}
.c-table-manifests tr:nth-child(2n + 1) {
  background: #f9f9f9;
}
.c-table-manifests tr:nth-child(1) {
  background: #fff;
}
.c-table-manifests th,
.c-table-manifests td {
  padding: 8px;
  border: 1px solid #cfdae1;
}
.c-table-manifests th {
  overflow: hidden;
  height: 32px;
  text-align: left;
}
.c-table-manifests th:first-child {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
}
.c-table-manifests th:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
}
.c-table-manifests td {
}
.c-table-manifests td a {
  font-weight: bold;
  color: #008ec9;
}
.c-table-manifests td a:hover {
  opacity: 0.8;
}
.c-table-manifests td:first-child {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
}
.c-table-manifests td:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #cfdae1;
  border-right: 3px solid #cfdae1;
  position: absolute;
  top: 0;
  left: -1px;
}
.subrow td:first-child::before {
  border-right: none;
}
.subrow td:first-child {
  background: initial;
  position: relative;
  z-index: 0;
}

/*------------- 202101 追加 start -------------*/
.c-table-manifests__frame {
  position: relative;
}
.c-table-manifests__table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.c-table-manifests__btn {
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-table-manifests__btn.prev {
  left: -16px;
}
.c-table-manifests__btn.next {
  right: -16px;
}
.c-table-manifests__btn p {
  width: 24px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: calc(50% - 40px);
  cursor: pointer;
}
.c-table-manifests__btn p::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(-45deg);
  top: calc(50% - 8px);
}
.c-table-manifests__btn.prev p {
  border-radius: 0 8px 8px 0;
}
.c-table-manifests__btn.next p {
  border-radius: 8px 0 0 8px;
}
.c-table-manifests__btn.prev p::after {
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  left: 6px;
}
.c-table-manifests__btn.next p::after {
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  right: 8px;
}
.c-table-manifests__btn p:hover {
  background: rgba(0, 0, 0, 0.6);
}
.c-table-manifests__btn.next::before {
  content: '';
  width: 48px;
  height: calc(100% - 2px);
  border-right: 1px solid #cfdae1;
  position: absolute;
  top: 1px;
  right: 16px;
}
/*------------- 202101 追加 end -------------*/
@media screen and (max-width: 768px) {
  .c-table-manifests__tbody__header {
    display: none !important;
  }
  .c-table-manifests__tbody__ttl {
    background: #fff;
    padding: 0 8px;
    cursor: pointer;
  }
  .c-table-manifests__tbody__ttl__ic {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #ebf1f3;
    margin-left: auto;
    position: relative;
  }
  .c-table-manifests__tbody__ttl__ic::before,
  .c-table-manifests__tbody__ttl__ic::after {
    content: '';
    display: block;
    width: 12px;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 6px);
  }
  .c-table-manifests__tbody__ttl.is-open
    .c-table-manifests__tbody__ttl__ic::before {
    background: #008ec9;
  }
  .c-table-manifests__tbody__ttl.is-close
    .c-table-manifests__tbody__ttl__ic::before {
    background: #008ec9;
  }
  .c-table-manifests__tbody__ttl.is-close
    .c-table-manifests__tbody__ttl__ic::after {
    background: #008ec9;
    transform: rotate(90deg);
  }
  .c-table-manifests__tbody__body {
    border-top: 1px solid #cfdae1;
  }
  /* Note change + to ~ to add memo area between ttl and table */
  .c-table-manifests__tbody__ttl.is-close
    ~ .c-table-manifests__frame
    .c-table-manifests__tbody__body {
    display: none;
  }
  /* Note change + to ~ to add memo area between ttl and table */
  .c-table-manifests__tbody__ttl.is-open
    ~ .c-table-manifests__frame
    .c-table-manifests__tbody__body {
    display: table;
  }
  .c-table-manifests__tbody table {
    font-size: 12px;
  }
  .c-table-manifests__tbody tr {
    background: #fff !important;
    border-bottom: 1px solid #cfdae1;
    padding: 8px;
    display: block;
  }
  .c-table-manifests {
    margin-bottom: 16px;
    background-color: #fff;
  }
  .c-table-manifests tr > * + * {
    margin-top: 8px;
  }
  .c-table-manifests th,
  .c-table-manifests td {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .c-table-manifests th[class^='base-t-'],
  .c-table-manifests td[class^='base-t-'] {
    width: 100% !important;
  }
  .c-table-manifests th {
    height: 48px !important;
  }
  .c-table-manifests th::before {
    display: none;
  }
  .c-table-manifests th a {
    width: 100% !important;
    height: 48px !important;
    border-radius: 4px !important;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 8px;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .c-table-manifests th a img {
    width: 32px !important;
    height: 32px !important;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .c-table-manifests th a::after {
    content: attr(data-text) '';
  }
  .c-table-manifests th a::before {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 2px solid #9bacb5;
    border-right: 2px solid #9bacb5;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 12px;
  }
  .c-table-manifests td::before {
    content: attr(data-text) '';
    width: 96px;
    min-height: 24px;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .subrow td:first-child::before {
    position: relative;
    content: attr(data-text) '';
    width: 96px;
    min-height: 24px;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
  }
  /*------------- 202101 追加 start -------------*/
  .c-table-manifests__tbody__spMore {
    width: calc(100% + 16px);
    height: 32px;
    font-weight: bold;
    color: #008ec9;
    border-top: 1px solid #cfdae1;
    margin-left: -8px;
    margin-bottom: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-table-manifests__tbody__spMore::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #008ec9 transparent transparent transparent;
    display: block;
    margin-left: 8px;
  }
  .c-table-manifests__tbody__spMore.arrowUp::after {
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent #008ec9 transparent;
  }
  .c-table-manifests__btn {
    display: none;
  }
  /*------------- 202101 追加 end -------------*/
}
/*------------------------------------------------------------------------------
table-slide
------------------------------------------------------------------------------*/
.c-table-slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.c-table-slide__table {
  width: calc(100% - 64px);
  order: 2;
  overflow-x: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.c-table-slide__table > div {
  width: 100%;
  display: flex;
}
/* .c-table-slide__table ul {
  vertical-align: top;
  display: table-cell;
} */
.c-table-slide__table ul:not(:last-child) li {
  border-right: 1px solid #cfdae1;
}
.c-table-slide__table li {
  border-top: 1px solid #cfdae1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-table-slide__table li:last-child {
  border-bottom: 1px solid #cfdae1;
}
.c-table-slide__table__thread {
  width: 160px;
  flex-shrink: 0;
  position: sticky;
  left: 0;
}
.c-table-slide__table__thread li {
  background: #ebf1f3;
  padding: 8px;
}
.c-table-slide__table__tbody {
  /* width: 200px; */
  flex: 1;
}
.c-table-slide__table__tbody li {
  padding: 8px;
}
.c-table-slide__table__tbody:nth-child(odd) li {
  background: #f9f9f9;
}
.c-table-slide__table__tbody:nth-child(even) li {
  background: #fff;
}

.c-table-slide__btn {
  width: 33px;
  border: 1px solid #cfdae1;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.c-table-slide__btn.prev {
  border-radius: 8px 0 0 8px;
  margin-right: -1px;
  order: 1;
}
.c-table-slide__btn.next {
  border-radius: 0 8px 8px 0;
  margin-left: -1px;
  order: 3;
}
.c-table-slide__btn::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(-45deg);
  top: calc(50% - 8px);
}
.c-table-slide__btn.prev::after {
  border-top: 3px solid #cfdae1;
  border-left: 3px solid #cfdae1;
  left: 10px;
}
.c-table-slide__btn.next::after {
  border-right: 3px solid #cfdae1;
  border-bottom: 3px solid #cfdae1;
  right: 10px;
}
.c-table-slide__btn.prev.is-active::after {
  border-top: 3px solid #008ec9;
  border-left: 3px solid #008ec9;
}
.c-table-slide__btn.next.is-active::after {
  border-right: 3px solid #008ec9;
  border-bottom: 3px solid #008ec9;
}
@media screen and (max-width: 768px) {
  .c-table-slide {
  }
  .c-table-slide__table {
    width: 100%;
  }
  .c-table-slide__table > div {
    display: block;
  }
  .c-table-slide__table__thread {
    display: none !important;
  }
  .c-table-slide__table__tbody {
    border: 1px solid #cfdae1;
    border-radius: 4px;
    background: #f9f9f9;
  }
  .c-table-slide__table__tbody + ul {
    margin-top: 8px;
  }
  .c-table-slide__table__tbody li {
    height: auto !important;
    background: transparent !important;
    border: none !important;
  }
  .c-table-slide__table__tbody li::before {
    content: attr(data-text) '';
    width: 96px;
    min-height: 24px;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
  }
  .c-table-slide__btn {
    display: none;
  }
}
/*------------------------------------------------------------------------------
table-sub
------------------------------------------------------------------------------*/
.c-table-sub {
}
.c-table-sub table {
  width: 100%;
  border-collapse: collapse;
}
.c-table-sub table th {
  font-weight: normal;
  text-align: left;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.c-table-sub table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.c-table-sub__bold {
  font-weight: bold;
}
.c-table-sub__small {
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .c-table-sub table {
    font-size: 12px;
  }
  .c-table-sub table tr {
    border-radius: 4px;
    border: 1px solid #cfdae1 !important;
    background: #f9f9f9 !important;
    padding: 8px;
    display: block;
  }
  .c-table-sub table tr:first-child {
    display: none !important;
  }
  .c-table-sub table tr + * {
    margin-top: 16px;
  }
  .c-table-sub table tr:not(:first-child) > * + * {
    margin-top: 8px;
  }
  .c-table-sub table td {
    width: 100%;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .c-table-sub table th[class^='base-t-'],
  .c-table-sub table td[class^='base-t-'] {
    width: 100% !important;
  }
  .c-table-sub table .c-table-sub__btn {
    height: 48px !important;
  }
  .c-table-sub table .c-table-sub__btn::before {
    display: none;
  }
  .c-table-sub table .c-table-sub__btn a {
    width: 100% !important;
    height: 48px !important;
    border-radius: 4px !important;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 8px;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .c-table-sub table .c-table-sub__btn a img {
    width: 32px !important;
    height: 32px !important;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .c-table-sub table .c-table-sub__btn a {
    color: #008ec9;
  }
  .c-table-sub table .c-table-sub__btn a::after {
    content: attr(data-text) '';
  }
  .c-table-sub table .c-table-sub__btn a::before {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 2px solid #9bacb5;
    border-right: 2px solid #9bacb5;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 5px);
    right: 12px;
  }
  .c-table-sub table td:not(.c-table-sub__btn)::before {
    content: attr(data-text) '';
    width: 96px;
    min-height: 24px;
    background: #ebf1f3;
    border: 1px solid #cfdae1;
    padding: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
  }
}
/*------------------------------------------------------------------------------
table-nowrap
------------------------------------------------------------------------------*/
.c-table-nowrap {
}
.c-table-nowrap table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-nowrap table th {
  font-weight: normal;
  text-align: left;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.c-table-nowrap table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.c-table-nowrap__bold {
  font-weight: bold;
}
.c-table-nowrap__small {
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .c-table-nowrap {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-nowrap__cell {
    width: 400px;
  }
  .c-table-nowrap table tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .c-table-nowrap table tr > *:first-child::before {
    content: '';
    width: 100%;
    height: 100%;
    border-left: 1px solid #ddd;
    border-right: 3px solid #ddd;
    position: absolute;
    left: -1px;
    top: 0;
  }
  .c-table-nowrap table tr > *:not(:first-child) {
    position: relative;
    z-index: 0;
  }
}
/*------------------------------------------------------------------------------
table-wrap
------------------------------------------------------------------------------*/
.c-table-wrap {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.c-table-wrap table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-wrap table tr > *:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}
.c-table-wrap table tr > *:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  border-left: 1px solid #ddd;
  border-right: 3px solid #ddd;
  position: absolute;
  left: -1px;
  top: 0;
}
.c-table-wrap table tr > *:not(:first-child) {
  position: relative;
  z-index: 0;
}
.c-table-wrap table th {
  font-weight: normal;
  text-align: left;
  border: 1px solid #cfdae1;
  background: #ebf1f3;
  padding: 8px;
}
.c-table-wrap table td {
  border: 1px solid #cfdae1;
  background: #fff;
  padding: 8px;
}
.c-table-wrap__bold {
  font-weight: bold;
}
.c-table-wrap__small {
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .c-table-wrap {
    width: 100%;
  }
}
/*------------------------------------------------------------------------------
table-availability
------------------------------------------------------------------------------*/
.c-table-availability {
}
.c-table-availability__thead {
  background: #fff;
  border-bottom: 2px solid #cfdae1;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: -1px;
}
.c-table-availability__thead__left {
  width: calc(100% - 80px - (88px * 7));
  min-width: 320px;
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.c-table-availability__thead__left > div {
  border-left: 1px solid #cfdae1;
  border-top: 1px solid #cfdae1;
  border-bottom: 1px solid #cfdae1;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-table-availability__thead__left > div:nth-child(2) {
  width: calc(100% - 48px);
}
.c-table-availability__thead__right {
}
.c-table-availability__thead__right table {
  width: calc(80px + (88px * 7));
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-availability__thead__right th {
  height: 32px;
  padding: 8px;
  border: 1px solid #cfdae1;
}
.c-table-availability__thead__right th.pcTh {
  display: table-cell;
}
.c-table-availability__thead__right th.spTh {
  display: none;
}
.c-table-availability__thead__right th:first-child {
  background: #fff;
}
.c-table-availability__thead__right th.left {
  text-align: left;
}
.c-table-availability__thead__right th .total {
  display: flex;
  justify-content: center;
}
.c-table-availability__thead__right th .total div {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #9bacb5;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-table-availability__thead__right th .total img {
  width: 12px;
  height: 12px;
}

.c-table-availability__tbody {
  overflow-x: auto;
}
.c-table-availability__tbody__box {
  background: #fff;
  margin-top: -1px;
  display: flex;
  justify-content: flex-start;
}
.c-table-availability__tbody__box__left {
  width: calc(100% - 80px - (88px * 7));
  min-width: 320px;
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.c-table-availability__tbody__box__left > div {
  border-left: 1px solid #cfdae1;
  border-top: 1px solid #cfdae1;
  border-bottom: 1px solid #cfdae1;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-table-availability__tbody__box__left > div:nth-child(2) {
  width: calc(100% - 48px);
}
.c-table-availability__tbody__box__left > div a {
  font-weight: bold;
  color: #008ec9;
}
.c-table-availability__tbody__box__right {
}
.c-table-availability__tbody__box__right table.availability__table {
  width: calc(80px + (88px * 7));
  height: calc(100% - 1px);
  font-size: 12px;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-availability__tbody__box__right tr.availability__table:first-child {
  display: none;
}
.c-table-availability__tbody__box__right tr.availability__table:nth-child(odd),
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(odd)
  th,
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(odd)
  td {
  background: #f9f9f9;
}
.c-table-availability__tbody__box__right tr.availability__table:nth-child(even),
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(even)
  th.availability__table,
.c-table-availability__tbody__box__right
  tr.availability__table:nth-child(even)
  td.availability__table {
  background: #fff;
}
.c-table-availability__thead__right th.pcTh {
  display: none;
}
.c-table-availability__thead__right th.spTh {
  display: table-cell;
}

.c-table-availability__tbody__box__right td.availability__table,
.c-table-availability__tbody__box__right th.availability__table {
  height: 32px;
  border: 1px solid #cfdae1;
}
.c-table-availability__tbody__box__right td.availability__table.left,
.c-table-availability__tbody__box__right th.availability__table.left {
  text-align: left;
}
.c-table-availability__tbody__box__right td.availability__table > *,
.c-table-availability__tbody__box__right th.availability__table > * {
  padding: 16px 8px;
}
.c-table-availability__tbody__box__right td.availability__table > a,
.c-table-availability__tbody__box__right th.availability__table > a,
.c-table-availability__tbody__box__right
  td.availability__table
  > p.no-allotment,
.c-table-availability__tbody__box__right
  th.availability__table
  > p.no-allotment {
  width: 100%;
  height: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-table-availability__tbody__box__right td.availability__table > a:hover,
.c-table-availability__tbody__box__right th.availability__table > a:hover {
  color: #008ec9;
}
.c-table-availability__tbody__box__right .red100 {
  background: #ff7878;
}
.c-table-availability__tbody__box__right .red75 {
  background: #ffaaaa;
}
.c-table-availability__tbody__box__right .red50 {
  background: #ffd4d4;
}
.c-table-availability__tbody__box__right .red25 {
  background: #ffebeb;
}
.c-table-availability__tbody__box__right .yellow {
  background: #fff4c7;
}
.c-table-availability__tbody__box__right .gray {
  background: #ebf1f3;
  color: #9bacb5;
}
.c-table-availability__tbody__box__right
  td.availability__table
  img.availability__table {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
@media screen and (max-width: 768px) {
  .c-table-availability__thead {
    flex-direction: column;
    position: relative;
    overflow-x: auto;
    border-bottom: none;
  }
  .c-table-availability__thead__right {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-availability__thead__right .spFixed {
    background: #fff;
    position: sticky;
    left: 0;
  }
  .c-table-availability__thead__right .spFixed::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -1px;
    border-left: 1px solid #cfdae1;
    border-right: 3px solid #cfdae1;
  }
  .c-table-availability__thead__left {
    display: none;
  }
  .c-table-availability__tbody__box {
    flex-direction: column;
    position: relative;
    border-bottom: none;
    margin-top: 16px;
  }
  .c-table-availability__tbody__box__left {
    width: 100%;
  }
  .c-table-availability__tbody__box__left > div:first-child {
    display: none;
  }
  .c-table-availability__tbody__box__left > div:nth-child(2) {
    width: 100%;
  }
  .c-table-availability__tbody__box__left > div {
    border-bottom: none;
  }
  .c-table-availability__tbody__box__right {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .c-table-availability__tbody__box__right tr.availability__table:first-child {
    display: table-row;
  }
  .c-table-availability__tbody__box__right th.availability__table {
    padding: 0 4px;
  }
  .c-table-availability__tbody__box__right .spFixed,
  .c-table-availability__tbody__box__right .spFixed {
    position: sticky;
    left: 0;
  }
  .c-table-availability__tbody__box__right .spFixed::before,
  .c-table-availability__tbody__box__right .spFixed::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -1px;
    border-left: 1px solid #cfdae1;
    border-right: 3px solid #cfdae1;
  }
}
/*------------------------------------------------------------------------------
modal
------------------------------------------------------------------------------*/
.c-modal.is-active {
  display: flex;
}
.c-modal {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: none;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.c-modal__frame {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  margin-top: 64px;
  position: relative;
  z-index: 1;
  transform: translateZ(
    1px
  ); /* https://app.asana.com/0/1199959752069927/1201975165691502/f */
}
.c-modal__frame.width-640 {
  max-width: 640px;
}
.c-modal__frame.width-narrow {
  max-width: 400px;
}
.c-modal__frame.width-wide {
  max-width: calc(100% - 450px);
}
.c-modal__frame.transparent {
  border: #000 1px solid;
}
.c-modal__frame__header {
  border-bottom: 1px solid #cfdae1;
  padding-left: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-modal__frame__header__ttl {
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  flex: 1;
}
.c-modal__frame__header__close {
  width: 48px;
  height: 48px;
  margin-left: auto;
  position: relative;
}
.c-modal__frame__header__close:hover {
  opacity: 0.8;
}
.c-modal__frame__header__close::before,
.c-modal__frame__header__close::after {
  content: '';
  display: block;
  width: 24px;
  height: 4px;
  border-radius: 4px;
  background: #9bacb5;
  position: absolute;
  top: 22px;
  left: 12px;
}
.c-modal__frame__header__close::before {
  transform: rotate(45deg);
}
.c-modal__frame__header__close::after {
  transform: rotate(-45deg);
}
.c-modal__frame__body {
  padding: 16px;
}
.c-modal__frame__body__btns {
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}
.c-modal__frame__body__btns > * + * {
  margin-left: 16px;
}
.c-modal__frame__body__btns.column {
  flex-direction: column;
  align-items: center;
}
.c-modal__frame__body__btns.column > * + * {
  margin-left: 0;
  margin-top: 16px;
}
.c-modal__overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}
.c-modal__overlay.transparent {
  background: transparent;
}
@media screen and (max-width: 768px) {
  .c-modal {
    padding: 16px;
  }
  .c-modal__frame {
    margin-top: 0;
  }
  .c-modal__frame.width-wide {
    max-width: 640px;
  }
}

.dispatch-resource {
  display: flex;
  margin: 5px 10px; /* TODO(goro) !! conflict with semantic-ui-react */
  width: 200px;
  align-items: center;
}
.dispatch-resource img {
  margin-right: 10px;
}
.dispatch-resource-content {
}

.dispatch-resource-content p {
  margin-top: 0px;
  margin-bottom: 0px;
}
.dispatch-resource-content-sub {
  font-size: 1rem;
  display: block;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.2em;
  color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 768px) {
}
/**/
