.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: inherit;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}

.DateInput__small {
  width: 150px;
}

.DateInput_input__small {
}

.SingleDatePicker_picker {
  /* Opened date picker should appear on top of everything, including react-table resizer */
  z-index: 11;
}
