.container {
  background-color: var(--Colors-Base-White, #fff);
  display: flex;
  padding-top: 20px;
  flex-direction: column;
}
.date-header {
  color: var(--Colors-Gray-900, #18181b);
  margin: 0 24px;
  font: 600 16px/150% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stats {
  align-self: start;
  display: flex;
  gap: 20px;
  font-size: 12px;
  color: var(--Colors-Primary-600, #008ec9);
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  line-height: 133%;
  justify-content: space-between;
  margin: 12px 0 0 24px;
}
.stats-marker {
  align-items: center;
  border-radius: 4px;
  border-color: rgba(0, 142, 201, 1);
  border-style: solid;
  border-width: 1px;
  display: flex;
  gap: 2px;
  padding: 4px 12px;
}
.stats-text {
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  align-self: stretch;
  flex-grow: 1;
  margin: auto 0;
}
.count {
  align-self: stretch;
  font: 600 16px/150% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.btn {
  background-color: #008ec9;
  color: #fff;
  padding: 0.1rem;
  border-radius: 0.5rem;
  font-size: 12px;
  font-weight: 500;
}
