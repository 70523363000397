.c-fieldWrapper {
}
.c-fieldWrapper__header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px !important;
}
.c-fieldWrapper__header > p:not(:first-child) {
  margin-left: 8px;
}
.c-fieldWrapper__header.error {
  color: var(--error600);
}
.c-fieldWrapper__body {
}
