.code-block {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  white-space: pre-wrap;
  word-break: break-all;
  display: inline-block;
}

.link {
  color: #008ec9;
  font-weight: bold;
  cursor: pointer;
}

.signature-box {
  border: 1px solid #000;
  width: 300px;
}
.signature-box + .signature-box {
  margin-top: 16px;
}
