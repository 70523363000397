/* Override style of datepicker calendar */
.react-datepicker-override {
  .react-datepicker-wrapper {
    /* Adjust width of input by accounting the space for icon */
    width: calc(100% - 40px);
  }

  .react-datepicker__input-container {
    display: flex;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    width: 100%;
    /* Need to use important to position calendar to the start of fieldset */
    left: -12px !important;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 2px;
  }
  .react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 2px;
  }

  .react-datepicker {
    width: 100%;
  }

  .react-datepicker__navigation-icon::before {
    border-color: var(--primary600);
  }

  .react-datepicker__month-container {
    width: 100%;
    padding: 4px;
  }

  .react-datepicker__header {
    background-color: inherit;
    border: none;
  }

  .react-datepicker__month {
    padding-bottom: 8px;
    margin: 0;
  }

  .react-datepicker__current-month {
    font-weight: var(--text-medium);
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-evenly;
  }

  .react-datepicker__day-name {
    flex: 1;
    color: var(--gray400);
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
  }

  .react-datepicker__day {
    margin: 0;
    flex: 1;
  }

  .react-datepicker__day--in-selecting-range {
    border-radius: 0;
  }

  /* when start selecting date */
  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
    border-radius: 4px 0 0 4px;
  }

  .react-datepicker__day--selecting-range-end {
    border-radius: 0 4px 4px 0;
  }

  .react-datepicker__day--outside-month {
    color: var(--gray300);
    pointer-events: none;
  }

  .react-datepicker__day--selected {
    color: var(--white);
    background-color: var(--primary600);
    border-radius: 4px;
  }

  .react-datepicker__day--in-range {
    color: inherit;
    background-color: var(--primary50);
    border-radius: 0;
  }

  .react-datepicker__day--range-start {
    color: var(--white);
    background-color: var(--primary600);
    border-radius: 4px 0 0 4px;
  }

  .react-datepicker__day--range-end {
    color: var(--white);
    background-color: var(--primary600);
    border-radius: 0 4px 4px 0;
  }

  /* for element with both classes  */
  .react-datepicker__day--range-start.react-datepicker__day--range-end {
    border-radius: 4px;
  }

  input {
    width: 100%;
  }
}

.react-datepicker-tzinput-override {
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 6px;
  }
}

@media screen and (max-width: 768px) {
  .react-datepicker-override {
    .react-datepicker {
      width: auto;
    }

    .react-datepicker__month-container {
      width: auto;
    }
  }
}
