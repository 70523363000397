.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.container {
  background: white;
  padding: 20px;
  border-radius: 4px;
}

.title {
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  align-self: center;
}

.subtitle {
  font-size: 18px;
  text-transform: capitalize;
  align-self: center;
  margin-top: 24px;
}

.inputWrapper {
  display: flex;
  margin-top: 24px;
  width: 100%;
  padding-right: 16px;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  line-height: 1;
  justify-content: center;
}

.input {
  border-radius: 10px;
  border: 1px solid var(--colour-9, #b5b8bc);
  background: var(--white, #fff);
  align-self: stretch;
  display: flex;
  width: 199px;
  height: 66px;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  padding: 18px 0;
}

.unit {
  align-self: stretch;
  margin: auto 0;
}

.buttonWrapper {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
}

.button {
  border-radius: 100px;
  background: var(--color-15, #3aa0ff);
  padding: 17px 70px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
