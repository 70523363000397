.c-table-list {
}
.c-table-list > table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-list > table th,
.c-table-list > table td {
  border: none;
}
.c-table-list > table th {
  width: 100px;
  font-weight: normal;
  text-align: left;
  background: #ebf1f3;
}
.c-table-list > table td div.input {
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: move;
  opacity: 1;
}

.c-table-list > table td div.input__source_language {
  border-right: none;
}

.c-table-list > table td div.input__translation_language {
  border-left: none;
}


@media screen and (max-width: 768px) {
  .c-table-list > table,
  .c-table-list > table tbody,
  .c-table-list > table tr,
  .c-table-list > table th,
  .c-table-list > table td {
    width: 100%;
    display: block;
  }
  .c-table-list > table tr + tr {
    margin-top: 8px;
  }
  .c-table-list > table th {
    width: 100%;
  }
  .c-table-list > table td {
    margin-top: -1px;
  }
  .c-table-list > table td .base-t-800 {
    width: 100%;
  }
  .c-table-list__comment {
    display: none;
  }
}


.accordion {
}

.accordion .accordion__summary {
  list-style: inside disclosure-closed;
}

.accordion .accordion__summary.open {
  list-style-type: disclosure-open;
}
.color-selector {
  display: flex;
  flex-wrap: wrap;
}

.color-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 96px;
}

.color-box input[type="checkbox"] {
  display: none;
}

/* カスタムチェックボックスのデザイン */
.color-box label {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 10px;
  line-height: 20px;
}

/* ボックスのデザイン */
.color-box label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #00f;
  background-color: #fff;
  border-radius: 50%;
}

.color-box input[type="checkbox"]:checked + label:before {
  background-color: #00f;
}

.color-pane {
  width: 60px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-custom-pane {
  width: 60px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}


