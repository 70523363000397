.from-input {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.from-input-actor-name {
  margin-left: 8px;
}
.buttons {
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}
.buttons > * + * {
  margin-left: 16px;
}
