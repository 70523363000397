/*  placeholder
--------------------------------------------- */
::placeholder {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
}

/*  p-list__msg
--------------------------------------------- */
.p-list__msg {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--warning600);
  border: 1px solid var(--warning600);
  border-radius: var(--radius-xl);
  background-color: var(--warning50);
  padding: 16px;
  margin-bottom: 24px;
}
.p-list__msg__ttl {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-list__msg__ttl i {
  font-size: 20px;
  margin-right: 16px;
}
.p-list__msg__ttl p {
  font-weight: var(--text-semibold);
}

/*  p-box
--------------------------------------------- */
.p-box {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px 24px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px calc(20/1108*100%);
}

.p-box__input {
  width: calc(402/1108*100%);
  margin-right: 15px;
}


.p-box__checkboxes {
  width: 264px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  -ms-align-items: center;
  align-items: center;
  gap: calc(16/264*100%);
}

.p-box__checkboxes span {
  color: var(--gray500);
  font-size: var(--text-xs-size);
}

.p-box__checkboxes:nth-child(n) {
  flex-shrink: 0;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .p-box__input {
    width: 100%;
  }
  .p-box__checkboxes {
    width: 100%;
  }
}

/*  p-reservationsCreateDetail__section
--------------------------------------------- */
.p-reservationsCreateDetail__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservationsCreateDetail__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservationsCreateDetail__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-reservationsCreateDetail__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-reservationsCreateDetail__section__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservationsCreateDetail__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-reservationsCreateDetail__section__body.is-active {
  display: block;
}

@media screen and (max-width: 1024px) {
  .p-reservationsCreateDetail__section__header {
    padding: 16px;
  }
  .p-reservationsCreateDetail__section__body {
    padding: 16px;
  }
}


/*  c-tableSmall__tips
--------------------------------------------- */
.c-tableSmall__tips {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
}


/*  p-list-section
--------------------------------------------- */
.p-list-section {
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  overflow: hidden;
}

.p-list-section__table th {
  font-weight: var(--text-semibold) !important;
  padding: 12px 8px !important;
  text-align: center !important;
}

.p-list-section__table td {
  padding: 8px !important;
}

.p-list-section__pager {
  padding: 20px 24px;
  border-top: 1px solid var(--gray200);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  background-color: var(--white);
}

@media screen and (max-width: 1024px) {
  .p-list-section {
    border: none;
  }

  .p-list-section__pager {
    margin-top: 8px;
    border: 1px solid var(--gray200);
    border-radius: var(--radius-xl);
  }
}

.p-invoide-details__summary__th {
  width: calc(100% / 9);
  font-weight: var(--text-semibold) !important;
  padding: 12px 8px !important;
  text-align: center !important;
}

.p-invoide-details__summary__td {
  padding: 8px !important;
  text-align: right !important;
}


.p-topPage-products__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-topPage-products__section:last-of-type {
  margin-bottom: 50px;
}
.p-topPage-products__section:not(:first-child) {
  margin-top: 30px;
}
.p-topPage-products__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-topPage-products__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-topPage-products__section__ttl > span {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 4px;
  display: block;
}
.p-topPage-products__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-topPage-products__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-topPage-products__section__body.is-active {
  display: block;
}
.p-topPage-products__section__body__textarea textarea {
  min-height: 44px;
}
.p-topPage-products__section__body__textarea2 textarea {
  min-height: 120px;
}

.p-topPage-products__section__body__item:not(:first-child) {
  margin-top: 14px;
}

.p-topPage-products__section__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.p-topPage-products__section__body__item.flex > *:not(:last-child) {
  margin-right: 16px;
}

.p-topPage-products__section__body__youtube i {
  color: #EB361C;
}

.p-topPage-products__section__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-topPage-products__section__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .p-topPage-products__section__header {
    padding: 16px;
  }
  .p-topPage-products__section__body {
    padding: 16px;
  }
}

.p-sysfee__filter__block {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.p-sysfee__filter__block__paymenttype {
  display: flex;
  align-items: center;
  line-height: var(--text-sm-height);
}

@media screen and (max-width: 768px) {
  .p-sysfee__filter__block {
    display: block;
  }
  .p-sysfee__filter__block__paymenttype {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* reservations */
.p-reservations {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservations__fixed__footer__margin {
  margin-bottom: 64px;
}
.p-reservations__header {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservations__tab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray300);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservations__tab li {
  width: fit-content;
  min-width: 62px;
  min-height: 40px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  padding: 10px 10px;
  cursor: pointer;
  color: var(--gray400);
}
.p-reservations__tab li:not(:last-child) {
  border-right: 1px solid var(--gray300);
}
.p-reservations__tab li.is-active {
  color: var(--primary700);
}
.p-reservations__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservations__bottom {
  width: 100%;
  /* border-top: 1px solid var(--gray200); */
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__empty {
  text-align: center;
  padding: 64px 24px;
}
.p-reservations__fixed {
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
    0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: bottom 0.15s linear, opacity 0.15s linear;
}
.width {
  width: 100%;
}
.is-active {
  bottom: 0;
  opacity: 1;
  transition-delay: 0.2s;
}
.p-reservations__fixed > p {
  font-size: var(--text-base-size);
  font-weight: var(--text-normal);
  line-height: var(--text-base-height);
  margin-right: 64px;
}
.p-reservations__fixed > p b {
  font-size: var(--text-xl-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xl-height);
}
.p-reservations__fixed > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-reservations__fixed > div > *:not(:first-child) {
  margin-left: 12px;
}

.p-reservations__productNameLink {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: var(--primary600)
}
.p-reservations__productNameLink > i {
  font-size: var(--text-base-size);
  color: var(--gray500);
}
.p-reservations__productNameLink > a {
  margin-left: 4px;
}

.p-reservations__calendarLink {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.p-reservations__calendarLink > i {
  font-size: var(--text-base-size);
}
.p-reservations__calendarLink > a {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .p-reservations {
    width: 100%;
    border-radius: var(--radius-none);
    border: none;
    background-color: transparent;
  }
  .p-reservations__header {
    padding: 16px 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .p-reservations__header > div {
    margin-left: 0;
    margin-top: 16px
  }
  .p-reservations__bottom {
    padding: 16px 0;
  }
  .p-reservations__fixed {
    width: 100%;
    height: 96px;
    padding: 0 32px;
  }
  .p-reservations__fixed > p {
    font-size: var(--text-xs-size);
    font-weight: var(--text-normal);
    line-height: var(--text-xs-height);
    position: relative;
    left: 0;
    margin-right: auto;
    min-width: 120px;
  }
  .p-reservations__fixed > p span {
    display: block;
  }
  .p-reservations__fixed > p b {
    font-size: var(--text-base-size);
    font-weight: var(--text-bold);
    line-height: var(--text-base-height);
  }
  .p-reservations__fixed > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 375px) {
  .p-reservations__header {
    flex-wrap: wrap;
  }
  .p-reservations__actions {
    margin-left: 0;
    margin-top: 16px;
  }
}

/* Column size */
.short {
  width: 120px;
}
.middle {
  width: 180px;
}
.long {
  width: 220px;
}

/* Copied from src/client/base.module.css as this is not handled in new UI/UX CSS */
@media screen and (max-width: 768px) {
  .base-t-spHidden {
    display: none !important;
  }
}

.table--link {
  display: flex;
  align-items: center;
}
.table--tab {
  padding-right: 5px;
}
@media screen and (max-width: 768px) {
  .table--tab {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 70px;
  }
  .table--link {
    max-width: 85px;
  }
}
