.button-row {
  margin-top: 8px;
  margin-left: auto;
  padding-left: 8px;
}
.calendar-edit-btns-mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .button-row {
    margin-left: 0;
    padding-left: 0;
  }
  .calendar-edit-btns-pc {
    display: none;
  }
  .calendar-edit-btns-mobile {
    display: flex;
  }
  .edit-button-container {
    margin-left: 4px;
    position: relative;
    z-index: 5;
  }
  .button-dropdown {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 8px;
    display: block;
    position: absolute;
    top: 44px;
    left: 0px;
  }
}
