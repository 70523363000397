.card__coupon {
  width: 100%;
}
.btnSmall {
  height: 32px;
  border-radius: 16px;
  font-size: 14px;
  margin: 16px auto;
  display: inline-flex;
  width: auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  padding: 0 16px;
}
.btnSmall__coupon {
  width: 24px;
  margin-right: 4px;
}
.btn--submit {
  color: #fff;
  background: #006abb;
}
