.table-header th,
.table-header td {
  color: #fff !important;
  background: #4a6c7a !important;
}

@media screen and (max-width: 768px) {
  .table-header {
    display: none !important;
  }
}