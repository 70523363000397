.div {
  position: fixed;
  top: 48px;
  right: 0;
  z-index: 100;
  background: #fff;
  width: 50dvw;
  height: calc(100dvh - 48px);
  overflow-y: auto;
}
.header {
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--colour-12, #f3f6f8);
  background: var(--white, #fff);
  display: flex;
  width: 100%;
  padding: var(--Dimension-Spacing-4, 16px) var(--Dimension-Spacing-6, 24px);
  gap: 40px 100px;
  flex-wrap: wrap;
}

.logo {
  aspect-ratio: 1.2;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
  margin: auto 0;
}

.mainContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

@media (max-width: 991px) {
  .mainContent {
    max-width: 100%;
  }
}

.orderDetails {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Inter, sans-serif;
  text-transform: capitalize;
  justify-content: center;
  padding: 40px;
}

@media (max-width: 991px) {
  .orderDetails {
    max-width: 100%;
    padding: 0 20px;
  }
}

.customerInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .customerInfo {
    max-width: 100%;
  }
}

.customerHeader {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .customerHeader {
    max-width: 100%;
  }
}

.customerName {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.name {
  color: var(--Black, #1e202f);
  font: 600 28px/1 Poppins, sans-serif;
}

.orderId {
  color: var(--colour-10, #393d3f);
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  margin-top: 12px;
}

.statusButton {
  justify-content: center;
  align-items: center;
  border-radius: var(--Dimension-Radius-md, 8px);
  background: var(--White, #fff);
  align-self: start;
  display: flex;
  margin-top: 12px;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: var(--colour-1, #5fd189);
  font-weight: 500;
  white-space: nowrap;
  padding: 10px 0;
}

@media (max-width: 991px) {
  .statusButton {
    white-space: initial;
  }
}

.statusIndicator {
  background-color: var(--colour-1, #5fd189);
  border-radius: 50%;
  align-self: stretch;
  display: flex;
  width: 12px;
  height: 12px;
  fill: var(--colour-1, #5fd189);
  margin: auto 0;
}

.statusText {
  align-self: stretch;
  margin: auto 0;
}

.issueButton {
  align-self: stretch;
  border-radius: var(--Dimension-Radius-md, 8px);
  border: 1px solid var(--colour-10, #393d3f);
  background: var(--white, #fff);
  padding: var(--Dimension-Spacing-4, 16px);
  gap: 10px;
  font-size: 16px;
  color: var(--colour-10, #393d3f);
  font-weight: 600;
  line-height: 1;
}

.divider {
  background-color: #f3f6f8;
  min-height: 1px;
  margin-top: 24px;
  width: 100%;
  border: 1px solid rgba(243, 246, 248, 1);
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
  }
}

.itemCount {
  color: var(--colour-10, #393d3f);
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin-top: 24px;
}

.itemList {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  color: var(--colour-10, #393d3f);
  font-weight: 400;
  line-height: 1;
  justify-content: start;
}

@media (max-width: 991px) {
  .itemList {
    max-width: 100%;
  }
}

.itemRow, .optionRow {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .itemRow {
    max-width: 100%;
  }
}

.optionRow {
  margin: 8px 0 0 16px;
}

.addOnSection {
  display: flex;
  margin-top: 24px;
  width: 100%;
  padding-left: 60px;
  flex-direction: column;
  font-size: 16px;
  color: var(--colour-10, #393d3f);
  font-weight: 400;
  line-height: 1;
  justify-content: start;
}

@media (max-width: 991px) {
  .addOnSection {
    max-width: 100%;
    padding-left: 20px;
  }
}

.addOnHeader {
  color: var(--colour-18, #707282);
  font-weight: 600;
  text-transform: uppercase;
}

.addOnItem {
  display: flex;
  margin-top: 12px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .addOnItem {
    max-width: 100%;
  }
}

.addOnRow {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .addOnRow {
    max-width: 100%;
  }
}

.totalRow {
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: start;
  gap: 40px 100px;
  font-size: 24px;
  color: var(--colour-10, #393d3f);
  font-weight: 600;
  white-space: nowrap;
  line-height: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .totalRow {
    max-width: 100%;
    white-space: initial;
  }
}

.commentsSection {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .commentsSection {
    max-width: 100%;
  }
}

.commentsHeader {
  color: var(--colour-10, #393d3f);
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}

@media (max-width: 991px) {
  .commentsHeader {
    max-width: 100%;
  }
}

.commentsText {
  flex: 1;
  border-radius: var(--Dimension-Radius-md, 8px);
  background: var(--colour-12, #f3f6f8);
  margin-top: 16px;
  min-height: 69px;
  width: 100%;
  padding: 8px var(--Dimension-Spacing-4, 16px);
  gap: 10px;
  font-size: 16px;
  color: var(--colour-18, #707282);
  font-weight: 500;
}

@media (max-width: 991px) {
  .commentsText {
    max-width: 100%;
  }
}

.footer {
  align-items: center;
  border-top: 1px solid var(--colour-16, #dadde2);
  background: var(--white, #fff);
  display: flex;
  margin-top: 88px;
  min-height: 94px;
  width: 100%;
  padding: var(--Dimension-Spacing-6, 24px);
  gap: var(--Dimension-Spacing-4, 16px);
  justify-content: start;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .footer {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
}

.iconWrapper {
  align-items: center;
  align-self: stretch;
  border-radius: var(--Dimension-Radius-md, 8px);
  background: var(--colour-16, #dadde2);
  display: flex;
  padding: 0px var(--Dimension-Spacing-4, 16px);
  gap: var(--Dimension-Spacing-6, 24px);
  justify-content: start;
  height: 100%;
  width: 52px;
}

.footerIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.cancelButton {
  align-self: stretch;
  border-radius: var(--Dimension-Radius-xl, 12px);
  border: 1px solid var(--color-17, #3e8cc4);
  background: var(--white, #fff);
  min-width: 240px;
  padding: var(--Dimension-Spacing-4, 16px) var(--Dimension-Spacing-6, 24px);
  gap: var(--Dimension-Spacing-6, 24px);
  color: var(--color-17, #3e8cc4);
  white-space: nowrap;
  text-transform: capitalize;
  flex: 1;
  margin: auto 0;
  font: 400 20px/1 Inter, sans-serif;
}

@media (max-width: 991px) {
  .cancelButton {
    white-space: initial;
    padding: 0 20px;
  }
}

.completedButton {
  align-self: stretch;
  border-radius: var(--Dimension-Radius-xl, 12px);
  background: var(--color-17, #3e8cc4);
  min-width: 240px;
  padding: var(--Dimension-Spacing-4, 16px) var(--Dimension-Spacing-6, 24px);
  gap: var(--Dimension-Spacing-6, 24px);
  color: var(--white, #fff);
  white-space: nowrap;
  text-transform: capitalize;
  flex: 1;
  margin: auto 0;
  font: 600 20px/1 Inter, sans-serif;
}

@media (max-width: 991px) {
  .completedButton {
    white-space: initial;
    padding: 0 20px;
  }
}
