.buttons-row {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .buttons-row {
        flex-wrap: wrap;
        margin-right: 8px;
    }
}
