.input-box {
  max-width: 600px;
}

.input-box + .input-box {
  margin-top: 16px;
}
.input-box p {
  margin-top: 4px;
}
.input-box a {
  color: #008ec9;
  font-weight: bold;
  text-decoration: underline;
}
