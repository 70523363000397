.c-listEditor {
  width: 100%;
}
.c-listEditor__ttl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px !important;
}
.c-listEditor__ttl > *:not(:first-child) {
  margin-left: 8px;
}
.c-listEditor__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.c-listEditor__body__list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.c-listEditor__body__list > *:not(:first-child) {
  margin-top: 12px;
  padding-top: 16px;
  border-top: 1px solid var(--gray200);
}
.c-listEditor__body__list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.c-listEditor__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-listEditor__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.c-listEditor__body__actions {
  display: flex;
  flex-direction: row;
  margin-left: 4px;
  gap: 4px;
}

.c-listEditor__body__list__item__text {
  width: 60px;
}
.c-listEditor__body__list__item__group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.url {
  /* margin: 0 8px; */
}
.custom {
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  .c-listEditor__body__list__item__group {
    flex-direction: column;
    align-items: flex-start;
  }
  .c-listEditor__body__list__item__group:not(:first-child) {
    margin-left: 8px;
    margin-top: 12px;
  }
  .sp {
    margin-left: 0 !important;
  }
  .url {
    margin: 0;
  }
}
