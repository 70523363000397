*{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}
a, button {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}
img{
  height: auto;
  vertical-align: middle;
}
svg{
  width: inherit;
  height: inherit;
}
section { display: block; }
html{
  font-size: 16px;
}
input, textarea, select, button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
:placeholder-shown,
::-webkit-input-placeholder,
:-ms-input-placeholder {
  color: #222 !important;
  cursor: pointer;
}
select::-ms-expand {
  display: none;
}
body {
  font-family: Helvetica, Arial, Verdana, Roboto, 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: var(--gray900);
  background-color: var(--bg);
  font-size: 14px;
  line-height: 1.5em;
  vertical-align: middle;
  overflow-x: hidden;
}
*::before,::after {
  box-sizing: border-box;
}
::placeholder {
  color: var(--gray400);
}

/*
 Fix for semantic modal layout issue
*/
.fix-semantic-modal {
  margin-top: 0px !important;
}

/*
 Fix for semantic modal layout issue
*/
.ui.page.modals.dimmer.transition.visible.active {
  display: flex !important;
}

/*
 Fix for fullscreen (width=95%) semantic modal sticking to left side of page
*/
.ui.fullscreen.scrolling.modal {
  left: auto !important;
}
.newline {
  white-space: pre-line;
}
p:first-child {
  margin: 0px; /* NOTE(goro) cancel semantic.css */
}