.container {
  justify-content: space-between;
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  color: #393d3f;
  padding: 40px 24px;
  height: 100vh;
}
.container.edit {
  background: #f3f6f8;
}

.title {
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.attractionSelector {
  margin: 24px 24px 0;
  padding: 17px 24px;
  border-radius: 100px;
  border: 1px solid var(--colour-16, #dadde2);
  background-color: var(--white, #fff);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  /* Remove default select styling */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Add custom dropdown arrow */
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.attractionSelector:focus {
  outline: none;
  border-color: var(--primary300);
  box-shadow: 0 0 0 3px var(--primary100);
}

.attractionName {
  display: flex;
  align-items: center;
  gap: 24px;
}

.chevronIcon {
  width: 12px;
  height: 6px;
  fill: var(--colour-10, #393d3f);
}

.waitTimeSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 0 16px;
}

.waitTimeLabel {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  align-self: center;
}

.waitTimeDisplay {
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid var(--colour-12, #f3f6f8);
  background-color: var(--white, #fff);
  box-shadow: 0px 4px 10.3px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  margin-top: 24px;
  min-height: 262px;
  width: 100%;
  flex-direction: column;
  white-space: nowrap;
  line-height: 1;
  padding: 57px 40px;
}

.waitTime {
  font-size: 100px;
  font-weight: 700;
  text-transform: capitalize;
}

.closedDisplay {
  font-size: 56px;
  font-weight: 600;
  text-transform: capitalize;
}

.timeUnit {
  font-size: 40px;
  font-weight: 600;
  text-transform: lowercase;
  margin-top: 8px;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
}
.updateButton,
.cancelButton {
  height: 46px;
  border: none;
  border-radius: 100px;
  background-color: #3aa0ff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  width: 100%;
}
.cancelButton {
  background-color: #fff;
  color: #3aa0ff;
  border: 1px solid #3aa0ff;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 24px;
  color: var(--colour-10, #393d3f);
  font-weight: 600;
  justify-content: center;
}

.title {
  width: 100%;
}
.instruction {
  color:  #393d3f;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  align-self: center;
  margin-top: 16px;
}
@media (max-height: 700px) {
  .instruction {
    display: none;
  }
}

.optionsContainer {
  align-self: center;
  display: flex;
  color: var(--colour-10, #393d3f);
  white-space: nowrap;
  line-height: 1;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}

.optionCard {
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid var(--colour-12, #f3f6f8);
  background-color: var(--white, #fff);
  box-shadow: 0px 4px 10.3px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  min-height: 120px;
  flex-direction: column;
  width: 130px;
  padding: 23px 31px;
}

.optionCardSelected {
  composes: optionCard;
  border: 3px solid var(--color-15, #3aa0ff);
}

.optionValue {
  font-size: 50px;
  font-weight: 700;
  text-transform: capitalize;
}

@media (max-height: 700px) {
  .optionValue {
    font-size: 30px;
  }
}

.optionUnit {
  font-size: 24px;
  font-weight: 600;
  text-transform: lowercase;
}
@media (max-height: 700px) {
  .optionUnit {
    font-size: 18px;
  }
}
.optionText {
  font-size: 24px;
  font-weight: 600;
}
