.c-tableSmall {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid var(--gray200);
  border-radius: 12px;
  /* Comment overflow to prevent dropdown from being cut out by table */
  /* overflow: hidden; */
}
.c-tableSmall th {
  font-weight: var(--text-medium);
  text-align: left;
  color: var(--gray600);
  background-color: var(--gray50);
  padding: 16px;
}
.c-tableSmall td {
  font-weight: var(--text-normal);
  padding: 16px;
}
.c-tableSmall td + td {
  border-left: 1px solid var(--gray200);
}
.c-tableSmall tr:not(:first-child) th,
.c-tableSmall tr:not(:first-child) td {
  border-top: 1px solid var(--gray200);
}
.c-tableSmall tr.error th {
  color: var(--error700);
  background-color: var(--error50);
}
.c-tableSmall tr.error td {
  background-color: var(--error50);
}
.c-tableSmall tfoot tr td,
.c-tableSmall tfoot tr th {
  border-top: 1px solid var(--gray200);
}
.c-tableSmall thead th {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  border-bottom: 1px solid var(--gray200);
}
.c-tableSmall tbody th:first-child {
  border-right: 1px solid var(--gray200);
}
.c-tableSmall__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-tableSmall__flex > a {
  margin-left: auto;
}
.c-tableSmall__more p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.c-tableSmall__more a {
  color: var(--primary700) !important;
  margin-top: 8px;
  display: inline-block;
}
.c-tableSmall.row th {
  border-radius: 0 !important;
}
.c-tableSmall.row th:first-child {
  border-radius: 12px 0 0 0 !important;
}
.c-tableSmall.row th:last-child {
  border-radius: 0 12px 0 0 !important;
}
.c-tableSmall.row td + td {
  border-left: none;
}

.c-table__sp {
  display: none;
}

@media screen and (max-width: 768px) {
  .c-tableSmall thead {
    display: none;
  }
  .c-tableSmall:not(:first-child) {
    margin-top: 16px;
  }
  .c-tableSmall tr {
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .c-tableSmall th {
    width: 100% !important;
    border-right: none !important;
    border-bottom: 1px solid var(--gray200);
    display: block;
    width: 100%;
  }
  .c-tableSmall th:not(:first-child) {
    border-top: 1px solid var(--gray200);
  }
  .c-tableSmall td {
    width: 100% !important;
    border-top: none !important;
    display: block;
  }
  .c-tableSmall td + td {
    border-left: none !important;
  }
  .c-tableSmall.row {
    border: none;
  }
  .c-tableSmall.row tr {
    border: 1px solid var(--gray200);
    border-radius: 12px;
    padding: 12px;
    overflow: visible;
  }
  .c-tableSmall.row tr:not(:first-child) {
    margin-top: 16px;
  }

  .c-tableSmall.row tfoot tr:first-child {
    margin-top: 16px;
  }

  .c-tableSmall.row th {
  }
  .c-tableSmall.row td {
    width: 100%;
    padding: 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .c-tableSmall.row td:not(:first-child) {
    margin-top: 12px;
  }
  .c-tableSmall.row td::before {
    content: attr(data-text) '';
    font-size: var(--text-xs-size);
    font-weight: var(--text-medium);
    line-height: var(--text-xs-height);
    padding-right: 12px;
    margin-right: auto;
    display: block;
    flex-shrink: 0;
  }
  .c-tableSmall.row td.noBefore {
    justify-self: flex-start;
  }
  .c-tableSmall.row td.noBefore::before {
    content: none;
    font-size: initial;
    font-weight: initial;
    line-height: initial;
    padding-right: initial;
    margin-right: initial;
    display: initial;
    flex-shrink: initial;
  }
  .c-tableSmall.row td.c-tableSmall__spTtl {
    width: calc(100% + 24px) !important;
    margin-left: -12px;
    text-align: left;
    padding: 0 12px 12px 12px;
    border-bottom: 1px solid var(--gray200);
    justify-content: flex-start;
  }
  .c-tableSmall.row td.c-tableSmall__spTtl > * {
    margin-left: auto;
  }
  .c-tableSmall.row td.c-tableSmall__spTtl::before {
    padding-right: 0;
    margin-right: 0;
  }
  .c-tableSmall.row td.c-tableSmall__spNoTh {
    justify-content: flex-start;
    text-align: left;
  }
  .c-tableSmall.row td.c-tableSmall__spNoTh::before {
    padding-right: 0;
    margin-right: 0;
  }
  .c-tableSmall.row td.c-tableSmall__spTopBorder {
    border-top: 1px solid var(--gray200) !important;
    padding-top: 12px;
  }

  .c-tableSmall td.c-table__detail {
    border-top: 1px solid var(--gray200) !important;
    justify-content: center;
    padding: 16px 16px 4px 16px;
  }
  .c-tableSmall td.c-table__detail a {
    text-decoration: none;
  }
  .c-tableSmall td.c-table__detail::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-table__sp {
    display: block;
  }
}

.tdError {
  color: var(--error700);
  background-color: var(--error50);
}

.hidden {
  display: none !important;
}
