.container {
  width: 100%;
  max-width: none;
  height: 88px;
  margin-left: auto;
  margin-right: auto;
  font-family: Inter, sans-serif;
}

@media (width<=991px) {
  .container {
    width: 100%;
    max-width: 232px;
  }
}

@media (width<=640px) {
  .container {
    max-width: 640px;
    padding: 0 10px;
  }
}

.allocationRow {
  align-items: center;
  height: 26px;
  margin-bottom: 6px;
  display: flex;
}

.directSalesIndicator {
  background-color: #008ec9;
  border-radius: 5px;
  width: 26px;
  height: 26px;
  margin-right: 6px;
}

.allocationText {
  color: #000;
  font-size: 14px;
  margin: 0;
}

.allocationText.noBookingSource {
  color: #ef4444;
}

.agencySalesIndicator {
  background-color: #ffe169;
  border-radius: 5px;
  width: 26px;
  height: 26px;
  margin-right: 6px;
}

.editButton {
  align-items: center;
  margin-top: 8px;
  margin-left: 3px;
  display: flex;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
}

.editIcon {
  width: 22px;
  height: 22px;
  margin-right: 7px;
}

.editText {
  color: #000;
  font-size: 14px;
}
