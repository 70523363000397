.steps-nav {
  width: calc(100% - 24px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.steps-nav li {
  width: calc(100% / 3);
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  padding-left: 32px;
  font-weight: bold;
  color: #4a6c7a;
  position: relative;
}
.steps-nav li span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.steps-nav li::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 0 24px 24px;
  position: absolute;
  top: 0;
  right: -24px;
}
.steps-nav li:nth-child(odd) {
  background: #fff;
}
.steps-nav li:nth-child(even) {
  background: #f9f9f9;
}
.steps-nav li:nth-child(odd)::after {
  border-color: transparent transparent transparent #fff;
}
.steps-nav li:nth-child(even)::after {
  border-color: transparent transparent transparent #f9f9f9;
}
.steps-nav li:nth-child(1) {
  z-index: 5;
}
.steps-nav li:nth-child(2) {
  z-index: 4;
}
.steps-nav li:nth-child(3) {
  z-index: 3;
}
.steps-nav li:nth-child(4) {
  z-index: 2;
}
.steps-nav li:nth-child(5) {
  z-index: 1;
}
.steps-nav li.is-active {
  color: #fff;
  background: var(--primary600);
}
.steps-nav li.is-active::after {
  border-color: transparent transparent transparent var(--primary600);
}
@media screen and (max-width: 768px) {
  .steps-nav {
    width: calc(100% - 8px);
    margin-top: 16px;
  }
  .steps-nav li {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding-left: 16px;
  }
  .steps-nav li::after {
    border-width: 16px 0 16px 8px;
    right: -8px;
  }
  .steps-nav li span {
    display: none;
  }
}
