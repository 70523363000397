.p-tagSettings {
}
.p-tagSettings__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}
.p-tagSettings__header__ttl {
  font-size: var(--text-lg-size);
  line-height: var(--text-lg-height);
  font-weight: var(--text-semibold);
}
.p-tagSettings__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-tagSettings__body {
}
.p-tagSettings__body .noData {
  padding: 16px;
  display: table-cell;
  text-align: center;
}
.p-tagSettings__footer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

/* Modal properties */
.p-tagSettingsModal {
}
.p-tagSettingsModal__item {
}
.p-tagSettingsModal__item:not(:first-child) {
  margin-top: 16px;
}
.p-tagSettingsModal__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray700);
  /* Override semantic */
  margin-bottom: 6px !important;
  display: flex;
  align-items: center;
}
.p-tagSettingsModal__item__ttl span {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
  display: block;
}
.p-tagSettingsModal__item__ttl p:first-child {
  margin-right: 6px;
}
.p-tagSettingsModal__item__body {
}
.p-tagSettingsModal__item__body__flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.p-tagSettingsModal__item__body__flex > *:not(:first-child) {
  margin-left: 8px;
}
.p-tagSettingsModal__tagColor__item {
  display: flex;
  align-items: center;
}
.p-tagSettingsModal__tagColor__item i {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: var(--radius-sm);
  border: 1px solid;
  opacity: 1;
  margin-right: 8px;
}

.p-tagSettingsModal__condition {
  margin-top: 16px;
  border-top: 1px solid var(--gray200);
}
.p-tagSettingsModal__condition__list {
}
.p-tagSettingsModal__condition__list > li {
  margin-top: 16px;
}
.p-tagSettingsModal__condition__list__item {
}

.p-tagSettingsModal__box {
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  padding: 16px;
}
.p-tagSettingsModal__condition__list__item__or {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 16px;
  color: var(--gray500);
}
.p-tagSettingsModal__condition__list__item__or::before,
.p-tagSettingsModal__condition__list__item__or::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: var(--gray200);
}
.p-tagSettingsModal__condition__list__item__or::before {
  margin-right: 10px;
}
.p-tagSettingsModal__condition__list__item__or::after {
  margin-left: 10px;
}
.p-tagSettingsModal__condition__add {
  margin-top: 16px;
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-tagSettingsModal__condition__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex > * {
  margin: 0;
}
.flex > *:not(:first-child) {
  margin-left: 12px;
}
.p-tagSettingsModal__checkboxes {
}
.p-tagSettingsModal__checkboxes > *:not(:first-child) {
  margin-top: 8px;
}
