.tag-select {
  margin-top: 8px;
  margin-left: 8px;
}
.header {
  display: flex;
  align-items: center;
  margin-top: -8px;
}
@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 8px;
    margin-top: 0;
  }
  .tag-select {
    margin-left: 0;
  }
}
