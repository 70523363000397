.g-section {
  margin: 0;
}

/* reservations */
.p-reservations {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservations__fixed__footer__margin {
  margin-bottom: 64px;
}
.p-reservations__header {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservations__tab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray300);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservations__tab li {
  width: fit-content;
  min-width: 62px;
  min-height: 40px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  padding: 10px 16px;
  cursor: pointer;
}
.p-reservations__tab li:not(:last-child) {
  border-right: 1px solid var(--gray300);
}
.p-reservations__tab li.is-active {
  color: var(--primary700);
}
.p-reservations__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservations__bottom {
  width: 100%;
  /* border-top: 1px solid var(--gray200); */
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__empty {
  text-align: center;
  padding: 64px 24px;
}
.p-reservations__fixed {
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
    0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: bottom 0.15s linear, opacity 0.15s linear;
}
.width {
  width: 100%;
}
.is-active {
  bottom: 0;
  opacity: 1;
  transition-delay: 0.2s;
}
.p-reservations__fixed > p {
  font-size: var(--text-base-size);
  font-weight: var(--text-normal);
  line-height: var(--text-base-height);
  margin-right: 64px;
}
.p-reservations__fixed > p b {
  font-size: var(--text-xl-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xl-height);
}
.p-reservations__fixed > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-reservations__fixed > div > *:not(:first-child) {
  margin-left: 12px;
}

@media screen and (max-width: 768px) {
  .p-reservations {
    width: 100%;
    border-radius: var(--radius-none);
    border: none;
    background-color: transparent;
  }
  .p-reservations__header {
    padding: 16px 0;
  }
  .p-reservations__bottom {
    padding: 16px 0;
  }
  .p-reservations__fixed {
    width: 100%;
    height: 96px;
    padding: 0 32px;
  }
  .p-reservations__fixed > p {
    font-size: var(--text-xs-size);
    font-weight: var(--text-normal);
    line-height: var(--text-xs-height);
    position: relative;
    left: 0;
    margin-right: auto;
    min-width: 120px;
  }
  .p-reservations__fixed > p span {
    display: block;
  }
  .p-reservations__fixed > p b {
    font-size: var(--text-base-size);
    font-weight: var(--text-bold);
    line-height: var(--text-base-height);
  }
  .p-reservations__fixed > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 375px) {
  .p-reservations__header {
    flex-wrap: wrap;
  }
  .p-reservations__actions {
    margin-left: 0;
    margin-top: 16px;
  }
}

/* For table */
.c-tableFrame {
  position: relative;
}
.c-table {
  /* overflow: auto; */
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
.c-table.thead {
  overflow-x: hidden;
}
.c-table.tbody {
  overflow-x: auto;
}
.c-table__body {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  word-wrap: break-word;
}
.c-table__body thead th {
  font-size: var(--text-xs-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-xs-height);
  text-align: left;
  color: var(--gray600);
  background-color: var(--gray50);
  border-top: 1px solid var(--gray200);
  border-bottom: 1px solid var(--gray200);
  padding: 16px;
}
.c-table__body thead th > i {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 8px;
  color: var(--gray500);
}
.c-table__body thead th.is-active {
  color: var(--primary700);
}
.c-table__body thead th.is-active > i {
  color: var(--primary700);
}
.c-table__body tbody tr:not(:first-child) th,
.c-table__body tbody tr:not(:first-child) td {
  border-top: 1px solid var(--gray200);
}
.c-table__body tbody tr:hover td {
  background-color: var(--primary50);
}
.c-table__body tbody th,
.c-table__body tbody td {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  background-color: var(--white);
  padding: 16px;
}
.c-table__body tbody th a,
.c-table__body tbody td a {
  color: var(--primary600);
  text-decoration: underline;
  /* Prevent content from overflowing */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thead__th {
  min-width: 180px;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
}
.thead__th.clickable {
  cursor: pointer;
}
.thead__th .sorted {
  color: var(--primary700);
}
.thead__th > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.thead__th .thead_subtext {
  color: var(--gray500);
}
.tbody__td {
  min-width: 180px;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
}

.sticky-top {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.sticky-left {
  position: sticky;
  left: 0;
  z-index: 2;
}
.sticky-left::after {
  content: '';
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.05);
}
table thead .sticky-left {
  z-index: 4;
}

.c-table__body thead th i + p {
  font-size: var(--text-xs-size) !important;
  font-weight: var(--text-semibold) !important;
  line-height: var(--text-xs-height) !important;
}
.c-table__body tbody td i + p {
  font-size: var(--text-sm-size) !important;
  font-weight: var(--text-normal) !important;
  line-height: var(--text-sm-height) !important;
}
.c-table__more p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.c-table__more a {
  color: var(--primary700) !important;
  margin-top: 8px;
  display: inline-block;
}

.c-loading {
  padding: 16px;
}
.c-table.tbody.noOverflow {
  overflow-x: unset;
}
.tbody__td.noOverflow {
  overflow: visible;
}

/* Add extra padding for scroll buttons except when no buttons are preferred */
.c-tableFrame.arrow table th:first-child:not(.c-noScrollButtons),
.c-tableFrame.arrow table td:first-child:not(.c-noScrollButtons) {
  padding-left: calc(12px + 28px);
}
.c-tableBtn {
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.c-tableBtn.left {
  left: 0;
}
.c-tableBtn.right {
  transform: scale(-1, 1);
  right: 0;
}
.c-tableBtn a {
  width: 28px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  /* top: 64px; */
  /* margin-top: 64px; */
  top: calc(50% - 40px);
  cursor: pointer;
}
.c-tableBtn a i {
  font-size: 24px;
  color: var(--white);
  display: block;
}

.c-table__sp {
  display: none;
}

/* Size */
.short {
  width: 120px;
}
.middle {
  width: 180px;
}
.long {
  width: 220px;
}

@media screen and (max-width: 768px) {
  .p-reservations__fixed__footer__margin {
    margin-bottom: 0;
  }
  .c-table.thead {
    display: none;
  }
  .c-table__body tbody tr:hover td {
    background-color: transparent;
  }
  .c-table tr {
    width: 100%;
    border-radius: var(--radius-xl);
    border: 1px solid var(--gray200);
    background-color: var(--white);
    display: block;
    overflow: hidden;
  }
  .c-table tr:not(:first-child) {
    margin-top: 8px;
  }
  .c-table td {
    width: 100% !important;
    border: none !important;
    display: flex;
    justify-content: flex-end;
  }
  .c-table td::before {
    content: attr(data-text) '';
    font-size: var(--text-xs-size);
    font-weight: var(--text-medium);
    line-height: var(--text-xs-height);
    padding-right: 12px;
    margin-right: auto;
    display: block;
    flex-shrink: 0;
  }
  .c-table td.c-table__spHeader {
    border-bottom: 1px solid var(--gray200) !important;
    justify-content: flex-start !important;
  }
  .c-table td.c-table__spHeader::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-table td.c-table__spHeader::after {
    width: 20px;
    height: 20px;
    box-shadow: none;
    font-family: 'icon-arrows-solid' !important;
    font-style: normal;
    font-size: 20px;
    color: var(--primary600);
    content: '\b454';
    position: absolute;
    top: calc(50% - 10px);
    right: 8px;
  }
  .c-table td.c-table__spHeader label {
    margin-right: 12px;
  }
  .c-table td.c-table__spHeader label p {
    font-size: var(--text-base-size) !important;
    font-weight: var(--text-semibold) !important;
    line-height: var(--text-base-height) !important;
  }

  .c-table__more a {
    float: right;
  }
  .c-table td.c-table__detail {
    border-top: 1px solid var(--gray200) !important;
    justify-content: center;
    padding: 8px 16px;
  }
  .c-table td.c-table__detail a {
    /* font-size: var(--text-sm-size);
    font-weight: var(--text-semibold);
    line-height: var(--text-sm-height); */
    text-decoration: none;
  }
  .c-table td.c-table__detail::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .c-tableBtn {
    display: none;
  }
  .c-table__sp {
    display: block;
  }
  .c-tableFrame.arrow table th:first-child,
  .c-tableFrame.arrow table td:first-child {
    padding-left: 16px;
  }
  .sticky-left::after {
    box-shadow: none;
  }
  .thead__th {
    max-width: none;
  }
  .tbody__td {
    max-width: none;
  }
  .c-tableFrame.arrow table th:first-child:not(.c-noScrollButtons),
  .c-tableFrame.arrow table td:first-child:not(.c-noScrollButtons) {
    padding-left: 16px;
  }

  .tbody__td.noOverflow {
    overflow-y: scroll;
    min-height: 150px;
  }
}
