.p-dashboardData__tab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-dashboardData__tab li {
  width: 100%;
  min-width: 62px;
  min-height: 40px;
  color: var(--gray400);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  text-align: center;
  padding: 10px 16px;
  flex-shrink: 1;
  cursor: pointer;
}
.p-dashboardData__tab li:not(:last-child) {
  border-right: 1px solid var(--gray200);
}
.p-dashboardData__tab li.is-active {
  color: var(--primary700);
}
.p-dashboardData__tabContent {
  margin-top: 20px;
}
.p-dashboardData__tabContent > * {
  display: none;
}
.p-dashboardData__tabContent > *.is-active {
  display: block;
}
