.company-name-box {
  padding-right: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button-box {
  margin-top: 24px;
}

.button-box + .button-box {
  margin-left: 8px;
}

.input-box {
  max-width: 400px;
}

.input-box + .input-box {
  margin-top: 16px;
}

.notification-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.notification-time div {
  max-width: 150px;
}

.notification-time div {
  max-width: 150px;
}

.scheds__body__content {
  width: 100%;
}
.scheds__body__content a {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .notification-time {
    display: block;
  }
  .notification-time div {
    max-width: 800px;
    padding: 1px;
  }
}

.add__button {
  width: max-content;
  color: var(--primary700);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
}
.add__button i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

.p-topPage-box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--gray50);
  padding: 16px;
}

.p-topPage-sortList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.g-section {
}

.p-general-products__section {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-general-products__section:not(:first-child) {
  margin-top: 30px;
}
.p-general-products__section__header {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-products__section__ttl {
  font-size: var(--text-lg-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-lg-height);
}
.p-general-products__section__ttl > span {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 4px;
  display: block;
}
.p-general-products__section__ic {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-general-products__section__body {
  border-top: 1px solid var(--gray200);
  padding: 24px;
  display: none;
}
.p-general-products__section__body.is-active {
  display: block;
}
.p-general-products__section__body__textarea textarea {
  min-height: 44px;
}
.p-general-products__section__body__textarea2 textarea {
  min-height: 120px;
}

.p-general-products__section__body__item:not(:first-child) {
  margin-top: 14px;
}

.p-general-products__section__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.p-general-products__section__body__item__txt {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  display: block;
}

.p-general-products__section__body__item.flex > *:not(:last-child) {
  margin-right: 16px;
}

.p-general-products__section__body__item small {
  padding-left: 45px;
  display: block;
  color: var(--gray500);
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

.p-general-products__section__body__youtube i {
  color: #EB361C;
}

.p-general-products__section__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-general-products__section__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .p-general-products__section__header {
    padding: 16px;
  }
  .p-general-products__section__body {
    padding: 16px;
  }
}

.p-general-list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-general-list__item:not(:first-child) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--gray200);
}
.p-general-list__item__ttl {
  width: 240px;
  color: var(--gray500);
  flex-shrink: 0;
  margin-right: 32px;
}
.p-general-list__item__ttl.full {
  width: 100%;
  margin-bottom: 4px;
}
.p-general-list__item__ttl__txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-list__item__ttl__txt > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-list__item__ttl__txt > div p {
  margin-left: 8px;
}
.p-general-list__item__ttl__txt span {
  content: attr(data-required);
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--white);
  background-color: var(--warning500);
  border-radius: var(--radius-full);
  padding: 2px 6px;
  margin-left: auto;
}
.p-general-list__item__ttl > span {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 4px;
}
.p-general-list__item__body {
  width: calc(100% - 240px - 32px);
}
.p-general-list__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -12px;
}
.p-general-list__item__body.flex > * {
  margin-top: 12px;
}
.p-general-list__item__body.flex > *:not(:last-child) {
  margin-right: 12px;
}
.p-general-list__item__body.flex > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}
.p-general-list__item__body.flex > div:not(:last-child) {
  margin-right: 12px;
}
.p-general-list__item__body.flex > div > div:last-child {
  margin-left: 4px;
}
.p-general-list__item__body.flex > p {
  width: 100%;
}
.p-general-list__item__body__flex {
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-list__item__body__flex__main {
  width: 100%;
}
.p-general-list__item__body__flex__actions {
  width: 36px;
  margin-left: 8px;
  flex-shrink: 0;
}
.p-general-list__item__body__flex > *:not(.p-general-list__item__body__flex__main),
.p-general-list__item__body__flex > *:not(.p-general-list__item__body__flex__actions) {
  margin-right: 12px;
}
.p-general-list__item__body__flex > *:last-child {
  margin-right: 0;
}
.p-general-list__item__body__flex.full {
  width: 100%;
}
.p-general-list__item__body__flex.full > * {
  width: 100%;
  flex-shrink: 1;
}
.p-general-list__item__body__photo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: -16px;
}
.p-general-list__item__body__photo li {
  width: 128px;
  height: 128px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
}
.p-general-list__item__body__photo li img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.p-general-list__item__body__photo li a {
  width: 24px;
  height: 24px;
  background-color: rgba(0,0,0,0.6);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
}
.p-general-list__item__body__photo li a i {
  font-size: 16px;
  color: var(--white);
}
.p-general-list__item__body__photo li.add {
  border: 1px dashed var(--primary700);
  color: var(--primary700);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.p-general-list__item__body__photo li.add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
.p-general-list__item__body__photo li.add p {
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  margin-top: 10px;
}
.p-general-list__item__body__list:not(:first-child) {
  margin-top: 16px;
}
.p-general-list__item__body__list__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-bottom: 4px;
}

.p-general-list__item__body__dot {
  padding-left: 50px;
  margin-top: 0px;
}

.p-general-list__item__body__dot li {
  color: var(--gray500);
  font-size: var(--text-xs-size);
  line-height: var(--text-xs-height);
  padding-left: 1em;
  text-indent: -1.5em;
}

.p-general-list__item__body__dot li i {
  position: relative;
  padding-right: 5px;
}

.p-general-list__item__body__dot li i::before{
  content: "・";
  color: var(--gray500);
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}

.p-general-acBox__body__list:not(:first-of-type) {
  margin-top: 10px;
}

.p-general-products__section__list:not(:first-of-type) {
  margin-top: 30px;
}

.p-general-products__section__list__term span{
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
}

.p-general-acBox__body__list__term span{
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
}

.p-general-products__section__list__desc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 20px;
}

.p-general-products__section__list__desc--narrow {
  margin-top: 8px;
}

.p-general-acBox__body__list__desc__txt {
  color: var(--gray500);
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  margin-top: 7px;
  margin-bottom: 9px;
}

.p-general-list__item.row {
  flex-direction: column;
}
.p-general-list__item.row > .p-general-list__item__ttl {
  width: 100%;
  margin-bottom: 16px;
  margin-right: 0;
}
.p-general-list__item.row > .p-general-list__item__body {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .p-general-list__item {
    flex-direction: column;
  }
  .p-general-list__item__ttl {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .p-general-list__item__ttl > p {
    width: 100%;
  }
  .p-general-list__item__ttl > p span {
    margin-left: 8px;
  }
  .p-general-list__item__body {
    width: 100%;
  }
  .p-general-list__item__body__photo li {
    width: 100px;
    height: 100px;
  }
  .p-general-list__item__body__flex.full {
    flex-direction: column;
    margin-top: 16px;
  }
  .p-general-list__item__body__flex.full > * {
    margin-right: 0;
  }
  .p-general-list__item__body__flex.full > *:not(:first-child) {
    margin-top: 16px;
  }
}


.p-general-frame {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
}
.p-general-frame:not(:first-child) {
  margin-top: 16px;
}
.p-general-frame__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-frame__header__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
}
.p-general-frame__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-general-frame__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-general-frame__body {
  border-top: 1px solid var(--gray200);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  padding: 20px;
  display: none;
}
.p-general-frame__body.gray {
  background-color: var(--gray50);
}
.p-general-frame__body.is-active {
  display: block;
}
.p-general-frame__body__note {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
  margin-top: 6px;
}


.p-general-box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--gray50);
  padding: 16px;
}
.p-general-box.padding-0 {
  padding: 0;
}
.p-general-box:not(:first-child) {
  margin-top: 16px;
}
.p-general-box__header {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-general-box__table {
  width: 100%;
  display: table;
  margin-top: 16px;
}
.p-general-box__table__header {
  display: table-row;
}
.p-general-box__table__body {
  display: table-row;
}
.p-general-box__table__item {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 8px;
}
.p-general-box__table__item:not(:last-child) {
  padding-right: 12px;
}
.p-general-box__table__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
}
.p-general-box__table__item__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-box__table__item__flex > * {
  flex-shrink: 1;
}
.p-general-box__table__item__flex > span {
  padding: 0 8px;
}
.p-general-box__table__add {
  width: max-content;
  color: var(--primary700);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
}
.p-general-box__table__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.p-general-box__table2 {

}
.p-general-box__table2__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray200);
}
.p-general-box__table2__header > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  padding-right: 16px;
}
.p-general-box__table2__body {
  border-bottom: 1px solid var(--gray200);
  padding: 16px;
}
.p-general-box__table2__body__item {
  width: 100%;
}
.p-general-box__table2__body__item:not(:first-child) {
  margin-top: 16px;
}
.p-general-box__table2__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.p-general-box__table2__body__item.flex > div {
  padding-right: 16px;
  flex-shrink: 0;
}
.p-general-box__table2__body__item.flex > div.right {
  width: 100%;
  flex-shrink: 1;
  padding-right: 0;
}
.p-general-box__table2__body__item.flex > div.right > * {
  margin-left: auto;
}
.p-general-box__table2__body__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-bottom: 4px;
}
.p-general-box__table2__body__item__body {

}
.p-general-box__table2__body__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-box__table2__body__item__body.flex > *:not(:last-child) {
  margin-right: 8px;
}
.p-general-box__table2__body__item__body.flex > div {
  flex-shrink: 0;
}
.p-general-box__table2__body__item__box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px;
}
.p-general-box__table2__body__item__box__table {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.p-general-box__table2__body__item__box__table__header,
.p-general-box__table2__body__item__box__table__body {
  display: table-row;
}
.p-general-box__table2__body__item__box__table__item {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 8px;
}
.p-general-box__table2__body__item__box__table__item:not(:last-child) {
  padding-right: 12px;
}
.p-general-box__table2__body__item__box__table__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-bottom: 4px;
}

.p-general-box__table2__actions {
  padding: 16px;
}
.p-general-box__table2__actions__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-box__table2__actions__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
@media screen and (max-width: 768px) {
  .p-general-box__table {
    display: block;
  }
  .p-general-box__table__body {
    width: 100%;
    border-radius: var(--radius-md);
    border: 1px solid var(--gray200);
    background-color: var(--white);
    padding: 16px;
    margin-bottom: 16px;
    display: block;
  }
  .p-general-box__table__item {
    display: block;
    padding-bottom: 0;
  }
  .p-general-box__table__item:not(:first-child) {
    margin-top: 16px;
  }
  .p-general-box__table__item:not(:last-child) {
    padding-right: 0;
  }
  .p-general-box__table__item__ttl {
    margin-bottom: 6px;
  }
  .p-general-box__table2__body__item.flex {
    flex-direction: column;
  }
  .p-general-box__table2__body__item.flex > div {
    width: 100%;
    padding-right: 0;
  }
  .p-general-box__table2__body__item.flex > div:not(:first-child) {
    margin-top: 16px;
  }
  .p-general-box__table2__body__item__body.spStock {
    flex-direction: column;
  }
  .p-general-box__table2__body__item__body.spStock fieldset {
    width: 100% !important;
  }
  .p-general-box__table2__body__item__body.spStock > div {
    width: 100%;
    margin-top: 8px;
    margin-right: 0 !important;
  }
  .p-general-box__table2__body__item__box__table {
    display: block;
  }
  .p-general-box__table2__body__item__box__table__header,
  .p-general-box__table2__body__item__box__table__body {
    display: block;
  }
  .p-general-box__table2__body__item__box__table__item {
    display: block;
    padding-right: 0 !important;
  }
}


.p-general-box__list {
  /*margin-top: 16px;*/
}
.p-general-box__list__item {

}
.p-general-box__list__item:not(:first-child) {
  margin-top: 12px;
}
.p-general-box__list__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-bottom: 6px;
}
.p-general-box__list__item__body {

}
.p-general-box__list__item__body__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-box__list__item__body__flex.wrap {
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.p-general-box__list__item__body__flex.wrap > * {
  margin-bottom: 12px;
}
.p-general-box__list__item__body__flex.full {
  width: 100%;
}
.p-general-box__list__item__body__flex.full > * {
  width: 100%;
  flex-shrink: 1;
}
.p-general-box__list__item__body__flex:not(:first-child) {
  margin-top: 12px;
}
.p-general-box__list__item__body__flex__main {
  width: 100%;
  margin-right: 12px;
}
.p-general-box__list__item__body__flex__actions {
  flex-shrink: 0;
}
.p-general-box__list__item__body__flex > *:not(.p-general-box__list__item__body__flex__main),
.p-general-box__list__item__body__flex > *:not(.p-general-box__list__item__body__flex__actions) {
  margin-right: 12px;
}
.p-general-box__list__item__body__flex > *:last-child {
  margin-right: 0;
}

.p-general-box__list__item__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-general-box__list__item__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
@media screen and (max-width: 768px) {
  .p-general-box__list__item__body__flex.full {
    flex-direction: column;
    margin-top: 16px;
  }
  .p-general-box__list__item__body__flex.full > * {
    margin-right: 0;
  }
  .p-general-box__list__item__body__flex.full > *:not(:first-child) {
    margin-top: 16px;
  }
}

.p-general-box__nomalFlex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-general-box__nomalFlex > div {
  flex-shrink: 0;
}
.p-general-box__nomalFlex > div:not(:last-child) {
  margin-right: 32px;
}
@media screen and (max-width: 768px) {
  .p-general-box__nomalFlex {
    flex-direction: column;
  }
  .p-general-box__nomalFlex > div {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0 !important;
  }
}


.p-general-acBox {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  overflow: hidden;
}
.p-general-acBox:not(:first-child) {
  margin-top: 30px;
}
.p-general-acBox__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.p-general-acBox__header__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-acBox__header__ttl__ic {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-general-acBox__header__ttl__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-general-acBox__header__ttl__ic i::before,
.p-general-acBox__header__ttl__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-general-acBox__header__ttl__txt {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  color: var(--gray900);
}
.p-general-acBox__header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.p-general-acBox__header__actions > * {
  margin-left: 12px;
}
.p-general-acBox__body {
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  border-top: 1px solid var(--gray200);
  padding: 21px 23px;
  display: none;
}
.p-general-acBox__body.is-active {
  display: block;
}

.p-general-acBox__body .p-general-acBox {
  margin-top: 14px;
}
@media screen and (max-width: 768px) {
  .p-general-acBox__header__actions a > p {
    display: none;
  }
  .p-schedSpWidth {
    width: 100%;
    margin-right: 0 !important;
  }
  .p-schedSpWidth > * {
    flex-shrink: 1;
    width: 100%;
  }
  .p-schedSpWidth > *:not(:last-child) {

  }
}

.p-general-products__fixed {
  width: 100%;
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06), 0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: bottom 0.2s linear;
}
.p-general-products__fixed.is-active {
  bottom: 0;
  opacity: 1;
}
.p-general-products__fixed__main {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 32px;
}
.p-general-products__fixed__main__ttl {
  margin-left: 8px;
}
.p-general-products__fixed__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-general-products__fixed__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-general-products__fixed__actions__select {
  position: relative;
}
.p-general-products__fixed__actions__select__menu {
  width: 240px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  bottom: 40px;
  right: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
}
.p-general-products__fixed__actions__select__menu.is-active {
  display: block;
}
.p-general-products__fixed__actions__select__menu__item {
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.p-general-products__fixed__actions__select__menu__item:hover {
  background-color: var(--gray50);
}
.p-general-products__fixed__actions__select__menu__item p {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
@media screen and (max-width: 768px) {
  .p-general-products__fixed {
    width: 100% !important;
    padding: 0 16px;
  }
  .p-general-products__fixed__actions {
    width: 100%;
  }
  .p-general-products__fixed__actions > *:first-child {
    margin-right: auto;
  }
}


/*  dropdown(3点リーダー有)
--------------------------------------------- */
/* dropdown */
.p-general-dropdown {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.p-general-dropdown__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.p-general-dropdown__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}
.p-general-dropdown__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.p-general-dropdown__body > i::before {
  line-height: 20px;
}
.p-general-dropdown__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.p-general-dropdown__body label {
  width: 100%;
  position: relative;
}
.p-general-dropdown__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.p-general-dropdown__body__selected__input {
  /* width: fit-content; */
  display: none;
}

.p-general-dropdown__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-general-dropdown__body__selected ul {
  display: block;
  margin-bottom: -4px;
}
.p-general-dropdown__body__selected ul li {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  background-color: var(--gray100);
  border-radius: var(--radius-sm);
  padding: 3px 4px 3px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}
.p-general-dropdown__body__selected ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.p-general-dropdown__body__selected ul li i::before {
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  color: var(--gray400);
  content: "\e9c8";
}

.p-general-dropdown.is-hover {
  border-color: var(--primary200);
}
.p-general-dropdown.is-active {
  border-color: var(--primary700);
}
.p-general-dropdown.is-input .p-general-dropdown__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.p-general-dropdown.is-active .p-general-dropdown__body__title {
  color: var(--primary700);
}

.p-general-dropdown__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.p-general-dropdown__menu {
  width: 100%;
  min-width: 160px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
  overflow-y: auto;
}
.p-general-dropdown__menu.overflow-no {
  overflow-y: visible;
}
.p-general-dropdown__menu.is-active {
  display: block;
}
.p-general-dropdown__menu__item {
  padding: 8px 10px;
  position: relative;
}
.p-general-dropdown__menu__item:hover {
  background-color: var(--gray50);
}
.p-general-dropdown__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.p-general-dropdown__menu__item i {
  opacity: 0;
}
.p-general-dropdown__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.p-general-dropdown__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.p-general-dropdown__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  text-align: left;
}
.p-general-dropdown__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}

/* override */
.p-general-dropdown__body__selected ul li span{
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.p-general-dropdown__body {
  padding: 8px 42px 8px 12px;
  flex-direction: column;
}




/* p-general-sortList */
.p-general-sortList {
  position: relative;
}
.p-general-sortList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-general-sortList__item:not(:first-child) {
  margin-top: 8px;
}
.p-general-sortList__item.is-disabled {
  color: var(--gray400);
  background-color: var(--gray50);
  pointer-events: none;
}
.p-general-sortList__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-general-sortList__item__left p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-general-sortList__item__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-general-sortList__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-general-sortList__item__ic i::before,
.p-general-sortList__item__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-general-sortList__item__right {
  margin-left: auto;
}
.p-general-sortList__item__right > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}

/*  p-general-badges
--------------------------------------------- */
.p-general-badges {
  content: attr(data-required);
  font-size: var(--text-xs-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xs-height);
  color: var(--white);
  border-radius: var(--radius-full);
  padding: 6px 8px;
  margin-left: auto;
  white-space: nowrap;
}
.p-general-badges--blue {
  background-color: var(--primary600);
}

.p-general-badges--green {
  background-color: var(--success500);
}

.p-general-badges--orange {
  background-color: var(--warning500);
}

.p-general-badges--gray {
  background-color: var(--gray500);
}


/*  p-general__msg
--------------------------------------------- */

.p-general__msg {
  width: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--warning600);
  border: 1px solid var(--warning600);
  border-radius: var(--radius-xl);
  background-color: var(--warning50);
  padding: 16px;
  margin-bottom: 24px;
}
.p-general__msg__ttl {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.p-general__msg__ttl i {
  font-size: 20px;
  margin-right: 16px;
}
.p-general__msg__ttl p {
  font-weight: var(--text-semibold);
}
.p-general__msg__list {
  padding-left: 24px;
  margin-left: 36px;
}
.p-general__msg__list li {
  list-style: disc;
  margin-top: 8px;
}
.p-general__msg__list li a {
  text-decoration: underline;
}

/*  placeholder
--------------------------------------------- */
::placeholder {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
}
