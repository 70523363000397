.orderCount {
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  color: #393d3f;
  margin: 0 0 16px;
}

.chartContainer {
  display: flex;
  height: 200px;
}

.yAxis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 8px;
  font-size: 12px;
  color: #b5b8bc;
}

.chart {
  flex: 1;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  border-bottom: 1px solid #dadde2;
  padding-bottom: 8px;
}

.bar {
  flex: 1;
  background-color: #3e8cc4;
  border-radius: 4px 4px 0 0;
  transition: height 0.3s ease;
}

.xAxis {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  color: #b5b8bc;
}

@media (max-width: 991px) {
  .chartContainer {
    height: 150px;
  }

  .chart {
    gap: 8px;
  }
}
