.container {
  z-index: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 24px;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
}

.chartContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .chartContainer {
    max-width: 100%;
  }
}

.chartWrapper {
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .chartWrapper {
    max-width: 100%;
  }
}

.chartInner {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

@media (max-width: 991px) {
  .chartInner {
    max-width: 100%;
  }
}

.chartContent {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .chartContent {
    max-width: 100%;
  }
}

.chartImageContainer {
  z-index: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .chartImageContainer {
    max-width: 100%;
  }
}

.chartImageWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.chartImageInner {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.chartImageBorder {
  background-color: #f3f6f8;
  min-height: 1px;
  width: 100%;
  border: 1px solid rgba(243, 246, 248, 1);
}

.chartImage {
  aspect-ratio: 500;
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #dadde2;
  margin-top: 32px;
}

.barChartContainer {
  z-index: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.barChart {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.bar {
  background-color: #e6ebff;
  z-index: 0;
  display: flex;
  width: 11px;
  cursor: pointer;
}

.highlightedBar {
  background-color: #3aa0ff;
}

.dashedLine {
  background-color: #dadde2;
  align-self: start;
  position: absolute;
  z-index: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  border: 1px dashed rgba(218, 221, 226, 1);
}

.busyInfoContainer {
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  color: #393d3f;
  justify-content: space-between;
  font: 400 16px Inter, sans-serif;
}

@media (max-width: 991px) {
  .busyInfoContainer {
    max-width: 100%;
  }
}

.busyInfo {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  width: 263px;
  gap: 4px;
  margin: auto 0;
}

.busyInfoIcon {
  aspect-ratio: 1.08;
  object-fit: contain;
  object-position: center;
  width: 13px;
  margin: auto 0;
}

.busyInfoText {
  flex-grow: 1;
  width: 243px;
}

.boldBlue {
  font-weight: 700;
  color: #3aa0ff;
}

.bold {
  font-weight: 700;
}
