.p-dashboardModal {
}
.p-dashboardModal__item:not(:first-child) {
  margin-top: 20px;
}
.p-dashboardModal__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-m-height);
  margin-bottom: 6px !important;
}
.p-dashboardModal__item__ttl span {
  font-weight: var(--text-normal);
  color: var(--gray600);
  display: block;
}
.p-dashboardModal__item__body {
}
.p-dashboardModal__item__body__note {
  width: 100%;
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
  background-color: var(--gray50);
  border-radius: var(--radius-md);
  padding: 16px;
  margin-top: 12px;
}
.p-dashboardModal__item__body__note__ttl {
  font-weight: var(--text-semibold);
}
.p-dashboardModal__item__body__note__body {
  margin-top: 12px;
}
.p-dashboardModal__item__body__note__body > ul {
}
.p-dashboardModal__item__body__note__body > ul > li:not(:first-child) {
  margin-top: 16px;
}
.p-dashboardModal__item__body__sort {
  margin-top: 20px;
}
.p-dashboardModal__item__body__sort__list {
}
.p-dashboardModal__item__body__sort__list > *:not(:first-child) {
  margin-top: 8px;;
}
.p-dashboardModal__item__flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.p-dashboardModal__item__radio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px 8px;
}
@media screen and (max-width: 768px) {
  .p-dashboardModal__item__radio {
    grid-template-columns: 1fr;
  }
}
.p-dashboardModal__item__targetDates {
  display: flex;
  align-items: center;
  width: 100%;
}
.p-dashboardModal__item__targetDates > *:not(:first-child) {
  margin-left: 4px;
}
.p-sortList {
  background-color: var(--gray50);
  border-radius: var(--radius-md);
  padding: 16px;
  margin-top: 12px;
  position: relative;
}
.p-sortList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item:not(:first-child) {
  margin-top: 12px;
}
.p-sortList__item.is-disabled {
  color: var(--gray400);
  background-color: var(--gray50);
  pointer-events: none;
}
.p-sortList__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-sortList__item__left p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-sortList__item__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-sortList__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-sortList__item__ic i::before,
.p-sortList__item__ic i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-sortList__item__right {
  margin-left: auto;
}
.p-sortList__item__right > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}

.p-frame {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
}
.p-frame:not(:first-child) {
  margin-top: 16px;
}
.p-frame__header {
  width: 100%;
  border-radius: var(--radius-md) var(--radius-md) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-frame__header__ttl {
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
}
.p-frame__header__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-frame__header__actions > *:not(:last-child) {
  margin-right: 12px;
}
.p-frame__body {
  border-top: 1px solid var(--gray200);
  border-radius: 0 0 var(--radius-md) var(--radius-md);
  padding: 20px;
  display: none;
}
.p-frame__body.is-active {
  display: block;
}