.tutorial__ttl {
  font-weight: bold;
  color: #008ec9;
  margin-bottom: 16px;
}
.tutorial__nav {
  width: calc(100% - 24px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tutorial__nav li {
  width: calc(100% / 3);
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  padding-left: 32px;
  font-weight: bold;
  color: #4a6c7a;
  position: relative;
}
.tutorial__nav li::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 0 24px 24px;
  position: absolute;
  top: 0;
  right: -24px;
}
.tutorial__nav li:nth-child(odd) {
  background: #fff;
}
.tutorial__nav li:nth-child(even) {
  background: #f9f9f9;
}
.tutorial__nav li:nth-child(odd)::after {
  border-color: transparent transparent transparent #fff;
}
.tutorial__nav li:nth-child(even)::after {
  border-color: transparent transparent transparent #f9f9f9;
}
.tutorial__nav li:nth-child(1) {
  z-index: 5;
}
.tutorial__nav li:nth-child(2) {
  z-index: 4;
}
.tutorial__nav li:nth-child(3) {
  z-index: 3;
}
.tutorial__nav li:nth-child(4) {
  z-index: 2;
}
.tutorial__nav li:nth-child(5) {
  z-index: 1;
}
.tutorial__nav li.is-active {
  color: #fff;
  background: #008ec9;
}
.tutorial__nav li.is-active::after {
  border-color: transparent transparent transparent #008ec9;
}
.tutorial__box {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #f9f9f9;
  padding: 40px 0;
  position: relative;
}
.tutorial__box__left {
  background: #fff;
  padding: 16px;
  position: relative;
}
.tutorial__box__left {
}
.tutorial__box__left__next {
  width: 240px;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 16px;
  background: #ffaaaa;
  margin-bottom: 8px;
}
.tutorial__box__left__desc {
  font-size: 20px;
  font-weight: bold;
  color: #008ec9;
}
.tutorial__box__right {
}
.tutorial__box::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 64px 0 0;
  border-color: #008ec9 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.tutorial__box::after {
  content: attr(data-num) '';
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.tutorial__box__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tutorial__box__pic {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background: #ebf1f3;
  margin-right: 16px;
  flex-shrink: 0;
}
.tutorial__box__pic img {
  width: inherit;
  height: inherit;
}
.tutorial__box__body {
}
.tutorial__box__body__ttl {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.tutorial__box__body__btn a {
  background: #cfdae1 !important;
  pointer-events: none;
}
.tutorial__box__body__next {
  width: 64px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  background: #ffaaaa;
  margin-top: 10px;
  position: relative;
  opacity: 0;
}
.tutorial__box__body__next::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #ffaaaa transparent;
  position: absolute;
  top: -6px;
  left: calc(50% - 6px);
}
.tutorial__box.is-active {
  border: 1px solid #008ec9;
}
.tutorial__box.is-active a {
  background: #ffcc00 !important;
  pointer-events: auto;
}
.tutorial__box.is-active .tutorial__box__body__next {
  opacity: 1;
}
.tutorial__box.is-finish {
  opacity: 0.5;
}

.tutorial__box--step2 {
  border: 4px solid #008ec9 !important;
  padding: 40px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tutorial__box--step2 .tutorial__box__left {
  width: 50%;
  max-width: 560px;
  margin-right: 64px;
}
.tutorial__box--step2 .tutorial__box__left::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 16px;
  border-color: transparent transparent transparent #008ec9;
  position: absolute;
  top: calc(50% - 16px);
  right: -40px;
}
.c-table-list {
}
.c-table-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-list table th,
.c-table-list table td {
  border: 1px solid #cfdae1;
  padding: 8px;
}
.c-table-list table th {
  width: 240px;
  font-weight: normal;
  text-align: left;
  background: #ebf1f3;
}
.c-table-list__note {
  font-size: 14px;
  margin-top: 8px;
}
.c-table-list__note.bold {
  font-weight: bold;
}
.c-table-list__note.red {
  color: #dc3e15;
}
.c-table-list__note.yellow {
  color: #ffcc00;
}
.c-table-list__note.green {
  color: #00bf98;
}
.c-table-list__note.blue {
  color: #008ec9;
}
@media screen and (max-width: 768px) {
  .tutorial__nav {
    width: calc(100% - 8px);
    margin-top: 16px;
  }
  .tutorial__nav li {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding-left: 16px;
  }
  .tutorial__nav li::after {
    border-width: 16px 0 16px 8px;
    right: -8px;
  }
  .tutorial__nav li span {
    display: none;
  }
  .c-table-list table,
  .c-table-list table tbody,
  .c-table-list table tr,
  .c-table-list table th,
  .c-table-list table td {
    width: 100%;
    display: block;
  }
  .c-table-list table tr + tr {
    margin-top: 8px;
  }
  .c-table-list table th {
    width: 100%;
    margin-bottom: -1px;
  }
  .c-table-list table td .base-t-800 {
    width: 100%;
  }
}
