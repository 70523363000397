.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}
.controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
}
.map-selector {
  width: 330px;
}
.date-range-container {
  width: 330px;
}
.gadget-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.stack {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 33%;
  height: 620px;
}
@media (min-width: 968px) {
  .page-content-custom {
    margin-top: -80px;
  }
}
