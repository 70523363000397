.container {
  border-radius: var(--Borders-Border-Radius-rounded-xl, 12px);
  border-color: rgba(228, 228, 231, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--Colors-Base-White, #fff);
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  justify-content: center;
  padding: 24px;
}
.container-inner {
  justify-content: center;
  border-radius: var(--Borders-Border-Radius-rounded-xl, 12px);
  display: flex;
  gap: 20px;
}
.stats-section {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.div-4 {
  color: var(--Colors-Gray-600, #52525b);
  align-self: stretch;
  font: 600 12px/133% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-5 {
  color: var(--Colors-Gray-700, #3f3f46);
  margin-top: 8px;
  font: 700 18px/156% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-6 {
  color: var(--Colors-Gray-500, #71717a);
  margin-top: 8px;
  font: 400 14px/143% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-7 {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 80px;
}
.stats-title {
  color: var(--Colors-Gray-600, #52525b);
  font: 600 12px/133% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-9 {
  color: var(--Colors-Gray-700, #3f3f46);
  margin-top: 8px;
  font: 700 18px/156% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-10 {
  color: var(--Colors-Gray-500, #71717a);
  margin-top: 8px;
  font: 400 14px/143% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-11 {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 30px;
}
.div-12 {
  color: var(--Colors-Gray-600, #52525b);
  align-self: stretch;
  font: 600 12px/133% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-13 {
  color: var(--Colors-Gray-700, #3f3f46);
  margin-top: 8px;
  font: 700 18px/156% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-14 {
  color: var(--Colors-Gray-500, #71717a);
  margin-top: 8px;
  font: 400 14px/143% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-15 {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  line-height: 133%;
  flex: 1;
  padding: 0 32px 8px;
}
.div-16 {
  color: var(--Colors-Gray-600, #52525b);
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  align-self: stretch;
}
.div-17 {
  color: var(--Colors-Gray-700, #3f3f46);
  margin-top: 8px;
  font: 700 18px/156% Hiragino Sans, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-18 {
  color: var(--Colors-Primary-700, #0171a3);
  font-family: Hiragino Sans, -apple-system, Roboto, Helvetica, sans-serif;
  text-decoration-line: underline;
  margin-top: 4px;
}
