.collection-element-box {
  margin-top: 10px;
  max-width: 600px;
}
.icon-box {
  padding-left: 2px;
  padding-right: 2px;
}
.input-box + .input-box {
  margin-top: 16px;
}

.products-input-box {
  width: 100%;
}
