.login-container {
  display: flex;
  justify-content: center;
}
.location-card {
  max-width: 400px;
  background: #fff;
  padding: 16px;
}
.link {
  color: #008ec9;
}
