.c-table-list {
}
.c-table-list > table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.c-table-list > table th,
.c-table-list > table td {
  border: none;
}
.c-table-list > table th {
  width: 100px;
  font-weight: normal;
  text-align: left;
  background: #ebf1f3;
}
.c-table-list > table td div.input {
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: move;
  opacity: 1;
}

.c-table-list > table td div.input__source_language {
  border-right: none;
}

.c-table-list > table td div.input__translation_language {
  border-left: none;
}

@media screen and (max-width: 768px) {
  .c-table-list > table,
  .c-table-list > table tbody,
  .c-table-list > table tr,
  .c-table-list > table th,
  .c-table-list > table td {
    width: 100%;
    display: block;
  }
  .c-table-list > table tr + tr {
    margin-top: 8px;
  }
  .c-table-list > table th {
    width: 100%;
  }
  .c-table-list > table td {
    margin-top: -1px;
  }
  .c-table-list > table td .base-t-800 {
    width: 100%;
  }
  .c-table-list__comment {
    display: none;
  }
}
