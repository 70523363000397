.table {
    border: 1px solid #ccc;
    border-collapse: collapse;
}
.table tr, .table th, .table td {
    border: 1px solid #ccc;
    padding: 8px;
    cursor: pointer;
}

.table__row {
    border: 1px solid #ccc;
}

tr.table__row--active {
    border: 3px solid #008ec9;
}

.table__row:hover {
    background: #f2f2f2;
}
