.p-siteControlWidget {
}
.p-siteControlWidget__action {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.p-siteControlWidget__action > *:not(:first-child) {
  margin-left: 6px;
}
.p-siteControlWidget__actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.p-siteControlWidget__action.delete {
  color: var(--error600)
}
.p-siteControlWidget__action:hover {
  cursor: pointer;
}
