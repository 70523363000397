.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }
}
