.duration-input {
  display: flex;
  justify-content: flex-start;
}

.duration-input > label > select {
  padding-left: 2px;
  padding-right: 20px;
}

.day-select {
  min-width: 80px;
  width: 200px;
}

.minute-select,
.hour-select {
  min-width: 80px;
  width: 128px;
}

label.sign-select {
  min-width: 40px;
  width: 100px;
}
