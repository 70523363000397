.g-section {
}

.num-of-task-base {
  align-items: center;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  height: 20px;
  justify-content: center;
  width: 20px;
  text-align: center;
}
.num-of-task-non-zero {
  background-color: var(--warning400);
}
.num-of-task-zero {
  background-color: var(--gray300);
}
.num-of-task-non-active-non-zero {
  background-color: var(--gray400);
}

/* reservations */
.p-reservations {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-reservations__fixed__footer__margin {
  margin-bottom: 64px;
}
.p-reservations__header {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservations__tab {
  background-color: var(--white);
  border-radius: var(--radius-sm);
  border: 1px solid var(--gray300);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reservations__tab li {
  width: fit-content;
  min-width: 62px;
  min-height: 40px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  padding: 10px 10px;
  cursor: pointer;
  color: var(--gray400);
}
.p-reservations__tab li:not(:last-child) {
  border-right: 1px solid var(--gray300);
}
.p-reservations__tab li.is-active {
  color: var(--primary700);
}
.p-reservations__actions {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__actions > *:not(:first-child) {
  margin-left: 12px;
}
.p-reservations__bottom {
  width: 100%;
  /* border-top: 1px solid var(--gray200); */
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.p-reservations__empty {
  text-align: center;
  padding: 64px 24px;
}
.p-reservations__fixed {
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06),
    0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: bottom 0.15s linear, opacity 0.15s linear;
}
.width {
  width: 100%;
}
.is-active {
  bottom: 0;
  opacity: 1;
  transition-delay: 0.2s;
}
.p-reservations__fixed > p {
  font-size: var(--text-base-size);
  font-weight: var(--text-normal);
  line-height: var(--text-base-height);
  margin-right: 64px;
}
.p-reservations__fixed > p b {
  font-size: var(--text-xl-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xl-height);
}
.p-reservations__fixed > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-reservations__fixed > div > *:not(:first-child) {
  margin-left: 12px;
}

.p-reservations__productNameLink {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: var(--primary600)
}
.p-reservations__productNameLink > i {
  font-size: var(--text-base-size);
  color: var(--gray500);
}
.p-reservations__productNameLink > a {
  margin-left: 4px;
}

.p-reservations__calendarLink {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.p-reservations__calendarLink > i {
  font-size: var(--text-base-size);
}
.p-reservations__calendarLink > a {
  margin-left: 4px;
}

@media screen and (max-width: 768px) {
  .p-reservations {
    width: 100%;
    border-radius: var(--radius-none);
    border: none;
    background-color: transparent;
  }
  .p-reservations__header {
    padding: 16px 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .p-reservations__header > div {
    margin-left: 0;
    margin-top: 16px
  }
  .p-reservations__bottom {
    padding: 16px 0;
  }
  .p-reservations__fixed {
    width: 100%;
    height: 96px;
    padding: 0 32px;
  }
  .p-reservations__fixed > p {
    font-size: var(--text-xs-size);
    font-weight: var(--text-normal);
    line-height: var(--text-xs-height);
    position: relative;
    left: 0;
    margin-right: auto;
    min-width: 120px;
  }
  .p-reservations__fixed > p span {
    display: block;
  }
  .p-reservations__fixed > p b {
    font-size: var(--text-base-size);
    font-weight: var(--text-bold);
    line-height: var(--text-base-height);
  }
  .p-reservations__fixed > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 375px) {
  .p-reservations__header {
    flex-wrap: wrap;
  }
  .p-reservations__actions {
    margin-left: 0;
    margin-top: 16px;
  }
}

/* Column size */
.short {
  width: 120px;
}
.middle {
  width: 180px;
}
.long {
  width: 220px;
}

/* Copied from src/client/base.module.css as this is not handled in new UI/UX CSS */
@media screen and (max-width: 768px) {
  .base-t-spHidden {
    display: none !important;
  }
}

.table--link {
  display: flex;
  align-items: center;
}
.table--tab {
  padding-right: 5px;
}
@media screen and (max-width: 768px) {
  .table--tab {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 70px;
  }
  .table--link {
    max-width: 85px;
  }
}
