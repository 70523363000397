.p-topPage-box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--gray50);
  padding: 16px;
}
.p-topPage-box.padding-0 {
  padding: 0;
}
.p-topPage-box:not(:first-child) {
  margin-top: 16px;
}

/* p-topPage-sortList */
.p-topPage-sortList {
  position: relative;
}
.p-topPage-sortList__item {
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 12px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .p-topPage-sortList__item {
    /* flex-wrap: wrap; */
  }
}
.p-topPage-sortList__item:not(:first-child) {
  margin-top: 8px;
}
.p-topPage-sortList__item.is-disabled {
  color: var(--gray400);
  background-color: var(--gray50);
  pointer-events: none;
}
.p-topPage-sortList__item__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
}
.p-topPage-sortList__item__left p {
  font-size: var(--text-sm-size);
  font-weight: var(--text-normal);
  line-height: var(--text-sm-height);
}
.p-topPage-sortList__item__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-topPage-sortList__item__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-topPage-sortList__item__ic i::before,
.p-topPage-sortList__item__ic i::after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-topPage-sortList__item__right {
  margin-left: auto;
}
.p-topPage-sortList__item__right > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
}

/*  p-topPage-badges
--------------------------------------------- */
.p-topPage-badges {
  content: attr(data-required);
  font-size: var(--text-xs-size);
  font-weight: var(--text-bold);
  line-height: var(--text-xs-height);
  color: var(--white);
  border-radius: var(--radius-full);
  padding: 6px 8px;
  margin-left: auto;
  white-space: nowrap;
}
.p-topPage-badges--blue {
  background-color: var(--primary600);
}

.p-topPage-badges--green {
  background-color: var(--success500);
}

.p-topPage-badges--orange {
  background-color: var(--warning500);
}

.p-topPage-badges--gray {
  background-color: var(--gray500);
}

/*  placeholder
--------------------------------------------- */
::placeholder {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
}

/*  body
--------------------------------------------- */
@media screen and (min-width: 1025px) {
  body {
    min-width: 1370px;
  }
}

.p-box__table3 {
  width: 100%;
}

.p-box__table__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}
.p-box__table__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
