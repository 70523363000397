.g-section {
}

/* p-inquiry-tab */
.p-inquiry-tabs {
  width: 100%;
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
}
.p-inquiry-tabs__head {
  padding: 16px 24px 0;
  width: 100%;
  border-bottom: 1px solid var(--gray200);
}
.p-inquiry-tabs__tab-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.p-inquiry-tabs__tab-scroll::-webkit-scrollbar{
  display: none;
}

.p-inquiry-tabs__tab-list {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  width: fit-content;
}

.p-inquiry-tabs__tab {
  position: relative;
  padding-inline: 4px;
  padding-bottom: 12px;
  color: var(--gray500);
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  cursor: pointer;
  white-space: nowrap;
}

.p-inquiry-tabs__tab:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
}

.p-inquiry-tabs__tab.components_is-active__B15DT {
  color: var(--primary700);
  border-bottom: 2px solid var(--primary700);
}

.p-inquiry-tabs__tab.components_is-active__B15DT:after {
  background-color: var(--primary700);
}

.p-inquiry-tabs__content {
  padding: 16px 24px;
}

/*  p-inquiry-products__section__body__item
--------------------------------------------- */

.p-inquiry-products__section__body__item:not(:first-of-type) {
    margin-top: 16px;
}


/*  p-inquiry-list
--------------------------------------------- */
.p-inquiry-list__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-inquiry-list__item:not(:first-child) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--gray200);
}
.p-inquiry-list__item__ttl {
  width: 240px;
  color: var(--gray500);
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  flex-shrink: 0;
  margin-right: 32px;
}
.p-inquiry-list__item__ttl__txt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-inquiry-list__item__ttl__txt > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-inquiry-list__item__body {
  width: calc(100% - 240px - 32px);
}
.p-inquiry-list__item__body__list__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
  color: var(--gray500);
  margin-bottom: 4px;
}
.p-inquiry-list__item__body__item {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px 24px;
}
.p-inquiry-list__item__body__item:not(:first-child) {
  margin-top: 14px;
}
.p-inquiry-list__item__add {
  width: max-content;
  color: var(--primary700);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
  gap: 8px;
}

.p-inquiry-list__item__add i[class^="c-icon-"] {
  font-size: var(--text-xl-size);
}


@media screen and (max-width: 768px) {
  .p-inquiry-list__item {
    flex-direction: column;
  }
  .p-inquiry-list__item__ttl {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .p-inquiry-list__item__body {
    width: 100%;
  }
}


/*  placeholder
--------------------------------------------- */
::placeholder {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
}


/*  p-inquiry-acBox
--------------------------------------------- */
.p-inquiry-acBox__body__list:not(:first-of-type) {
  margin-top: 10px;
}
.p-inquiry-acBox__body__list__term span{
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
}
.p-inquiry-acBox__body__list__term p{
  font-size: var(--text-xs-size);
  font-weight: var(--text-normal);
  line-height: var(--text-xs-height);
  color: var(--gray500);
}
.p-inquiry-acBox__body__list__desc {
  margin-top: 4px;
}
.p-inquiry-acBox__body__list__desc__txt {
  color: var(--gray500);
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  margin-top: 7px;
  margin-bottom: 9px;
}

.p-inquiry-acBox {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  overflow: hidden;
}
.p-inquiry-acBox:not(:first-child) {
  margin-top: 30px;
}
.p-inquiry-acBox__header {
  width: 100%;
  border-radius: var(--radius-xl) var(--radius-xl) 0 0;
  background-color: var(--gray50);
  padding: 12px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.p-inquiry-acBox__header__ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-inquiry-acBox__header__ttl__ic {
  flex-shrink: 0;
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-inquiry-acBox__header__ttl__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-inquiry-acBox__header__ttl__ic i::before,
.p-inquiry-acBox__header__ttl__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-inquiry-acBox__header__ttl__txt {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray900);
}
.p-inquiry-acBox__header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.p-inquiry-acBox__header__actions > * {
  margin-left: 18px;
}
.p-inquiry-acBox__body {
  border-radius: 0 0 var(--radius-xl) var(--radius-xl);
  border-top: 1px solid var(--gray200);
  padding: 21px 23px;
  display: none;
}
.p-inquiry-acBox__body.is-active {
  display: block;
}

.p-inquiry-acBox__body .p-inquiry-acBox {
  margin-top: 14px;
}
@media screen and (max-width: 768px) {
  .p-inquiry-acBox__header__actions a > p {
    display: none;
  }
}


/*  p-products__fixed
--------------------------------------------- */
.p-products__fixed {
  width: 100%;
  height: 80px;
  background-color: var(--white);
  box-shadow: 0 -4px 4px -2px rgba(16, 24, 40, 0.06), 0 -4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 0 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: -80px;
  opacity: 0;
  right: 0;
  z-index: 10;
  transition: bottom 0.2s linear;
}
.p-products__fixed.is-active {
  bottom: 0;
  opacity: 1;
}
.p-products__fixed__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.p-products__fixed__actions > *:not(:last-child) {
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  .p-products__fixed {
    width: 100% !important;
    padding: 0 16px;
  }
  .p-products__fixed__actions {
    width: 100%;
  }
  .p-products__fixed__actions > *:first-child {
    margin-left: auto;
  }
}

/*  c-customize-tableSmall__spTtl__icWrap
--------------------------------------------- */
.c-customize-tableSmall__spTtl__icWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-customize-tableSmall__spTtl__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.c-customize-tableSmall__spTtl__ic i {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-customize-tableSmall__spTtl__ic i::before,
.c-customize-tableSmall__spTtl__ic i::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}

/*  p-bookingWidgetCustomize-box-wh
--------------------------------------------- */
.p-bookingWidgetCustomize-box-wh {
  width: 100%;
  border-radius: var(--radius-2xl);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px;
}

/*  p-bookingWidgetCustomize-box
--------------------------------------------- */
.p-bookingWidgetCustomize-box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--gray50);
  padding: 16px;
}
.p-bookingWidgetCustomize-box.padding-0 {
  padding: 0;
}
.p-bookingWidgetCustomize-box:not(:first-child) {
  margin-top: 16px;
}
.p-bookingWidgetCustomize-box__header {
  font-size: var(--text-sm-size);
  font-weight: var(--text-medium);
  line-height: var(--text-sm-height);
}
.p-bookingWidgetCustomize-box__table {
  width: 100%;
  display: table;
  margin-top: 16px;
}
.p-bookingWidgetCustomize-box__table__header {
  display: table-row;
}
.p-bookingWidgetCustomize-box__table__body {
  display: table-row;
}
.p-bookingWidgetCustomize-box__table__item {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 8px;
}
.p-bookingWidgetCustomize-box__table__item:not(:last-child) {
  padding-right: 12px;
}
.p-bookingWidgetCustomize-box__table__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
}
.p-bookingWidgetCustomize-box__table__item__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-bookingWidgetCustomize-box__table__item__flex > * {
  flex-shrink: 1;
}
.p-bookingWidgetCustomize-box__table__item__flex > span {
  padding: 0 8px;
}
.p-bookingWidgetCustomize-box__table__add {
  width: max-content;
  color: var(--primary700);
  font-size: var(--text-xs-size);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
}
.p-bookingWidgetCustomize-box__table__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
.p-bookingWidgetCustomize-box__table2 {

}
.p-bookingWidgetCustomize-box__table2__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid var(--gray200);
}
.p-bookingWidgetCustomize-box__table2__header > p {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  padding-right: 16px;
}
.p-bookingWidgetCustomize-box__table2__body {
  border-bottom: 1px solid var(--gray200);
  padding: 16px;
}
.p-bookingWidgetCustomize-box__table2__body__item {
  width: 100%;
}
.p-bookingWidgetCustomize-box__table2__body__item:not(:first-child) {
  margin-top: 16px;
}
.p-bookingWidgetCustomize-box__table2__body__item.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.p-bookingWidgetCustomize-box__table2__body__item.flex > div {
  padding-right: 16px;
  flex-shrink: 0;
}
.p-bookingWidgetCustomize-box__table2__body__item.flex > div.right {
  width: 100%;
  flex-shrink: 1;
  padding-right: 0;
}
.p-bookingWidgetCustomize-box__table2__body__item.flex > div.right > * {
  margin-left: auto;
}
.p-bookingWidgetCustomize-box__table2__body__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-bottom: 4px;
}
.p-bookingWidgetCustomize-box__table2__body__item__body {

}
.p-bookingWidgetCustomize-box__table2__body__item__body.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-bookingWidgetCustomize-box__table2__body__item__body.flex > *:not(:last-child) {
  margin-right: 8px;
}
.p-bookingWidgetCustomize-box__table2__body__item__body.flex > div {
  flex-shrink: 0;
}
.p-bookingWidgetCustomize-box__table2__body__item__box {
  width: 100%;
  border-radius: var(--radius-md);
  border: 1px solid var(--gray200);
  background-color: var(--white);
  padding: 16px;
}
.p-bookingWidgetCustomize-box__table2__body__item__box__table {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.p-bookingWidgetCustomize-box__table2__body__item__box__table__header,
.p-bookingWidgetCustomize-box__table2__body__item__box__table__body {
  display: table-row;
}
.p-bookingWidgetCustomize-box__table2__body__item__box__table__item {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 8px;
}
.p-bookingWidgetCustomize-box__table2__body__item__box__table__item:not(:last-child) {
  padding-right: 12px;
}
.p-bookingWidgetCustomize-box__table2__body__item__box__table__item__ttl {
  font-size: var(--text-xs-size);
  font-weight: var(--text-medium);
  line-height: var(--text-xs-height);
  color: var(--gray600);
  margin-bottom: 4px;
}

.p-bookingWidgetCustomize-box__table2__actions {
  padding: 16px;
}
.p-bookingWidgetCustomize-box__table2__actions__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-bookingWidgetCustomize-box__table2__actions__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
@media screen and (max-width: 768px) {
  .p-bookingWidgetCustomize-box__table {
    display: block;
  }
  .p-bookingWidgetCustomize-box__table__body {
    width: 100%;
    border-radius: var(--radius-md);
    border: 1px solid var(--gray200);
    background-color: var(--white);
    padding: 16px;
    margin-bottom: 16px;
    display: block;
  }
  .p-bookingWidgetCustomize-box__table__item {
    display: block;
    padding-bottom: 0;
  }
  .p-bookingWidgetCustomize-box__table__item:not(:first-child) {
    margin-top: 16px;
  }
  .p-bookingWidgetCustomize-box__table__item:not(:last-child) {
    padding-right: 0;
  }
  .p-bookingWidgetCustomize-box__table__item__ttl {
    margin-bottom: 6px;
  }
  .p-bookingWidgetCustomize-box__table2__body__item.flex {
    flex-direction: column;
  }
  .p-bookingWidgetCustomize-box__table2__body__item.flex > div {
    width: 100%;
    padding-right: 0;
  }
  .p-bookingWidgetCustomize-box__table2__body__item.flex > div:not(:first-child) {
    margin-top: 16px;
  }
  .p-bookingWidgetCustomize-box__table2__body__item__body.spStock {
    flex-direction: column;
  }
  .p-bookingWidgetCustomize-box__table2__body__item__body.spStock fieldset {
    width: 100% !important;
  }
  .p-bookingWidgetCustomize-box__table2__body__item__body.spStock > div {
    width: 100%;
    margin-top: 8px;
    margin-right: 0 !important;
  }
  .p-bookingWidgetCustomize-box__table2__body__item__box__table {
    display: block;
  }
  .p-bookingWidgetCustomize-box__table2__body__item__box__table__header,
  .p-bookingWidgetCustomize-box__table2__body__item__box__table__body {
    display: block;
  }
  .p-bookingWidgetCustomize-box__table2__body__item__box__table__item {
    display: block;
    padding-right: 0 !important;
  }
}


.p-bookingWidgetCustomize-box__list {
  /*margin-top: 16px;*/
}
.p-bookingWidgetCustomize-box__list__item {

}
.p-bookingWidgetCustomize-box__list__item:not(:first-child) {
  margin-top: 12px;
}
.p-bookingWidgetCustomize-box__list__item__ttl {
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-bottom: 6px;
}
.p-bookingWidgetCustomize-products__section__list__term span{
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
  color: var(--gray700);
}
.p-bookingWidgetCustomize-products__section__list__term span:not(:first-of-type) {
  display: block;
  margin-top: 16px;
}
.p-bookingWidgetCustomize-products__section__list__desc {
  margin-top: 5px;
}
.p-bookingWidgetCustomize-box__list__item__body__flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-bookingWidgetCustomize-box__list__item__body__flex.wrap {
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.p-bookingWidgetCustomize-box__list__item__body__flex.wrap > * {
  margin-bottom: 12px;
}
.p-bookingWidgetCustomize-box__list__item__body__flex.full {
  width: 100%;
}
.p-bookingWidgetCustomize-box__list__item__body__flex.full > * {
  width: 100%;
  flex-shrink: 1;
}
.p-bookingWidgetCustomize-box__list__item__body__flex:not(:first-child) {
  margin-top: 12px;
}
.p-bookingWidgetCustomize-box__list__item__body__flex__main {
  width: 100%;
  margin-right: 12px;
}
.p-bookingWidgetCustomize-box__list__item__body__flex__actions {
  flex-shrink: 0;
}
.p-bookingWidgetCustomize-box__list__item__body__flex > *:not(.p-bookingWidgetCustomize-box__list__item__body__flex__main),
.p-bookingWidgetCustomize-box__list__item__body__flex > *:not(.p-bookingWidgetCustomize-box__list__item__body__flex__actions) {
  margin-right: 12px;
}
.p-bookingWidgetCustomize-box__list__item__body__flex > *:last-child {
  margin-right: 0;
}

.p-bookingWidgetCustomize-box__list__item__body__add {
  width: max-content;
  color: var(--primary700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.p-bookingWidgetCustomize-box__list__item__body__add i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}
@media screen and (max-width: 768px) {
  .p-bookingWidgetCustomize-box__list__item__body__flex.full {
    flex-direction: column;
    margin-top: 16px;
  }
  .p-bookingWidgetCustomize-box__list__item__body__flex.full > * {
    margin-right: 0;
  }
  .p-bookingWidgetCustomize-box__list__item__body__flex.full > *:not(:first-child) {
    margin-top: 16px;
  }
}

.p-bookingWidgetCustomize-box__nomalFlex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.p-bookingWidgetCustomize-box__nomalFlex > div {
  flex-shrink: 0;
}
.p-bookingWidgetCustomize-box__nomalFlex > div:not(:last-child) {
  margin-right: 32px;
}
@media screen and (max-width: 768px) {
  .p-bookingWidgetCustomize-box__nomalFlex {
    flex-direction: column;
  }
  .p-bookingWidgetCustomize-box__nomalFlex > div {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 0 !important;
  }
}



/*  dropdown(3点リーダー有)
--------------------------------------------- */
/* dropdown */
.p-reports-dropdown {
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  position: relative;
}
.p-reports-dropdown__body {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.p-reports-dropdown__body::before {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  font-family: 'icon-arrows-solid' !important;
  font-style: normal;
  color: var(--primary700);
  content: "\b450";
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}

.p-reports-dropdown__spTtl__icWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.p-reports-dropdown__spTtl__ic {
  width: 20px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 3px 5px;
  margin-right: 8px;
  cursor: all-scroll;
}
.p-reports-dropdown__spTtl__ic p {
  width: 10px;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-reports-dropdown__spTtl__ic p::before,
.p-reports-dropdown__spTtl__ic p::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--gray500);
}
.p-reports-dropdown__body > i {
  width: 20px;
  height: 20px;
  text-align: center;
  flex-shrink: 0;
  color: var(--gray500);
  margin-right: 8px;
}
.p-reports-dropdown__body > i::before {
  line-height: 20px;
}
.p-reports-dropdown__body > figure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
}
.p-reports-dropdown__body label {
  width: 100%;
  position: relative;
}
.p-reports-dropdown__body__title {
  width: 100%;
  height: 100%;
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray400);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.1s linear;
}
.p-reports-dropdown__body__selected__input {
  /* width: fit-content; */
  display: none;
}

.p-reports-dropdown__body__selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.p-reports-dropdown__body__selected ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -4px;
}
.p-reports-dropdown__body__selected ul li {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  background-color: var(--gray100);
  border-radius: var(--radius-sm);
  padding: 3px 4px 3px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-reports-dropdown__body__selected ul li i {
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.p-reports-dropdown__body__selected ul li i::before {
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  color: var(--gray400);
  content: "\e9c8";
}

.p-reports-dropdown.is-hover {
  border-color: var(--primary200);
}
.p-reports-dropdown.is-active {
  border-color: var(--primary700);
}
.p-reports-dropdown.is-input .p-reports-dropdown__body__title {
  width: fit-content;
  font-size: 10px;
  line-height: 10px;
  height: 10px;
  border-color: var(--gray700);
  background-color: var(--white);
  padding: 0 2px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
}
.p-reports-dropdown.is-active .p-reports-dropdown__body__title {
  color: var(--primary700);
}

.p-reports-dropdown__desc {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
  color: var(--gray600);
  margin-top: 6px;
}

.p-reports-dropdown__menu {
  width: 100%;
  min-width: 160px;
  max-height: 360px;
  border: 1px solid var(--gray200);
  border-radius: var(--radius-md);
  background-color: var(--white);
  box-shadow: var(--shadow-lg);
  padding: 6px;
  position: absolute;
  left: 0;
  margin-top: 4px;
  z-index: 20;
  display: none;
  overflow-y: auto;
}
.p-reports-dropdown__menu.overflow-no {
  overflow-y: visible;
}
.p-reports-dropdown__menu.is-active {
  display: block;
}
.p-reports-dropdown__menu__item {
  padding: 8px 10px;
  position: relative;
}
.p-reports-dropdown__menu__item:hover {
  background-color: var(--gray50);
}
.p-reports-dropdown__menu__item input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.p-reports-dropdown__menu__item i {
  opacity: 0;
}
.p-reports-dropdown__menu__item input:checked + i {
  opacity: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'icon-general-solid' !important;
  font-style: normal;
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
}
.p-reports-dropdown__menu__item input:checked + i::before {
  color: var(--primary600);
  content: "\e914";
}
.p-reports-dropdown__menu__item p {
  font-size: var(--text-base-size);
  line-height: var(--text-base-height);
  text-align: left;
}
.p-reports-dropdown__menu__item p span {
  font-size: var(--text-sm-size);
  line-height: var(--text-base-height);
  color: var(--gray600);
  margin-left: 8px;
}

/* override */
.p-reports-dropdown__body__selected ul li span{
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.p-reports-dropdown__body {
  padding: 8px 42px 8px 12px;
  flex-direction: column;
}

.p-bookingWidgetCustomize-products-list__color {
  padding: 10px 12px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  -ms-align-items: center;
  align-items: center;
  gap: 16px;
  border-radius: var(--radius-md);
  background-color: var(--white) !important;
  border: 1px solid var(--gray200);
}

.p-bookingWidgetCustomize-products-list__color:hover {
  background-color: var(--gray50);
  transition: all 200ms linear;
}

.p-bookingWidgetCustomize-products-list__color span i{
  margin-right: 4px;
}

.p-bookingWidgetCustomize-products-list__color-box {
  display: block;
  width: 24px;
  aspect-ratio: 1/1;
  background-color: var(--white);
  border-radius: var(--radius-xs);
}

/*
.p-bookingWidgetCustomize-products-list__color-box {
  display: block;
  width: 24px;
  aspect-ratio: 1/1;
  background-color: var(--white);
  border-radius: var(--radius-xs);
  flex-shrink: 0;
}
*/

.p-bookingWidgetCustomize-products-list__color i {
  font-family: 'icon-general-outline' !important;
  font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.p-bookingWidgetCustomize-products-list__color i:before {
  content: "\c855";
  font-size: 16px;
}

/*  p-previewBtn
--------------------------------------------- */
.p-previewBtn {
  height: 48px;
  font-size: var(--text-base-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-base-height);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-previewBtn:hover {
  background-color: var(--primary700);
}
.p-previewBtn.large {
  width: 240px;
}
.p-previewBtn.narrow {
  width: 128px;
}
.p-previewBtn.guide {
  width: 160px;
  height: 32px;
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}
.p-previewBtn.guide img {
  height: 20px;
  margin-right: 8px;
}

.code-block {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  white-space: pre-wrap;
  word-break: break-all;
  display: inline-block;
}

.form-title {
  color: var(--gray700);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  line-height: var(--text-sm-height);
}

.form-body {
  margin-right: 15px;
}

.add__button {
  width: max-content;
  color: var(--primary700);
  font-size: var(--text-sm-size);
  font-weight: var(--text-semibold);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
}
.add__button i {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 8px;
}

.company__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.company__item__box {
  width: 100%;
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.button__setting__item__box {
  width: 100%;
  display: flex;
  background-color: var(--gray50);
  border: 1px solid var(--gray200);
  border-radius: var(--radius-xl);
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.button__preview__box {
  width: 100%;
  background-color: var(--gray50);
  border-radius: var(--radius-xl);
  border: 1px solid var(--gray200);
  padding: 15px;
  margin-bottom: 10px;
}

.color_section {
  width: 100%;
}
.color_section__a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  background-color: var(--white) !important;
}
