.c-tooltip {
  padding: 12px;
  background-color: var(--white);
  border-color: var(--gray300);
  border-width: 1px;
  border-style: solid;
  border-radius: var(--radius-sm);
  color: #71717A;
}
.c-tooltip__header {
  font-size: var(--text-sm-size);
  margin-bottom: 8px;
}
.c-tooltip__header__ttl {
  margin: 0;
}
.c-tooltip__body {
  font-size: var(--text-sm-size);
  line-height: var(--text-sm-height);
}
.c-tooltip__body__item {
  margin: 0 !important;
}
